.container {
  position: relative;
  background-image: url('https://d3izcfql7lesks.cloudfront.net/shutterstock_1065510131_bgagx5.png');
  height: 10vh !important;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 350px;
  padding-left: 200px;
  padding-bottom: 400px;
}

.bannerImage {
  position: absolute;
}

.card {
  width: 420px;
  height: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 20px;
  padding: 50px;
}

.giftButton {
  border: none;
  background-color: black;
  color: white;
  margin-top: 10px;
  padding: 5px 10px 5px 10px;
  border-radius: 30px;
}
@media only screen and (max-width: 500px) {
  .card {
    margin: 0px !important;
    padding-left: 55px;
    justify-content: center;
  }
  .tgf {
    margin-left: 20px;
  }
  .container {
    background: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/UI+images/shutterstock_1065510131_bgagx5.png')
      no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: black;
    opacity: 0.9;
  }
}
