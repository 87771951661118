.container {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2947_rqo8h9.png');
  background-size: 100%;
  background-repeat: no-repeat;
}

.container2 {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2948_f7urh6.png');
  background-size: 100%;
  margin-top: 80px;
  background-repeat: none !important;
}
.container2 div {
  padding-top: 200px;
  padding-bottom: 200px;
  padding-left: 100px;
}

.container3 {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2948_ldtipl.png');
  background-size: 100%;
}

.cardHover {
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  /* transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12); */
  cursor: pointer;
}

.cardHover:hover {
  /* transform: scale(1.1); */
  transform: scale(1.2);
  text-decoration: underline;
  filter: drop-shadow(0px 3px 6px rgb(0, 0, 0, 16%));
}
.pagination {
  margin: 0px;
  float: right;
}
.pagesPart {
  float: right;
  width: 100%;
  margin-right: 10%;
}
.pagination .page-item .page-link {
  min-width: 23px !important;
}
.reviewHeader {
  font-size: 35px;
  margin-bottom: 14px;
}
.subText {
  color: #101010;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.viewProject {
  font-size: 15px;
  margin-left: 5px;
}
.reviewTitle {
  font-size: 15px;
}
.name {
  font-weight: 500;
  margin-left: -3px;
}
.cardContent {
  margin-top: 36px;
}
.spaceTxt {
  margin-bottom: 42px;
}
.packageTxt {
  font-weight: 500;
  font-size: 13px;
  color: #5e605f;
  margin-top: 25px;
}
.reviewContainer {
  width: 500px;
  height: auto;
  background-color: #fafafa;
  border-radius: 10px;
  margin: 20px;
  padding: 20px 30px;
}
.sort {
  font-weight: 600;
  font-size: 16px;
  margin: 0px;
  margin-right: 11px;
}
.selectSort {
  width: 123px;
  border-radius: 5px;
  border: 0.5px solid #242424;
  color: rgb(0, 0, 0);
  font-size: 14px;
  height: 30px;
}
select:focus {
  outline: none;
  border: 0.5px solid #242424;
}
.designButton {
  border-radius: 5vw;
  padding: 7px 28px;
  color: #f5f0ec;
  background-color: black;
  font-weight: 400;
  font-size: 14px;
}
.wholeReview {
  /* padding-top: 200px;
    padding-bottom: 200px; 
    padding-left: 100px; */
  padding-top: 200px;
  height: 500px;
  width: 70%;
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
}
/*.subContainer2{
    background-image:none;
}*/
.mw {
  max-width: 90%;
}
.container3 div {
  padding-top: 300px;
  padding-bottom: 200px;
  padding-left: 100px;
}
.dropdown button {
  background-color: white !important;
  color: black !important;
  border-color: #242424 !important;
  border-radius: 3px;
}

.dropdown-item .show {
  width: 50% !important;
}
.pagination span {
  border-color: white !important;
  background-color: white !important;
  color: black !important;
}
.pagination a {
  border-color: white !important;
  background-color: white !important;
  color: black !important;
}
.coloums {
}
@media only screen and (max-width: 600px) {
  .container2 {
    background-image: none;
    margin-top: 0px;
  }
  .container2 div {
    padding-top: 30px;
    padding-bottom: 0px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .subContainer2 {
    background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/UI+images/');
  }
  .container3 {
    background-image: none;
  }
  .container3 div {
    padding-top: 30px;
    padding-bottom: 0px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .imageBanner {
    width: 100%;
    height: 314px;
    object-fit: cover;
  }
  .wholeBannerPart {
    position: relative;
  }
  .wholeTxtPart {
    position: absolute;
    top: 34%;
    padding: 7px 22px;
    bottom: 0px;
    height: auto;
  }
  .reviewHeader {
    font-size: 22px;
    margin-bottom: 14px;
  }
  .subText {
    font-size: 13px;
    line-height: 17px;
  }
  .columns {
    width: 100%;
  }
  .waveImg {
    width: 25px !important;
  }
  .reviewContainer {
    padding: 20px 25px;
  }
  .viewProject {
    font-size: 11px;
  }
  .reviewContainer span {
    font-size: 12px;
  }
  .spaceTxt {
    margin-bottom: 42px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .mw {
    margin-top: 0px !important;
  }
  .Review_container2__1ztUy div {
    padding-bottom: 150px;
  }
}
