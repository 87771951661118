/* ------------------------------------------------------- */
.cancleItemCard {
  padding: 10px;
  background-color: #ffffff;
  position: relative;
  margin-top: 0;
}
.page__header {
  margin: 50px 0px;
  font-size: 30px;
  font-weight: 500;
}
.order__tab__active {
  border-bottom: 3px solid #5f5d5d;
}
.order__tab {
  border-bottom: 1px solid #7372726b;
}
.order__tab > div {
  margin-right: 20px;
  font-weight: 400;
}
.cancelContainer {
  background-color: white;
  max-width: 85%;
}
.cancelBody {
  background-color: white;
}
.bordered {
  border: 1px solid;
}

.cancelBody1 {
  background-color: white;
  color: black;
  padding: 15px 15px 15px 15px;
}
.select {
  border: none;
  border-bottom: 1px solid #a8a8a8;
}
.vl {
  border-left: 2px solid grey;
  height: 10px;
}
.textBox {
  height: 100px;
  width: 100%;
  border: 1px solid #a8a8a8;
  border-radius: 5px;
  padding: 5px;
}

.textBox2 {
  height: 150px;
  width: 100%;
}
.textBox3 {
  height: 85px;
  width: 100%;
}

.headerCancelation {
  background-color: #141414;
  padding: 15px 15px 15px 15px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.header {
  background-color: #141414;
  /* height: 67px; */
  color: #ffffff;
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
}

.order__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.order__actions {
  display: flex;
}
.order__actions > div:first-child {
  margin-right: 20px;
}
.order__actions > div > span {
  border-bottom: 1px solid black;
}
.order__container {
  margin: 10px 0px;
}
/* p{
    text-align: center;
    letter-spacing: NaNpx;
    color: #020202;
    opacity: 1;
  } */

.MuiDrawer-paper {
  width: 50%;
  padding: 20px;
}

.drawer1Title {
  font-size: 16px;
  font-weight: 800;
  font-family: 'Quicksand';
}

.drawer1Content {
  font-size: 15px;
  font-weight: 600;
  font-family: 'Quicksand';
}

.continueButton {
  background-color: black;
  color: white;
  font-weight: 400;
  height: 50px !important;
  padding: 4px 20px 4px 20px;
  border: none;
  font-size: small;
  cursor: pointer;
}

.modalRow {
  /* height: 399px;
  max-height: 100%; */
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
  background-color: #f4f4f4;
}

.modalHeader {
  background-color: #d0ecf4;
  height: 67px;
  color: black;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.changeButton {
  color: black;
  background-color: white;
  font-weight: 400;
  font-size: small;
  padding: 4px 20px 4px 20px;
  border: 1px solid;
  cursor: pointer;
}
.container-order {
  text-align: center;
  padding: 20px;

  background-color: white;
  max-width: 40% !important;

  border-radius: 20px;
  padding-left: 50px;
  padding-right: 50px;
}
.getStartedButton2 {
  padding: 18px 35px 18px 35px;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .container-order {
    text-align: center;
    min-width: 90% !important;
    max-width: 90% !important;
    background-color: white;
    margin-top: 70px;
  }

  .cancleItemCard {
    margin-top: 1rem;
  }
  .thanks {
    font-size: 20px;
    padding-left: 1%;

    text-align: center;
  }
  .email {
    font-size: 14px;
    padding-left: 1%;

    text-align: center;
  }
  .padd {
    width: 30px;
  }
}

/* span{
    text-decoration: underline;
    
    
  } */
.getStartedButton2 {
  border-radius: 30px;
  background-color: black;
  color: white;
  font-weight: 700;
  font-size: 15px;
  height: 60px !important;
  /* padding: 7px 25px 12px 25px; */
  border: none;
  cursor: pointer;
}
.continueButton {
  background-color: black;
  color: white;
  font-weight: 400;
  height: 50px !important;
  padding: 4px 20px 4px 20px;
  border: none;
  cursor: pointer;
}

.modalRow {
  /* height: 399px;
  max-height: 100%; */
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
  background-color: #f4f4f4;
}

.modalHeader {
  background-color: #d0ecf4;
  height: 67px;
  color: black;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.changeButton {
  color: black;
  background-color: white;
  font-weight: 400;
  padding: 4px 20px 4px 20px;
  border: 1px solid;
  cursor: pointer;
}
.getStartedButton1 {
  border-radius: 30px;
  background-color: black;
  color: white;
  margin-top: 5px;
  width: 220px;
  height: 60px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-21) / var(--unnamed-line-spacing-30)
    var(--unnamed-font-family-quicksand);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 21px/30px Quicksand;
  letter-spacing: 0px;
  color: #f5f0ec;
  opacity: 1;
  margin-left: 20px;
  border: none;
  cursor: pointer;
}
.order__items {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background: white;
  border-bottom: 0.5px solid #7372726b;
}
.order__content {
  background: white;
  margin: 0;
  padding: 20px;
}
.order__content1 {
  background: white;
  margin: 0;
}
.order__name {
  color: #d02424;
  font-size: 19px;
  font-weight: 900;
}
.order__desc {
  font-size: 15px;
  font-weight: 800;
}
.order__sold {
  font-size: 15px;
  font-weight: 800;
  margin: 5px 0;
}
.order__price {
  font-size: 15px;
  font-weight: 800;
  font-family: 'QuickSand';
}
.order__id {
  margin-top: 20px;
  font-size: 12px;
  color: #767676;
  font-weight: 400;
}

.order__act__btns {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
}
.drawer1 {
  /* padding: 20px;
  font-size: 11px;
  font-weight: 800;
  font-family: "Quicksand"; */
  background-color: #2c2c2c;
}
.MuiDrawer-paper {
  padding: 20px;
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 14%);
  -webkit-tap-highlight-color: transparent;
}

.borderBottom {
  border-bottom: 2px solid grey;
  /* height: 10px; */
}
.order__act__btn1 {
  width: 100%;
  background: black;
  color: white;
  padding: 6px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px;
}
.order__act__btn2 {
  width: 100%;
  color: black;
  border: 1px solid;
  background: white;
  padding: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
}
.black {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_3158_wxbffq.png');
  color: white;
  height: 200px;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
}
.black1 {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2952_2x_benqqj.png');
  color: black;
  height: 250px;
  text-align: center;
  padding-top: 80px;
  font-size: 16px;
  font-weight: 800;
}
/* h3{
    text-align: center;
font: normal normal medium 25px/35px Quicksand;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
  }
  h6{
    text-align: center;
font: normal normal normal 20px/55px Quicksand;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
  } */
.home-card-view {
  height: 500px;
}
.will {
  background: black;
  color: white;

  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
}
.padd {
  padding-top: 40px;
  padding-bottom: 10px;
}
.order__act__btn {
  padding: 6px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  border: 1px solid #1a1818;
  margin-top: 10px;
  border-radius: 2px;
}
/* -------------------------------------------------------- */

@media only screen and (max-width: 500px) {
  .page__header {
    font-size: 20px;
  }
  .order__tab {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
  /* .order__items {
    font-size: 9px;
  }
  .order__name {
    font-size: 10px;
  } */
  .order__desc,
  .order__sold,
  .order__price {
    font-size: 14px;
    font-family: 'Quicksand';
  }
  .order__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .order__id {
    font-size: 12px;
    color: #767676;
  }
  .order__act__btns,
  .order__act__btn1,
  .order__act__btn {
    font-size: 14px;
  }
  .MuiDrawer-paper {
    width: 75%;
    padding: 20px;
  }
  .drawer1Title {
    font-size: 14px;
    font-weight: 800;
    font-family: 'Quicksand';
  }
  .drawer1Content {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Quicksand';
  }
}
