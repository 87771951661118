.Questionaire2Container {
  position: relative;
  top: 0;
  left: 0;
  font-family: 'Quicksand';
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
  height: '100%';
}

.header {
  /* font-weight: 100; */
  padding-left: 150px;
}

.mesonryDesign {
  width: 42% !important;
}
.mesonryStage {
  width: 60% !important;
}

.mesonryRent {
  width: 60% !important;
}

@media only screen and (max-width: 1600px) {
  .mesonryStage {
    width: 80% !important;
  }

  .mesonryDesign {
    width: 60% !important;
  }
}

@media only screen and (max-width: 900px) {
  .mesonryStage {
    width: 100% !important;
  }

  .mesonryDesign {
    width: 100% !important;
  }
}

.designIconContainer {
  width: 145px;
  height: 145px;
  position: relative;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.designIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.locationSelect {
  width: 200px;
  border-radius: 20px;
  border-style: solid;
  border-color: #2424245e;
  padding: 5px;
  margin: auto;
  padding-right: 20px;
  margin-top: 30px;
  font-size: 14px;
  color: black;
  padding-inline-end: inherit;
}
.locationSelect option {
  position: relative;
}

.roomInput {
  width: 200px;
  border-radius: 20px;
  border-style: solid;
  border-color: #2424245e;
  padding: 4px 20px;
  padding-right: 20px;
  /* margin-left: -23vw; */
  margin-top: 5vh;
  background-color: white;
}
.designClick {
  position: absolute;
  z-index: 1;
  top: 5%;
  right: 17%;
}

.roomDesignClick {
  position: absolute;
  z-index: 1;
  /* top: 5%;
    right: 10%; */
}

.designIconContainerActive {
  background-color: #e5f9ff;
  border: 1px solid #2b95b3;
}

.designIconContainer:hover {
  background-color: #e5f9ff;
}

.height {
  height: 150px;
}

@media only screen and (max-width: 500px) {
  .header {
    width: 80%;
    font-size: 20px;
    padding-left: 0px;
  }
  .headerContainer {
    margin-top: 2vh !important;
    height: 100px !important;
  }
  .mesonryDesign {
    height: 100%;
    margin-top: 5vh;
    width: 95%;
  }
  .mesonryRent {
    height: 100%;
    margin-top: 5vh;
    width: 90% !important;
  }
  .locationContainer {
    height: 100%;
    margin-top: 0px !important;
    width: 95%;
  }
  .locationSelect {
    padding: 8px 11px;
    margin: auto;
    /* padding-right: 20px; */
    margin-top: 30px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 11px;
  }
  .height {
    height: 100px;
  }
  .mesonryDesign p {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .mesonryStage p {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .mesonryStage span {
    margin-bottom: 20px;
  }
  .mesonryStage {
    padding: 0px 16px;
  }
}

@media only screen and (max-width: 500px) {
  .header {
    width: 90%;
    font-size: 18px;
    padding-left: 0px;
    font-weight: 500;
  }
  .mesonryDesign {
    height: 100%;
    margin-top: 0px;
    width: 100% !important;
    padding: 0px 20px;
  }
  .mesonryRent {
    height: 100%;
    margin-top: 5vh;
    width: 90% !important;
  }
  .locationContainer {
    height: 100%;
    margin-top: 5vh;
    width: 95%;
  }
  .locationSelect {
    width: 100%;
  }

  .height {
    height: 50px;
  }
  .headerContainer {
    height: 100px !important;
  }
  .designIconContainer {
    width: 115px;
    height: 115px;
  }
  .roomInput {
    margin-top: 1vh;
    margin-bottom: 5vh;
  }
}

@media only screen and (max-width: 1000px) {
  .imageContainer {
    height: 100% !important;
    width: 100% !important;
  }
  .headerContainer {
    height: 150px;
  }
}
