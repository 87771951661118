.image_gallery_thumbnail:hover {
    border: 2px solid black !important;
}
.image_gallery_thumbnail.active,
.image_gallery_thumbnail:focus {
    border: 2px solid black !important;
}
.image-gallery-slide.center {
    position: relative;
    object-fit: contain !important;
}

.breadCrumbs{
    padding: 10px;
    position: relative;
    display: flex;
    margin: 0px auto;
    width: 80%;
    margin-top: 20px;
    flex-direction: row;
    gap: 2px;
    padding-left: 5px;
    cursor: pointer;
}
.productWeb{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: max-content;
    padding: 4px 4px 4px 4px;
}
.productBodyWeb{
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 80%;
    height: max-content;
    margin: 0px auto;
    flex-direction: row;
    top: 20px;
    gap: 10px;
    bottom: 10px;
}
.productDescriptionContainer{
    position: relative;
    margin: 0px auto;
    width: 80%;
    padding: 20px 0px 0px 0px;
}
.productImagesWeb{
    position: relative;
    padding: 0px 4px 4px 4px;
    height: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: scroll;
    width: max-content;
}
.variantImageWeb{
    position: relative;
    width: 100px;
    height: 100px;
}
.image_gallery_web{
    position: relative;
    padding: 0px 4px 4px 4px;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: scroll;
    width: max-content;
}

.ImageWeb{
    width: 35%;
    /* width: 500px; */
    height: 500px;
    background-color: white;
}
.Image{
    position: relative;
    height: 500px;
}
.renderImage{
    position: relative;
    width: 100% !important;
    height: 500px !important;
}
.arImageWeb{
    position: relative;
    padding: 4px 4px 4px 4px;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    bottom: 0;
    object-fit: contain;
}
.arIconWeb:hover {
    border: 2px solid black !important;
  }
  .arIconWeb.active,
  .arIconWeb:focus {
    border: 2px solid black !important;
  }
.arIconWeb{
    position: relative;
    width: 100px;
    height: 100px;
    bottom: 0;
    background-color: #FFFFFF;
    object-fit: contain;
}
.productDetailsLeft{
    position: relative;
    display: flex;
    max-width: 40%;
    padding: 2px 2px 2px 2px;
    flex-direction: column;
    gap: 10px;
}
.productNameWeb{
    position: relative;
    width: 100%;
    font-size: larger;
    font-weight: bold;
}
.vendorNameWeb{
    position: relative;
    color: #E39625;
    font-weight: bold;
    font-size: 18px;
    text-decoration: underline;
}
.vendorNameWeb:hover{
    color: #E39625;
}
.pricesWeb{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.productDescriptionWeb{
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
    height: auto;
}
.productDescriptionWeb .description{
    margin: 0;
    padding: 0;
}
.productDescriptionWeb .readmore{
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    color: lightblue;
}
.productVariantsWebList{
    position: relative;
    display: flex;
    padding: 2px 2px 2px 2px;
    flex-direction: row;
    gap: 20px;
    max-width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    height: max-content;
}
/* width */
::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
    background: #e5dcd5;
}
  
  /* Track */
::-webkit-scrollbar-track {
    border-radius: 50vh;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #e5cdb9;
    border-radius: 50vh;
}
.buttonsWeb{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.addWeb{
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    padding-right: 40px;
    border: 2px solid black;
    background-color: white;
    font-size: large;
    border-radius: 50vh;
}
.buyWeb{
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: black;
    font-size: large;
    color: white;
    border-radius: 50vh;
}
.productDetailsRight{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 18%;
    background-color: #FFFFFF;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
}
.shareWeb{
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 4px 4px 4px 12px;
    align-items: center;
    background-color: #F1EEEA;
}
.shareWeb:hover{
    cursor: pointer;
}
.shareImage{
    position: relative;
    display: flex;
    align-items:end;
    justify-items:right;
    width: 20px;
    height: 20px;
}
.specifications{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 4px 4px 4px 4px;
    background-color: #F1EEEA;
}
.specHeading{
    position: relative;
    width: 100%;
    font-size: large;
    font-weight: 600;
    padding: 2px 2px 8px 10px;
}
.bottomBorder{
    position: relative;
    width: 100%;
    border-bottom: 1px solid lightgray;
}
.roomViewButtonWeb{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F1EEEA;
    width: 100%;
    padding: 4px 4px 4px 12px;
}
.vendorHouse{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: #F1EEEA;
    width: 100%;
    padding: 4px 4px 4px 2px;
}
.returnPolicy{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F1EEEA;
    width: 100%;
    padding: 4px 4px 4px 4px;
}
.returnPolicy:hover{
    cursor: pointer;
}
.variantsHeading{
    font-weight: 700;
    font-size:large;
    width: 80%;
    position:'relative';
    top:'5px';
    margin: 0px auto;
}
.variantsWeb{
    width: 80%;
    margin: 0px auto;
}
.completeRoomWeb{
    margin: 0px auto;
    width: 80%;
    display: flex;
    margin-bottom: 25px;
    flex-direction: row;
}
.roomImageContainerWeb{
    margin: auto;
    padding:6px;
    display: flex;
    flex-direction: column;
}
.roomImageWeb{
    margin: auto;
    /* width: 100%; */
    /* height: 100%; */
    border: 1px solid #DEE3EC;
    background-color: #ffffff;
    box-shadow: 0px 0px 3px 0px #00000014;
}
.roomList{
    position: relative;
    display: flex;
    margin: auto;
    width: 100%;
    flex-direction: row;
    gap: 10px;
    overflow: scroll;
}



.header{
    position: relative;
    height: 80px;
    display: flex;
    flex-direction: column;
}
.curations{
    position: relative;
    display: flex;
    flex-direction: row;
}
.productBody{
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 4px;
    padding-right: 8px;
    gap: 10px;
}
.path{
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    /* padding-left: 5px; */
    font-size: small;
}
.brandName{
    position: relative;
    font-weight: bold;
    width: 100%;
    text-decoration: underline;
    font-size: medium;
    cursor: pointer;
}
.arImage{
    position: relative;
    /* width: 100%; */
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    object-fit: contain;   
}
.arIcon{
    position: relative;
    width: 75px;
    height: 75px;
    bottom: 0;
}
.arMob{
    position: relative;
    animation: animate 1.5s alternate infinite ease-in;
}
@keyframes animate{
    0%{
        transform: scale(0.8);
    }
    100% {
        transform:scale(1.3) 
    }
}
.productImages{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: max-content;
    height: max-content;
}
.productImageBody{
    position: relative;
    height: max-content;
    display: flex;
    flex-direction: row;
    gap: 2px;
}
.image_gallery_thumbnails_container{
    position: relative;
    padding: 0px 4px 4px 4px;
    height: 220px !important;
    display: flex;
    flex-direction: column;
    gap: 2px;
    overflow-y: scroll;
    width: max-content;
}
.arButton{
    position: relative;
    /* display: flex; */
    bottom: 0;
}
.productVarients{
    position: relative;
    display: flex !important;
    gap: 8px;
    flex-direction: row;
    overflow-x: scroll;
    scroll-behavior:smooth;
}
.card{
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.varientImage{
    /* position: relative; */
    display: flex !important;
    flex: 0 0 auto;
    margin-right: 10px;
    width: 150px !important;
    height: 150px;
}
#image_gallery_content{
    position: relative;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.price{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.pixelprice{
    position: relative;
    font-weight: bold;
}
.roomtag{
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 10px;
}
.roomview{
    position: relative;
    font-weight: bold;
    text-decoration: underline;
}
.buttons{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 8px;
    padding-right: 8px;
}
.button{
    position: relative;
    padding: 10px 10px 10px 10px;
    border: 2px solid black;
    border-radius: 50vh;
    font-size: larger;
    font-weight: bold;
    background-color: white;
}
.buy{
    position: relative;
    padding: 10px 10px 10px 10px;
    border-radius: 50vh;
    background-color: black;
    font-size: larger;
    font-weight: bold;
    color: white;
}
.dropdowns{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.share{
    position: relative;
    display: flex;
    font-size: larger;
    padding-left: 2px;
    font-weight: bolder;
    flex-direction: row;
}
.share:hover{
    cursor: pointer;
}
.varientHeading{
    position: relative;
    display: flex;
    font-size: larger;
    padding-left: 2px;
    font-weight: bolder;
    flex-direction: row;
}
.variantDetails{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 4px 4px 4px 4px;
}
.varient_Image{
    position: relative;
    width: 100%;
    height: 240px;
}
.varientProductDetails{
    position: relative;
    background-color: #F1EEEA;
    padding: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.varientprices{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: large;
}
.completeRoom{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.roomHead{
    position: relative;
    display: flex;
    font-size: large;
    padding-left: 2px;
    font-weight: bold;
}
.roomImage{
    position: relative;
    width: 100%;
    height: 200px;
    background-color: white;
}
.relatedProducts{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.relatedProductsHead{
    position: relative;
    display: flex;
    font-size: large;
    padding-left: 2px;
    font-weight: bold;
}
.choose{
    position: relative;
    width: 100%;
    font-weight: bold;
}
.relatedProductDetails{
    position: relative;
    background-color: #F1EEEA;
    padding: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
@media (max-width: 640px) {
    .accordion-item .accordion-collapse {
      display: none;
    }
    .completeRoomWeb{
        margin: 0px auto;
        margin-bottom: 25px;
        width: 100%;
    }
    .variantsHeading{
        font-weight: 700;
        font-size:large;
        width: 100%;
        position:'relative';
        top:'5px';
        margin: 0px auto;
    }
    .productNameMob{
        position: relative;
        margin-top: 10px;
    }
    .header{
        position: relative;
        height: 100px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .roomIcon{
        position: relative;
        display: flex;
        flex-direction: row;
    }
    .productImage{
        position: relative;
        padding-right: 4px !important;
        width: 100%;
        height: 300px;
        object-fit: contain;
    }
    .arImage{
        position: relative;
        /* width: 100%; */
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        object-fit: contain;   
    }
    .mobImage{
        position: relative;
        width: 280px;
        height: 300px;
    }
    .model{
        position: relative;
        width: 100%;
        height: 100%;
    }
  }

@media (min-width : 768px) and (max-width: 1023px) {
    .productBody{
        position: relative;
        display: flex;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        padding-left: 4px;
        padding-right: 8px;
        gap: 10px;
    }
    .model{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .productNameMob{
        position: relative;
        width: 80%;
        margin: 0px auto;
        margin-top: 40px;
    }
    .brandName{
        position: relative;
        width: 100%;
        margin: 0px auto;
    }
    .roomIcon{
        position: relative;
        margin: 0px auto;
        width: 80%;
        display: flex;
        flex-direction: row;
    }
    .choose{
        position: relative;
        margin: 0px auto;
        width: 80%;
        font-weight: bold;
    }
    .roomtag{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: end;
    }
    .price{
        position: relative;
        margin: 0px auto;
        width: 80%;
    }
    .buttons{
        position: relative;
        margin: 0px auto;
        width: 80%;
    }
    .dropdowns{
        position: relative;
        margin: 0px auto;
        width: 80%;
    }
    .productVarients{
        position: relative;
        margin: 0px auto;
        width: 80%;
    }
    .variantDetails{
        position: relative;
        margin: 0px auto;
        width: 80%;
    }
    .relatedProducts{
        position: relative;
        margin: 0px auto;
        width: 80%;
    }
    .productImage{
        position: relative;
        display: flex;
        padding-right: 4px !important;
        width: 100%;
        background-color: #FFFFFF;
        height: 400px;
        object-fit: contain;
    }
    .productImages{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: max-content;
        height: 400px;
    }
    .productImageBody{
        position: relative;
        width: 80%;
        margin: 0px auto;
        height: max-content;
        display: flex;
        flex-direction: row;
        gap: 36px;
    }
    .image_gallery_thumbnails_container{
        position: relative;
        padding: 0px 4px 4px 4px;
        height: max-content !important;
        display: flex;
        flex-direction: column;
        gap: 2px;
        overflow-y: scroll;
        max-width: max-content;
    }
    .arImage{
        position: relative;
        /* width: 100%; */
        /* height: 100%; */
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        object-fit: contain;   
    }
    .image_gallery_image{
        position: relative;
        width: 400px;
        height: 350px;
    }
    .mobImage{
        position: relative;
        width: 400px;
        height: 400px;
    }
}

@media (min-width: 1024px) and (max-width: 1380px) {
    .productBodyWeb{
        position: relative;
        display: flex;
        width: 90%;
        margin: 0px auto;
        flex-direction: row;
        top: 20px;
        bottom: 20px;
        gap: 10px;
    }
    .ImageWeb{
        width: 35%;
        object-fit: contain !important;
        height: 500px;
        background-color: white;
    }
    .image-gallery-slide.center {
        position: relative;
        height: 450px !important;
        object-fit: contain !important;
    }
    .renderImage{
        position: relative;
        width: 100% !important;
        height: 450px !important;
    }
    .productDetailsLeft{
        position: relative;
        display: flex;
        max-width: 35%;
        padding: 2px 2px 2px 2px;
        flex-direction: column;
        gap: 10px;
    }
    .productDetailsRight{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 20%;
        background-color: #FFFFFF;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        padding-right: 8px;
    }
    .addWeb{
        position: relative;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 20px;
        padding-right: 20px;
        border: 2px solid black;
        background-color: white;
        font-size: large;
        border-radius: 50vh;
    }
    .buyWeb{
        position: relative;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: black;
        font-size: large;
        color: white;
        border-radius: 50vh;
    }
    .productDescriptionContainer{
        position: relative;
        margin: 0px auto;
        width: 90%;
    }
    .variantsHeading{
        font-weight: 700;
        font-size: 'large';
        width: 90%;
        position:'relative';
        top:'5px';
        margin: 0px auto;
    }
    .variantsWeb{
        width: 90%;
        margin: 0px auto;
    }
    .completeRoomWeb{
        margin: 0px auto;
        margin-bottom: 25px;
        width: 90%;
    }
    .breadCrumbs{
        position: relative;
        display: flex;
        margin: 0px auto;
        margin-top: 22px;
        width: 90%;
        flex-direction: row;
        gap: 2px;
        padding-left: 5px;
    }
}
  