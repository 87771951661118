.shopMainCont{
    margin-top: 75px;
    width: 100%;
    padding-bottom: 80px;
   
}

.filterCont{
    width: 100%;
    background-color: #F1EEEA;
    box-shadow: 0px 4px 12px 0px #0000000F;
    
}

.filterInnerCont{
    display: flex;
    flex-direction: row;
    width: 80%;
    padding: 20px 10px;
    margin: auto;
    justify-content: space-between;
   

}

.filterInnerHeading{
    font-weight: 700;
    font-size: 24px;
    color: #171717;
    margin-bottom: 1rem
}

.badgeContainer{
    display: flex;
    flex-wrap: wrap;
}

.budgetContainer{
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 1rem
}

.sliderBox{
    width: 120px;
    height: 39px;
    background: #FFFFFF;
    border: 0.5px solid #A5A5A5;
    margin-right: 0.5rem;
    padding: 4px;
}



.sliderBoxInner{
    margin-bottom:2.5px;
    line-height:1;
    font-size: 11px;
    font-weight: 400;
    color:#A5A5A5;
}

.sliderBoxInnerValue{
    margin:0;
    font-size: 13px;
    font-weight: 500;
    color: #171717;
}

.sliderInput{
    font-size: 13px;
    border: none;
    outline: none;
    width: 100%;
    line-height: 1
}

.filterBtnCont{
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 1rem;
    align-items: end;
}

.shopCont2{
    background-color: #F5F5F5;
    width: 100%;
    padding-top: 2rem;
}
.moodboardCont{
    width: 80%;
    max-width: 1250px;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 4rem;

}

.moodboardContTitle{
    font-size: 18px;
    font-weight: 700;
    color: #171717;
    margin-bottom: 0.5rem;
    text-align: center;
}

.moodboardContSubTitle{
    font-size: 15px;
    font-weight: 600;
    color: #171717;
    margin-bottom: 1rem;
    text-align: center;
   
}
.shopCont4{
    background-color: #ffffff;
    width: 100%;
}

.categoryContainer{
    width: 80%;
    margin:  auto;
    padding-bottom: 4rem;
    padding-top: 2rem;
  
}

.bannerCont{
    width: 90%;
    margin: 2rem auto;
    position: relative;
    
}

.bannerImage{
    width:100%;
    height: 100%;
    object-fit: contain;
}

/* .bannerHotspot{
    height: 24px;
    width: 24px;
    background-color: #ffffff;
    border-radius: 100%;
    position: absolute;
} */

.bannerContent{
    height: 100%;
    width: 40%;
    position: absolute;
    top:0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 15px;
}

.bannerText{
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;
    line-height: 1;
}

.newArrivalContainer{
    width: 80%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin: auto;
    
}

.bestSellersContainer{
    width: 80%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin: auto;
}

.hireDesignerBanner{
    display: flex;
    align-items: center;
    background-color: #D9EFF5;
    color: #171717;
    width: 100%;
    height: 240px;
    margin: 4rem auto 2rem auto
    /* margin-top: 12rem; */
}

.hireDesignerBannerMob{
    display: none;
}

@media only screen and (max-width: 1000px) {
    .filterInnerCont{
        flex-direction: column;
        width:100%;
        justify-content: unset;
    }

    .filterBtnCont{
        justify-content: start;
    } 

    .bannerCont{
        width: 95%;
    }
    .bannerContent{
        width: 55%;
       
    }

    .bannerText{
        font-size: 28px;
    }
     
    .hireDesignerBanner{
        display: none;
    }

    .hireDesignerBannerMob{
        height: 450px;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        background-color: #D9EFF5;
        padding:20px 20px 0 20px;
        text-align: center;
    }

    .moodboardCont{
        width: 90%;
       
    }

}

@media only screen and (max-width: 600px) {
    .filterInnerHeading{
        padding-top: 15px;
        font-size: 16px;
    }

    .categoryContainer{
        width: 90%;
    }

    .bannerCont{
        width: 100%;
        left: 0;
        justify-content: start;
    }

    .bannerText{
        font-size: 24px;
    }

    .bannerCont{
        height: 60vh;
    }

    .bannerContent{
        width: 90%;
        left: 0;
        justify-content: start;
        padding: 25px 15px 15px 15px;
    }

    .bannerImage{
        object-fit: cover;
    }

    .newArrivalContainer{
        width: 100%;  
    }
    .moodboardCont{
        width: 90%;
        padding: 10px;
    }

    .bestSellersContainer{
        width: 100%;  
    }
}