.container {
  max-width: 100% !important;
  font-family: 'Quicksand';
  background-color: #ffffff;
}

.renderContainer {
  width: 401px;
  height: 350px;
  border: 1px solid #cbcbcb;
  margin: 2%;
}

.renderContainer3 {
  width: 401px;
  height: 358px;
  border: 1px solid #cbcbcb;
  margin: 2%;
}

.center__text {
  position: absolute;
  top: 40%;
  left: 20%;
  transform: translate(-50%, -50%);
}
.modalHeader {
  background-color: #141414;
  height: 67px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.modalRow {
  height: 400px;
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}

.createCol {
  height: 400px;
  background-color: #f2feff;
}

.createCol h6 {
  margin-top: 5vh;
  margin-left: 2vw;
  padding-left: 2px;
  font-weight: 100 !important;
}

.createCol h5 {
  margin-top: 5vh;
  margin-left: 2vw;
  padding-left: 2px;
  font-weight: 100 !important;
}

.createCol p {
  margin-top: 3vh;
  margin-left: 2vw;
  padding-left: 2px;
  font-weight: 100 !important;
}
.textPadding {
  margin-top: 1vh;
  margin-left: 2vw;
  padding-left: 2px;
  /* font-weight: 100 !important; */
  text-align: center;
  font: normal normal bold 15px 'Quicksand';
  letter-spacing: 0px;
  color: #1a1818;
  opacity: 1;
}

.imageCol h6 {
  margin-top: 5vh;
  margin-left: 0vw;
  font-size: 16px;
  padding-left: 2px;
  font-weight: 100 !important;
}

.borderTop {
  border-top: 1px solid #cbcbcb;
}

.createCol input {
  margin-top: 10px;
  margin-left: 2vw;
  font-weight: 900;
  background-color: transparent;
  width: 70%;
  border: none;
  font-size: 18px;
  border-bottom: 2px solid;
}

.imageCol select {
  width: 90%;
  margin-left: 0vw;
  padding: 5px;
  margin-top: 20px;
}

.headerContainer {
  /* height: 8%; */
  /* margin-top: 30px; */
  width: 100%;
  height: 100%;
  border-top: 1px solid #cbcbcb;
}

.headerContainer1 {
  width: 100%;
  height: 100%;
  border-top: 1px solid #cbcbcb;
}

.previewImgContainer {
  height: 290px;
  overflow: hidden;
}

.headerContainer span {
  font-size: 20px;
  padding: 0px 0px 0px 0px;
  font-weight: 800;
}

.button {
  border: none;
  margin-top: 10px;
  /* margin-right: 30px; */
  background-color: #000000;
  padding-left: 20px;
  height: 30px;
  padding-right: 20px;
  color: #ffffff;
  border-radius: 20px;
}

.button1 {
  border: none;
  height: 28px;
  /* margin-right: 30px; */
  background-color: #000000;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  border-radius: 20px;
  /* padding: 12px 29px 11px 28px */
}
.button3 {
  border: none;
  height: 40px;
  /* margin-right: 30px; */
  background-color: red;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  /* border-radius: 20px; */
}

.cancelBtn {
  /* width: 128px; */
  /* height: 35px; */
  border: 1px solid #1a1818;
  background-color: transparent;
  border-radius: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 10px 30px 10px 30px;
}

.deleteBtn {
  /* width: 128px; */
  /* height: 35px; */
  border: 1px solid #1a1818;
  background-color: #000000;
  color: #ffffff;
  font-weight: 500;
  border-radius: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 10px 42px 10px 37px;
}

.packageBtn {
  /* width: px; */
  height: 40px;
  border: 1px solid #1a1818;
  color: #000000;
  border-radius: 20px;
  background-color: white;
  text-align: center;
  font: normal normal bold 13px/11px 'Quicksand';
  letter-spacing: 0px;
  color: #1a1818;
  opacity: 1;
  padding: 10px 16px 12px 20px;
}

.buttonContainer1 {
  /* width: 90%; */
  /* margin-top: 50px; */
  /* padding-left: 20px; */
  height: 8%;
  width: 100%;
  border-top: 1px solid #cbcbcb;
  margin-left: 0px;
  margin-right: 0px;
}

.buttonContainer {
  /* width: 90%; */
  /* margin-top: 50px; */
  padding-left: 20px;
  height: 8%;
  width: 100%;
  /* border-top: 1px solid #cbcbcb; */
}

.buttonCreate {
  /* width: 140px; */
  padding: 10px;
  background-color: #ffce4a;
  border-radius: 10px;
  border: none;
  float: right;
  margin-top: 15vh;
  margin-right: 5vw;
}

.editButton {
  line-height: 12px;
  width: 18px;
  font-size: 8pt;
  font-family: tahoma;
  margin-top: 1px;
  margin-right: 2px;
}

@media only screen and (max-width: 500px) {
  .container {
    width: 100%;
    background-color: #ffffff;
  }

  .renderContainer1 {
    width: 401px;
    height: 380px;
    border: 1px solid #cbcbcb;
    margin: 2%;
    margin-bottom: 20px;
    margin-top: 100px;
  }
  /* .headerContainer {
    height: 0%;
    margin-top: 80px;
  } */
  /* .headerContainer1 {
    height: 100%;
    margin-top: 80px;
  } */
  .headerContainer span {
    font-size: 17px;
    padding-left: 20px;
  }
  .button {
    margin-right: 0px;
    width: 195px;
    height: 45px;
  }
  .modalHeader h4 {
    font-size: 15px;
    margin-top: 25px !important;
    margin-left: 25px !important;
    display: inline-flex;
  }
  .modalContent {
    height: 80%;
  }
  .button2 {
    border: none;
    height: 28px;
    /* margin-right: 30px; */
    background-color: #000000;
    padding-left: 20px;
    padding-right: 20px;
    color: #ffffff;
    border-radius: 20px;
  }
  .button3 {
    border: none;
    height: 40px;
    /* margin-right: 30px; */
    background-color: red;
    padding-left: 20px;
    padding-right: 20px;
    color: #ffffff;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    /* border-radius: 20px; */
    font-size: 12px;
  }
  .imageCol {
    height: 60%;
    font-size: 15;
    font-weight: bold;
  }
  .createCol {
    height: 60%;
  }
  .createCol h6 {
    margin-top: 4vh;
    font-size: 12px;
  }
  .createCol input {
    width: 90%;
    font-size: 17px;
  }
  .buttonContainer {
    max-width: 100% !important;
    padding-top: 40px;
    margin: auto !important;
    padding-left: 0px;
  }
  .imageCol h6 {
    margin-top: 20px;
  }

  .buttonCreate {
    /* width: 140px;
    height: 45px; */
    padding: 10px;
    background-color: #ffce4a;
    border-radius: 10px;
    border: none;
    float: right;
    margin-top: 15vh;
    margin-right: 5vw;
    font-size: 15px;
    font-weight: 500;
  }

  .overlay h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }

  .center__text {
    position: absolute;
    top: 20%;
    left: 30%;
    font-size: 20px;
    transform: translate(-50%, -50%);
  }
  .cancelBtn {
    /* width: 128px; */
    /* height: 35px; */
    border: 1px solid #1a1818;
    background-color: transparent;
    border-radius: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 7px 20px 7px 20px;
    font-size: 14px;
  }
  .deleteBtn {
    /* width: 128px; */
    /* height: 35px; */
    border: 1px solid #1a1818;
    background-color: #000000;
    color: #ffffff;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px 47px 10px 47px;
    font-size: 14px;
  }
}

@media screen and (min-width: 2000px) {
  .container {
    max-width: 70% !important;
    background-color: #ffffff;
  }
}
