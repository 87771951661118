.bannerContainer {
  /* background-image: url("https://pixel-mortar.s3.ap-south-1.amazonaws.com/Mask+Group+54.png"); */
  height: 55vh;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
}

.bannerContainerMask {
  position: absolute;
  background: rgba(35, 36, 35, 80%);
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  /* filter: blur(1px);
    -webkit-filter: blur(1px) */
}

.bannerButton {
  padding: 6px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-top: 15px;
  border-radius: 25px;
  color: black;
  font-weight: 600;
  border: none;
  width: 155px;
  border: 0.5px solid #dedede;
  color: black;
  background-color: #f5f0ec;
}

.bannerCarousel {
  height: auto;
  max-width: 100%;
  /* background-image: url("https://d3izcfql7lesks.cloudfront.net/Group_3159_obwbmr.png"); */
  background-size: 100%;
  background-repeat: no-repeat;

  background-size: 100%;
  overflow-x: hidden;
}

.cardBorder {
  border: '1px solid';
}

.hovered1 {
  height: 200px;
  width: 200px;
}

.back {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/coming-soon/coming-soon3.png');
  /* height: 200px; */
}
.content {
  color: black;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  /* background-image: url("https://d3izcfql7lesks.cloudfront.net/Group_3158_wxbffq.png"); */
  /* height: 200px; */
}

.productOnline {
  margin-top: 6px;
  width: 9px;
  height: 10px;
  border-radius: 6px;
  background-color: #3aff0b;
}
.numberOne {
  margin-bottom: -13px;
  margin-right: 9px;
  z-index: 10;
  width: 22px;
  border-radius: 12px;
  background-color: white;
  text-align: center;
  height: 23px;
  padding: 2px;
}
.mainContainer {
  max-width: 80% !important;
  background-color: white;
  margin-top: 80px;
  margin-right: 5px;
  margin: 0px auto;
}
.mainContainer1 {
  max-width: 80% !important;
  background-color: white;
  margin-top: 30px;
  margin-right: 5px;
}
.dropDown {
  background-color: #f5f0ec;
  border-radius: 20px;
  width: 224px;
  color: black;
}

.shareLink {
  position: relative;
  width: 550px;
  height: 35px;
  display: inline-flex;
  border-radius: 20px;
  border: 1px solid #f0f0f0;
  padding: 5px;
  padding-left: 20px;
}

.container3 {
  width: 100%;
  background: #f4eae6 0% 0% no-repeat padding-box;
  height: auto;
  margin-top: 30px;
}
.container3::after {
  height: 50px;
  width: 50px;
  transform: rotate(45deg);
  content: '';
  position: absolute;
  background: #f4eae6;
  left: 700px;
  z-index: 1;
  top: 520px;
}

.shopButton {
  background-color: black;
  color: white;
  border-radius: 30px;
  padding: 3px 10px 2px 10px;
  border: none;
}

.cardHoverScale {
  transition: all 0.3s ease-in-out;
}
/* .cardHoverScale:hover{
  transform: scale(1.1);
  filter:drop-shadow(0px 3px 6px rgb(0,0,0,16%));
} */
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  bottom: 0;
  /* background-color: rgb(0 0 0 / 30%); */
  justify-content: center !important;
  align-items: center !important;
}
.overlay1 {
  /* background-color: #2c2c2cb5;
  justify-content: center !important;
  align-items: center !important; */
  opacity: 0.4;
  filter: alpha(opacity=40);
  background-color: #2c2c2cb5;
}
.listButton1 {
  /* background-color: pink; */
  font-weight: bold;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 6px;
}

.filterButton {
  width: 86px;
  height: 32px;
  padding: 0px;
  border-radius: 50px;
  margin-left: 10px;
  background-color: #f7f7f7;
  text-align: left;
  border-color: #f7f7f7;
}

.listButton {
  background-color: #f7f7f7;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 6px;
}

.listButton1 {
  /* background-color: pink; */
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 6px;
  font-weight: bold;
}

.filterHead {
  font-size: 16px;
  font-family: 'Quicksand';
  font-weight: bold;
}

.searchInput {
  width: 300px;
  border: none;
  font-size: 15px;
  opacity: 0.7;
  background-color: white;
  border-bottom: 0.5px solid #707070;
  margin-top: 30px;
  outline: none;
}

/* .renderColumn_container {
      height: 150px;
      width: 150px;
      border-radius: 3px;
      margin-top: 3vh;
      border: 0.5px solid #000000;
    } */

.renderColumn_container {
  width: 268px;
  height: 100%;
  border-radius: 10px;
  border: 3px solid rgba(163, 163, 163, 0.2);
}
.star {
  font-size: 25px;
  color: gold;
}

.pillCircle {
  width: 80px;
  height: auto;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  font-family: 'Quicksand';
  /* border: 2px solid #3498db; */
  color: white;
  background: #f03f3f;
  margin: 0 auto;
  z-index: 1;
  transform: translateY(-50%);
  line-height: 40px;
  padding: 0px;
  /* position: absolute; */
}

.heart {
  font-size: 25px;
  color: red;
}

/* .img {
      object-fit: cover;
      object-position: top;
      display: block;
      height: 40;
      width: 40;
      padding-top: 20px;
      border: solid;
      transition: 0.5s ease;
    } */

.container1 {
  position: relative;
}
.img {
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  height: 20;
  padding: 0%;
}

.container1:hover .img {
  opacity: 0.3;
  /* background-color: Black; */
  /* position: absolute; */
  z-index: 10;
}
.card1 {
  width: '17rem';
  height: 'auto';
  border-radius: '10px';
}
.card :hover {
  transform: scale(1.5);
}
.container1:hover .middle {
  opacity: 1;
  position: absolute;
}
.hovered {
  /* position: absolute; */
  z-index: 1;
  /* display: block; */
  /* opacity: ; */
}

.text {
  border: 3px solid white;
  color: white;
  font-size: 8px;
  padding: 5px 5px;
}

.cartButton1 {
  width: 50%;
  height: 52px;
  border-radius: 3px;
  margin-top: 3vh;
  border: 0.5px solid #dedede;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: black;
  margin-bottom: 40px;
}
.nav_container {
  max-width: 100%;
  height: 50px;
  background-color: #f2feff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 10px; */
}

.button {
  background-color: #000000 !important;
  /* padding: 20px 30px 20px 30px; */
  border: solid;
  /* border-radius: 30px; */
  font-weight: 800;
  width: 100%;
}

.button1 {
  padding: 20px 30px 20px 30px;
  border: groove;
  border-radius: 30px;
  font-weight: 800;
  width: 100%;
}

.cartButton {
  /* margin-left: 30px;
  margin-right: 50px;
  width: 80%;
  color: #000000 !important;
  font-weight: 400;
  margin-bottom: 1px;
  position: absolute;
  bottom: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border: 0.5px solid #dedede;
  border-radius: 2px;
  opacity: 1; */
  margin-left: auto;
  margin-right: auto;
  width: 55%;
  left: 0px;
  right: 0px;
  color: #000000 !important;
  font-weight: 400;
  margin-bottom: 1px;
  position: absolute;
  /* bottom: 0px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border: 0.5px solid #dedede;
  border-radius: 2px;
  opacity: 1;
  justify-content: center;
  font-size: 15px;
}
.cartButton:hover,
.cartButton:active,
.cartButton:focus {
  box-shadow: black;
  border: 0.5px solid #dedede !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
}

.cartSimilarButton {
  color: #000000 !important;
  font-weight: 400;
  margin-bottom: 1px;
  /* bottom: 0px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border: 0.5px solid #dedede;
  border-radius: 2px;
  opacity: 1;
  justify-content: center;
  font-size: 15px;
}
.cartSimilarButton:hover,
.cartSimilarButton:active,
.cartSimilarButton:focus {
  box-shadow: black;
  border: 0.5px solid #dedede !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
}

.similarButton {
  margin-left: 30px;
  margin-right: 50px;
  width: 80%;
  font-weight: 800;
  text-align: center;
  background-color: white;
  border: 1px solid;
  border-color: black;
  border-radius: 0px;
}

.similarButton1 {
  /* margin-left: 30px;
  margin-right: 50px; */
  width: 100%;
  margin-top: 100px;
  font-weight: 400;
  text-align: center;
  background: #f4eae6 0% 0% no-repeat padding-box;
  opacity: 1;
  bottom: 0px;
  font: normal normal 600 12px/35px 'Quicksand';
  letter-spacing: 0px;
  color: #272727;
  cursor: pointer;
  border-radius: 2px;
}

.drawerContainer {
  width: 280px;
}
.heart {
  box-shadow: 1px 1px 2px black;
  border-radius: 50%;
  cursor: pointer;
  height: 41px;
  width: 41px;
  background-image: url('https://res.cloudinary.com/lab10/image/upload/v1621063036/samples/heart-edit_lx314x.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 2900%;
}

.heart:hover {
  background-position: right;
}

.is_animating {
  animation: heart-burst 0.8s steps(28) 1;
}

.buttonWhite {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: black;
  background-color: white;
  padding: 5px 10px 5px 10px;
  font-weight: 900;
  height: 40px;
  width: 100%;
}

.buttonBlack {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: white;
  background-color: black;
  padding: 5px 10px 5px 10px;
  font-weight: 600;
  height: 40px;
  width: 100%;
  font-size: 14px;
}

.pill {
  background-color: #ddd;
  border: none;
  color: black;
  /* padding: 10px 20px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* margin: 4px 2px; */
  cursor: pointer;
  border-radius: 16px;
}

@keyframes heart-burst {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

.modalContainer p {
  margin-bottom: 0px !important;
}

.saveExplore {
  position: absolute;
  bottom: 0px;
  margin-left: 131px;
  margin-bottom: 10px;
  height: 25px;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 25px;
  padding-bottom: 25px;
  border: none;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  background-color: rgb(255, 255, 255);
  width: 21%;
}

.copyLinkBtn {
  float: right;
  position: absolute;
  width: 20%;
  right: 0;
  top: 0;
  height: 35px;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
}
.link {
  font-size: 16px;
}

@media only screen and (max-width: 500px) {
  .mainContainer {
    /* min-width: 100% !important; */
    width: 82% !important;
    padding: 0px !important;
    margin: 0px !important;
    max-width: 100% !important;
    margin: 0px auto !important;
  }
  .renderColumn_container {
    width: 162px;
    height: 265px;
  }
  .renderColumn_container img {
    margin-top: 0px;
    width: 30%;
  }
  .searchInput {
    width: 90%;
    font-size: 14px;
  }

  .shareLink {
    position: relative;
    width: 100%;
    height: 35px;
    display: inline-flex;
    border-radius: 20px;
    border: 1px solid #f0f0f0;
    padding: 5px;
    padding-left: 10px;
    padding-top: 10px;
  }

  .saveExplore {
    position: absolute;
    bottom: 0px;
    margin-left: 131px;
    margin-bottom: 10px;
    height: 25px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 25px;
    padding-bottom: 25px;
    border: none;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    background-color: rgb(255, 255, 255);
    width: 21%;
  }

  .copyLinkBtn {
    float: right;
    position: absolute;
    width: 20%;
    right: 0;
    top: 0;
    height: 35px;
    color: #ffffff;
    border-radius: 20px;
    background-color: black;
    font-size: 12px;
  }
  .link {
    font-size: 11px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .mainContainer {
    max-width: 96% !important;
    background-color: white;
    margin-top: 80px;
    margin-right: 5px;
  }
  .cartButton {
    width: 65%;
    font-size: 12px;
  }
}

@media only screen and (min-width: 310px) and (max-width: 800px) {
  .mainContainer {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .mainContainer {
    max-width: 96% !important;
    background-color: white;
    margin-top: 80px;
    margin-right: 5px;

    margin: 0px auto;
  }
}

@media only screen and (max-width: 500px) {
  .similarButton2 {
    width: 80%;
    left: 13px;
    right: 0;
  }
}
