.headTagContainer{
    padding: 10px;
    width: 100%;
    height: 53px
}

@media only screen and (max-width: 600px){
    .headTagContainer{
        height:auto;
        width:100%
    }
}
