.productCard{
    /* min-width: 250px;
    min-height: 330px; */
    width: 295px;
    height: 370px;
    border: 1px solid #DEE3EC;
    padding:6px;
    box-shadow: 0px 0px 3px 0px #00000014;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
}

.topContainer{
    height: 60%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.productImage{
    height: 90%;
    width: 80%;
    object-fit: contain;
    cursor: pointer;
}


  

.arContainer{
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    background-color: #F6F6F6;
    padding: 4px 12px;
    font-size: 11px;
    font-weight: 700;
    cursor: pointer;
    z-index: 2;
}

.btnContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}


.productCardTag{
    background-color: #7B8569;
    height: 21px;
    color: #ffffff;
    font-size: 11px;
    font-weight: 700;
    position: absolute;
    top:16px;
    left:-6px;
    padding:3px 5px;
}

.productCardHeart{
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    top:5px;
    right:5px;
    padding:0px 10px;
    border-radius: 50%;
    background-color: #F6F6F6;
    z-index: 2;
}

.bottomContainer{
    height: 40%;
    background-color: #F1EEEA;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.vendorName{
    color:  #E39625;
    font-size: 12px;
    font-weight: 700;
    
  

}

.productName{
    color:  #171717;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.productName:hover{
   text-decoration: underline;
}

.priceCont{
    display: flex;
    justify-content: start;
    align-items: center;
}

.pnmPrice{
    color:  #171717;
    font-size: 18px;
    font-weight: 700;
    margin-right: 0.5rem;
}

.price{
    color:  #171717;
    font-size: 14px;
    font-weight: 500;
}

@media only screen and (max-width: 600px) {
    /* .productCard{
        width: 255px;
    } */
    .productName{
        text-decoration: underline;
    }
}