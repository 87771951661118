.leftArr{
    position: absolute;
    top: 40%;
    left: -30px !important;
    border: 1px solid 
    #171717;
    padding: 14px !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    z-index: 1;
    object-fit: none;
    cursor: pointer;
    background-color: #ffffff;

}

.rightArr{
    position: absolute;
    top: 40%;
    right: -30px !important;
    border: 1px solid 
    #171717;
    padding: 14px !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    z-index: 1;
    object-fit: none;
    cursor: pointer;
    background-color: #ffffff;

}

.stackedCarouselDetails{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
    color: #171717;
    font-size: 18px;
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
    .leftArr{
       display: none;
    }
    .rightArr{
        display: none;
    }
   }