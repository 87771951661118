.filled{
    background: #1A1818;
    border-radius: 39px;
   
    color:#ffffff;
   
    font-weight: 700;
    cursor: pointer;
    width: max-content;
    line-height: 1;
    text-align: center;
}



.outlined{
    border-radius: 39px;
    border: 1px solid #000000;
    color:#000000;
    font-size:16px;
    font-weight: 700;
    cursor: pointer;
    width: max-content;
    line-height: 1;
    text-align: center;

}

.small{
    height: 36px;
    padding: 10px 20px;
    font-size:13px;
}

.large{
    height: 50px;
    padding: 16px 40px;
    font-size:16px;
}

.filledDisabled{
    background-color: rgb(0 0 0 / 41%);
    cursor: not-allowed;
}

.outlinedDisabled{
    border: 1px solid rgb(0 0 0 / 41%);
    color:rgba(26, 24, 24, 0.4);
    cursor: not-allowed;
}