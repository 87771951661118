.mobileBannerContainer {
  width: 100%;
  background-color: #f9f9f9;
  padding: 15px;
}
.heading {
  font-size: 22px;
  font-weight: 500;
  margin-top: 100px;
}

.findStyle {
  position: absolute;
  top: 30px;
  left: 0px;
  bottom: 0;
  right: 0;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  text-align: center;
}

.headingImg {
  width: 160px;
  height: 160px;
}

.headingImgTittle {
  font-size: 14px;
  font-weight: 600;
}

.displayrow {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.BannerImg {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background: linear-gradient(180deg, #000 50%, transparent);
  border-radius: 8px;
  /* visibility: hidden; */
}

.banner {
  /* background-image: url("../../assets/images/Explore/exploreBanner.png");
        background-size: contain;
        background-repeat: no-repeat; */
  width: 100%;
  height: 100%;
  /* padding-top: 49%; */
  position: relative;
  border-radius: 8px;
}

.container {
  margin-top: 5vh;
  font-family: 'Quicksand';
  padding-bottom: 50px;
}

.mainHeader {
  text-align: center;
  justify-content: center !important;
  font-family: 'Quicksand';
}

.mainHeader h2 {
  font-size: 30px;
}

.mainHeader h6 {
  margin-top: 1vh;
  font-size: 17px;
  opacity: 1;
  font-weight: 400;
}
.filterImg {
  margin-bottom: 50px;
}
.modal {
  border: none !important;
  height: 100% !important;
  overflow: scroll;
  width: 70% !important;
}

.modal .modal-content {
  width: 70% !important;
}

.modal_backdrop {
  background-color: rgba(250, 253, 250, 0.4) !important;
}
.productImg {
  width: 100%;
  height: 330px;
}

.modalRow {
  padding: 20px 50px 20px 50px;
  height: 600px;
  position: relative;
}

.arIcon {
  position: absolute;
  bottom: 7px;
  width: 26px;
  right: 7px;
  transition: transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.arIconbtn {
  position: absolute;
  bottom: 7px;
  width: 26px;
  right: 7px;
}
.arIcon:hover {
  transform: scale(1.2);
}

.filterButton {
  border-radius: 30px;
  margin-top: 10px;
  padding: 5px 20px 5px 20px;
  background-color: #000000;
  color: #ffffff;
  display: inline-flex;
}

.tagBtns {
  padding: 6px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  background-color: #ffffff;
  color: black;
  font-weight: 500;
  border: none;
  border: 1px solid #a0a0a0;
  min-width: 125px;
  text-transform: capitalize;
}

.tagBtnss {
  padding: 3%;
  /* padding-left: 20px;
    padding-right: 20px; */
  margin-top: 10px;
  /* font-size: 0.6rem; */
  margin-right: 10px;
  /* border-radius: 50%; */
  background-color: #ffffff;
  color: black;
  font-weight: 600;
  /* height: 30px; */
  /* border: none; */
  border: 1px solid #a0a0a0;
  font-size: 14px;
  font-family: 'Quicksand';
  min-width: 40%;
}

.tagBtnsss {
  padding: 7px 24px;
  margin-top: 32px;
  margin-left: 23px;
  background-color: #ffffff;
  color: black;
  border: 1px solid #1a1818;
  font-size: 13px;
  font-family: 'Quicksand';
  margin-bottom: 50px;
  font-weight: 600;
  height: 40px;
}
button:focus {
  box-shadow: none !important;
}
.tagBtnsss:hover {
  background-color: black;
  color: white;
}
.text1 {
  font-weight: 600;
  font-size: '14px';
  font-family: 'QuickSand';
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  bottom: 0;
  background-color: #2c2c2cb5;
}

.footerButton {
  border-radius: 30px;
  background-color: #ffffff;
  color: #000000;
  border: 0.5px solid #707070;
  padding: 8px 10px 8px 10px;
  width: 150px;
  margin-bottom: 10px;
}

.buttonWhite {
  border-radius: 25px;
  color: black;
  background-color: white;
  padding: 5px 3em 5px 3em;
  font-weight: 400;
  height: 40px;
  width: 100%;
  font-size: 13px;
}

.button {
  width: 131px;
  height: 42px;
  border-radius: 10px 10px 0 0;
  border: none;
  float: right;
  margin-top: -7.5vh;
  margin-right: 5vw;
  position: sticky;
  bottom: -6px;
  right: 0px;
  z-index: 100;
  background-image: linear-gradient(to right, #ffe5a0 0%, #ffce4a 100%);
  font-size: 16px;
  font-weight: 700;
}

.createButton {
  width: 131px;
  height: 42px;
  border-radius: 10px 10px 0 0;
  border: none;
  position: absolute;
  top: -42px;
  right: 50px;
  z-index: 100;
  background-image: linear-gradient(to right, #ffe5a0 0%, #ffce4a 100%);
  font-size: 16px;
  font-weight: 700;
}

.fullScreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: #2c2c2c;
  top: 0;
  left: 0;
  opacity: 0.89;
}
.fullScreen_top {
  bottom: 19vh;
  right: 0;
  margin-right: 3.8vw;
  position: fixed;
}
.fullScreen_bottom {
  bottom: 10vh;
  right: 0;
  margin-right: 3.2vw;
  position: fixed;
}
.modal .modal-dialog {
  width: 50% !important;
}
.whileBtns {
  width: 100%;
}

/* Grid css */
.item {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0.5rem auto;
  position: relative;
  /* border: 1px solid #f9fafb; */
  border-radius: 2px;
  transition: 0.3s ease;
  font-size: 0;
  background-color: white;
  border-radius: 4px;
}

.item img {
  max-width: 100%;
}

.content {
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in-out;
  opacity: 0;
  /* border: 1px solid black; */
}

.content div {
  opacity: 0;
  transition-delay: 1s;
  transition: 0.3s ease;
  font-size: 20px;
}

.item:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}
.item:hover .content {
  opacity: 1;
  border-radius: 4px;
}
.item:hover .content div {
  opacity: 1;
  color: #f9fafb;
}

.gridContainer {
  margin: 3rem auto;
}

.gridRow {
  column-width: 18em;
  -moz-column-width: 20em;
  -webkit-column-width: 20em;

  column-gap: 1rem;
  -moz-column-gap: 1rem;
  -webkit-column-gap: 1rem;
}

.my_masonry_grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
  margin-top: 50px;
}
.my_masonry_grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry_grid_column > div {
  margin-bottom: 30px;
}

.text1 {
  font-weight: 600;
  font-size: '12px';
  font-family: 'QuickSand';
  margin-top: 20px;
}

.tagBtnss2 {
  padding: 2% 3%;
  margin-top: 18px;
  margin-right: 10px;
  background-color: #ffffff;
  color: black;
  font-weight: 600;
  border: 1px solid #a0a0a0;
  font-size: 12px;
  font-family: 'Quicksand';
  min-width: 30%;
}
.tagBtnss1 {
  padding: 2% 3%;
  margin-top: 18px;
  margin-right: 10px;
  background-color: white;
  color: black;
  font-weight: 600;
  border: 1px solid #f4a293;
  font-size: 12px;
  font-family: 'Quicksand';
  min-width: 30%;
}
/* .tagBtnss1 {
    padding: 3%;
    margin-top: 10px;
    margin-right: 10px;
    background-color: white;
    color: black;
    font-weight: 600;
   
    border: 1px solid #F4A293;
    font-size: 12px;
    font-family: "Quicksand";
    min-width: 40%;
  } */
@media screen and (max-width: 500px) {
  .mainHeader {
    padding: 30px;
    text-align: left;
  }
  .mainHeader h6 {
    opacity: 1;
  }
  .modalRow {
    padding: 19px 0px 50px 0px;
  }
  .modalRow1 {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
  }
  .modalRow {
    height: auto;
    /* margin: 20px; */
  }
  .filterImg {
    margin-bottom: 30px;
  }
  .my_masonry_grid_column {
    padding-bottom: 35px;
  }
  .button {
    width: 126px;
    height: 37px;
    font-size: 14px;
  }
  .text1 {
    margin-top: 19px;
  }
  .mainHeader h2 {
    font-size: 25px;
  }
  .tagBtnss2 {
    min-width: 29%;
    padding: 2% 1%;
  }
}
@media screen and (max-width: 2000px) {
  .text1 {
    font-weight: 600;
    font-size: '20px';
    font-family: 'QuickSand';
  }
}

@media screen and (min-width: 1500px) {
  .container {
    max-width: 1640px;
  }
}
