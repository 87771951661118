.container {
  width: 100%;
  height: 100vh;
}

.button1 {
  height: 50px;
  padding: 3px 30px 3px 30px;
  width: 12vw;
  background-color: white;
  border: 1px solid #000;
  border-radius: 30px;
  font-size: 1vw;
}

.button2 {
  height: 50px;
  margin-left: 2vw;
  padding: 3px 1vh 3px 1vh;
  width: 12vw;
  background-color: black;
  color: white;
  border: 1px solid #000;
  border-radius: 30px;
  font-size: 1vw;
}
