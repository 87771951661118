.leftArr{
    position: absolute;
    top: 40%;
    left: -55px !important;
    border: 1px solid #DFD9D6;
    padding: 14px !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    z-index: 1;
    object-fit: none;
    cursor: pointer;

}

.rightArr{
    position: absolute;
    top: 40%;
    right: -55px !important;
    border: 1px solid #DFD9D6;
    padding: 14px !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    z-index: 1;
    object-fit: none;
    cursor: pointer;

}