.button {
  padding: 6px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  color: black;
  font-weight: 500;
  border: none;
  background-color: #d2edf5;
  min-width: 125px;
  font-weight: 400;
}

.submitButton {
  margin-bottom: 50px;
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  padding: 6px;
  font-weight: 400;
  padding-left: 25px;
  margin-top: 30px;
  padding-right: 25px;
}

.submitButton:hover {
  font-weight: 900;
}
.button1 {
  padding: 6px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  color: black;
  font-weight: 500;
  border: 1px solid #d3d3d3;
  min-width: 125px;
  cursor: pointer;
  background-color: #fafafa;
}

.button1:hover {
  font-weight: 900;
  background-color: #d2edf5;
}

.container2Image {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.container2 {
  width: 100%;
  background-color: #f5f0ec;
  height: auto;
  margin-top: 100px;
  padding: 10vh 15vw 10vh 15vw;
}

.topContainer {
  filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 6%));
}

@media only screen and (max-width: 500px) {
  .button {
    min-width: 120px;
  }
  .button1 {
    min-width: 120px;
  }
}
