body {
  background: #fff;
}

.card.product .btn {
  bottom: 1.5rem;
  max-width: 80%;
}

.price {
  font-size: 1.75rem;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  /* background-color: black ;
  border-color: black ; */
  color: black;
  text-decoration: underline;
}
.pagination > li > a {
  /* border: 1px solid black ; */
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: black;
  border-color: black;
  outline: none;
  color: white;
}
.pagination > li > a,
.pagination > li > span {
  color: black;
  border-radius: 20px;
  margin-right: 5px;
  padding: '10px';
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  /* border: 1px solid black;
  border-radius: 20px; */
}

@media (max-width: 768px) {
  .cardContainer {
    margin-bottom: 2rem;
  }
}

/* JSFiddle layout display fix only, don't use */
body {
  min-height: 620px;
}

.star {
  font-size: 25px;
  color: gold;
}

.heart {
  font-size: 25px;
  color: red;
}
.tagname {
  font-size: 16px;
  margin: 0px auto;
  margin-top: 6px;
  width: 90%;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .tagname {
    font-size: 12px;
    width: 90%;
    margin: 0px auto;
    margin-top: 6px;
  }
}
.hr {
  border-top: 0.5px solid #707070;
  padding-top: 20px;
  width: 60%;
}
.styledbtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 2px;
  opacity: 1;
}
