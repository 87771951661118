.pageContainer {
  overflow-y: hidden;
  margin-top: 50px
}

.container {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2440_gztd1o.png');
  width: 120%;
  height: 100vh;
  display: flex;
  position: fixed;
  overflow-y: auto;
  background-size: contain;
  background-color: #f5f0ec;
}

.button {
  display: flex;
  position: relative;
  justify-content: center;
}



.updateBtn:hover {
  font-weight: 900;
}

.updateBtn {
  background-color: #000000;
  border-radius: 30px;
  color: #f5f0ec;
  padding: 10px 30px 10px 30px;
  font-weight: 400;
  margin-top: 40px;
}

.sidebar_content {
  width: 60%;
  margin: auto;
  margin-top: 75px;
}

.sidebar_content h5 {
  text-align: left;
  font-size: 16px;
  padding-top: 30px;
  cursor: pointer;
}

.card_input {
  border: none !important;
  width: 100% !important;
  border-bottom: 1px solid #707070 !important;
  font-weight: 700;
  opacity: 0.6;
}

.label {
  font-size: 14px;
  font-weight: 500;
  /* margin-bottom: 20px; */
}

.paymentBtn {
  background-color: #000000;
  border-radius: 30px;
  color: #f5f0ec;
  padding: 10px 25px 10px 25px;
  font-weight: 400;
}

.paymentBtn:hover {
  font-weight: 900;
}

.modalContainer {
  width: 100%;
  position: relative;
  margin-top: 5vh;
  text-align: center;
}

.modal_input_container {
  width: 90% !important;
  margin: auto !important;
  text-align: left;
  margin-top: 20px !important;
  font-family: 'QuickSand' !important;
}

.modal_input_container label {
  font-size: 0.8vw;
}

.modalContainer h4 {
  width: 100% !important;
  text-align: center;
  font-weight: 100;
}

.addresses_new {
  width: 100%;
  background-color: #d2edf5;
  padding: 20px;
  font-weight: 800;
  height: 70px;
}
.modal_input:focus {
  border: 1px solid black !important;
}
.modal_input {
  width: 220px;
  padding: 5px;
  font-size: 0.8vw;
  height: 35px;
  border: 1px solid black;
}

.active {
  text-decoration: underline;
}

.cardHeader {
  background-color: #000000 !important;
  color: #ffffff;
  font-weight: 800;
  padding: 0.75rem 2.25rem !important;
}

.cardHeader1 {
  background-color: #e5e5e5 !important;
  color: #000000;
  font-weight: 800;
}
.divider {
  width: 100%;
  height: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar {
  position: fixed;
  left: 0%;
  width: 20%;
  height: 100%;
  z-index: 10;
  background-color: white;
}

.cardDiv {
  width: 500px;
  padding-left: '300px';
  height: auto;
  margin: auto;
}

.mobileBackground {
  min-height: 100vh;
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2440_gztd1o.png');
}
