.container {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2950_vyovf3.png');
  background-size: 100%;
}

.mainContainer h5 {
  font-weight: 900 !important;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
}

.input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  width: 500px;
  background-color: #fafafa;
}

.underline {
  text-decoration: underline;
}
.submitButton {
  border: none;
  background-color: black;
  color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 30px;
  margin-top: 40px;
  font-size: 14px;
  margin-bottom: 100px;

  width: 150px;
}
