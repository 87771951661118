.roomCard{
    width:100%;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 15px;
    padding:5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

/* .roomCardNoHover{
    width:100%;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 15px;
    padding:5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
} */

/* .roomCard:hover{
    background-color: #949A86;
} */

.cardImage{
    width: 70%;
    height: 70%;
    object-fit: contain;
    margin-bottom: 0.5rem;
}