@import url('https://fonts.cdnfonts.com/css/sofia-pro');

.container {
  padding-top: 13vh !important;
  font-family: 'Quicksand';
  background-color: #f7f7f7;
  max-width: 100% !important;
  margin: 0px !important;
}

.header {
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 10px;
}

.card {
  width: auto;
  height: 840px;
  background-color: #ffffff !important;
  border-radius: 0px !important;
  border-right: none;
  border-left: none;
  border-bottom: none;
  filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 18%));
}
.cardSubText {
  font-size: 14px;
  font-weight: normal;
  font-family: 'Quicksand';
  color: black;
}

.typoAccordian {
  font-size: 12px !important;
}

.card_title {
  font-size: 1.5rem !important;
  font-weight: bolder !important;
  text-transform: capitalize !important;
}

.price {
  font-family: 'Sofia Pro', sans-serif;
  width: 100% !important;
  font-size: 1rem;
}

.sub {
  width: 50% !important;
  margin-top: 3vh !important;
  font-weight: 500;
}

.button {
  width: 182px;
  height: 48px;
  color: #f5f0ec;
  background-color: #171717;
  border-radius: 30px;
  font-weight: 400;
}
.button:hover {
  font-weight: 900;
}
.faqSection {
  background-color: #ffffff;
  display: inline-block;
  width: 100%;
}
.Enhanced {
  position: relative;
  margin-right: 1rem;
  width: max-content;
  margin: auto;
}
.Enhanced:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  border: solid 3px #eab146;
  border-color: #eab146 transparent transparent transparent;
  border-radius: 35%;
  transform-origin: 0;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}
.Exclusive {
  position: relative;
  margin-right: 1rem;
  width: max-content;
  margin: auto;
}

.textSub {
  text-align: center;
}

.Exclusive:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;

  border: solid 3px #a1cfdb;
  border-color: #a1cfdb transparent transparent transparent;
  border-radius: 35%;
  transform-origin: 0;
  transition-delay: 0.5s;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition-property: transform 0.25s ease-out;
}

.Express {
  position: relative;
  margin-right: 1rem;
  width: max-content;
  margin: auto;
}

.Express:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  /* transform: scaleX(0); */
  border: solid 3px #d99fa6;
  border-color: #d99fa6 transparent transparent transparent;
  border-radius: 35%;
  transform-origin: 0;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

.header3 {
  font-weight: 400;
  font-size: 24px;
}
.prices {
  align-items: center;
  display: flex;
  justify-content: center;
}
.strick {
  color: #cbcbcb;
  font-weight: 600;
  text-decoration: line-through;
}
.original {
  color: #1a1818;
  font-weight: 600;
  position: relative;
}
.prices div {
  padding: 0px 20px;
  font-size: 35px;
}
.original:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 100%;
  border: solid 3px #e6caca;
  border-color: #e6caca transparent transparent transparent;
  border-radius: 35%;
  transform-origin: 0;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}
.designButton {
  padding: 10px 25px;
  font-size: medium;
  border-radius: 25px;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  color: #f5f0ec;
  background-color: black;
  font-weight: 400;
  /* font-size: 1vw; */
}
@media only screen and (max-width: 500px) {
  .subParaText {
    margin-top: 12px;
    font-size: 15px;
  }
  .card {
    width: 100% !important;
    max-width: 100% !important;
    height: auto;
    padding-bottom: 20px;
    box-shadow: 1px 2px 8px #e6dfdf;
    margin-bottom: 20px;
  }
  .container {
    max-width: 100% !important;
    padding-bottom: 20px;
  }
  .container {
    margin-top: 2vh !important;
  }
  .button {
    font-size: 14px;
    width: 168px;
    height: 41px;
  }
  .cardSubText {
    width: 95% !important;
    font-size: 13px;
    margin-top: 3px;
  }
  .needHelp {
    padding: 0px 19px;
  }
  .typoHeader {
    font-weight: 100;
    font-size: 14px;
  }
  .prices div {
    padding: 0px 13px;
    font-size: 18px;
  }
}
