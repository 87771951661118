@import url('https://fonts.cdnfonts.com/css/sofia-pro');

.container {
  margin-top: 7vh !important;
  font-family: 'Quicksand';
}
.PackageContainer {
  background-color: #ffffff;
  position: relative;
  top: 0;
  left: 0;
  font-family: 'Quicksand';
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.header {
  font-size: 2rem;
  font-weight: 100;
}

.card {
  width: auto;
  height: 100%;
  background-color: #ffffff !important;
  border-radius: 0px !important;
  border-right: none;
  border-left: none;
  border-bottom: none;
  filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 18%));
}

.card_title {
  font-size: 1.5rem !important;
  font-weight: bolder !important;
  text-transform: capitalize !important;
}

.Enhanced {
  position: relative;
  margin-right: 1rem;
  width: max-content;
  margin: auto;
}

.Enhanced:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  /* transform: scaleX(0); */
  border: solid 3px #eab146;
  border-color: #eab146 transparent transparent transparent;
  border-radius: 35%;
  transform-origin: 0;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

.Exclusive {
  position: relative;
  margin-right: 1rem;
  width: max-content;
  margin: auto;
}

.Exclusive:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;

  border: solid 3px #a1cfdb;
  border-color: #a1cfdb transparent transparent transparent;
  border-radius: 35%;
  transform-origin: 0;
  transition-delay: 0.5s;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition-property: transform 0.25s ease-out;
}

.Express {
  position: relative;
  margin-right: 1rem;
  width: max-content;
  margin: auto;
}

.Express:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  /* transform: scaleX(0); */
  border: solid 3px #d99fa6;
  border-color: #d99fa6 transparent transparent transparent;
  border-radius: 35%;
  transform-origin: 0;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

/* .underline:hover:after{
  transform: scaleX(1);
  transform-origin:bottom left;
} */

.textSub {
  text-align: center;
}

.price {
  font-family: 'Sofia Pro', sans-serif;
  width: 100% !important;
  font-size: 1rem;
}

.sub {
  width: 50% !important;
  margin-top: 3vh !important;
  font-weight: 500;
}

.button {
  width: 182px;
  height: 48px;
  color: #f5f0ec;
  background-color: #171717;
  border-radius: 30px;
  font-weight: 400;
}

.button:hover {
  font-weight: 900;
}

.button1 {
  width: 182px;
  height: 48px;
  color: grey;
  /* background-color: #171717; */
  border-radius: 30px;
  /* font-size: 1vw; */
}
.prices {
  align-items: center;
  display: flex;
  justify-content: center;
}
.strick {
  color: #cbcbcb;
  font-weight: 600;
  text-decoration: line-through;
}
.original {
  color: #1a1818;
  font-weight: 600;
  position: relative;
}
.prices div {
  padding: 0px 20px;
  font-size: 35px;
}
.original:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 100%;
  /* transform: scaleX(0); */
  border: solid 3px #e6caca;
  border-color: #e6caca transparent transparent transparent;
  border-radius: 35%;
  transform-origin: 0;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}
@media only screen and (max-width: 500px) {
  .card {
    width: 90% !important;
    max-width: 100% !important;
  }
  .container {
    max-width: 100% !important;
  }
  .container {
    margin-top: 2vh !important;
  }
  .card_title {
    font-size: 1.5rem !important;
    margin-bottom: 20px !important;
  }
  .price {
    font-size: 1rem !important;
  }
  .sub {
    font-size: 4vw;
  }

  .button {
    font-size: 14px;
    padding: 9px 21px;
    border-radius: 30px;
    width: 100%;
    height: auto;
  }
  .header {
    font-size: 19px;
    font-weight: 500;
  }

  .prices div {
    padding: 0px 13px;
    font-size: 18px;
  }
}

@import url('https://fonts.cdnfonts.com/css/sofia-pro');

/* .container{
    
    font-family: 'Quicksand';
    background-color: #F7F7F7;
    max-width: 100% !important;
    margin: 0px !important;
    
} */

.header {
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 10px;
}

.card {
  width: 100%;
  /* height: 280px; */
  background-color: #ffffff !important;
  border-radius: 0px !important;
  border-right: none;
  border-left: none;
  border-bottom: none;
  box-shadow: 0px 3px 10px #0000002e;
  /* -webkit-filter: drop-shadow(0px 2px 3px rgb(0,0,0,18%));
  filter: drop-shadow(0px 2px 3px rgb(0,0,0,18%)); */
  padding: 20px;
  min-height: 305px;
}
.cardSubText {
  font-size: 14px;
  font-weight: normal;
  font-family: 'Quicksand';
  color: black;
}

.card_title {
  font-size: 1.5rem !important;
  font-weight: bolder !important;
  text-transform: capitalize !important;
}

.price {
  font-family: 'Sofia Pro', sans-serif;
  width: 100% !important;
  font-size: 1rem;
}

.sub {
  width: 50% !important;
  margin-top: 3vh !important;
  font-weight: 500;
}

.button {
  width: 182px;
  height: 48px;
  color: #f5f0ec;
  background-color: #171717;
  border-radius: 30px;
  font-weight: 400;
}

.button:hover {
  font-weight: 900;
}

.Enhanced {
  position: relative;
  margin-right: 1rem;
  width: max-content;
  margin: auto;
}

.Enhanced:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  /* transform: scaleX(0); */
  border: solid 3px #eab146;
  border-color: #eab146 transparent transparent transparent;
  border-radius: 35%;
  transform-origin: 0;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

.Exclusive {
  position: relative;
  margin-right: 1rem;
  width: max-content;
  margin: auto;
}

.Exclusive:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  border: solid 3px #a1cfdb;
  border-color: #a1cfdb transparent transparent transparent;
  border-radius: 35%;
  transform-origin: 0;
  transition-delay: 0.5s;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition-property: transform 0.25s ease-out;
}

.Express {
  position: relative;
  margin-right: 1rem;
  width: max-content;
  margin: auto;
}

.Express:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  /* transform: scaleX(0); */
  border: solid 3px #d99fa6;
  border-color: #d99fa6 transparent transparent transparent;
  border-radius: 35%;
  transform-origin: 0;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}
.included {
  width: 100%;
  text-align: center;
  color: #101010;
  font-weight: 600;
  font-size: 30px;
  margin-top: 55px;
  margin-bottom: 30px;
  width: 100%;
}
/* .underline:hover:after{
    transform: scaleX(1);
    transform-origin:bottom left;
  } */

.cardss {
  width: 17.5%;
  margin-right: 30px;
}
.cardss:last-child {
  margin-right: 0px;
}
.images {
  width: 40px;
}
.title {
  text-align: left;
  color: #101010;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.content {
  text-align: left;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
}
.designButton {
  padding: 10px 25px;
  font-size: medium;
  border-radius: 25px;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  color: #f5f0ec;
  background-color: black;
  font-weight: 400;
  font-size: 1vw;
}
.textSub {
  text-align: center;
}
.prices {
  align-items: center;
  display: flex;
  justify-content: center;
}
.strick {
  color: #cbcbcb;
  font-weight: 600;
  text-decoration: line-through;
}
.original {
  color: #1a1818;
  font-weight: 600;
  position: relative;
}
.prices div {
  padding: 0px 20px;
  font-size: 35px;
}
.original:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 100%;
  /* transform: scaleX(0); */
  border: solid 3px #e6caca;
  border-color: #e6caca transparent transparent transparent;
  border-radius: 35%;
  transform-origin: 0;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}
@media only screen and (max-width: 500px) {
  .card {
    width: 90% !important;
    max-width: 100% !important;
    height: max-content;
  }
  .container {
    max-width: 100% !important;
  }
  .container {
    margin-top: 2vh !important;
  }
  .newPackage_included__1KT3S {
    font-size: 24px;
    margin-top: 27px;
  }
  .cardss {
    width: 90%;
    margin-right: 0px;
  }
  .designButton {
    font-size: 14px;
  }
  .images {
    margin: 0px auto;
  }
  .title {
    text-align: center;
  }
  .content {
    text-align: center;
  }
  .included {
    margin-top: 35px;
    font-size: 25px;
  }
  .cardss .card {
    max-height: auto;
    height: 250px;
    min-height: auto;
  }
  .prices div {
    padding: 0px 13px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .packages {
    width: 85% !important;
  }
  .card {
    min-height: 325px;
  }
}
