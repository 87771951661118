.curatedMainCont{
    width: 100%;
    margin-top: 75px;
    min-height: 100vh;
    /* padding-bottom: 200px; */
}

.filterCont{
    padding-top: 20px;
    width: 100%;
    background-color: #F1EEEA;
    box-shadow: 0px 4px 12px 0px #0000000F;
    
}

.filterInnerCont{
    display: flex;
    flex-direction: row;
    width: 80%;
    padding: 20px 10px;
    margin: auto;
    justify-content: space-between;
    

}


.filterInnerHeading{
    font-weight: 700;
    font-size: 24px;
    color: #171717;
    margin-bottom: 1rem
}

.badgeContainer{
    display: flex;
    flex-wrap: wrap;
}

.budgetContainer{
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 1rem
}


.sliderBox{
    width: 120px;
    height: 39px;
    background: #FFFFFF;
    border: 0.5px solid #A5A5A5;
    margin-right: 0.5rem;
    padding: 4px;
}



.sliderBoxInner{
    margin-bottom:2.5px;
    line-height:1;
    font-size: 11px;
    font-weight: 400;
    color:#A5A5A5;
}

.sliderBoxInnerValue{
    margin:0;
    line-height:1;
    font-size: 13px;
    font-weight: 500;
    color: #171717;
}

.filterBtnCont{
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 1rem;
    align-items: end;
}

.trendingThemesContainer{
    width: 80%;
    padding-top: 4rem;
    padding-bottom: 2rem;
    margin: auto;
}

.trendingThemesContTitle{
    font-size: 28px;
    font-weight: 700;
    color: #171717;
}

.moodboardCont{
    width: 80%;
    max-width: 1250px;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 4rem;

}

.moodboardContTitle{
    font-size: 16px;
    font-weight: 600;
    color: #171717;
    margin-left: 8px;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.moodboardContTitle:hover{
    text-decoration: underline;
}

.moodBoardCard{
    width: 100%;
    max-width: 1250px;
}

.stackedCarouselContainer{
    width: 100%;
    /* background-color:  #F1EEEA; */
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background: linear-gradient(to bottom, #F1EEEA 70%, #ffffff 70%);
    background-image: url(https://d3izcfql7lesks.cloudfront.net/semiCirclesVectorBg.svg);
    background-size: 100%;

    /* height: 500px; */
}

.stackedCarouselContTittle{
    color: #171717;
    font-size: 28px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.stackedCarouselBadgeContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: 2rem;
    
}



.stackedCarouselContainerInner{
    width: 80%;
    margin:auto
}

.stackedCarouselContainerInnerMost{
    width: 80%;
    margin:auto
}

.sliderInput{
    font-size: 13px;
    border: none;
    outline: none;
    width: 100%;
    line-height: 1
}

@media only screen and (max-width: 1000px) {
    .filterInnerCont{
        flex-direction: column;
        width:100%;
        justify-content: unset;
    }

    .filterBtnCont{
        justify-content: start;
    } 

    .moodboardCont{
        width: 90%;
    }

    .moodboardContTitle{
        text-decoration: underline;
    }

    .stackedCarouselContainerInner{
        width: 90%;
     
    }

    .stackedCarouselContainerInnerMost{
        width: 90%;
     
    }

    /* .stackedCarouselContainer{
        height: 450px;
    } */

}

@media only screen and (max-width: 600px) {
    .filterInnerHeading{
        font-size: 16px;
    }

    .moodboardCont{
        width: 100%;
        padding: 10px;
    }

    .trendingThemesContainer{
        width: 100%;
        padding: 25px 10px 10px 10px;
    }

    .stackedCarouselContainer{
        width: 100%;
        /* background-color:  #F1EEEA; */
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;
        background: linear-gradient(to bottom, #F1EEEA 70%, #ffffff 70%);
        background-image: url(https://d3izcfql7lesks.cloudfront.net/semiCirclesVectorBg.svg);
        background-size: 100%;
        height: 560px;
    }
    /* .stackedCarouselContainer{
        height: 350px;
    } */

}