.pageContainer {
  /* margin-right: -15px !important; */
  overflow: hidden;
}
.mainContainer {
  max-width: 85% !important;
  margin-top: 100px;
}
.inputGst {
  width: 100%;
}
.inputGst input {
  width: 95%;
  padding: 5px 10px;
  font-size: 14px;
  height: 42px;
  border: 1px solid #a5a5a5;
  color: #000;
  margin-top: 15px;
}
.inputGst input:focus {
  border: 1px solid #000000 !important;
}
.card {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 23px;
}
.gstTxt {
  font-weight: 800;
  float: left;
  font-size: 17px;
}
.card h5 {
  font-size: 16px;
  font-weight: 900;
}
.applyBtnss {
  border-radius: 4px;
  width: 30%;
  padding: 7px 0px;
  color: #1a1818;
  border: 1px solid #707070;
  font-weight: 700;
  background-color: transparent;
}
.promoIn {
  width: 70%;
  border: 1px solid #707070;
  padding: 7px 6px;
  border-right: 0.5px solid #707070;
  border-radius: 4px;
}
.promoIn:focus {
  width: 70%;
  border: 1px solid #707070 !important;
  padding: 7px 6px;
  border-right: 0.5px solid #707070;
  border-radius: 4px;
}
.qtyBtnss {
  border-radius: 30px;
  border: 0.5px solid #707070;
  background-color: rgb(255, 255, 255);
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.cartData {
  font-size: 14px;
  line-height: 23px;
}
.card span {
  font-size: 15px;
  font-weight: 100;
}

.card1 {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 20px;
}

.card1 h5 {
  font-size: 17px;
  font-weight: 900;
}

.card1 span {
  font-size: 15px;
  font-weight: 100;
}

.priceContainer {
  display: flex;
  /* flex:1; */
  text-decoration: underline;
  margin-top: 5vh;
  /* margin-left: 10vw; */
  justify-content: space-between;

  position: absolute;
  /* justify-content: center; */
}
.editAddress {
  font-size: 17px;
  font-weight: 400;
  cursor: pointer;
}

.summaryContainer {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  /* height: 80vh; */
  position: relative;
  background-color: #ffffff;
  margin-bottom: 50px;
  margin-top: 0px !important;
}

.summaryContainer h3 {
  font-size: 17px;
  padding-left: 30px;
  font-weight: 900;
  margin-bottom: 0px;
}
.myCart {
  font-weight: 600;
}
.couponBtn {
  width: 100%;
  height: 50px;
  color: black;
  background-color: white;
  border-radius: 5px;
  margin-top: 2vh;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  opacity: 1;
  font-size: 14px;
}

.summarySubContainer {
  width: 85%;
  /* height: 200px; */
  position: relative;
  margin: 18px auto;
  /* top: 20%; */
  /* left: 10%; */
}
.orderSum {
  width: 100%;
  border-bottom: 1px solid #000;
  position: relative;
  padding: 16px 0px;
  padding-bottom: 23px;
}
.summary_headings {
  border-bottom: 1px solid rgba(188, 188, 188, 0.5);
  padding-bottom: 8px;
  padding-top: 2vh;
}
.summary_headings span {
  font-size: 13px;
  font-weight: 600;
}
.heading {
  font-size: 16px;
  font-family: 'Quicksand';
  font-weight: bold;
}

.checkoutBtn {
  width: 100%;
  height: 50px;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  margin-top: 5vh;
  font-weight: 600;
  font-size: 14px;
}
.promoBtn {
  text-align: center;
  width: 100%;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 500;
}
.qtyBtn {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 50%;
  background-color: transparent;
}

.deliveryBtn {
  background-color: #000000;
  border-radius: 5px;
  padding: 13px 15px;
  /* width: 200px; */
  /* height: 46px; */
  font-weight: bold;
  font-size: 13px;
  font-family: 'QuickSand';
  color: #ffffff;
  margin-top: 26px;
}
.paymentBtn {
  background-color: #000000;
  border-radius: 5px;
  padding: 7px;
  /* width: 211px;
  height: 46px; */
  font-weight: 900;
  color: #ffffff;
  font-size: 14px;
}
.modalHeads {
  font-weight: 600;
  margin-bottom: 36px;
}
.applyButton {
  background-color: white;
  border: solid 1px;
  width: 84px;
  border-radius: 5px;
  padding: 3px 0px;
}

.buttonContainer {
  width: 211px;
  display: inline-flex;
  justify-content: between;
  margin-top: 10px;
}

.checkoutContainer {
  width: 100%;
  height: auto;
  margin-top: 3vh;
  background-color: #ffffff;
  padding: 40px !important;
}

.checkoutRecentContainer {
  width: 89%;
  height: auto;
  margin-top: 3vh;
  background-color: #ffffff;
  padding: 40px !important;
}

.shipmentContainer {
  width: 100%;
  height: auto;
  padding: 40px;
  margin-top: 2vh;
  background-color: #ffffff;
}

.shipmentContainer span {
  font-size: 15px;
  font-weight: 100;
}

.shipmentContainer h4 {
  font-size: 21px;
  font-weight: 800;
}

.shipmentContainer h5 {
  font-size: 20px;
  font-weight: 800;
}

.shipmentContainer h6 {
  font-size: 19px;
  font-weight: 400;
}

.edit_btn {
  background-color: #ffffff;
  color: black;
  width: 100px;
  border: 0.5px solid #1a1818;
  border-radius: 5px;
}

.delete_btn {
  background-color: #ffffff;
  color: black;
  width: 100px;
  border-radius: 5px;
  margin-left: 10px;
  border: 0.5px solid #1a1818;
}

.card img {
  width: 100%;
}
.checkoutContainer h5 {
  font-size: 17px;
  font-weight: 500;
  /* margin-bottom: 25px; */
}

.checkoutRecentContainer p {
  font-size: 15px;
}

.checkoutInput {
  width: 92%;
  height: 40px;
  border: 1px solid #a5a5a5;
  padding: 0px 9px;
  font-size: 14px;
}
.checkoutInput:focus {
  border: 1px solid #000000 !important;
}
.addBtn {
  font-size: 14px !important;
  margin-top: 25px !important;
  margin-bottom: 0px !important;
}
.checkoutContainer span {
  font-size: 13px;
  font-weight: 400;
}

.modalContainer {
  width: 100%;
  /* height: 60vh; */
  position: relative;
  margin-top: 5vh;
  text-align: center;
}

.modal_input_container {
  width: 90% !important;
  margin: auto !important;
  text-align: left;
  /* margin-top: 20px !important; */
}

.modal_input_container label {
  font-size: 1vw;
}

.modalContainer h4 {
  width: 100% !important;
  text-align: center;
  font-weight: 100;
}

.addresses_new {
  width: 100%;
  background-color: #d2edf5;
  padding: 20px;
  font-weight: 800;
  height: 70px;
}

.modal_input {
  width: 95%;
  padding: 5px;
  font-size: 14px;
  height: 34px;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
  color: #000;
}

.modal_input:focus {
  border: 0.5px solid !important;
}

.couponTitle {
  font-family: 'QuickSand';
  font-size: 26px;
  font-weight: bold;
  text-decoration-thickness: 'from-font';
}
.couponDesc {
  font-family: 'QuickSand';
  font-size: 15px;
  font-weight: medium;
  text-decoration-thickness: 'from-font';
}

.applyBtn {
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  border-radius: 4px;
}

.disableBtn {
  background-color: #a8a8a8;
  color: #ffffff;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  border-radius: 4px;
}
.checkoutContainer select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png);
  background-repeat: no-repeat;
  background-size: 4%;
  background-position-x: 96%;
  background-position-y: 13px;
  border: 1px solid #a5a5a5;
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
  box-shadow: none;
  border-radius: 0px;
  opacity: 1;
}
@media only screen and (max-width: 500px) {
  .mainContainer {
    max-width: 100% !important;
  }
  .card {
    width: 100% !important;
  }
  .summaryContainer {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    /* height: 100vh; */
    position: relative;
    background-color: #ffffff;
  }

  .checkMainContainer {
    margin-top: 100px !important;
  }
  .card img {
    width: 100%;
  }
  .card h5 {
    font-size: 13px;
  }
  .card h6 {
    font-size: 12px;
  }
  .card span {
    font-size: 12px;
  }

  .card1 {
    width: 100% !important;
    height: 200px;
  }

  .card1 img {
    width: 160%;
  }
  .card1 h5 {
    font-size: 13px;
  }
  .card1 h6 {
    font-size: 12px;
  }
  .card1 span {
    font-size: 12px;
  }

  .priceContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .summaryContainer {
    margin-top: 12vh;
  }

  .checkoutRecentContainer {
    width: 100%;
  }

  .checkoutInput {
    width: 100%;
    margin-top: 15px;
    padding-left: 10px;
    outline: none;
    box-shadow: none;
  }
  .checkoutInput:focus {
    box-shadow: none !important;
  }
  .modalContainer {
    width: 100%;
    height: 500px;
    position: relative;
    margin-top: 50px !important;
    /* margin-bottom: 200px !important; */
    text-align: center;
    padding-bottom: 5vh;
    /* overflow-y: auto; */
  }

  .modal_input_container {
    width: 90% !important;
    /* margin: auto !important; */
    text-align: left;
    margin-top: 0px !important;
  }

  .modal_input_container label {
    font-size: 14px;
  }

  .modalContainer h4 {
    width: 100% !important;
    text-align: center;
    font-weight: 100;
  }

  .modal_input {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    height: auto;
    padding: 9px 13px;
    margin-top: 6px;
  }
  .modal .modal-dialog {
    width: 90% !important;
    position: relative !important;
    display: flex;
    /* top: -5%; */
    left: 7%;
    background-color: transparent !important;
    max-width: 80% !important;
    height: 100% !important;
    margin-top: 100px;
    margin-bottom: 200px;
  }
  .myCart {
    font-weight: 500;
    font-size: 17px;
  }
  .continueShop {
    margin-top: 15px;
    margin-left: 4px;
    font-size: 14px;
  }
  .heading {
    font-size: 14px;
  }
  .checkoutContainer {
    padding: 22px !important;
  }
  .checkoutContainer h5 {
    font-size: 16px;
  }
  .addressPart p {
    font-size: 13px !important;
  }
  .addNew {
    font-size: 16px;
  }
  .coupenDra {
    margin-left: 24px;
  }
}
a {
  color: #000;
}
