.header{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0B0E15;
    color: #DFD9D6;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    position: fixed;
    top:0;
    z-index: 10;
}

.headerContainerWeb{
    width: 90%;
    height: 75px;
    display: flex;
    justify-content: space-between ;
    align-items: center;

}
.bottomHeader{
    position: relative;
    display: flex;
    font-size: large;
    width: 90%;
    background-color: #0B0E15;
    align-items: center;
    justify-content: center;
}

.headerContainerMob{
    display: none;
}

.headerLogo{
    height: 2.2em;
    cursor: pointer;
}

.cartLogo{
    position: relative;
    cursor: pointer;
}

.cartBadge{
  position: absolute;
  right:-8px;
  font-size: 10px;
  font-weight: 900;
  background-color: #edd3d8ed;
  width: 15px;
  height: 15px;
  color: #000000;
  border-radius: 10px;
  text-align: center;
}

.accountLogo{
    margin-left: 1.5em;
    cursor: pointer;
}

.headerNavigation{
    padding: 0 15px;
    font-size: 16px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {

.header{
    height: 100px;
}

.headerContainerWeb{
    display: none;
}

.headerContainerMob{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 90%;
    /* padding-top: 15px; */
}

.mobHeader{
    display: flex;
    justify-content: space-between ;
    align-items: center;
}

.headerNavigation{
    font-size: 12px;
}
.bottomHeader{
    display: none !important;
}
.bottomHeaderMob{
    position: relative;
    display: flex;
    font-size: medium;
    width: 100%;
    background-color: #0B0E15;
    align-items: center;
    justify-content: center;
}

}