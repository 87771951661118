.itemContainer {
  /* width: 287px;
  height: 287px; */
  border: 0.5px solid rgba(112, 112, 112, 0.5);
}
.shareLink {
  width: 81%;
}
.shareBoards {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.saveButton:hover,
.copyLinkBtn:hover,
.deleteBtn:hover,
.cancelBtn:hover,
.createBoardButton1:hover,
.createBoardButton:hover {
  font-weight: 900;
}
.saveBoards {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.createBoard {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.overlay {
  background-color: #2c2c2c75;
  position: absolute;
  z-index: 1 !important;
  height: 100%;
  width: 100%;
}
.prices {
  font-size: 19px;
  margin-top: 14px;
}
.imgsView {
  margin-top: 30px;
}
.imageCol {
  justify-content: center;
  /* display: flex; */
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px !important;
}
.nameTitle {
  font-size: 19px;
  font-weight: 400;
}
.renderContainer {
  /* width: 287px;
  height: 287px; */
  border: 0.5px solid rgba(112, 112, 112, 0.5);
  margin-top: 41px;
  position: relative;
  height: 272px;
}
.renderContainer img:last-child {
  height: 100%;
}
.saveBtn {
  float: right;
  border-radius: 20px;
  opacity: 1;
  border: none;
  background-color: #ffffff;
  margin-top: 20px;
  margin-right: 10px;
  font-size: 14px;
  font-family: 'QuickSand';
  padding: 5px 15px 5px 15px;
}

.deleteIcon {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  background-color: white;
  padding: 0px 7px !important;
  width: 32px !important;
  height: 32px !important;
  border-radius: 30px;
  box-shadow: 4px 3px 6px #979797;
  margin-top: 18px;
}

.buttonWhite {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: black;
  background-color: white;
  padding: 7px 0px;
  font-weight: 600;
  width: 90%;
  font-size: 13px;
}
.modalImage {
  background: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/socialShare.png);
  background-repeat: no-repeat;
  background-size: 32%;
}
.ShareModalHeader {
  height: 40px;
  color: #000000;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.ShareModalHeader h4 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 27px;
}
.copyLinkBtn {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  right: 0;
  top: 0;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
  padding: 0px 18px;
  font-weight: 800;
}
.shareContent {
  border-radius: 20px;
  border: 1px solid #a5a5a5;
  font-size: small;
  width: 100%;
  padding: 15px 0px !important;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  padding-left: 22px !important;
  color: #1c1c1c;
}
.shareLink {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
/* .modalRow_share {
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
} */
.modalContainer p {
  margin-bottom: 0px !important;
  font-size: 14px;
}
.productImg {
  width: 100%;
  height: 236px;
  /* margin-top: 22px; */
}
.saveExplore {
  position: absolute;
  bottom: 0px;
  margin-left: 131px;
  margin-bottom: 10px;
  height: 25px;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 25px;
  padding-bottom: 25px;
  border: none;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  background-color: rgb(255, 255, 255);
  width: 21%;
}

.copyLinkBtn {
  float: right;
  position: absolute;
  width: 20%;
  right: -98px;
  top: 2px;
  height: 35px;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
}
.link {
  font-size: 16px;
}
.buttonBlack {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: white;
  background-color: black;
  padding: 7px 0px;
  font-size: 13px;
  font-weight: 900;
  /* height: 40px; */
  width: 90%;
}

.saveButton {
  width: 128px;
  height: 35px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  float: left;
  margin-top: 60px;
  /* margin-left: 30px; */
  font-size: 14px;
}
.modalRows .createCol {
  padding-top: 21px;
  max-height: 90vh;
  overflow-y: scroll;
}
.viewBtn {
  border-radius: 20px;
  width: 146px;
  height: 34px;
  background-color: #ffffff;
  position: absolute;
  bottom: 10%;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  border: none;
}

.button {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
}

.container2 {
  background: url('https://d3izcfql7lesks.cloudfront.net/Group_3157_axoy65.png');
  max-width: 110%;
  /* height: 900px;  */
  background-color: #f5f0ec;
  padding-top: 55px;
  padding-bottom: 102px;
}
.view {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: white;
  position: absolute;
  z-index: 1000;
  bottom: -21px;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 5px #2125295e;
}
.container2 h4 {
  font-weight: 500;
  font-size: 22px;
}
.header_btn {
  border-radius: 20px;
  padding: 3px 21px;
  margin-left: 10px;
  border: 0.5px solid #a0a0a0;
  background-color: #ffffff;
  font-size: 14px;
  font-family: 'QuickSand';
  /* padding-left: 10px;
  padding-right: 10px; */
}
.modalHeader {
  background-color: #141414;
  height: 56px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.modalRows .createCol {
  padding-top: 21px;
  padding-bottom: 10%;
}
.modalRow {
  height: 300px;
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.modalRows {
  /* height: 300px; */
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.modalRow_share {
  background: url('https://d3izcfql7lesks.cloudfront.net/Mask_Group_24_tevtsk.png');
  /* height: 399px; */
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}

.modalRow_delete {
  background-color: #f2feff;
  height: 336px;
  max-height: 100%;
  padding-top: 13px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}

.modalRow_delete h4 {
  font-weight: 800;
  /* width: 44%; */
  text-align: center;
  font-size: 19px;
  padding: 0px 26%;
  line-height: 27px;
}
.deleteTxt {
  font-size: 13px;
}
.createCol {
  /* height: 399px; */
  background-color: #f2feff;
  padding-left: 30px !important;
  padding-top: 49px;
}
.wholeBoards {
  margin-top: auto;
  margin-bottom: auto;
}
.createCol h6 {
  margin-top: 5vh;
  font-weight: 100 !important;
}
.boardTiles {
  margin-top: auto;
  margin-bottom: auto;
}
.createBoardButton {
  width: 128px;
  height: 31px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  font-size: 13px;
  margin-left: 30px;
  border: none;
  float: left;
  /* margin-top: 3vh; */
}
.createBoardButton1 {
  width: 128px;
  height: 35px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  float: left;
  margin-top: 60px;
  /* margin-left: 30px; */
  font-size: 14px;
}
.createCol input {
  margin-top: 10px;
  margin-left: 0px;
  font-weight: 600;
  background-color: transparent;
  width: 86%;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #707070;
  padding-bottom: 4px;
  color: #1a1818;
}
.createCol input:focus {
  outline: none;
}
.shareFacebookBtn {
  height: 50px !important;
  width: 214px;
  border: 1px solid #bfbfbf;
  background-color: #ffffff;
  border-radius: 30px;
}

.copyLinkBtn {
  float: right;
  position: absolute;
  width: 100px;
  right: -98px;
  top: 2px;
  height: 35px;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
}

.shareLink {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 24px;
}

.cancelBtn {
  width: 123px;
  height: 34px;
  border: 1px solid #1a1818;
  background-color: transparent;
  border-radius: 20px;
  font-size: 15px;
}

.deleteBtn {
  width: 123px;
  height: 34px;
  border: 1px solid #1a1818;
  background-color: #000000;
  color: #ffffff;
  font-weight: 500;
  border-radius: 20px;
  font-size: 15px;
}

.HoverContainer {
  position: absolute;
  text-align: left;
  z-index: 1;
  transform: translate(150%, -80%);
  font-size: 12px;
  background-color: #ffffff;
  border-radius: 20px;
  border: 2px solid #e2e2e2;
}

.HoverContainer ul {
  list-style: none;
  margin-left: -30px;
  margin-top: 10px;
  margin-right: 10px;
}

.shareContainer {
  height: 199px;
  padding: 20px 10px 0px 20px;
  width: 100%;
  background: url('https://d3izcfql7lesks.cloudfront.net/Mask_Group_24_tdsgnn.png');
  background-size: 100%;
}

.header {
  font-size: 14px;
  font-family: 'Quicksand';
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.renderCol {
  width: 25%;
}
.price {
  font-size: 15px;
  font-family: 'Quicksand';
  margin-top: -40px !important;
  font-weight: bold;
  padding-right: 0px;
}
.image2 {
  /* height: 70%; */
  width: 100%;
  border-radius: 10px;
}
.save1 {
  font-size: 14px;
}
.heart {
  font-size: 25px;
  color: red;
}
.editBoard {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.header1 {
  font-size: 22px;
  font-weight: 700;
}
.heart {
  box-shadow: 1px 1px 6px #00000042;
  border-radius: 50%;
  cursor: pointer;
  height: 41px;
  width: 41px;
  background-image: url('https://res.cloudinary.com/lab10/image/upload/v1621063036/samples/heart-edit_lx314x.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 2900%;
}

.heart:hover {
  background-position: right;
}

.is_animating {
  animation: heart-burst 0.8s steps(28) 1;
}
@keyframes heart-burst {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

@media only screen and (max-width: 500px) {
  .renderCol {
    width: 50% !important;
  }

  .save1 {
    font-size: 11px;
  }
  .renderCol {
    margin-bottom: 20px;
  }
  .imgOption {
    width: 100% !important;
    transform: none !important;
  }
  .createBoardButton {
    /* width: 128px; */
    /* height: 35px; */
    background-color: #000000;
    border-radius: 20px;
    color: #ffffff;
    /* margin-left: 20px; */
    border: none;
    float: left;
    font-size: 11px;
    /* margin-top: 10px; */
    /* font-size: 10px; */
  }

  .renderContainer {
    width: 100%;
    height: 100%;
  }
  .header {
    font-size: 12px;
    font-family: 'Quicksand';
    font-weight: bold;
  }
  br {
    display: none;
  }
  .modalHeader h4 {
    font-size: 15px;
    margin-top: 25px !important;
    margin-left: 25px !important;
    display: inline-flex;
  }
  .price {
    font-size: 14px;
    font-family: 'Quicksand';
    margin-top: -40px !important;
    font-weight: bold;
  }
  .HoverContainer {
    transform: translate(120%, -70%);
  }
  .modalRow_delete h4 {
    width: 90%;
    font-size: 17px;
  }
  .modalRow_delete h6 {
    font-size: 10px;
    margin-top: -30px;
  }
  .container2 {
    padding-top: 50px;
    height: auto !important;
  }
  .imageCol {
    height: 40%;
  }
  .createCol {
    /* min-height: 60vh; */
    /* height: 60%; */
    padding-bottom: 42px;

    background-color: #f2feff;
  }
  .image2 {
    /* height: 70%; */
    width: 100%;
    border-radius: 10px;
  }
  .createCol h6 {
    margin-top: 4vh;
  }
  .createCol input {
    width: 90%;
  }

  .saveExplore {
    position: absolute;
    bottom: 0px;
    margin-left: 131px;
    margin-bottom: 10px;
    height: 25px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 25px;
    padding-bottom: 25px;
    border: none;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    background-color: rgb(255, 255, 255);
    width: 21%;
  }

  .copyLinkBtn {
    float: right;
    position: absolute;
    width: 20%;
    right: -98px;
    top: 2px;
    height: 35px;
    color: #ffffff;
    border-radius: 20px;
    background-color: black;
    font-size: 12px;
  }
  .link {
    font-size: 11px;
  }

  .shareLink {
    position: relative;
    width: 100%;
    height: 35px;
    display: inline-flex;
    border-radius: 20px;
    border: 1px solid #f0f0f0;
    padding: 5px;
    padding-left: 10px;
    padding-top: 10px;
  }
  .modalRow {
    height: auto;
    max-height: 100%;
  }
}
