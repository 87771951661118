.container {
  margin-top: 130px !important;
  font-family: 'Quicksand';
}
.pageContainer {
 
  overflow: hidden;
}
.roomContainer {
  background-color: white;
  margin-bottom: 10px;
  padding: 20px;

}
.roomtype1 {
  margin-bottom: 10px;
 
}
.error {
  border: 1px solid red;
}
.error1 {
  color: red;
}
.headerRow {
  padding-bottom: 20px;
 
}
.applyButton {
  background-color: #fff;
  border: 1px solid;
  width: 84px;
  height: 40px;
  border-radius: 5px;
  padding: 3px 0;
}
.roomtype2 {
  margin-bottom: 10px;
  padding: 20px;
}

.heading {
  font-size: 16px;
  font-family: 'Quicksand';
  font-weight: bold;
}

.heading1 {
  font-size: 23px;
  font-family: 'Quicksand';
  font-weight: 600;
  padding-left: 0px;
  margin-bottom: 11px;
  margin-left: 12px;
}
.roomtype {
  position: relative;
  width: 100px;
  left: -15px;
  text-align: center;
}
.inputGst {
  width: 100%;
}
.inputGst input {
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  height: 42px;
  border: 1px solid #a5a5a5;
  color: #000;
  margin-top: 15px;
}
.gstTxt {
  font-weight: 800;
  float: left;
  font-size: 17px;
}
.inputGst input:focus {
  border: 1px solid #000000 !important;
}
.checkoutInput {
  width: 95%;
  padding: 5px;
  font-size: 14px;
  height: 40px;
  border: 1px solid #a5a5a5;
  color: #000;
  margin-bottom: 13px;
}
.modal_input_container .checkoutInput {
  width: 95%;
  padding: 5px;
  font-size: 14px;
  height: 34px;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
  color: #000;
  margin-bottom: 3px;
}
.modal_input_container .modalContainer {
  padding: 0px 20px;
}
.checkoutInput:focus {
  border: 1px solid #000000 !important;
}
.modal_input_container span {
  font-size: 13px;
  color: #000000;
  padding-bottom: 8px;
}
.checkoutBtn {
  width: 100%;
  height: 50px;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  margin-top: 5vh;
  font-weight: 600;
  font-size: 14px;
}
.promoBtn {
  text-align: center;
  width: 100%;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 500;
}
.nameContainer form {
  width: 90%;
}
.couponBtn {
  width: 100%;
  height: 50px;
  color: #000;
  background-color: #fff;
  margin-top: 2vh;
  border: 1px solid #aaa;
  border-radius: 5px;
  opacity: 1;
  font-size: 14px;
}

.summary_headings {
  border-bottom: 1px solid rgba(188, 188, 188, 0.5);
  padding-bottom: 8px;
  padding-top: 4px;
}

.summaryContainer {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  margin-bottom: 50px;
  margin-top: 0px;
}
.roomContainer h5 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 13px;
}
.summaryContainer h3 {
  font-size: 17px;
  padding-left: 30px;
  font-weight: 900;
  margin-bottom: 0px;
}
.card {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 23px;
}
.gstTxt {
  font-weight: 800;
  float: left;
  font-size: 17px;
}
.card h5 {
  font-size: 16px;
  font-weight: 900;
}
.editAddress {
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.summarySubContainer {
  width: 85%;
  position: relative;
  margin: 18px auto;
 
}
.orderSum {
  width: 100%;
  border-bottom: 1px solid #000;
  position: relative;
  padding: 16px 0px;
  padding-bottom: 23px;
}
.summary_headings {
  border-bottom: 1px solid rgba(188, 188, 188, 0.5);
  padding-bottom: 8px;
  padding-top: 2vh;
}
.summary_headings span {
  font-size: 13px;
  font-weight: 600;
}
.subHeading {
  font-size: 14px;
  font-family: 'Quicksand';
  font-weight: 600;
}

.checkoutRecentContainer {
  width: 89%;
  height: auto;
  background-color: #ffffff;
  padding: 20px !important;
}

.checkoutRecentContainer p {
  font-size: 15px;
}

.deliveryBtn {
  background-color: #000000;
  border-radius: 5px;
  padding: 7px;
  width: 211px;
  height: 46px;
  font-weight: 900;
  color: #ffffff;
}

.paymentBtn {
  background-color: #000000;
  border-radius: 5px;
  padding: 7px;
  font-weight: 900;
  color: #ffffff;
}
.buttonContainer {
  width: 211px;
  display: inline-flex;
  justify-content: between;
  margin-top: 10px;
}

.edit_btn {
  background-color: #ffffff;
  color: black;
  width: 100px;
  border: 0.5px solid #1a1818;
  border-radius: 5px;
}

.delete_btn {
  background-color: #ffffff;
  color: black;
  width: 100px;
  border-radius: 5px;
  margin-left: 10px;
  border: 0.5px solid #1a1818;
}
.couponTitle {
  font-family: 'QuickSand';
  font-size: 26px;
  font-weight: bold;
  text-decoration-thickness: 'from-font';
}
.couponDesc {
  font-family: 'QuickSand';
  font-size: 15px;
  font-weight: medium;
  text-decoration-thickness: 'from-font';
}

.applyBtn {
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  border-radius: 4px;
}

.disableBtn {
  background-color: #a8a8a8;
  color: #ffffff;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  border-radius: 4px;
}

.modalContainer {
  width: 100%;
  position: relative;
  margin-top: 5vh;
  padding: 0px 20px;
}

.modalContainer1 {
  width: 50%;
  height: 20vh;
  position: relative;
  margin-top: 5vh;
  text-align: center;
}
.modal_input_container {
  width: 95% !important;
  margin: auto !important;
  text-align: left;
  align-items: center;
}

.modal_input_container label {
  font-size: 1vw;
}

.modalContainer h4 {
  width: 100% !important;
  text-align: center;
  font-weight: 100;
}

.modal_input {
  width: 95%;
  padding: 5px;
  font-size: 14px;
  height: 34px;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
  color: #000;

}
.modal_input1 {
  width: 270px;
  padding: 5px;
  font-size: 1vw;
  height: 35px;
  border: 1px solid red;
}
.modal .modal-dialog {
  width: 90% !important;
  position: relative !important;
  display: flex;
  left: 7%;
  background-color: transparent !important;
  max-width: 80% !important;
  height: 100% !important;
  margin-top: 100px;
  margin-bottom: 200px;
}
.addNew {
  font-size: 18px;
  text-align: center;
  margin-left: 9px;
  margin-bottom: 16px;
}
.modal_input:focus {
  border: 0.5px solid !important;
}
.modal_input1:focus {
  border: 0.5px solid !important;
}

.deleteConfirm {
  font-size: 24px;
  font-family: 'Quicksand';
  font-weight: 800;
  padding: 10px;
}

.deleteInfo {
  font-size: 20px;
  font-family: 'Quicksand';
  font-weight: 600;
  padding: 10px;
}

.cancelBtn {
  width: 128px;
  height: 35px;
  border: 1px solid #1a1818;
  background-color: transparent;
  border-radius: 20px;
}

.deleteBtn {
  width: 128px;
  height: 35px;
  border: 1px solid #1a1818;
  background-color: #000000;
  color: #ffffff;
  font-weight: 500;
  border-radius: 20px;
}

.modalRow_delete {
  background-color: #f2feff;
  height: 399px;
  max-height: 100%;
  padding-top: 80px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.rooms {
  font-weight: 600;
  align-items: center;
}
.price {
  margin-top: 18px;
}
.textTrans {
  text-transform: uppercase;
  font-size: 13px;
}
.editStyle small {
  font-weight: 600;
  font-size: 14px;
}
.selectTypes {
  word-wrap: normal;
  color: #383838;
  border: 0.5px solid #b9b9b9;
}
.addRoom {
  font-weight: 500;
  font-size: 15px;
}
@media only screen and (max-width: 500px) {
  .modalContainer {
    width: 100%;
    height: auto;
    position: relative;
    margin-top: 50px !important;
    margin-bottom: 200px !important;
    text-align: center;
    padding-bottom: 5vh;
  }

  .modal_input_container {
    width: 90% !important;
    text-align: left;
    margin-top: 0px !important;
  }

  .modal_input_container label {
    font-size: 2vw;
  }

  .modalContainer h4 {
    width: 100% !important;
    text-align: center;
    font-weight: 100;
  }

  .modal_input {
    font-size: 15px;
    font-weight: 700;
    height: auto;
    padding: 10px 14px;
  }
  .paymentBtn {
    margin-bottom: 16px;
  }

  .paymentMethod {
    background-color: #f5f5f5;
    width: 100%;
  }

  .paymentMethod:hover {
    background-color: black;
    color: white;
  }

  .dropDownStyled {
    appearance: none;
    color: #383838;
    font-weight: 500;
    background-image: url('../../assets/images/dropDown.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
    border-radius: 5px;
    width: 100%;
    height: calc(1.5em + 0.75rem + 3px);
    padding: 0.5rem 1rem;
    background-color: #ffffff;
    border: 1px solid #ced4da !important;
  }

  .dropDownStyled2 {
    width: 96%;
    padding: 0px 10px;
    height: 50px;
    margin-top: 5px;
    appearance: none;
    color: #383838;
    font-weight: 500;
    background-image: url('../../assets/images/dropDown.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
    border-radius: 5px;
    border: 1px solid #a5a5a5 !important;
  }

  .dropDownStyled2:focus,
  .dropDownStyled:focus {
    border: 1px solid #000000 !important;
  }
  .modalContainer {
    margin-bottom: 0px !important;
  }
  .paymentBtn {
    font-size: 11px;
  }
}
