.container {
  max-width: 90% !important;
  font-family: 'Quicksand';
}

.mesonry {
  text-align: center;
  display: flex;
  justify-content: center !important;
  width: auto;
}

.mesonry div:nth-child(2) div:first-child,
.mesonry div:nth-child(2) div:first-child img {
  height: 100%;
  object-fit: cover;
}
.myBoardContainer:nth-child(odd) {
  float: left;
  margin: 0px;
}
.myBoardContainer:nth-child(even) {
  padding: 0px;
  float: right;
}
.mesonry div:nth-child(2) div:last-child img {
  height: 97px;
  object-fit: cover;
}
.menus {
  display: inline-flex;
  justify-content: space-between;
}
.menus p {
  font-size: 0.9rem !important;
  text-align: left;
  padding-left: 5px;
  cursor: pointer;
  font-weight: 500;
}
.editBoard {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mesonryItems {
  text-align: center;
  justify-content: center !important;
  width: 50% !important;
}

.spaceGirdImg {
  width: 100%;
  /* padding: 5px; */
  height: 100%;
  object-fit: cover;
  border: 0.5px solid #e3e3e3;
  cursor: pointer;
  /* border-radius: 10px; */
}
.mesonry div:nth-child(1) div:nth-child(1) {
  height: 100%;
}
.mesonry div:nth-child(2) {
  /* height:100%; */
}
.mesonry div:nth-child(3) {
  height: 100%;
}
/* .mesonry div:first-child div:last-child{
  height:100%;
} */
.mesonry div:nth-child(2) div:first-child,
.mesonry div:nth-child(2) div:last-child {
  /* height:auto; */
}
.myBoardContainer {
  max-width: 40% !important;
  min-height: 250px;
  padding: 0px;
}

.button {
  width: 140px;
  height: 45px;
  border-radius: 10px 10px 0 0;
  border: none;
  float: right;
  margin-top: -7.5vh;
  margin-right: 5vw;
  position: sticky;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  background-image: linear-gradient(to right, #ffe5a0 0%, #ffce4a 100%);
  font-size: 20px;
  font-weight: bold;
}
.saveButton:hover,
.copyLinkBtn:hover,
.deleteBtn:hover,
.cancelBtn:hover,
.createBoardButton:hover {
  font-weight: 900;
}
.saveButton {
  width: 128px;
  height: 35px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  float: left;
  margin-top: 60px;
  /* margin-left: 30px; */
  font-size: 14px;
}
.createBoard {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.createBoardButton {
  width: 128px;
  height: 35px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  float: left;
  margin-top: 60px;
  /* margin-left: 30px; */
  font-size: 14px;
}
.modal {
  width: 35% !important;
}
.modalHeader {
  background-color: #141414;
  height: 56px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ShareModalHeader {
  height: 40px;
  color: #000000;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.modalRow {
  height: 300px;
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}

.createCol {
  /* height: 60%; */
  background-color: #f2feff;
  padding-left: 30px !important;
  padding-top: 49px;
}
.wholeBoards {
  margin-top: auto;
  margin-bottom: auto;
}
.shareLink {
  position: relative;
}

.shareContent {
  border-radius: 20px;
  border: 1px solid #a5a5a5;
  font-size: small;
  width: 77%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  padding-left: 22px !important;
  color: #1c1c1c;
}

.shareContainer {
  height: 199px;
  padding: 20px 10px 0px 20px;
  width: 100%;
  background: url('https://d3izcfql7lesks.cloudfront.net/Mask_Group_24_tdsgnn.png');
  background-size: 100%;
}
.ShareModalHeader h4 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 27px;
}
.createCol h6 {
  margin-top: 5vh;
  font-weight: 100 !important;
}
.modalImage {
  background: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/socialShare.png');
  background-repeat: no-repeat;
  background-size: 32%;
}
.modalRow_share {
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}

.cancelBtn {
  width: 123px;
  height: 34px;
  border: 1px solid #1a1818;
  background-color: transparent;
  border-radius: 20px;
  font-size: 15px;
}

.modalRow_delete {
  background-color: #f2feff;
  height: 336px;
  max-height: 100%;
  padding-top: 13px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}

.deleteBtn {
  width: 123px;
  height: 34px;
  border: 1px solid #1a1818;
  background-color: #000000;
  color: #ffffff;
  font-weight: 500;
  border-radius: 20px;
  font-size: 15px;
}

.modalRow_delete h4 {
  font-weight: 800;
  width: 40%;
  text-align: center;
}

.copyLinkBtn {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  right: 0;
  top: 0;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
  padding: 0px 18px;
  font-weight: 800;
}

.shareLink {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.shareFacebookBtn {
  height: 50px !important;
  width: 214px;
  border: 1px solid #bfbfbf;
  background-color: #ffffff;
  border-radius: 30px;
}

.createCol input {
  margin-top: 10px;
  margin-left: 0px;
  font-weight: 600;
  background-color: transparent;
  width: 86%;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #707070;
  padding-bottom: 4px;
  color: #1a1818;
}
.header {
  font-weight: 700;
  font-size: 17px;
  padding: 0px 10px;
}
.renderCol {
}
.imageCol {
  justify-content: center;
  /* display: flex; */
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px !important;
}

.deleteConfirm {
  font-weight: 800;
  /* width: 44%; */
  text-align: center;
  font-size: 19px;
  padding: 0px 26%;
  line-height: 27px;
}

.deleteInfo {
  font-size: 20px;
  font-family: 'Quicksand';
  font-weight: 600;
  padding: 10px;
}

.buttonWhite {
  border-radius: 20px;
  color: black;
  background-color: white;
  padding: 5px 3em 5px 3em;
  font-weight: 400;
  height: 40px;
  width: 100%;
  font-size: 13px;
}

.fullScreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  /* opacity: 1px; */
  top: 0;
  left: 0;
}
.fullScreen_top {
  bottom: 19vh;
  right: 0;
  margin-right: 3.8vw;
  position: fixed;
}
.fullScreen_bottom {
  bottom: 10vh;
  right: 0;
  margin-right: 3.2vw;
  position: fixed;
}

@media only screen and (max-width: 500px) {
  .myBoardContainer {
    max-width: 100% !important;
    min-height: 200px;
  }
  .container {
    max-width: 95% !important;
    padding: 0 !important;
  }
  .spaceGirdImg {
    width: 100%;
    /* border-radius: 5px; */
    /* padding: 0; */
  }
  .mesonry {
    padding: 0;
    width: 100%;
  }
  .modalHeader h4 {
    font-size: 15px;
    margin-top: 25px !important;
    margin-left: 25px !important;
    display: inline-flex;
  }
  .imageCol {
    max-height: 100%;
  }
  .createCol {
    /* height: 60%; */
    padding-bottom: 41px;
  }
  .createCol h6 {
    margin-top: 4vh;
  }
  .createCol input {
    width: 90%;
  }
  .deleteConfirm {
    font-size: 16px;
    font-family: 'Quicksand';
    font-weight: 800;
    padding: 10px;
  }

  .deleteInfo {
    font-size: 16px;
    font-family: 'Quicksand';
    font-weight: 600;
    padding: 10px;
  }
  .shareContent {
    width: 61%;
  }
  .modalRow_delete {
    /* background-color: #f2feff; */
    height: 281px;
  }
  .container2 h4 {
    font-weight: 500;
    font-size: 19px;
  }
  .mesonry div:nth-child(2) div:last-child img {
    height: 65px;
  }
  .modalRow_delete h4 {
    width: 100%;
    font-size: 17px;
    padding: 0px 25px;
  }
  .modalRow {
    height: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mesonry div:nth-child(2) div:last-child img {
    height: 72px;
    object-fit: cover;
  }
}
