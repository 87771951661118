.category {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 0px 160px
}

.categoryImageContainer img {
  width: 300px; 
  height: 300px;
}

.categoryImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.subcategory { 
}

.subcategoryImage {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.subcategories {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.subcategory {
  flex: 0 0 calc(16.666% - 10px);
  display: flex;
  flex-direction :column;
  align-items: center;
}

.subcategoryContainer {
    position: relative;
    margin-top: 15px;
}

.subcategoryImage {
  width: 150px; 
  height: auto;
  text-align: center;
  object-fit: cover;
}

.categoryTitle {
  text-align: center;
}

.subcategoryTitle{
  text-align: center;
  display: grid;
}

.typesSection {
  width: 80%;
  margin: 0px auto;
  padding-top: 105px;
}

.roomType {
  font-size: 15px;
  margin-top: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width: 640px) {

  .category {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-left: 0;
  }
  .categoryImageContainer img {
    width: 160px;
    height: 160px;
  }

  .subcategoryContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    max-width: 350px; 
  }

  .subcategoryContainer {
    position: relative;
    margin-top: -4px;
}

  .subcategoryImage {
    width: 100px;
  }

  .subcategories {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    /* grid-gap: 8px;  */
    margin-top: 15px;
  }

  .categoryTitle {
    font-size: 18px;
  }

  .subcategoryTitle {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

@media (min-width : 768px) and (max-width: 1023px) { 

  .category {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: 0px 60px
  }

  .categoryImageContainer img {
    width: 300px;
    height: 300px;
  }

  .subcategoryContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center; 
  }

  .subcategory {
    flex: 0 0 calc(50% - 10px); 
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: normal;
    word-wrap: break-word; 
  }

  .subcategoryImage {
    width: 130px;
  }

  .subcategories {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-gap: 18px;
  }

  .categoryTitle {
    font-size: 18px;
  }

  .subcategoryTitle {
    text-align: center  ;
  }

}

@media (min-width: 1536px) {
  .categoryImageContainer img {
    width: 400px;
    height: 400px;
  }

  .subcategory {
    flex: 0 0 calc(14.285% - 10px); 
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: normal;
    word-wrap: break-word; 
  }

  .subcategoryImage {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  .subcategories {
    display: grid;
    grid-template-columns: repeat(6, 1fr); 
    margin-top: 30px;
  }

  .categoryTitle {
    font-size: 18px;
  }

  .subcategoryTitle {
    text-align: center;
  }

  .subcategoryContainer {
    position: relative;
    margin-top: -4px;
    width: 100%;
}
}




