.homeContainer{
    /* margin-top: 65px; */
    width: 100%;
    background-color: #F5F5F5;
}

.homeContainer2{
    width: 80%;
    margin: 8rem auto 0 auto;
    
}

.homeContainer3{
    width: 100%;
    background-color: #ffffff;
}

.homeContainer4{
    width: 100%;
    
}

.homeContainer5{
    width: 80%;
    background-color: #F5F5F5;
    margin: auto;
}

.categoryContainer{
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin: auto;
}

.newArrivalContainer{
    width: 80%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin: auto;
    
}



.banner{
    width: 100%;
    position: relative;
}

.bannerVideo{
    height: 100vh;
}

.bannerOverlay{
    display: grid;
    place-content: center;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background:
      linear-gradient(180deg, rgba(7, 11, 19, 0.92) 10.14%, rgba(7, 11, 19, 0.34) 88.84%)
}

.bannerHeading{
 font-size: 56px;
 font-weight: 700;
 color:#FFFFFF;
 line-height: 64px;
 margin-bottom: 1rem;
}

.bannerSubHeading{
    font-size: 18px;
    font-weight: 500;
    color:#DFD9D6
}

.filterRowWeb{
    display: flex;
}
.filterRowMob{
    display: none;
}

.filterCol{
    background-color: #F1EEEA;
    position: absolute;
    background-image: url('https://d3izcfql7lesks.cloudfront.net/semiCirclesVectorBg.svg');
    background-position: center;
    background-repeat: no-repeat;
   
    -moz-transition: bottom 0.5s ease-out;
    -webkit-transition: bottom 0.5s ease-out;
    -o-transition: bottom 0.5s ease-out;
    transition: bottom 0.5s ease-out;
}

.filterInner{
    width: 100%;
    padding: 25px 15px;
    position: relative;
}

.badgeContainer{
    display: flex;
    flex-wrap: wrap;
    /* margin-bottom: 1.5rem; */
}

.sliderBox{
    width: 120px;
    height: 39px;
    background: #FFFFFF;
    border: 0.5px solid #A5A5A5;
    margin-right: 0.5rem;
    padding: 4px;
}

.filterText{
    position: absolute;
    background-color: #F8F4F0;
    color: #171717;
    padding: 6px 20px;
    bottom:0;
    right:10px;
    font-size: 11px;
    font-weight: 500;

}

.sliderBoxInner{
    margin-bottom:2.5px;
    line-height:1;
    font-size: 11px;
    font-weight: 400;
    color:#A5A5A5;
}

.sliderBoxInnerValue{
    margin:0;
    font-size: 13px;
    font-weight: 500;
    color: #171717;
}

.budgetContainer{
    height: 0;
    overflow: hidden;
    -moz-transition: height 0.5s ease-out;
    -webkit-transition: height 0.5s ease-out;
    -o-transition: height 0.5s ease-out;
    transition: height 0.5s ease-out;
}

.budgetBtnContainer{
    justify-content: center;
}

.filterInnerHeading{
    font-weight: 700;
    font-size: 28px;
    color: #171717;
    margin-bottom: 1rem
}



.hireDesignerBanner{
    display: flex;
    align-items: center;
    background-color: #D9EFF5;
    color: #171717;
    width: 100%;
    height: 240px;
    /* margin-top: 12rem; */
}

.hireDesignerBannerMob{
    display: none;
}

.stackedCarouselContainer{
    width: 100%;
    /* background-color:  #F1EEEA; */
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background: linear-gradient(to bottom, #F1EEEA 70%, #ffffff 70%);
    background-image: url(https://d3izcfql7lesks.cloudfront.net/semiCirclesVectorBg.svg);
    background-size: 100%;

    /* height: 500px; */
}

.stackedCarouselContTittle{
    color: #171717;
    font-size: 28px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.stackedCarouselBadgeContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: 2rem;
    
}



.stackedCarouselContainerInner{
    width: 80%;
    margin:auto
}

.stackedCarouselContainerInnerMost{
    width: 80%;
    margin:auto
}

.partnersContainer{
 display: flex;
 flex-direction: row;
 margin: auto;
 padding-top: 4rem;
 padding-bottom: 4rem;
}

.partnerLeft{
    display: flex;
    flex-direction: column;
    flex: 0.5;
    justify-content: center;
}

.partnerRight{
    display: flex;
    flex-direction: column;
    flex: 0.5;
}
.partnerHeader{
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    width: 90%;
}

.partnerSubHeader{
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    width: 90%;
}

.vendorAutoScroll{
    height: 480px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    overflow-y: scroll;     
     
}

.vendorAutoScroll::-webkit-scrollbar {
    width: 6px;
   
  }
  
  /* Track */
  .vendorAutoScroll::-webkit-scrollbar-track {
    background: #F5F5F5; 
  }
   
  /* Handle */
  .vendorAutoScroll::-webkit-scrollbar-thumb {
    background: #999; 
    border-radius: 4px;
  }

.sliderInput{
    font-size: 13px;
    border: none;
    outline: none;
    width: 100%;
    line-height: 1
}

.moodboardCont{
    width: 100%;
    max-width: 1250px;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 9rem;

}

.moodboardContTitle{
    font-size: 18px;
    font-weight: 700;
    color: #171717;
    margin-bottom: 0.5rem;
    text-align: center;
}

.moodboardContSubTitle{
    font-size: 15px;
    font-weight: 600;
    color: #171717;
    margin-bottom: 1rem;
    text-align: center;
   
}

.shop_button {
    width:  125px;
    height: 40px;
    border-radius: 40px;
    background-color: #1A1818;
    color: white;
    font-weight: bold;
    font-family: "Quicksand", sans-serif;
    margin-top: 10px;
    z-index: 50;
  }

@media only screen and (max-width: 1000px){

    .bannerVideo{
        height: 60vh;
    }

    .bannerOverlay{
        height: 60vh;
    }

    .filterRowWeb{
        display: none;
    }
    
    .filterRowMob{
        display: flex;
    }
    .filterCol{
        position: relative;
    }

    .homeContainer2{
        width: 100%;
        margin: 2rem auto 0 auto;
    }

    /* .moodboardCont{
        width: 90%;
    } */


    .hireDesignerBanner{
        display: none;
    }

    .hireDesignerBannerMob{
        height: 450px;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        background-color: #D9EFF5;
        padding:20px 20px 0 20px;
        text-align: center;
    }

    .categoryContainer{
        width: 90%;
    }

    .stackedCarouselContainerInner{
        width: 90%;
     
    }

    
    .stackedCarouselContainerInnerMost{
        width: 90%;
     
    }

    .partnersContainer{
        flex-direction: column;
    }

    .partnerLeft{
        flex:1;
        align-items: center;
    }

    .partnerRight{
        flex:1;
        margin-top:2rem
    }

    .partnerHeader{
        text-align: center;
        width: 100%;
    }
    .partnerSubHeader{
        text-align: center;
        width: 100%;
    }

    
}

@media only screen and (max-width: 600px) {

   

    .bannerHeading{
        font-size: 28px;
        line-height: 36px;
    }

    .bannerSubHeading{
        font-size: 16px;
        padding: 0 10px 0 10px;
    }

    .filterCol{
        width: 90%;
    }

    .filterInnerHeading{
        font-weight: 700;
        font-size: 16px;
        color: #171717;
        margin-bottom: 1rem
    }

    .newArrivalContainer{
        width: 100%;  
    }

    .stackedCarouselContainer{
        width: 100%;
        /* background-color:  #F1EEEA; */
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;
        background: linear-gradient(to bottom, #F1EEEA 70%, #ffffff 70%);
        background-image: url(https://d3izcfql7lesks.cloudfront.net/semiCirclesVectorBg.svg);
        background-size: 100%;
        height: 560px;
    }
    

    .budgetBtnContainer{
        justify-content: end;
        margin-top: 0.5rem;
       
    }



    .filterInner{
        padding: 30px 15px;
    }

    .moodboardCont{
        /* width: 100%; */
        padding: 10px;
    }

    .homeContainer5{
        width: 90%;
    }

   

    .partnersContainer{
        flex-direction: column;
    }

    .partnerHeader{
        text-align: center;
        width: 100%;
        font-size: 20px;
    }
    .partnerSubHeader{
        display: none;
        width: 100%;
    }

    .partnerLeft{
        flex:1;
        align-items: center;
    }

    .partnerRight{
        flex:1;
        margin-top:2rem
    }
}

