.aboutUsContainer1 {
  background-color: #ffffff;
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutusBackground.png');
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 75px 50px 0 50px;
}

.slider {
  position: relative !important;
  bottom: -180px;
  max-width: 1500px;
  width: 75%;
  padding: 10px;
  margin: auto;
}

.aboutUsContainer2 {
  color: #ffffff;
  height: 110vh;
  background-color: #1a1a1a;
  padding: 50px;
  display: flex;
  justify-content: center;
}

.aboutUsContainer3 {
  height: auto;
  background-color: #ffffff;
  padding: 50px;
}
.aboutUsContainer4 {
  height: auto;
  background-color: #ffffff;
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus2.1.png');
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 50px;
}

.designButton {
  padding: 10px 25px;
  font-size: medium;
  border-radius: 25px;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  background-color: black;
  color: #ffffff;
}

.designButton:hover {
  font-weight: 900;
}

.aboutUsContainer3Text {
  font-weight: 600;
  font-size: 14px;
  width: 70%;
}

.innerContainer {
  max-width: 1500px;
  width: 75%;
  padding: 10px;
  margin: 75px auto auto auto;
}

@media screen and (min-width: 1500px) {
  .aboutUsContainer3Text {
    width: 45%;
  }
}

@media screen and (max-width: 1300px) and (min-width: 500px) {
  .slider {
    bottom: -130px;
  }
  .aboutUsContainer3 {
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .innerContainer {
    width: 95%;
  }

  .aboutUsContainer1 {
    height: auto;
    padding: 10px;
  }

  .aboutUsContainer2 {
    padding: 50px 10px 50px 10px;
  }

  .aboutUsContainer3 {
    padding: 10px;
    height: auto;
  }

  .aboutUsContainer4 {
    padding: 10px;
  }

  .aboutUsContainer3Text {
    width: 90%;
  }
  .aboutUsContainer2 {
    height: auto;
  }

  .slider {
    width: 90%;
    bottom: -75px;
  }
}
