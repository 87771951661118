.searchContainer{
    flex: 0.8;
    max-width: 1200px;
}

.searchbar{
    border-radius: 2px;
    border: 2px solid #7B8569;
}


.searchLogo{
    width: 28px;
    height: 28px;
    padding: 2px;
    cursor: pointer;
    
}

.option{
    padding-left: 4px;
    padding-bottom: 4px;
    font-weight: 400;
    color: #171717;
    background-color: #EEEAE6;
}

.option:hover{
    background-color: #ffffff;
    cursor: pointer;
}






