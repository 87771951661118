.sideBar {
  padding: 2% !important;
  background-color: #f7f7f7;
  /* height: 60vh; */
  height: 100%;
  position: fixed !important;
  left: 0%;
  /* margin-right: 100px; */
  /* margin-top: 3%; */
}

.attachFileButton {
  border-radius: 30px;
  font-size: 15px;
  font-weight: 800;
  border: none;
  color: white;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  background-color: #1a1818;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.priceButton {
  padding: '5px 10px 5px 10px';
  border: 2px solid #000000;
  /* border-radius: 30px; */
  width: 30%;
}
.buttonStyle {
  width: 42px;
  height: 41px;
  font-size: 28px;
  background-color: #f5f5f5;
}
.wholeBtn {
  justify-content: center;
  width: 134px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 37px;
  align-items: center;
  display: flex;
}
.inputPeople {
  width: 48px;
  height: 31px;
  /* margin-top: 20px; */
  border: none;
  justify-content: center;
}
.perfectStyle {
  text-align: center;
  font-size: 25px;
  position: absolute;
  width: 25%;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  z-index: 1;
  top: 26px;
}
.priceButton1 {
  padding: '5px 10px 5px 10px';
  border: 2px solid #000000;
  /* border-radius: 30px; */
  width: 30%;

  border: 1px solid red;
}

.priceButton1:focus {
  border: 2px solid #000000;
}

.priceButton:focus {
  border: 2px solid #000000;
}

.wishlistButton {
  padding: 0.8vw 1.5vw;
  border-radius: 30px;
  background-color: #000000;
  color: #f5f0ec;
  font-weight: 400;
}

.wishlistButton:hover {
  font-weight: 900;
}

.button {
  padding: 8px 12px 8px 12px;
  border-radius: 30px;
  background-color: #000000;
  color: #f5f0ec;
  font-weight: 400;
}

.button:hover {
  font-weight: 900;
}

.saveButton {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0.8vw 1.5vw;
  border-radius: 30px;
  background-color: #000000;
  color: #f5f0ec;
  font-weight: 400;
}

.saveButton:hover {
  font-weight: 900;
}

.error {
  border: 1px solid red;
}
.error1 {
  color: red;
}

.modalContainer {
  width: 35vw;
  /* height: 70vh; */
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding-bottom: 10px;
  outline: none;
}

.audio-react-recorder canvas {
  height: 10px;
}

.containerText {
  position: relative;
  width: 70%;
}
.containerText textarea {
  display: block;
  width: 100% !important;
  resize: vertical;
  border: 1.5px solid #e0e0e0;
  border-radius: 3px;
}

.containerText .microphoneIco1 {
  /* padding: 10px 20px; */
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 10px; /* <==== */
}

.time {
  font-size: 8rem;
  margin-bottom: 1rem;
  color: white;
}
.containerText .Trash {
  /* padding: 10px 20px; */
  font-size: 20px;
  position: absolute;
  right: 30px;
  top: 10px; /* <==== */
}
.containerText .textField {
  display: block;
  width: 100% !important;
  resize: vertical;
}
.containerText .audio {
  padding: 10px 20px;
  font-size: 20px;
  position: absolute;
  right: 45px;
  top: -5px; /* <==== */
}

.audioContainer {
  width: 35vw;
  /* height: 70vh; */
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding-bottom: 10px;
}

.modalContainer1 {
  width: 70%;
  /* height: 70%; */
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f4f4f4;
  /* margin-top:10% */
}
.imgcmt {
  margin-left: 20%;
  width: 50%;
}

.modal_container {
  height: 15vh;
  width: 100%;
  background-color: #d2edf5;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .annotateImg{
  width: 100%;
  height: 100px;
} */

.modalContainer p {
  font-size: 14px;
  margin-top: 10%;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.modalAnnotate {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.container {
  height: 100% !important;
  margin-top: 1vh;
  margin-left: 15vw;
  background-color: #ffffff;
}

.container1 {
  min-height: 100vh !important;
  margin-left: 15vw;
  padding-left: 80px !important;
  background-color: #ffffff;
}

.scrollableContainer {
  width: '75%';
}

.active {
  font-weight: 800;
}

.sideBar h6 {
  margin-right: 0px;
}

.container {
  padding-left: 50px !important;
}

.dropBox {
  /* width: 0px !important; */
  height: 200px;
  /* background-color: #f5f0ec; */
  background-color: #d0ecf4;
  border: 1.5px solid #d0ecf4;
  border-radius: 6px;
  /* margin-left: 10px; */
}
.dropTxt {
  font-weight: 500;
  font-size: 14px;
}
.borderImg {
  padding-top: 0px !important;
  height: 200px;
  border: 1px dashed #ffffff;
  width: 100%;
  justify-content: center;
}
.thumbImg {
  width: 55px;
  height: 55px;
  margin-bottom: 12px;

  transition: transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.thumbImg1 {
  width: 80px;
  height: 55px;
  margin-bottom: 12px;
  display: none;
  transition: transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.borderImg:hover .thumbImg1 {
  display: block;
  /* transform: scale(1.2); */
}
.borderImg:hover .thumbImg {
  display: none;
  /* transform: scale(/1.2); */
}
.imageRow {
  height: 200px;
  width: 95%;
  /* background-image: url('https://d3izcfql7lesks.cloudfront.net/minh-pham-OtXADkUh3-I-unsplash_ikfunq.png'); */
  /* margin-left: 20px; */
  margin-bottom: 10px;
}

.microphoneIco {
  position: absolute;
  right: 275px;
  /* top: 8px; */
}

.designClick {
  position: absolute;
  top: 5%;
  right: 10%;
}

.materialClick {
  position: absolute;
  bottom: 7%;
  right: 5%;
  width: 15px;
  z-index: 1;
}
.itemsBox {
  width: 100%;
  height: 160px;
  margin-top: 30px;
  border: 1px solid #dedede;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-size: 14px;
  font-weight: 800;
  position: relative;
}

.dot {
  padding: 10px;
}
.hoverImg {
  display: none;
}
.ArtsBox:hover .oriImg {
  display: none;
}

.ArtsBox:hover .hoverImg {
  display: block;
}
.overLayerRoom {
  position: absolute;
  background-color: #00000061;
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1500px) {
  .itemsBox {
    width: 130px;
    height: 130px;
    margin-top: 30px;
    font-size: 12px;
    font-weight: 800;
    box-shadow: 0px 3px 6px #cccccc29;
    border: 0.5px solid #dedede;
    border-radius: 5px;
  }
}

.ArtsBox {
  width: 93%;
  height: -webkit-max-content;
  height: max-content;
  margin-top: 30px;
  border-radius: 5px;
  padding: 12px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 3px 6px #0000000f;
  position: relative;
  margin-bottom: 23px;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.imgMaterial {
  object-fit: cover;
  max-width: 100%;
  height: 250px;
  border-radius: 5px;
}
.titleTxt {
  color: #151515;
  font-weight: 600;
  margin: 4px 0px;
  float: left;
  margin-top: 10px !important;
}
.ArtsBox2 {
  width: 97% !important;
  height: 314px;
  /* position: relative; */
  border-radius: 5px;
  /* margin-right: 30px;
  margin-left: 20px; */
  margin-top: 30px;
  border: 1px solid #dedede;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.uploadButton {
  margin-top: 10px;
  border-radius: 30px;
  font-size: 11px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 800;
  height: 40px;
  background-color: #ffffff;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.textArea {
  width: 80%;
  height: 300px;
  margin-top: 30px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.main__desktop__viewer {
  display: contents;
  /* background-color: #ffffff; */
}

.main__mobile__viewer {
  display: none !important;
}

.slider__info {
  color: black;
}

@media only screen and (max-width: 600px) {
  .main__desktop__viewer {
    display: none !important;
  }

  .main__mobile__viewer {
    display: block !important;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 4000px) {
  .container1 {
    height: 100% !important;
    margin-left: 25vw;
    padding-left: 80px !important;
    background-color: #ffffff;
  }
  .container {
    height: 100% !important;
    margin-top: 5vh;
    margin-left: 25vw;
    padding-left: 50px !important;
    background-color: #ffffff;
  }
}

@media only screen and (max-width: 500px) {
  .modalContainer {
    width: 90%;
    /* height: 70vh; */
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    padding-bottom: 10px;
  }

  .modalContainer1 {
    width: 60vw;
    height: auto;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #f4f4f4;
  }

  .modal_container {
    height: 15vh;
    width: 100%;
    background-color: #d2edf5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modalContainer p {
    font-size: 14px;
    margin-top: 10%;
  }
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.deleteIcon {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  background-color: white;
  padding: 0px 7px !important;
  width: 32px !important;
  height: 32px !important;
  border-radius: 30px;
  box-shadow: 4px 3px 6px #979797;
  margin-top: 18px;
}
.deleteTxt {
  font-size: 13px;
}

.cancelBtn {
  width: 60px;
  height: 34px;
  border: 1px solid #1a1818;
  background-color: transparent;
  color: #ffffff;
  border-radius: 20px;
  font-size: 15px;
}

.deleteBtn {
  width: 60px;
  height: 34px;
  border: 1px solid #1a1818;
  background-color: #ffffff;
  color: #000000;
  font-weight: 500;
  border-radius: 20px;
  font-size: 15px;
}
