.back {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/common-images/Order+cancellation.png');
  /* height: 200px; */
}
.content {
  color: black;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  background-image: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/design_dairies/pattern_success.png);
  /* height: 200px; */
  background-color: #e9dbd2;
}
.failedOrder {
  margin: 31px 0px;
}
.failedOrder h5 {
  font-size: 15px;
  line-height: 12px;
}
.button1 {
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
}

.button2 {
  border-radius: 30px;
  background-color: transparent;
  color: black;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  border: solid 1px;
}
@media only screen and (max-width: 600px) {
  .padd {
    width: 30px;
  }

  .thanks {
    font-size: 20px;
    padding-left: 1%;

    text-align: center;
  }

  .check {
    margin-top: 10%;
    text-align: center;
  }

  .email {
    font-size: 14px;
    padding-left: 1%;

    text-align: center;
  }

  .container-order {
    text-align: center;
    min-width: 90% !important;
    max-width: 90% !important;
    background-color: white;
  }
  .getStartedButton {
    border-radius: 30px;

    max-height: 50px !important;
  }
  .failedOrder h5 {
    line-height: 22px;
  }
}
