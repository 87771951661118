.topbar {
  width: 100%;
  height: 50px;
  justify-content: space-between;
  display: flex;
  position: fixed;
  z-index: 1000;
  background-color: #ffffff;
}
.modalSave .modal-dialog {
  width: 724px !important;
}
.swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}
.modal-backdrop.show {
  opacity: 0.5 !important;
}
.swipe-wrap {
  overflow: hidden;
  position: relative;
}
.swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}
button {
  border: none;
}
.MuiButtonBase-root {
  /* padding-left: 0px !important; */
}
.typeSection .MuiButtonBase-root {
  font-size: 15px !important;
}
.typeSection .MuiSvgIcon-root {
  color: white !important;
}
.questionaireFooter {
  width: 100%;
  height: 60px !important;
  display: flex;
  justify-content: space-between;
  position: fixed;
  overflow-x: hidden !important;
  background-color: #ffffff;
  bottom: 0;
  font-family: 'Quicksand';
  overflow-y: hidden;
  align-self: center;
  border: 0.5px solid #b1b1b1;
}

.questionnaireNextBtn {
  cursor: pointer;
  position: relative;
  /* width: 130px;
    height: 41px; */
  background-color: black;
  border-radius: 30px;
  color: white;
  /* padding-top: 0px !important; */
  font-weight: 400;
  margin-top: 0px !important;
  align-items: center;
  padding: 10px 48px 12px 49px;
}
.questionnaireNextBtnDisable {
  background-color: #a8a8a8 !important;
}
.questionnaireNextBtn:disabled {
  background-color: #a8a8a8 !important;
}
.questionnaireNextBtn:hover {
  /* background-color: #a8a8a8; */
  font-weight: 900;
}
.questionnaireNextBtn[disabled]:hover {
  font-weight: 900;
}

button[disabled='disabled'],
button:disabled {
  background-color: #00000029;
}

button:disabled:hover {
  font-weight: 400;
}

.questionaireFooterPara {
  font-weight: initial;
  align-self: center;
}

.questionaireFooterSkip {
  /* text-decoration: underline; */
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
}
.questionaireFooterSkip:hover {
  text-decoration: underline;
}
.navbar_items {
  width: 40%;
  z-index: 10;
}
body {
  padding-right: 0px !important;
  font-family: 'Quicksand', sans-serif;
}

.navbar_items p {
  /* margin-left: 50px; */
  color: #000000;
  font-weight: 600;
}
.input-group-text {
  background-color: transparent !important;
  border: 1px solid #707070 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #1a1818 !important;
}
.input-group-text {
  border: 1px solid #707070 !important;
  margin-left: -2px !important;
}
.input-group .form-control {
  border: 1px solid #707070 !important;
}

.pagination .page-item .page-link {
  min-width: 23px !important;
}
.input-group .form-control:focus {
  outline: none;
  box-shadow: none !important;
}
.ml-0 {
  margin-left: 0px !important;
}

.navbar_icons {
  margin-left: -15%;
}

.navbar_icons img {
  padding-left: 30px;
}

.tabs {
  z-index: 40;
  display: flex;
  background-color: #ffffff;
  height: 40px;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tabs p {
  padding: 10px;
  opacity: 0.5;
  padding-bottom: 0px;
  cursor: pointer !important;
}

.tabs-active {
  padding-top: 10px;
  border-bottom: 2px solid #000000;
  margin-bottom: 0px;
  opacity: 1;
}

.navbar-container {
  height: auto !important;
}

.backdrop {
  background-color: #2c2c2c !important;
  opacity: 1 !important;
}

.modal {
  position: fixed;
  background-color: transparent !important;
  font-family: 'Quicksand';
}

.modal .modal-dialog {
  /* width: 724px; */
  width: 550px;
  background-color: transparent !important;
  max-width: 100% !important;
  /* height: 100% !important; */
  /* margin-top: 75px; */
}
.modal-open .modal {
  overflow-y: hidden !important ;
}

.MuiMenu-paper {
  background-color: #ffffff !important;
  border-radius: 21px;
  border: 1px solid #e2e2e2;
  box-shadow: none !important;
  position: fixed !important;
  font-size: 12px !important;
}
.MuiMenuItem-root {
  font-size: 10px !important;
}
.MuiAccordionSummary-content {
  justify-content: space-between !important;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails {
  margin-left: 10vw !important;
}

.font-weight-800 {
  font-weight: 800;
}
.navbarpm {
  padding-top: 5px !important;
  display: flex !important;
  background-color: #ffffff !important;
  justify-content: space-between !important;
  font-family: Quicksand !important;
  width: 100% !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
  position: fixed !important;
  z-index: 3 !important;
  padding-bottom: 0px !important;
}
.font-weight-100 {
  font-weight: 100;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiAccordion-root {
  border: 1px solid white !important;
  border-radius: 10px !important;
  background-color: #f7f7f7 !important;
  /* background-color: #fff !important; */
}
.productContainer .MuiAccordion-root {
  border: 1px solid white !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  /* background-color: #fff !important; */
}
.MuiAccordionSummary-root {
  padding-left: 25px !important;
  /* background-color: white !important; */
}

.MuiAccordion-root:before {
  display: none;
}
.MuiAccordion-root {
  margin-bottom: 25px;
  /* padding-top: ; */
}
.MuiAccordionDetails-root {
  padding-left: 25px !important;
}
@media only screen and (max-width: 500px) {
  .tabs {
    margin-top: 0px;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .tabs p {
    font-size: 15px;
  }
  .questionaireFooter {
    height: 80px !important;
    z-index: 15;
    display: block;
  }
  .questionnaireNextBtn {
    margin-top: 20px !important;
  }
  .questionaireFooterSkip {
    color: black;
    font-size: 16px;
    font-weight: 700;
    padding-left: 10vw;
    margin-right: 10px;
    margin-top: 10px;
  }
  .questionaireFooterPara {
    font-size: 14px;
    padding-left: 10vw;
  }
  .questionnaireNextBtn:hover {
    /* background-color: #a8a8a8; */
  }
  .topbar {
    box-shadow: 0px 0px 6px #e8e6e6;
  }
  .MuiMenuItem-root {
    width: auto;
    overflow: hidden;
    font-size: 1rem;
    box-sizing: border-box;
    min-height: 29px !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 0px !important;
    padding-top: 0px !important;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 0px !important;
  }
}

@media only screen and (max-width: 500px) {
  .questionnaireNextBtn {
    margin-left: 0px;
    padding: 8px 25px 8px 25px;
    /* margin-left: -20px; */
  }
  .modal .modal-dialog {
    width: 95% !important;
    position: relative !important;
    display: flex;
    top: -5%;
    /* left: 7%; */
    background-color: transparent !important;
    /* max-width: 80% !important; */
    height: 100% !important;
  }

  .backdrop {
    background-color: #2c2c2c !important;
    opacity: 1 !important;
    width: 100% !important;
    height: 100% !important;
  }
  .modal-open .modal {
    margin-top: 21px;
  }
  .modal .modal-dialog {
    top: 0%;
    margin-top: 0px;
  }
  .modal-content {
    width: 98% !important;
    height: 100%;
    overflow-y: scroll;
  }
  .modal-open .modalAdd {
    overflow-y: scroll !important;
  }
  .modalAdd .modalContainer {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 360px) {
  .tabs p {
    font-size: 12px;
  }
}

@media screen and (min-width: 2000px) {
  .borderBottom {
    margin-bottom: -1vh !important;
    padding-bottom: 1vh;
  }
}
