.footerContainer{
    width: 100%;
    background-color: #171717;
    height: 420px;
    color:#ffffff;
    padding: 25px 0 15px 0
}

.footerInnerContainer{
    width: 90%;
    height: 90%;
    margin: auto;
    display: flex;
}

.footerLeft{
    width: 43%;
    display: flex;
    flex-direction: column;
}
.footerRight{
    display: flex;
    flex-direction:row;
    flex: 1;
    justify-content: space-between;

}

.footerLogo{
    height: 2em;
    width: max-content;
    cursor: pointer;
    margin-bottom: 1.5rem;
}

.qrContainer{
    display: flex;
    justify-content: space-between;
    padding: 2px 4px 2px 10px;
    border: 1px solid #ECECEC;
    border-radius: 5px;
    height: 84px;
    max-width: 295px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
}

.navListContainer{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
}

.mobileFooterTop{
    display: none;
    
}

.mobileFooterBottom{
    display: none;
    justify-content: end;
    flex-direction: row;
}

.socialMediaContMob{
    display: none;
}

.copyRightTxt{
    text-align: center;
}

.socialMediaContWeb{
    display: flex;
    justify-content: start;
    margin-top: 0.5rem;
}

@media only screen and (max-width: 600px) {
    .footerContainer{
        height:auto;
        padding: 25px 15px 25px 15px
    }

    .footerInnerContainer{
        width: 100%;
        height: auto;
        flex-direction: column;
    }

    .footerLeft{
        width: 100%;
    }

    .footerRight{
        display: none;
    }

    .qrContainer{
        width: 90%;
        max-width: none;
    }

    .footerLogo{
        height: 40px;
    }

    .mobileFooterTop{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1.5rem;
        
    
    }

    .mobileFooterBottom{
        display: flex;
        justify-content: start;
        margin-top: 1rem;
        padding-bottom: 1.5rem ;
        border-bottom: 1px solid #FFFFFF;
    }

    .socialMediaContMob{
        display: flex;
        justify-content: center;
        padding-top: 1.5rem 
    }

    .copyRightTxt{
        padding-top: 5px;
    }

    .socialMediaContWeb{
        display: none;
       
    }

    
  }

