.leftContainer {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/refferal+page+bg+image.png');
  height: 100vh;
  background-image: 100%;
}

.input {
  margin: 10px;
  padding: 10px;
  color: #b9b9b9;
  border: 0.5px solid #a5a5a567;
  margin-left: 0px;
}

.textArea {
  margin: 10px;
  padding: 10px;
  color: #b9b9b9;
  border: 0.5px solid #a5a5a567;
  height: 180px;
  margin-left: 0px;
}

.sendButton {
  border: none;
  background-color: black;
  color: white;
  border-radius: 30px;
  padding: 5px 20px 5px 20px;
}
@media only screen and (max-width: 600px) {
  /* .leftContainer {
    display: none !important;
  } */
}
.wholeContainer {
  /* height: calc(100vh-50px) !important; */
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
}
