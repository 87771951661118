

.wholeContainer {
 
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
}

.otpInput {
  border: none;
  border-bottom: 1px solid #707070;
  text-align: center;
}

.signup_input::input[type='number']::-webkit-inner-spin-button,
.signup_input::input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.otpInput::placeholder {
  color: red;
  padding: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.imgContainer {
  width: 100% !important;
  padding: 0px !important;
  height: 100vh;
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+4819.png');
  /* background-size: 150%; */
  background-size: cover;
  background-position: right;
  /* height: 760px; */
  /* margin-top: -10px;
  padding-left: -100px !important; */
  background-repeat: no-repeat;
  /* overflow-y: hidden !important;
  overflow-x: hidden !important; */
}

.signup_header {
  font-size: 28px;
  color: #c7b7a5;
  margin-top: 11px;
  margin-bottom: 15px;
}

.container {
  display: grid;
  place-items: center;
  height: calc(100vh-75px) !important;
  width: 100%;
  /* overflow: hidden;
  overflow-y: hidden !important;
  overflow-x: hidden !important; */
}

.container::-webkit-scrollbar {
  display: none;
}

.signup_text {
  font-weight: 900;
  margin: 0;
}

.signup_input {
  width: 411px;
  height: 60px;
  border: 1px solid #cecece;
  padding-left: 20px !important;
  padding: 10px;
}

.signup_input:focus {
  border: 1px solid #000;
}

.signup_button {
  width: 411px;
  margin-top: 20px;
  font-weight: 900;
  height: 55px;
  background-color: black;
  border: none;
  color: white;
}

.signupwithgoogle_button {
  width: 411px;
  margin-top: 20px;
  font-weight: 500;
  height: 55px;
  background-color: #ffffff;
  border: none;
  color: black;
  border: 1px solid #bfbfbf;
}

.verifyButton {
  border: none;
  background-color: black;
  color: white;
  font-weight: 900;
  padding: 10px 50px 10px 50px;
  font-size: 14px;
}

.cancelButton {
  border: 1px solid #101010;
  background-color: white;
  color: black;
  margin-left: 20px;
  font-weight: 900;
  padding: 10px 50px 10px 50px;
  font-size: 14px;
}

.login_button {
  width: 411px;
  height: 55px;
  border: 1px solid #bfbfbf;
  font-weight: 900;
  background-color: #ffffff;
  margin-top: 10px;
}

.container span {
  font-size: 12px;
  color: #6a6a6a;
  font-weight: 500;
}

.innerContainer {
  width: 70% !important;
  padding-top: 30px;
}

.innerContainer1 {
  width: 40% !important;
}

.card1 {
  display: none;
}

@media only screen and (max-width: 500px) {
  .mainContainer {
    position: relative;
    max-width: 100% !important;
    /* overflow-y: scroll !important; */
    height: 84vh;
  }

  .wholeContainer {
    position: relative !important;
    background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+4819.png');
  }
  .container {
    position: relative !important;
    margin-top: 20vh;
    top: 3%;
    left: 5%;
    padding: 10px 0px 30px 0px !important;
    width: 100% !important;
    max-width: 90% !important;
    background-color: #ffffff;
    height: auto;
    border-radius: 8px;
  }

  .imgContainer {
    height: 100vh !important;
    width: 100%;
    position: fixed;
    background-size: 150%;
    position: fixed !important;
    background-size: cover;
  }
  .card1 {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .imgContainer img {
    position: fixed;
    width: 150%;
    height: 100%;
  }

  .signup_header {
    font-size: 23px;
    /* margin-top: 25%; */
  }
  .signup_text {
    font-size: 25px;
  }
  .signup_input {
    width: 100%;
  }

  .signupwithgoogle_button {
    width: 100%;
  }

  .innerContainer {
    width: 90% !important;
    max-width: 90vw;
    padding-top: 5px;
    height: auto !important;
    background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+4819.png');
    padding: 10%;
  }
  .signup_button {
    width: 100%;
  }
  .login_button {
    width: 100%;
  }
  .card {
    padding: 10px;
    color: #6a6a6a;
  }
}

@media only screen and (max-width: 800px) {
  .mainContainer {
    position: relative;
    max-width: 100% !important;
    overflow-y: scroll !important;
    /* height: 84vh; */
  }

  .wholeContainer {
    position: relative !important;
    background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+4819.png');
  }
  .container {
    position: relative !important;
    margin-top: 20vh;
    top: 3%;
    left: 5%;
    padding: 10px 0px 30px 0px !important;
    width: 100% !important;
    max-width: 90% !important;
    background-color: #ffffff;
    height: auto;
    border-radius: 8px;
  }

  .imgContainer {
    height: 100vh !important;
    width: 100%;
    position: fixed;
    background-size: 150%;
    position: fixed !important;
    background-size: cover;
  }
  .card1 {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .imgContainer img {
    position: fixed;
    width: 150%;
    height: 100%;
  }

  .signup_header {
    font-size: 23px;
  }
  .signup_text {
    font-size: 25px;
  }
  .signup_input {
    width: 100%;
  }

  .signupwithgoogle_button {
    width: 100%;
  }

  .innerContainer {
    width: 90% !important;
    max-width: 90vw;
    padding-top: 5px;
  }
  .signup_button {
    width: 100%;
  }
  .login_button {
    width: 100%;
  }
  .card {
    padding: 10px;
    color: #6a6a6a;
  }
}
@media only screen and (max-width: 360px) {
  .signup_header {
    margin-top: 5% !important;
  }
}
