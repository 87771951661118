.mainContainer {
  width: 80%;
  margin: auto;
  /* max-width: 1200px; */
  min-height: 100vh;
}

.masonryContainer {
  width: 100%;
  padding-top: 110px;
  margin: auto;
  padding-bottom: 30px;
}

.typesSection {
  width: 80%;
  margin: 0px auto;
  padding-top: 105px;
}

.toggleImg {
  width: 23px;
  height: 19px;
  margin-top: 2px;
  margin-right: 9px;
}

@media (max-width: 640px) {
  .typesSection {
    width: 100%;
  }
}

.uploadBtn {
  padding: 8px 10px;
  background-color: #000000;
  color: #ffffff;
  font-size: 12px;
}
.buttonWhite {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: black;
  background-color: white;
  padding: 5px 10px 5px 10px;
  font-weight: 600;
  height: 40px;
  width: 100%;
  font-size: 14px;
}
.buttonBlack {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: white;
  background-color: black;
  padding: 5px 10px 5px 10px;
  font-weight: 600;
  height: 40px;
  width: 100%;
  font-size: 14px;
}
.arPart {
  margin: 21px 0px;
  display: flex;
  align-items: center;
  background-color: white;
}
.view {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
}
.arImg {
  width: 16px;
  height: 19px;
  margin-right: 7px;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 200px;
  z-index: 1;
  bottom: 0;
  left: 0;
  /* background-color: rgb(0 0 0 / 60%); */
  justify-content: center !important;
  align-items: center !important;
}

.budgetFilterBtn {
  width: 50px;
  height: 39px;
  background: #ffffff;
  border: 1px solid #aeadad;
  margin-right: 0.5rem;
  padding: 4px;
  border-radius: 8px;
}

.sliderBox {
  display: flex;
  align-items: center;
  width: 60px;
  height: 39px;
  background: #ffffff;
  border: 1px solid #000000;
  margin-right: 0.5rem;
  padding: 4px;
  border-radius: 8px;
}

.sliderBoxInnerValue {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  color: #171717;
}

.sliderInput {
  font-size: 13px;
  border: none;
  outline: none;
  width: 100%;
  line-height: 1;
}

.typesSectionwom {
  width: 75%;
  padding-top: 110px;
}
.roomType {
  font-size: 15px;
  margin-top: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.roomBox {
  border: 0.5px solid #dedede;
  height: 155px;
  box-shadow: 0px 0px 0px #dedede;
}
.roomBoxbytype {
  border: 0.5px solid #dedede;
  height: 130px;
  box-shadow: 0px 0px 0px #dedede;
}
.roomTypeName {
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  margin-top: 6px;
  margin-bottom: 40px;
}
.arIcon {
  position: absolute;
  bottom: 7px;
  width: 26px;
  right: 7px;
  transition: transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.arIconbtn {
  position: absolute;
  bottom: 7px;
  width: 26px;
  right: 7px;
}
.arIcon:hover {
  transform: scale(1.2);
}
.selectStyle {
  background-color: #232424;
  color: white !important;
  height: 45px;
  border-radius: 5px !important;
}
.selectStyle svg {
  color: white !important;
}
.selectStyle li {
  font-size: 14px !important;
}
.bestSelller {
  width: 80%;
  margin: 0px auto;
}
.shopbycategory {
  width: 100%;
  margin: 0px auto;
}
.shopbycategorybrand {
  width: 80%;
  margin: 0px auto;
}
.mustHave {
  width: 80%;
  margin: 0px auto;
}
.carouselBorder {
  border: 0.5px solid #c6c6c6;
  border-radius: 7px;
  background-color: #ffffff;
  /* height: 275px; */
}
.carouselImg {
  width: 100%;
  border-radius: 6px;
}
.paraCard {
  font-family: Quicksand;
  font-size: 17px;
  width: 90%;
  margin: 0px auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.bestTxt {
  font-family: Quicksand;
  font-size: 18px;
  margin-top: 71px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 10px;
}
.headerName {
  font-size: 22px;
}
.priceTxt {
  font-size: 18px;
  margin-top: 15px;
}
.TxtClr {
  font-size: 15px;
  margin-bottom: 0px;
}
.getLookSection {
  width: 85%;
  margin: 0px auto;
  display: flex;
  margin-top: 57px;
  margin-bottom: 75px;
  cursor: pointer;
}
.lookFirst {
  width: 60%;
}
.lookFirst img {
  width: 122%;
  height: 100%;
}
.lookLast {
  width: 40%;
  position: relative;
}
.lookLast img {
  width: 100%;
}

.look {
  position: absolute;
  top: 43%;
  width: 100%;
  bottom: 0px;
  text-align: center;
  font-size: 31px;
  font-weight: 400;
  line-height: 47px;
}
.modeIdea {
  width: 90%;
  margin: 0px auto;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.gridSection img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.ideaSecImg {
  width: 100%;
  margin-bottom: 26px;
  height: 173px;
}
.ideaFirstImg {
  height: 375px;
}
.imagefit {
  width: 100%;
  height: 427px !important;
  object-fit: contain;
}
.QrLeft {
  height: 500px;
}
.QrBottomMob {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Mask+Group+20.png');
}
.closeBtn {
  position: absolute;
  right: 10px;
  z-index: 1;
  width: 27px;
  top: 5px;
}
.txtSec {
  position: absolute;
  top: 35%;
  bottom: 0px;
  align-items: center;
  /* flex: 1; */
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0px 84px;
}
.txtSec h4 {
  font-size: 18px;
  font-weight: 700;
}
.txtSec p {
  font-size: 14px;
  font-weight: 500;
}
.qrStyle {
  margin-top: 38%;
  padding: 0px 21px;
}
@media only screen and (max-width: 500px) {
  .ideaFirstImg,
  .ideaSecImg {
    height: auto;
  }
  .gridSection img {
    width: 100%;
    margin-bottom: 21px;
  }

  .lookFirst {
    width: 100%;
  }
  .lookFirst img {
    width: 100%;
    height: auto;
  }
  .getLookSection {
    display: block;
  }
  .lookLast {
    width: 100%;
    position: relative;
  }
  .look {
    position: absolute;
    top: 40%;
    width: 100%;
    bottom: 0px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    padding: 0px 31px;
  }
  .morePart {
    padding: 0px 20px;
  }
}
.ideaSecImg {
  width: 100%;
  margin-bottom: 26px;
  height: 173px;
}
.ideaFirstImg {
  height: 375px;
}
.imagefit {
  width: 100%;
  height: 427px !important;
  object-fit: contain;
}
.priceData {
  margin-bottom: 51px;
}

.dataName {
  position: relative;
  top: -10px;
  /* white-space: nowrap; */
  width: 120px;
  /* overflow: hidden; */
  font-weight: 600;
  text-align: center;
  text-overflow: ellipsis;
  /* margin-bottom: 20px; */
}

.item:hover {
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 25%);
}
.item {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0.5rem auto;
  position: relative;
  /* border: 1px solid #f9fafb; */
  border-radius: 2px;
  transition: 0.3s ease;
  font-size: 0;
  background-color: white;
  border-radius: 4px;
}
.item:hover .content {
  opacity: 1;
  border-radius: 4px;
}
.content {
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in-out;
  opacity: 0;
  /* border: 1px solid black; */
}
.item:hover .content div {
  opacity: 1;
  color: #f9fafb;
}
.content div {
  opacity: 0;
  transition-delay: 1s;
  transition: 0.3s ease;
  font-size: 20px;
}

.buttonImg {
  margin-top: 10px;
  padding-right: 30px;
  padding-left: 30px;
}
.imageFit {
  width: 100%;
  height: 200px;
}
.modalImage {
  background: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/socialShare.png);
  background-repeat: no-repeat;
  background-size: 32%;
  background-color: white;
  width: 550px;
  outline: none;
}
.modalRow_share {
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.ShareModalHeader {
  height: 40px;
  color: #000000;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ShareModalHeader h4 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 27px;
}
.shareLink {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 24px;
}
.ShareModalHeader h4 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 27px;
}
.modal_share {
  width: 80%;
}
.shareContent {
  border-radius: 20px;
  border: 1px solid #a5a5a5;
  font-size: small;
  width: 79%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  padding-left: 22px !important;
  color: #1c1c1c;
}

.copyLinkBtn {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  right: 0;
  top: 0;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
  padding: 0px 18px;
  font-weight: 800;
}
@media only screen and (max-width: 600px) {
  .modalImage {
    width: 94%;
  }
  .arPart {
    margin: 26px auto;
  }
  .ideaFirstImg,
  .ideaSecImg {
    height: auto;
  }
  .gridSection img {
    width: 100%;
    margin-bottom: 21px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 1;
    top: 0;
    left: -10;
    /* background-color: rgb(0 0 0 / 60%); */
    justify-content: center !important;
    align-items: center !important;
  }
  .lookFirst {
    width: 100%;
    /* height: 427px !important; */
    object-fit: contain;
  }
  .lookFirst img {
    width: 100%;
    height: auto;
  }
  .getLookSection {
    display: block;
  }
  .lookLast {
    width: 100%;
    position: relative;
  }
  .look {
    top: 7px;
    bottom: 0px;
    font-size: 15px;
  }
  .bestTxt {
    font-family: Quicksand;
    font-size: 15px;
    margin-top: 53px;
    /* padding: 0px 17px; */
  }
  .morePart {
    padding: 0px 20px;
  }
  .typesSection {
    text-align: center;
  }
  .paraCard {
    font-size: 12px;
  }
  .roomBox {
    border: 0.5px solid #dedede;
    height: 130px;
    box-shadow: 0px 0px 0px #dedede;
  }
  .lookLast img {
    height: 62px;
    border-bottom-left-radius: 4px;
    object-fit: cover;
    border-bottom-right-radius: 4px;
  }
  .bestSelller {
    width: 100%;
    margin: 0px auto;
  }

  .typesSectionwom {
    width: 100%;
    text-align: center;
    padding-top: 0px;
  }
  .leftFiter {
    display: none;
  }
  .product {
    cursor: pointer;
    font-family: Quicksand;
    font-size: 14px !important;
    text-align: left;
    font-weight: 500 !important;
    margin: 0px !important;
    margin-bottom: 27px !important;
  }
  .bannerImg {
    height: 170px;
    width: 100%;
  }

  .dataNamess {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    font-weight: 700;
    text-align: left;
    text-overflow: ellipsis;
    font-size: 15px;
    margin-bottom: 9px !important;
    margin-top: 3px;
  }
  .stock {
    white-space: nowrap;
    width: 100%;
    /* overflow: hidden; */
    font-weight: 700;
    text-align: left;
    text-overflow: ellipsis;
    font-size: 12px;
    margin-bottom: 9px !important;
    margin-top: 3px;
  }
  .priceData {
    margin-bottom: 0px;
  }
  .designIcon {
    height: 131px;
  }
  .heartAlign {
    margin: 0px auto !important;
    width: 92%;
    padding: 5px 11px;
  }
  .roomType {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .roomBoxbytype {
    border: 0.5px solid #dedede;
    height: 93px;
    box-shadow: 0px 0px 0px #dedede;
    border-radius: 6px;
  }
  .toggleImg {
    width: 23px;
    height: 19px;
    margin-top: 2px;
    margin-right: 9px;
  }
  .buttonImg {
    margin-top: 10px;
    padding-right: 0px;
    padding-left: 14px;
  }
  .imageFit {
    width: 100%;
    height: 100px;
    object-fit: contain;
  }
}
.filterButton {
  width: 86px;
  height: 32px;
  padding: 0px;
  border-radius: 50px;
  margin-left: 10px;
  background-color: #f7f7f7;
  text-align: left;
  border-color: #f7f7f7;
}
.similarButton1 {
  /* margin-left: 30px;
  margin-right: 50px; */
  width: 110%;
  /* top:-15px; */
  font-weight: 400;
  text-align: center;
  background: #f4eae6 0% 0% no-repeat padding-box;
  border: 0.2px solid #000000;
  opacity: 1;
  bottom: -10px;
  font: normal normal 600 12px/35px 'Quicksand';
  letter-spacing: 0px;
  color: #272727;
  cursor: pointer;
  border-radius: 2px;
  position: absolute;
  margin-left: 10px;
}
.cardWhole {
  width: 80%;
}
.shopArrow {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #f4eae6;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  bottom: -21px;
}
.noProducts {
  text-align: center;
  margin-bottom: 40px;
  font-weight: 500;
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .buttonImg {
    margin-top: 10px;
    padding-right: 12px;
    padding-left: 12px;
  }
  .imageFit {
    width: 100%;
    height: 175px;
  }
}
.toggleImg {
  width: 23px;
  height: 19px;
  margin-top: 2px;
  margin-right: 9px;
}
.designIconContainer1:hover {
  background-color: #e5f9ff;
}

/* shop by brand */

.brand {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
  background-color: #f1eeea;
  position: relative;
}

.design {
  position: absolute;
  bottom: 0;
  left: 0;
  /* width: 30%; */
}
.brandbanner {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f1eeea;
  position: relative;
  justify-content: flex-start;
  /* align-items: center; */
}

.brandLogo {
  width: 70%;
  height: 175px;
}

.brandLogo img {
  width: 175px;
  height: 175px;
}

.brandimage {
  position: relative;
  width: auto;
  height: 300px;
}

.text {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 21px;
  bottom: 25px;
  position: relative;
}

.shop_button {
  width: 125px;
  height: 40px;
  border-radius: 40px;
  background-color: #1a1818;
  color: white;
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
}
.shop {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.ARbutton {
  position: relative; /* Changed to relative */
  cursor: pointer;
  width: 50px;
  right: 70px;
  bottom: 10px;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  text-align: center;
  justify-content: center;
  font-family: 'Quicksands', sans-serif;
  background-color: white; /* Added background color */
  border: 1px solid #ccc; /* Added border */
  border-radius: 4px; /* Added border radius */
  padding: 10px; /* Added padding */
  width: 200px; /* Added width */
  top: 84px; /* Adjusted positioning */
  left: calc(50%); /* Adjusted positioning */
  transform: translateX(-50%); /* Adjusted positioning */
}

.tooltip::before {
  content: '';
  position: absolute;
  top: 100%; /* Changed to top */
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent; /* Changed border color */
}

.tooltipimg {
  position: relative;
  bottom: 52px;
  left: 43px;
  width: 20px;
  height: 20px;
}

.ARbutton:hover + .tooltip {
  visibility: visible;
  opacity: 1;
}

.brand1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 150px;
  background-color: #f1eeea;
  position: relative;
  overflow: hidden;
  top: 100px;
}

.brand1 > div {
  position: relative;
}

.brandbanner1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f1eeea;
  position: relative;
  justify-content: flex-start;
}

.brandLogo1 {
  width: 70%;
  height: 175px;
  position: absolute;
  bottom: 10px;
}

.brandLogo1 img {
  width: 70%;
  position: relative;
  /* bottom: 50%; */
}

.brandimage1 {
  position: relative;
  width: auto;
  height: 300px;
  bottom: 50%;
  /* object-fit: contain; */
}

.category_text {
  font-size: 28px;
}

@media (max-width: 640px) {
  /* Mobile devices */

  .brand {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
  }

  .ARbutton {
    display: none;
  }

  .brand1 {
    display: none;
  }

  .design {
    height: 100px;
    z-index: -2;
  }

  .brandbanner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
    /* background-color: #F1EEEA; */
    position: relative;
    justify-content: flex-start;
    align-items: center;
  }

  .brandLogo {
    width: 200px;
    height: 100px;
  }

  .brandLogo img {
    width: 125px;
    height: 100px;
    right: 160px;
    position: relative;
    top: -28px;
    background-color: #f1eeea;
    z-index: 1;
  }

  .textcont {
    width: 100vw;
    background-color: #f1eeea;
    z-index: 1;
    position: absolute;
    justify-content: center;
    top: 200px;
    right: 0px;
    height: 50px;
    opacity: 0.9;
  }

  br {
    display: block !important;
  }

  .text {
    position: relative;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 15px;
    word-wrap: break-word;
    top: -10px;
    text-align: left;
    z-index: 1;
    padding: 1rem;
    color: black;
  }

  .shop_button {
    width: 100px;
    height: 30px;
    border-radius: 40px;
    background-color: #1a1818;
    color: white;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    margin-top: 2rem;
    position: relative;
    left: 100px;
    top: 80px;
    z-index: 1;
  }

  .ARbutton {
    position: relative;
    cursor: pointer;
    width: 40px;
    right: 185px;
    bottom: -15px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    text-align: center;
  }

  .tooltip span {
    visibility: hidden;
    width: 160px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip img {
    width: 12px;
    height: 12px;
  }

  .tooltip:hover span {
    visibility: visible;
    opacity: 1;
  }

  .tooltipimg {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .brandimage {
    width: 100vw;
    bottom: 300px;
    height: 300px;
    object-fit: cover;
    margin: 0 auto;
    left: -2px;
  }

  .category_text {
    font-size: 28px;
    position: relative;
    left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  /* Tablet devices */
  .brand {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
  }

  .design {
    height: 100px;
  }
  
  .toggleImg {
    width: 23px;
    height: 19px;
    margin-top: 2px;
    margin-right: 9px;
  }
  .brandbanner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
    background-color: #f1eeea;
    position: relative;
    justify-content: flex-start;
    align-items: center;
  }

  .brandLogo {
    width: 200px;
    height: 100px;
  }

  .brandLogo img {
    width: 200px;
    height: 150px;
    right: 200px;
    position: relative;
    top: -15px;
  }

  .text {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 15px;
    right: 210px;
    top: 5px;
    text-align: center;
  }

  .shop_button {
    width: 125px;
    height: 40px;
    border-radius: 40px;
    background-color: #1a1818;
    color: white;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    margin-top: 1rem;
    position: relative;
    right: 190px;
  }

  .ARbutton {
    position: relative;
    cursor: pointer;
    width: 40px;
    right: 185px;
    bottom: -15px;
    z-index: -2;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    text-align: center;
  }

  .tooltip span {
    visibility: hidden;
    width: 160px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip img {
    width: 12px;
    height: 12px;
  }

  .tooltip:hover span {
    visibility: visible;
    opacity: 1;
  }

  .tooltipimg {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .brandimage {
    width: 60%;
    bottom: 252px;
    height: 250px;
    object-fit: cover;
    margin: 0 auto;
    left: 304px;
  }

  .brand1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 200px;
    background-color: #f1eeea;
    position: relative;
    top: 100px;
  }

  .brandbanner1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f1eeea;
    position: relative;
    justify-content: flex-start;
  }

  .brandLogo1 {
    width: 100%;
    height: 175px;
    position: absolute;
  }

  .brandLogo1 img {
    width: 70%;
    position: relative;
    /* bottom: 50%; */
  }

  .brandimage1 {
    height: 200px;
    object-fit: cover;
    margin: 0 auto;
  }
}
