.wholeContainer {
  width: 100%;
  height: auto;
}
.bannerBg {
  width: 100%;
  text-align: center;
  padding: 100px 0px;
  background-image: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/partner/Group-81.png);
  background-size: 100%;
  padding-bottom: 25%;
  background-repeat: no-repeat;
}
.headerTitle {
  text-align: center;
  font-weight: 600;
  font-size: 50px;
}
.buttonBlack {
  border-radius: 30px;
  margin-top: 35px;
  padding: 11px 40px;
  background-color: #000000;
  color: #ffffff;
  display: inline-flex;
  font-size: 16px;
}
.bgImages {
  background-color: #848b73;
  text-align: center;
  position: relative;
  margin-bottom: 63px;
}
.imgBan {
  width: 80%;
  margin-top: -274px;
}
.bgImages p {
  width: 70%;
  margin: 0px auto;
  margin-top: 58px;
  color: #ffffff;
  padding-bottom: 80px;
  font-size: 30px;
  font-weight: 500;
}
.pathLeft {
  position: absolute;
  bottom: 8%;
  left: 12%;
  width: 17%;
}
.pathRight {
  position: absolute;
  bottom: 7%;
  right: 0px;
  width: 10%;
}
.innerContainer {
  width: 80%;
  margin: 0px auto;
}
.greySection {
  background-color: #fafafa;
  padding: 90px 0px;
  margin-bottom: 30px;
}
.secImg {
  width: 100%;
}
.firstLeft {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 90px;
  width: 79%;
}
.firstLeft h2 {
  color: #101010;
  font-size: 37px;
  font-weight: 600;
}
.firstLeft p {
  color: #101010;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}
.showcase {
  text-align: center;
  font-weight: 600;
  font-size: 35px;
}
.logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: 0px auto;
  margin-top: 7%;
}
.logos img {
  width: 100%;
  padding: 0px 22px;
}
.logos img:nth-child(2) {
  width: 412px;
  height: 22px;
}
.formSection {
  width: 100%;
  height: auto;
  background-image: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/partner/Group+3231png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.container5 {
  width: 80%;
  /* background-color: #f5f0ec; */
  /* height: auto; */
  display: flex;
  justify-content: space-evenly;
  padding: 5vh 5vw 5vh 5vw;
  overflow: hidden;
}

.innerForm {
  width: 50%;
  margin: 0px auto;

  text-align: center;
}
.innerForm h3 {
  color: #101010;
  text-align: center;
  padding-top: 73px;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 61px;
}
.inputStyle {
  width: 100%;
  padding: 14px 12px;
  border-radius: 8px;
  border: 0.5px solid #b7b7b7;
  margin-bottom: 19px;
}
.form {
  width: 50%;
  margin: 0px auto;
}
.submitTxt {
  color: #0d0909;
  font-size: 13px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 52px;
}

@media only screen and (max-width: 500px) {
  .headerTitle {
    font-size: 22px;
    padding: 0px 21px;
  }
  .imgBan {
    margin-top: -43px;
  }
  .bgImages p {
    margin-top: 35px;
    color: #ffffff;
    padding-bottom: 80px;
    font-size: 19px;
    font-weight: 500;
    width: 90%;
  }
  .pathRight {
    right: 1px;
    width: 30%;
  }
  .pathLeft {
    display: none;
  }
  .innerContainer {
    width: 85%;
    margin: 0px auto;
  }
  .firstLeft {
    width: 100%;
    text-align: left;
    margin: 0px auto;
    padding: 0px 24px;
  }
  .Partner_secImg__3UXO- {
    align-items: center;
  }
  .greySection {
    padding: 39px 0px;
  }
  .firstLeft h2 {
    color: #101010;
    font-size: 25px;
    font-weight: 600;
    word-break: break-all;
  }
  .logos img {
    padding: 0px 22px;
    margin-top: 33px;
  }
  .showcase {
    text-align: center;
    font-weight: 600;
    font-size: 27px;
    padding: 0px 15px;
  }
  .innerForm {
    width: 85%;
    margin: 0px auto;
    text-align: center;
  }
  .innerForm h3 {
    color: #101010;
    text-align: left;
    padding-top: 73px;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 61px;
  }
  .form {
    width: 95%;
    margin: 0px auto;
  }
  .logos {
    flex-wrap: wrap;
  }
}
