@import url(https://fonts.cdnfonts.com/css/sofia-pro);
@import url(https://fonts.cdnfonts.com/css/sofia-pro);
@import url(https://fonts.cdnfonts.com/css/sofia-pro);
@import url(https://fonts.cdnfonts.com/css/sofia-pro);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators li {
  background-color: #000000 !important;
}

.react-player__shadow {
  background: radial-gradient(rgb(0 0 0), rgb(0 0 0 / 66%) 60%) !important;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.react-player__shadow:hover {
  background: radial-gradient(rgb(0 0 0), rgb(0 0 0 / 66%) 60%) !important;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html {
  scroll-behavior: smooth;
}
iframe {
  display: none;
}
.slick-next,
.slick-prev {
  padding: 14px !important;
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
  border: none;
  border-radius: 50px !important;
  background-color: #ffffff;
  z-index: 1 !important;
  -webkit-filter: drop-shadow(0px 3px 6px rgb(0, 0, 0, 16%));
          filter: drop-shadow(0px 3px 6px rgb(0, 0, 0, 16%));
  object-fit: none !important;
  /* background: #ffffff !important; */
}
.slick-prev {
  left: -43px !important;
}
.slick-next {
  right: -43px !important;
}
.bghjHX {
  margin: 0px !important;
  overflow-x: hidden;
  overflow-y: visible;
  height: 230px;
}
.css-1gsv261 {
  border: none;
  border-color: transparent !important;
}
.css-ciie9r-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
  background-color: #000000 !important;
  border-radius: 11px;
}
.userInput {
  z-index: 1000;
}
.MuiTabs-indicator {
  background-color: transparent !important;
}
.makeStyles-paper-2 {
  overflow: scroll;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: #fff;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 0px) !important;
}
.image-gallery-slides div {
  display: none;
}
.image-gallery-slides div:first-child {
  display: block;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails {
  overflow: scroll;
  scrollbar-width: none;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails::-webkit-scrollbar {
  width: 0px;
  /* height: 2em */
}
input:focus {
  outline: none;
  box-shadow: none;
}
.iVQili:hover:enabled,
.iVQili:focus:enabled {
  color: #000 !important;
  background-color: white !important;
  box-shadow: none !important;
}
.bdKNMg {
  padding: 0px 10px;
}
.iVQili {
  background-color: white !important;
  box-shadow: none !important;
}
/* }
.carousel-inner {
  overflow: visible !important;
} */
@media screen and (max-width: 767px) {
  .mXmYO {
    padding-left: 0px !important;
  }
  .makeStyles-paper-2 {
    width: 100%;
  }
  /* .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: #fff;
} */
  /* .css-1m4ljmh-MuiTypography-root{
  width: 76% !important;
}
.MuiAccordion-root {
  margin: 0px 18px !important;
  margin-bottom: 10px !important;
}   */

  .productContainer .MuiAccordion-root {
    background-color: #fff !important;
  }
}
.card-deck .card {
  padding-bottom: 23px;
}
@media screen and (min-width: 1550px) {
  .bghjHX {
    height: 287px;
  }
}

@media only screen and (max-width: 500px) {
  .slick-prev {
    left: -40px !important;
  }
  .slick-next {
    right: -40px !important;
  }

  .react-tel-input .form-control {
    width: 100% !important;
  }
  .react-tel-input .country-list {
    width: 260px !important;
  }
}

.Questionaire_mesonry__3HR0i {
  text-align: center;
  width: 90%;
  margin-top: 20%;
}

.Questionaire_mesonryDesign__x9XCr {
  text-align: center;
  width: 65% !important;
  /* margin-top: 35vh; */
}

.Questionaire_nameContainer__upxgU {
  /* text-align: center; */
  width: 70%;
  margin-top: 0px;
}

/* .mesonry div div:nth-child(2){
  text-align: center;
}
.mesonry div div:last-child{
  text-align: left;
}
.mesonry div:nth-of-type(even){
  
}
.mesonry div:nth-of-type(odd){
  
}
.mesonry div div{

} */
.Questionaire_nameContainer__upxgU input {
  width: 80%;
  border-color: #707070;
  border: none;
  border-bottom: 1px solid;
  background-color: transparent;
  font-size: 24px;
}
.Questionaire_nameContainer__upxgU input:focus-visible {
  outline: none;
}
.Questionaire_canvasjs-chart-credit__3N7uy {
  display: none;
}
.Questionaire_emailTextStyle__2CCzc {
  width: 80%;
}
.Questionaire_mesonryRooms__2N5kM {
  text-align: center;
  width: 85%;
  /* height: 100%; */
}
.Questionaire_welcomeLottie__3ZEkR {
  /* width: 470px; */
  margin: 0px auto;
  text-align: center;
  font-size: 33px;
  font-weight: 600;
  /* margin-top: 0px; */
  z-index: 100;
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 55px; */
}

.Questionaire_fewMore__24It1 {
  position: absolute;
  bottom: 30px;
  width: 22%;
  margin: 0px auto;
  left: -17%;
  right: 0px;
  bottom: 32%;
  font-weight: 400;
  font-size: 21px;
}

.Questionaire_fewMoreMobile__1RQVn {
  width: 90%;
  margin: 0px auto;
  font-weight: 400;
  font-size: 21px;
}

.Questionaire_mesonryInitalRoom__3hZen {
  text-align: center;
  width: 60%;
  margin-top: 0px;
}

.Questionaire_spaceGirdImg__2lHp6 {
  width: 95%;
  margin-bottom: 10px;
  border-radius: 10px;
}

.Questionaire_styleContainer__3op82 {
  margin-top: 10vh;
  margin-right: 38px;
}

.Questionaire_styleImg__2cATx {
  width: 100%;
  border-radius: 4px;
}
.Questionaire_imageAlignment__3F1D4 {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.Questionaire_container__2dagT {
  position: relative;
  top: 0;
  left: 0;
  font-family: 'Quicksand';
  width: 100%;
  overflow-x: hidden;
  background-color: white;
  margin-bottom: 76px;
}

.Questionaire_container__2dagT::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.Questionaire_styleTick__3ycs3 {
  position: absolute;
  height: 18px;
  top: 8px;
  right: 13px;
  z-index: 10;
}

.Questionaire_designClick__1vsZv {
  position: absolute;
  z-index: 1;
  top: 5%;
  right: 15% !important;
}

.Questionaire_resultPadding__2hdtN {
  padding-left: 20px;
}

.Questionaire_header__ymacu {
  width: 30%;
  position: fixed;
  top: 40%;
}

.Questionaire_roomDesignClick__5-F3r {
  position: absolute;
  z-index: 10;
  top: 5%;
  right: 15%;
}
.Questionaire_shop_room__1a7Nl {
  padding-left: 18px;
}
.Questionaire_designIconContainer__1KIUh {
  width: 115px;
  height: 115px;
  position: relative !important;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.Questionaire_designIcon__29D2T {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  padding: 20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Questionaire_imageContainer__ZELo0 {
  height: 100% !important;
}

.Questionaire_locationSelect__1ghOs {
  width: 200px;
  border-radius: 20px;
  border-style: solid;
  border-color: #2424245e;
  padding: 4px 20px;
  margin: auto;
  padding-right: 20px;
  margin-top: 30px;
}

.Questionaire_roomInput__3yzaW {
  width: 200px;
  border-radius: 20px;
  border-style: solid;
  border-color: #2424245e;
  padding: 4px 20px;
  padding-right: 20px;
  margin-left: -23vw;
  margin-top: 5vh;
}

.Questionaire_designIconContainerActive__XShKx {
  background-color: #e5f9ff;
  border: 1px solid #2b95b3;
}

.Questionaire_designIconContainer__1KIUh:hover {
  background-color: #e5f9ff;
}

.Questionaire_resultContainer__3f0u7 {
  width: 70%;
  height: 100%;
  margin-top: 5vh;
}

.Questionaire_resultContainer1__qFYeF {
  width: 100%;
  margin-top: 6vh;
  overflow: hidden;
  height: 100%;
}

.Questionaire_styleAcive__1LJOD {
  background-color: #d8f1ff;
  border-radius: 20px;
  margin-top: 50px;
  position: relative;
}

/* .styleContainer{
    margin-top: 20%;
} */

.Questionaire_colorContainer__3v3F8 {
  margin-top: 28vh;
}

@media only screen and (max-width: 500px) {
  .Questionaire_header__ymacu {
    width: 80%;
    position: absolute;
    margin-top: 5vh !important;
  }
  .Questionaire_mesonry__3HR0i div div {
    margin-bottom: 13px;
  }
  .Questionaire_resultContainer1__qFYeF {
    width: 100%;
    margin-top: 7vh;
    /* height: 150vh */
  }

  .Questionaire_header__ymacu h1 {
    font-size: 18px;
    /* font-weight: 400; */
    line-height: 29px;
  }

  .Questionaire_mesonry__3HR0i {
    width: 85% !important;
    height: 100%;
    margin-top: 10vh !important;
  }

  .Questionaire_spaceGirdImg__2lHp6 {
    width: 90%;
    height: 100%;
  }

  .Questionaire_mesonryDesign__x9XCr {
    /* height: 100%; */
    /* margin-top: 10vh; */
    width: 95% !important;
  }
  .Questionaire_mesonryRooms__2N5kM {
    width: 95% !important;
  }
  .Questionaire_mesonryRooms__2N5kM p {
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 13px;
  }
  .Questionaire_mesonryInitialRoom__2IHsl {
    width: 95% !important;
  }
  .Questionaire_mesonryInitialRoom__2IHsl p {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .Questionaire_mesonryDesign__x9XCr p {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .Questionaire_colorContainer__3v3F8 p {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .Questionaire_mesonryInitalRoom__3hZen {
    margin-top: 53px;
  }
  .Questionaire_mesonryInitalRoom__3hZen p {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .Questionaire_styleContainer__3op82 {
    height: 100%;
    margin-top: 2vh !important;
    width: 100% !important;
    margin: 0px auto;
  }

  /* .styleImg{
        width: 90%;
        height: 100%;
    } */
  .Questionaire_styleContainer__3op82 h4 {
    font-size: 18px;
  }

  .Questionaire_styleContainer__3op82 p {
    font-size: 16px;
  }

  .Questionaire_colorContainer__3v3F8 {
    width: 90%;
    margin-top: 53px !important;
    height: 100%;
  }

  .Questionaire_colorImg__2DxHQ {
    width: 90%;
    height: 100%;
  }

  .Questionaire_mesonryRooms__2N5kM {
    height: 100%;
    margin-top: 45px !important;
    width: 85%;
  }

  .Questionaire_mesonryInitalRoom__3hZen {
    height: 100%;
    width: 95%;
  }

  .Questionaire_nameContainer__upxgU {
    height: 47vh;
    margin-top: 20vh;
    width: 95%;
  }

  .Questionaire_nameContainer__upxgU input {
    font-size: 18px;
    width: 85%;
  }
  .Questionaire_emailTextStyle__2CCzc {
    width: 90%;
  }
  .Questionaire_basedHead__15f5b {
    width: 90%;
  }
  .Questionaire_basedHead__15f5b h4 {
    font-size: 18px !important;
    font-weight: 500;
    line-height: 28px;
  }
  .Questionaire_subBanner__rOG69 {
    width: 100% !important;
  }
  .Questionaire_moreTxt__2TvaX {
    font-size: 13px;
  }
  .Questionaire_boxImg__3ANOF {
    text-align: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .Questionaire_boxImg__3ANOF img {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .Questionaire_headDomain__3Uhfg {
    font-size: 18px;
  }
  .Questionaire_paraTxt__2phz1 {
    text-align: center !important;
  }
  .Questionaire_mx5__2rT4R {
    text-align: center;
  }
  .Questionaire_span__22eta {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 15px;
  }
  .Questionaire_resultContainer__3f0u7 {
    height: 100%;
    width: 80%;
    margin-top: 30vh;
  }

  .Questionaire_resultContainer__3f0u7 h1 {
    font-size: 20px;
  }

  .Questionaire_resultContainer__3f0u7 h5 {
    font-size: 17px;
  }

  .Questionaire_resultContainer__3f0u7 p {
    font-size: 16px;
  }

  .Questionaire_resultImage__102Su {
    height: 100%;
  }

  .Questionaire_resultList__12RsI h5 {
    font-size: 16px;
  }

  .Questionaire_resultList__12RsI p {
    font-size: 12px;
    text-align: center !important;
    margin-bottom: 31px;
  }
  .Questionaire_imageContainer__ZELo0 {
    height: auto !important;
  }
  .Questionaire_shareContent__1sk2Y {
    width: 100%;
  }

  .Questionaire_copyLinkBtn__3Wywe {
    width: 77%;
  }
}

.Questionaire_resultContainer1__qFYeF {
  width: 100%;
  margin-top: 6vh;
  /* overflow: hidden; */
}
.Questionaire_w3ProgressContainer__Sfzcv {
  width: 100%;
  height: 1.5em;
  position: relative;
  background-color: #f1f1f1;
}
.Questionaire_w3Progressbar__ib_7o {
  background-color: #757575;
  height: 100%;
  position: absolute;
  line-height: inherit;
}
.Questionaire_w3RoundXlarge__ADn2G {
  border-radius: 16px !important;
}
.Questionaire_progressBar__qZfyC {
  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
  box-shadow: none !important;
}

.Questionaire_progress1__JL90B {
  border-radius: 40px !important;
  background-color: white !important;

  /* Changes below */
  box-shadow: inset 0 0 0 2px #337ab7 !important;
  border: none;
}
.Questionaire_progress__3iVBl {
  height: 10px;
  border-radius: 4.25rem;
  width: 30%;
}
progress[value]::-webkit-progress-bar {
  background-color: #ededed;
  border-radius: 40px;
}

progress[value]::-webkit-progress-value {
  border-radius: 40px;
  background-color: brown;
}

.Questionaire_progress2__qt9zY {
  height: 10px;
  border-radius: 4.25rem;
  width: 30%;
}
.Questionaire_progress2__qt9zY[value]::-webkit-progress-bar {
  background-color: #ededed;
  border-radius: 40px;
}

.Questionaire_progress2__qt9zY[value]::-webkit-progress-value {
  border-radius: 40px;
  background-color: rgb(42, 126, 165);
}

.Questionaire_progress3__2fG8W {
  height: 10px;
  border-radius: 4.25rem;
  width: 30%;
}
.Questionaire_progress3__2fG8W[value]::-webkit-progress-bar {
  background-color: #ededed;
  border-radius: 40px;
}

.Questionaire_progress3__2fG8W[value]::-webkit-progress-value {
  border-radius: 40px;
  background-color: rgb(42, 126, 165);
}
/* @media only screen and (min-height: 900px){
    .styleContainer{
        margin-top: 300vh !important ;
    }
} */

@media only screen and (max-width: 1000px) {
  .Questionaire_mesonry__3HR0i {
    margin-top: 35vh;
  }

  .Questionaire_header__ymacu {
    position: absolute;
    /* margin-top: 10vh;
    top: 50%; */
    width: 80%;
    /* text-align: center; */
  }

  .Questionaire_styleContainer__3op82 {
    margin-top: 30%;
    width: 100%;
    height: 100%;
  }

  .Questionaire_styleContainer__3op82 h4 {
    margin-top: 1vh;
  }

  .Questionaire_colorContainer__3v3F8 {
    margin-top: 50%;
  }

  .Questionaire_mesonryRooms__2N5kM {
    margin-top: 50vh;
    /* z-index: 10; */
  }
}

@media only screen and (min-width: 1600px) {
  .Questionaire_mesonryDesign__x9XCr {
    width: 52% !important;
  }
  .Questionaire_mesonryRooms__2N5kM {
    width: 57% !important;
  }
  .Questionaire_mesonryInitalRoom__3hZen {
    width: 65%;
  }
}
.Questionaire_modalRow_share__1Kp7B {
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.Questionaire_modal_share__3_w0_ {
  width: 80%;
}
.Questionaire_shareLink__2QE-D {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 24px;
}
.Questionaire_shareContent__1sk2Y {
  border-radius: 20px;
  border: 1px solid #a5a5a5;
  font-size: small;
  width: 79%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  padding-left: 22px !important;
  color: #1c1c1c;
}
.Questionaire_copyLinkBtn__3Wywe {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  right: 0;
  top: 0;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
  padding: 0px 18px;
  font-weight: 800;
}

.donutchart-innertext-value {
  visibility: hidden;
}

.topbar {
  width: 100%;
  height: 50px;
  justify-content: space-between;
  display: flex;
  position: fixed;
  z-index: 1000;
  background-color: #ffffff;
}
.modalSave .modal-dialog {
  width: 724px !important;
}
.swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}
.modal-backdrop.show {
  opacity: 0.5 !important;
}
.swipe-wrap {
  overflow: hidden;
  position: relative;
}
.swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}
button {
  border: none;
}
.MuiButtonBase-root {
  /* padding-left: 0px !important; */
}
.typeSection .MuiButtonBase-root {
  font-size: 15px !important;
}
.typeSection .MuiSvgIcon-root {
  color: white !important;
}
.questionaireFooter {
  width: 100%;
  height: 60px !important;
  display: flex;
  justify-content: space-between;
  position: fixed;
  overflow-x: hidden !important;
  background-color: #ffffff;
  bottom: 0;
  font-family: 'Quicksand';
  overflow-y: hidden;
  align-self: center;
  border: 0.5px solid #b1b1b1;
}

.questionnaireNextBtn {
  cursor: pointer;
  position: relative;
  /* width: 130px;
    height: 41px; */
  background-color: black;
  border-radius: 30px;
  color: white;
  /* padding-top: 0px !important; */
  font-weight: 400;
  margin-top: 0px !important;
  align-items: center;
  padding: 10px 48px 12px 49px;
}
.questionnaireNextBtnDisable {
  background-color: #a8a8a8 !important;
}
.questionnaireNextBtn:disabled {
  background-color: #a8a8a8 !important;
}
.questionnaireNextBtn:hover {
  /* background-color: #a8a8a8; */
  font-weight: 900;
}
.questionnaireNextBtn[disabled]:hover {
  font-weight: 900;
}

button[disabled='disabled'],
button:disabled {
  background-color: #00000029;
}

button:disabled:hover {
  font-weight: 400;
}

.questionaireFooterPara {
  font-weight: initial;
  align-self: center;
}

.questionaireFooterSkip {
  /* text-decoration: underline; */
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
}
.questionaireFooterSkip:hover {
  text-decoration: underline;
}
.navbar_items {
  width: 40%;
  z-index: 10;
}
body {
  padding-right: 0px !important;
  font-family: 'Quicksand', sans-serif;
}

.navbar_items p {
  /* margin-left: 50px; */
  color: #000000;
  font-weight: 600;
}
.input-group-text {
  background-color: transparent !important;
  border: 1px solid #707070 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #1a1818 !important;
}
.input-group-text {
  border: 1px solid #707070 !important;
  margin-left: -2px !important;
}
.input-group .form-control {
  border: 1px solid #707070 !important;
}

.pagination .page-item .page-link {
  min-width: 23px !important;
}
.input-group .form-control:focus {
  outline: none;
  box-shadow: none !important;
}
.ml-0 {
  margin-left: 0px !important;
}

.navbar_icons {
  margin-left: -15%;
}

.navbar_icons img {
  padding-left: 30px;
}

.tabs {
  z-index: 40;
  display: flex;
  background-color: #ffffff;
  height: 40px;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tabs p {
  padding: 10px;
  opacity: 0.5;
  padding-bottom: 0px;
  cursor: pointer !important;
}

.tabs-active {
  padding-top: 10px;
  border-bottom: 2px solid #000000;
  margin-bottom: 0px;
  opacity: 1;
}

.navbar-container {
  height: auto !important;
}

.backdrop {
  background-color: #2c2c2c !important;
  opacity: 1 !important;
}

.modal {
  position: fixed;
  background-color: transparent !important;
  font-family: 'Quicksand';
}

.modal .modal-dialog {
  /* width: 724px; */
  width: 550px;
  background-color: transparent !important;
  max-width: 100% !important;
  /* height: 100% !important; */
  /* margin-top: 75px; */
}
.modal-open .modal {
  overflow-y: hidden !important ;
}

.MuiMenu-paper {
  background-color: #ffffff !important;
  border-radius: 21px;
  border: 1px solid #e2e2e2;
  box-shadow: none !important;
  position: fixed !important;
  font-size: 12px !important;
}
.MuiMenuItem-root {
  font-size: 10px !important;
}
.MuiAccordionSummary-content {
  justify-content: space-between !important;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails {
  margin-left: 10vw !important;
}

.font-weight-800 {
  font-weight: 800;
}
.navbarpm {
  padding-top: 5px !important;
  display: flex !important;
  background-color: #ffffff !important;
  justify-content: space-between !important;
  font-family: Quicksand !important;
  width: 100% !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
  position: fixed !important;
  z-index: 3 !important;
  padding-bottom: 0px !important;
}
.font-weight-100 {
  font-weight: 100;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiAccordion-root {
  border: 1px solid white !important;
  border-radius: 10px !important;
  background-color: #f7f7f7 !important;
  /* background-color: #fff !important; */
}
.productContainer .MuiAccordion-root {
  border: 1px solid white !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  /* background-color: #fff !important; */
}
.MuiAccordionSummary-root {
  padding-left: 25px !important;
  /* background-color: white !important; */
}

.MuiAccordion-root:before {
  display: none;
}
.MuiAccordion-root {
  margin-bottom: 25px;
  /* padding-top: ; */
}
.MuiAccordionDetails-root {
  padding-left: 25px !important;
}
@media only screen and (max-width: 500px) {
  .tabs {
    margin-top: 0px;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .tabs p {
    font-size: 15px;
  }
  .questionaireFooter {
    height: 80px !important;
    z-index: 15;
    display: block;
  }
  .questionnaireNextBtn {
    margin-top: 20px !important;
  }
  .questionaireFooterSkip {
    color: black;
    font-size: 16px;
    font-weight: 700;
    padding-left: 10vw;
    margin-right: 10px;
    margin-top: 10px;
  }
  .questionaireFooterPara {
    font-size: 14px;
    padding-left: 10vw;
  }
  .questionnaireNextBtn:hover {
    /* background-color: #a8a8a8; */
  }
  .topbar {
    box-shadow: 0px 0px 6px #e8e6e6;
  }
  .MuiMenuItem-root {
    width: auto;
    overflow: hidden;
    font-size: 1rem;
    box-sizing: border-box;
    min-height: 29px !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 0px !important;
    padding-top: 0px !important;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 0px !important;
  }
}

@media only screen and (max-width: 500px) {
  .questionnaireNextBtn {
    margin-left: 0px;
    padding: 8px 25px 8px 25px;
    /* margin-left: -20px; */
  }
  .modal .modal-dialog {
    width: 95% !important;
    position: relative !important;
    display: flex;
    top: -5%;
    /* left: 7%; */
    background-color: transparent !important;
    /* max-width: 80% !important; */
    height: 100% !important;
  }

  .backdrop {
    background-color: #2c2c2c !important;
    opacity: 1 !important;
    width: 100% !important;
    height: 100% !important;
  }
  .modal-open .modal {
    margin-top: 21px;
  }
  .modal .modal-dialog {
    top: 0%;
    margin-top: 0px;
  }
  .modal-content {
    width: 98% !important;
    height: 100%;
    overflow-y: scroll;
  }
  .modal-open .modalAdd {
    overflow-y: scroll !important;
  }
  .modalAdd .modalContainer {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 360px) {
  .tabs p {
    font-size: 12px;
  }
}

@media screen and (min-width: 2000px) {
  .borderBottom {
    margin-bottom: -1vh !important;
    padding-bottom: 1vh;
  }
}

.gJwCWj > li {
  background: 'white';
}

.Questionaaire2_Questionaire2Container__N2_sY {
  position: relative;
  top: 0;
  left: 0;
  font-family: 'Quicksand';
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
  height: '100%';
}

.Questionaaire2_header__2OMkT {
  /* font-weight: 100; */
  padding-left: 150px;
}

.Questionaaire2_mesonryDesign__2DjDg {
  width: 42% !important;
}
.Questionaaire2_mesonryStage__1fj_M {
  width: 60% !important;
}

.Questionaaire2_mesonryRent__1nIr2 {
  width: 60% !important;
}

@media only screen and (max-width: 1600px) {
  .Questionaaire2_mesonryStage__1fj_M {
    width: 80% !important;
  }

  .Questionaaire2_mesonryDesign__2DjDg {
    width: 60% !important;
  }
}

@media only screen and (max-width: 900px) {
  .Questionaaire2_mesonryStage__1fj_M {
    width: 100% !important;
  }

  .Questionaaire2_mesonryDesign__2DjDg {
    width: 100% !important;
  }
}

.Questionaaire2_designIconContainer__2xfdc {
  width: 145px;
  height: 145px;
  position: relative;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.Questionaaire2_designIcon__2lePe {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Questionaaire2_locationSelect__3ogg9 {
  width: 200px;
  border-radius: 20px;
  border-style: solid;
  border-color: #2424245e;
  padding: 5px;
  margin: auto;
  padding-right: 20px;
  margin-top: 30px;
  font-size: 14px;
  color: black;
  -webkit-padding-end: inherit;
          padding-inline-end: inherit;
}
.Questionaaire2_locationSelect__3ogg9 option {
  position: relative;
}

.Questionaaire2_roomInput__jmN5q {
  width: 200px;
  border-radius: 20px;
  border-style: solid;
  border-color: #2424245e;
  padding: 4px 20px;
  padding-right: 20px;
  /* margin-left: -23vw; */
  margin-top: 5vh;
  background-color: white;
}
.Questionaaire2_designClick__6uB_b {
  position: absolute;
  z-index: 1;
  top: 5%;
  right: 17%;
}

.Questionaaire2_roomDesignClick__3S9vs {
  position: absolute;
  z-index: 1;
  /* top: 5%;
    right: 10%; */
}

.Questionaaire2_designIconContainerActive__1Yp5N {
  background-color: #e5f9ff;
  border: 1px solid #2b95b3;
}

.Questionaaire2_designIconContainer__2xfdc:hover {
  background-color: #e5f9ff;
}

.Questionaaire2_height__1T_kh {
  height: 150px;
}

@media only screen and (max-width: 500px) {
  .Questionaaire2_header__2OMkT {
    width: 80%;
    font-size: 20px;
    padding-left: 0px;
  }
  .Questionaaire2_headerContainer__2Em2m {
    margin-top: 2vh !important;
    height: 100px !important;
  }
  .Questionaaire2_mesonryDesign__2DjDg {
    height: 100%;
    margin-top: 5vh;
    width: 95%;
  }
  .Questionaaire2_mesonryRent__1nIr2 {
    height: 100%;
    margin-top: 5vh;
    width: 90% !important;
  }
  .Questionaaire2_locationContainer__SL0Lo {
    height: 100%;
    margin-top: 0px !important;
    width: 95%;
  }
  .Questionaaire2_locationSelect__3ogg9 {
    padding: 8px 11px;
    margin: auto;
    /* padding-right: 20px; */
    margin-top: 30px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 11px;
  }
  .Questionaaire2_height__1T_kh {
    height: 100px;
  }
  .Questionaaire2_mesonryDesign__2DjDg p {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .Questionaaire2_mesonryStage__1fj_M p {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .Questionaaire2_mesonryStage__1fj_M span {
    margin-bottom: 20px;
  }
  .Questionaaire2_mesonryStage__1fj_M {
    padding: 0px 16px;
  }
}

@media only screen and (max-width: 500px) {
  .Questionaaire2_header__2OMkT {
    width: 90%;
    font-size: 18px;
    padding-left: 0px;
    font-weight: 500;
  }
  .Questionaaire2_mesonryDesign__2DjDg {
    height: 100%;
    margin-top: 0px;
    width: 100% !important;
    padding: 0px 20px;
  }
  .Questionaaire2_mesonryRent__1nIr2 {
    height: 100%;
    margin-top: 5vh;
    width: 90% !important;
  }
  .Questionaaire2_locationContainer__SL0Lo {
    height: 100%;
    margin-top: 5vh;
    width: 95%;
  }
  .Questionaaire2_locationSelect__3ogg9 {
    width: 100%;
  }

  .Questionaaire2_height__1T_kh {
    height: 50px;
  }
  .Questionaaire2_headerContainer__2Em2m {
    height: 100px !important;
  }
  .Questionaaire2_designIconContainer__2xfdc {
    width: 115px;
    height: 115px;
  }
  .Questionaaire2_roomInput__jmN5q {
    margin-top: 1vh;
    margin-bottom: 5vh;
  }
}

@media only screen and (max-width: 1000px) {
  .Questionaaire2_imageContainer__1ovaO {
    height: 100% !important;
    width: 100% !important;
  }
  .Questionaaire2_headerContainer__2Em2m {
    height: 150px;
  }
}

.Header_header__1VCKf{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0B0E15;
    color: #DFD9D6;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    position: fixed;
    top:0;
    z-index: 10;
}

.Header_headerContainerWeb__Ea28j{
    width: 90%;
    height: 75px;
    display: flex;
    justify-content: space-between ;
    align-items: center;

}
.Header_bottomHeader__230HM{
    position: relative;
    display: flex;
    font-size: large;
    width: 90%;
    background-color: #0B0E15;
    align-items: center;
    justify-content: center;
}

.Header_headerContainerMob__rYvmR{
    display: none;
}

.Header_headerLogo__pHUY0{
    height: 2.2em;
    cursor: pointer;
}

.Header_cartLogo__12KEc{
    position: relative;
    cursor: pointer;
}

.Header_cartBadge__2VhLW{
  position: absolute;
  right:-8px;
  font-size: 10px;
  font-weight: 900;
  background-color: #edd3d8ed;
  width: 15px;
  height: 15px;
  color: #000000;
  border-radius: 10px;
  text-align: center;
}

.Header_accountLogo__DsTmM{
    margin-left: 1.5em;
    cursor: pointer;
}

.Header_headerNavigation__1siMh{
    padding: 0 15px;
    font-size: 16px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {

.Header_header__1VCKf{
    height: 100px;
}

.Header_headerContainerWeb__Ea28j{
    display: none;
}

.Header_headerContainerMob__rYvmR{
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    width: 90%;
    /* padding-top: 15px; */
}

.Header_mobHeader__2CLNz{
    display: flex;
    justify-content: space-between ;
    align-items: center;
}

.Header_headerNavigation__1siMh{
    font-size: 12px;
}
.Header_bottomHeader__230HM{
    display: none !important;
}
.Header_bottomHeaderMob__JaKTr{
    position: relative;
    display: flex;
    font-size: medium;
    width: 100%;
    background-color: #0B0E15;
    align-items: center;
    justify-content: center;
}

}
.Search_searchContainer__28UAS{
    flex: 0.8 1;
    max-width: 1200px;
}

.Search_searchbar__3DiTW{
    border-radius: 2px;
    border: 2px solid #7B8569;
}


.Search_searchLogo__1RZ8V{
    width: 28px;
    height: 28px;
    padding: 2px;
    cursor: pointer;
    
}

.Search_option__3Qq02{
    padding-left: 4px;
    padding-bottom: 4px;
    font-weight: 400;
    color: #171717;
    background-color: #EEEAE6;
}

.Search_option__3Qq02:hover{
    background-color: #ffffff;
    cursor: pointer;
}







.Rooms_imageContainer__2Q9-7 {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Card-mockup_h0ltd3.png');
  height: 100vh;
  width: 100%;
  padding: 0px;
  position: relative;
  background-size: 100%;
  margin-bottom: -100px;
  z-index: -10;
  padding-top: 5px !important;
}

.Rooms_leftContainer__3dsa8 {
  padding: 5vw !important;
}

.Rooms_leftContainer__3dsa8 h2 {
  font-size: 22px;
}

.Rooms_stepperContainer__ZykRm {
  z-index: 100 !important;
}

.Rooms_dotted__LduCs {
  width: 100px;
  height: 10px;
  margin-top: -25px;
  margin-left: -10px;
  background-color: transparent;
}

.Rooms_designCompletedDialog__2jz6q {
  width: '100%';
  height: 120px;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #1a1818;
}
@media only screen and (max-width: 1240px) {
  .Rooms_leftContainer__3dsa8 {
    width: 90% !important;
  }
  .Rooms_imageContainer__2Q9-7 {
    width: 100%;
    height: 100vh !important;
    background-size: 100%;
    background-image: url('https://d3izcfql7lesks.cloudfront.net/Card-mockup_dvmeak.png');
    background-position: left;
    background-repeat: no-repeat;
  }

  .Rooms_stepCountCircle__1jXjK:before {
    content: '';
    position: absolute;
    border: 1px dashed black;
    width: 100%;
    top: 15px;
    right: 0px;
    left: 0px;
    margin-left: calc(-50% - 20px - 15px);
    width: 100vw;
  }

  .Rooms_stepCountCircless__13Mr7:before {
    content: '';
    position: absolute;
    border: 1px dashed black;
    width: 100%;
    top: 15px;
    right: 0px;
    left: 0px;
    /* margin-left: calc(-50% - 20px - 15px);
    width: 100vw; */
  }
  .Rooms_firstStep__1fVtM:before {
    content: '';
    position: absolute;
    border: 1px dashed black;
    width: 100%;
    top: 15px;
    right: 0px;
    left: 179px;
    z-index: 1;
    margin-left: 0px;
  }
  .Rooms_lastStep__odqR3:before {
    content: '';
    position: absolute;
    border: 1px dashed black;
    width: 100%;
    top: 15px;
    left: 16px;
    right: 196px;
    z-index: 1;
  }
  .Rooms_conceptCarouselContainer__3Sx1p .Rooms_carousel-inner__15Jyc {
    overflow: visible !important;
  }
}
/* Get Started Screen */

.Rooms_pageContentContainer__iw_T_ {
  height: 90vh;
}
.Rooms_rightSideContainer__1bvaD {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
  background-color: #f5f0ec;
  z-index: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.Rooms_rightSideContentWrapper__23508 {
  max-width: 1500px;
}

/* left sidebar */

.Rooms_leftSlidebarContainer__iA65t {
  position: relative;
  height: 100%;
  padding: 20px;
}
.Rooms_desktopSidebar__34WJj {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.Rooms_desktopSidebar__34WJj img {
  max-width: 60px;
  margin-bottom: 15px;
}
.Rooms_desktopSidebar__34WJj h4,
.Rooms_mobileSidebar__2PaxN h4 {
  text-decoration: underline;
  font-weight: bold;
}
.Rooms_desktopSidebar__34WJj h5,
.Rooms_mobileSidebar__2PaxN h5 {
  font-weight: 600;
  font-size: 1.2rem;
}
.Rooms_mobileSidebar__2PaxN {
  display: flex;
  padding: 20px;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .Rooms_mobileSidebar__2PaxN {
    min-height: 500px;
  }
}
.Rooms_mobileSidebar__2PaxN img {
  max-width: 60px;
  margin-right: 15px;
}
.Rooms_mobileSidebarSec1__1TQ3N {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

/* Cards Desktop Styles*/
.Rooms_cardImageDesktop__Ut-Eb img {
  margin-top: -60px;
}
.Rooms_cardsRowDesktop__20xPf {
  width: 100%;
  align-items: flex-start;
  justify-content: space-evenly;
}
.Rooms_cardContainerDesktop__3Tepq,
.Rooms_stepCountContainerDesktop__jDntx,
.Rooms_cardContentsDesktop__3s4DR {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Rooms_stepCountContainerDesktop__jDntx h5 {
  text-decoration: underline;
  margin: 0px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
}
.Rooms_stepCountCircle__1jXjK {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  border: 2px solid black;
  background-color: #f5f0ec;
  z-index: 2;
}

.Rooms_stepCountCircless__13Mr7 {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  border: 2px solid black;
  background-color: #f5f0ec;
}
.Rooms_activeStepCircle__1NzVN {
  background-color: #ff4e00;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  z-index: 1;
}
/* .active .numberCount{
  display: none;
}
.active{

} */
.Rooms_cardContentWrapperDesktop__34xhh {
  background-color: #f3e6db;
  border-radius: 10px;
  margin-top: 80px;
  height: 305px;
  padding: 20px;
}
.Rooms_cardContentsDesktop__3s4DR p {
  text-align: center;
  font-size: 0.8rem;
  margin-top: 20px;
}
.Rooms_desktopCardImage1__1QdbX img {
  margin-bottom: 8px;
}

/* Cards Mobile Styles*/
.Rooms_conceptCarouselContainer__3Sx1p {
  padding: 20px;
  /* height: 760px; */
  height: 100%;
  /* overflow: hidden; */
  overflow-x: visible;
  padding-bottom: 20px;
}
.Rooms_carouselCardImageContainer__1Tem- {
  height: 100%;
}
.Rooms_carouselCardImageContainer__1Tem- img {
  height: 100%;
  object-fit: contain;
}
.Rooms_carouselCardContainer__1lI2B p {
  margin: 50px 0px;
  max-width: 500px;
  text-align: center;
}
.Rooms_carouselCardContainer__1lI2B {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Rooms_conceptCarouselItem__2Kyas {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.Rooms_stepCountContainerMobile__2IOpD {
  display: flex;
  align-items: center;
  margin: 40px 0px;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100vw;
}
@media screen and (max-width: 767px) {
  .Rooms_pageContentContainer__iw_T_ {
    height: 100%;
  }
}

/* dottedLink */

.Rooms_dottedLink__4KGtO {
  position: absolute;
  border: 1px dotted black;
  width: 280px;
  top: 15px;
  right: -140px;
  z-index: 1;
}

@media screen and (max-width: 1500px) {
  .Rooms_dottedLink__4KGtO {
    width: 220px;
    right: -110px;
  }
}
/* .dottedLinkMobileLeft,
.dottedLinkMobileRight {
  position: absolute;
  border: 1px dotted black;
  z-index: 1;
}
.dottedLinkMobileLeft {
  width: 0px;
  top: 15px;
  left: -200px;
}
.dottedLinkMobileRight {
  width: 600px;
  top: 15px;
  right: -600px;
} */
/* @media screen and (max-width: 1412px) {
  .carousel-inner {
    overflow: visible;
  }
} */

/* select {
    width:150px;
    -webkit-border-top-right-radius: 15px;
    -webkit-border-bottom-right-radius: 15px;
    -moz-border-radius-topright: 15px;
    -moz-border-radius-bottomright: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding:2px;
} */

.package_container__3w5C- {
  margin-top: 7vh !important;
  font-family: 'Quicksand';
}
.package_PackageContainer__2EPFF {
  background-color: #ffffff;
  position: relative;
  top: 0;
  left: 0;
  font-family: 'Quicksand';
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.package_header__3bUoL {
  font-size: 2rem;
  font-weight: 100;
}

.package_card__39yfL {
  width: auto;
  height: 100%;
  background-color: #ffffff !important;
  border-radius: 0px !important;
  border-right: none;
  border-left: none;
  border-bottom: none;
  -webkit-filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 18%));
          filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 18%));
}

.package_card_title__FRysa {
  font-size: 1.5rem !important;
  font-weight: bolder !important;
  text-transform: capitalize !important;
}

.package_Enhanced__1DX_D {
  position: relative;
  margin-right: 1rem;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.package_Enhanced__1DX_D:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  /* transform: scaleX(0); */
  border: solid 3px #eab146;
  border-color: #eab146 transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.package_Exclusive__2THf8 {
  position: relative;
  margin-right: 1rem;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.package_Exclusive__2THf8:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;

  border: solid 3px #a1cfdb;
  border-color: #a1cfdb transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  transition-delay: 0.5s;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition-property: -webkit-transform 0.25s ease-out;
  transition-property: transform 0.25s ease-out;
  transition-property: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.package_Express__jBnN4 {
  position: relative;
  margin-right: 1rem;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.package_Express__jBnN4:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  /* transform: scaleX(0); */
  border: solid 3px #d99fa6;
  border-color: #d99fa6 transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

/* .underline:hover:after{
  transform: scaleX(1);
  transform-origin:bottom left;
} */

.package_textSub__2Wpeh {
  text-align: center;
}

.package_price__2RW0K {
  font-family: 'Sofia Pro', sans-serif;
  width: 100% !important;
  font-size: 1rem;
}

.package_sub__37C7A {
  width: 50% !important;
  margin-top: 3vh !important;
  font-weight: 500;
}

.package_button__3mfvj {
  width: 182px;
  height: 48px;
  color: #f5f0ec;
  background-color: #171717;
  border-radius: 30px;
  font-weight: 400;
}

.package_button__3mfvj:hover {
  font-weight: 900;
}

.package_button1__3oFSd {
  width: 182px;
  height: 48px;
  color: grey;
  /* background-color: #171717; */
  border-radius: 30px;
  /* font-size: 1vw; */
}
.package_prices__1fxUj {
  align-items: center;
  display: flex;
  justify-content: center;
}
.package_strick__3Rk8f {
  color: #cbcbcb;
  font-weight: 600;
  text-decoration: line-through;
}
.package_original__3m4W7 {
  color: #1a1818;
  font-weight: 600;
  position: relative;
}
.package_prices__1fxUj div {
  padding: 0px 20px;
  font-size: 35px;
}
.package_original__3m4W7:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 100%;
  /* transform: scaleX(0); */
  border: solid 3px #e6caca;
  border-color: #e6caca transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
@media only screen and (max-width: 500px) {
  .package_card__39yfL {
    width: 90% !important;
    max-width: 100% !important;
  }
  .package_container__3w5C- {
    max-width: 100% !important;
  }
  .package_container__3w5C- {
    margin-top: 2vh !important;
  }
  .package_card_title__FRysa {
    font-size: 1.5rem !important;
    margin-bottom: 20px !important;
  }
  .package_price__2RW0K {
    font-size: 1rem !important;
  }
  .package_sub__37C7A {
    font-size: 4vw;
  }

  .package_button__3mfvj {
    font-size: 14px;
    padding: 9px 21px;
    border-radius: 30px;
    width: 100%;
    height: auto;
  }
  .package_header__3bUoL {
    font-size: 19px;
    font-weight: 500;
  }

  .package_prices__1fxUj div {
    padding: 0px 13px;
    font-size: 18px;
  }
}

/* .container{
    
    font-family: 'Quicksand';
    background-color: #F7F7F7;
    max-width: 100% !important;
    margin: 0px !important;
    
} */

.package_header__3bUoL {
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 10px;
}

.package_card__39yfL {
  width: 100%;
  /* height: 280px; */
  background-color: #ffffff !important;
  border-radius: 0px !important;
  border-right: none;
  border-left: none;
  border-bottom: none;
  box-shadow: 0px 3px 10px #0000002e;
  /* -webkit-filter: drop-shadow(0px 2px 3px rgb(0,0,0,18%));
  filter: drop-shadow(0px 2px 3px rgb(0,0,0,18%)); */
  padding: 20px;
  min-height: 305px;
}
.package_cardSubText__3R5E1 {
  font-size: 14px;
  font-weight: normal;
  font-family: 'Quicksand';
  color: black;
}

.package_card_title__FRysa {
  font-size: 1.5rem !important;
  font-weight: bolder !important;
  text-transform: capitalize !important;
}

.package_price__2RW0K {
  font-family: 'Sofia Pro', sans-serif;
  width: 100% !important;
  font-size: 1rem;
}

.package_sub__37C7A {
  width: 50% !important;
  margin-top: 3vh !important;
  font-weight: 500;
}

.package_button__3mfvj {
  width: 182px;
  height: 48px;
  color: #f5f0ec;
  background-color: #171717;
  border-radius: 30px;
  font-weight: 400;
}

.package_button__3mfvj:hover {
  font-weight: 900;
}

.package_Enhanced__1DX_D {
  position: relative;
  margin-right: 1rem;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.package_Enhanced__1DX_D:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  /* transform: scaleX(0); */
  border: solid 3px #eab146;
  border-color: #eab146 transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.package_Exclusive__2THf8 {
  position: relative;
  margin-right: 1rem;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.package_Exclusive__2THf8:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  border: solid 3px #a1cfdb;
  border-color: #a1cfdb transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  transition-delay: 0.5s;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition-property: -webkit-transform 0.25s ease-out;
  transition-property: transform 0.25s ease-out;
  transition-property: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.package_Express__jBnN4 {
  position: relative;
  margin-right: 1rem;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.package_Express__jBnN4:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  /* transform: scaleX(0); */
  border: solid 3px #d99fa6;
  border-color: #d99fa6 transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
.package_included__1Rp-n {
  width: 100%;
  text-align: center;
  color: #101010;
  font-weight: 600;
  font-size: 30px;
  margin-top: 55px;
  margin-bottom: 30px;
  width: 100%;
}
/* .underline:hover:after{
    transform: scaleX(1);
    transform-origin:bottom left;
  } */

.package_cardss__D8-V0 {
  width: 17.5%;
  margin-right: 30px;
}
.package_cardss__D8-V0:last-child {
  margin-right: 0px;
}
.package_images__3TihK {
  width: 40px;
}
.package_title__1xX7p {
  text-align: left;
  color: #101010;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.package_content__2Aq9n {
  text-align: left;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
}
.package_designButton__1mHjK {
  padding: 10px 25px;
  font-size: medium;
  border-radius: 25px;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  color: #f5f0ec;
  background-color: black;
  font-weight: 400;
  font-size: 1vw;
}
.package_textSub__2Wpeh {
  text-align: center;
}
.package_prices__1fxUj {
  align-items: center;
  display: flex;
  justify-content: center;
}
.package_strick__3Rk8f {
  color: #cbcbcb;
  font-weight: 600;
  text-decoration: line-through;
}
.package_original__3m4W7 {
  color: #1a1818;
  font-weight: 600;
  position: relative;
}
.package_prices__1fxUj div {
  padding: 0px 20px;
  font-size: 35px;
}
.package_original__3m4W7:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 100%;
  /* transform: scaleX(0); */
  border: solid 3px #e6caca;
  border-color: #e6caca transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
@media only screen and (max-width: 500px) {
  .package_card__39yfL {
    width: 90% !important;
    max-width: 100% !important;
    height: -webkit-max-content;
    height: max-content;
  }
  .package_container__3w5C- {
    max-width: 100% !important;
  }
  .package_container__3w5C- {
    margin-top: 2vh !important;
  }
  .package_newPackage_included__1KT3S__WwmWb {
    font-size: 24px;
    margin-top: 27px;
  }
  .package_cardss__D8-V0 {
    width: 90%;
    margin-right: 0px;
  }
  .package_designButton__1mHjK {
    font-size: 14px;
  }
  .package_images__3TihK {
    margin: 0px auto;
  }
  .package_title__1xX7p {
    text-align: center;
  }
  .package_content__2Aq9n {
    text-align: center;
  }
  .package_included__1Rp-n {
    margin-top: 35px;
    font-size: 25px;
  }
  .package_cardss__D8-V0 .package_card__39yfL {
    max-height: auto;
    height: 250px;
    min-height: auto;
  }
  .package_prices__1fxUj div {
    padding: 0px 13px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .package_packages__2ca2V {
    width: 85% !important;
  }
  .package_card__39yfL {
    min-height: 325px;
  }
}

.Footer_footerContainer__2KsCD{
    width: 100%;
    background-color: #171717;
    height: 420px;
    color:#ffffff;
    padding: 25px 0 15px 0
}

.Footer_footerInnerContainer__1Ecar{
    width: 90%;
    height: 90%;
    margin: auto;
    display: flex;
}

.Footer_footerLeft__Aq2ua{
    width: 43%;
    display: flex;
    flex-direction: column;
}
.Footer_footerRight__2Jl3u{
    display: flex;
    flex-direction:row;
    flex: 1 1;
    justify-content: space-between;

}

.Footer_footerLogo__2KSJm{
    height: 2em;
    width: -webkit-max-content;
    width: max-content;
    cursor: pointer;
    margin-bottom: 1.5rem;
}

.Footer_qrContainer__2JbuL{
    display: flex;
    justify-content: space-between;
    padding: 2px 4px 2px 10px;
    border: 1px solid #ECECEC;
    border-radius: 5px;
    height: 84px;
    max-width: 295px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
}

.Footer_navListContainer__3tMpn{
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: space-between;
}

.Footer_mobileFooterTop__1S1ds{
    display: none;
    
}

.Footer_mobileFooterBottom__3qOkK{
    display: none;
    justify-content: end;
    flex-direction: row;
}

.Footer_socialMediaContMob__22Ua_{
    display: none;
}

.Footer_copyRightTxt__2f7kE{
    text-align: center;
}

.Footer_socialMediaContWeb__2MbPo{
    display: flex;
    justify-content: start;
    margin-top: 0.5rem;
}

@media only screen and (max-width: 600px) {
    .Footer_footerContainer__2KsCD{
        height:auto;
        padding: 25px 15px 25px 15px
    }

    .Footer_footerInnerContainer__1Ecar{
        width: 100%;
        height: auto;
        flex-direction: column;
    }

    .Footer_footerLeft__Aq2ua{
        width: 100%;
    }

    .Footer_footerRight__2Jl3u{
        display: none;
    }

    .Footer_qrContainer__2JbuL{
        width: 90%;
        max-width: none;
    }

    .Footer_footerLogo__2KSJm{
        height: 40px;
    }

    .Footer_mobileFooterTop__1S1ds{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1.5rem;
        
    
    }

    .Footer_mobileFooterBottom__3qOkK{
        display: flex;
        justify-content: start;
        margin-top: 1rem;
        padding-bottom: 1.5rem ;
        border-bottom: 1px solid #FFFFFF;
    }

    .Footer_socialMediaContMob__22Ua_{
        display: flex;
        justify-content: center;
        padding-top: 1.5rem 
    }

    .Footer_copyRightTxt__2f7kE{
        padding-top: 5px;
    }

    .Footer_socialMediaContWeb__2MbPo{
        display: none;
       
    }

    
  }


.MyBoard_container__1FsiJ {
  max-width: 90% !important;
  font-family: 'Quicksand';
}

.MyBoard_mesonry__3KM5i {
  text-align: center;
  display: flex;
  justify-content: center !important;
  width: auto;
}

.MyBoard_mesonry__3KM5i div:nth-child(2) div:first-child,
.MyBoard_mesonry__3KM5i div:nth-child(2) div:first-child img {
  height: 100%;
  object-fit: cover;
}
.MyBoard_myBoardContainer__2l8fL:nth-child(odd) {
  float: left;
  margin: 0px;
}
.MyBoard_myBoardContainer__2l8fL:nth-child(even) {
  padding: 0px;
  float: right;
}
.MyBoard_mesonry__3KM5i div:nth-child(2) div:last-child img {
  height: 97px;
  object-fit: cover;
}
.MyBoard_menus__1XDdV {
  display: inline-flex;
  justify-content: space-between;
}
.MyBoard_menus__1XDdV p {
  font-size: 0.9rem !important;
  text-align: left;
  padding-left: 5px;
  cursor: pointer;
  font-weight: 500;
}
.MyBoard_editBoard__1nLbT {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.MyBoard_mesonryItems__22mxo {
  text-align: center;
  justify-content: center !important;
  width: 50% !important;
}

.MyBoard_spaceGirdImg__KWejl {
  width: 100%;
  /* padding: 5px; */
  height: 100%;
  object-fit: cover;
  border: 0.5px solid #e3e3e3;
  cursor: pointer;
  /* border-radius: 10px; */
}
.MyBoard_mesonry__3KM5i div:nth-child(1) div:nth-child(1) {
  height: 100%;
}
.MyBoard_mesonry__3KM5i div:nth-child(2) {
  /* height:100%; */
}
.MyBoard_mesonry__3KM5i div:nth-child(3) {
  height: 100%;
}
/* .mesonry div:first-child div:last-child{
  height:100%;
} */
.MyBoard_mesonry__3KM5i div:nth-child(2) div:first-child,
.MyBoard_mesonry__3KM5i div:nth-child(2) div:last-child {
  /* height:auto; */
}
.MyBoard_myBoardContainer__2l8fL {
  max-width: 40% !important;
  min-height: 250px;
  padding: 0px;
}

.MyBoard_button__3Bk3P {
  width: 140px;
  height: 45px;
  border-radius: 10px 10px 0 0;
  border: none;
  float: right;
  margin-top: -7.5vh;
  margin-right: 5vw;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  background-image: linear-gradient(to right, #ffe5a0 0%, #ffce4a 100%);
  font-size: 20px;
  font-weight: bold;
}
.MyBoard_saveButton__24UlO:hover,
.MyBoard_copyLinkBtn__343I2:hover,
.MyBoard_deleteBtn__10VJm:hover,
.MyBoard_cancelBtn__3HKDn:hover,
.MyBoard_createBoardButton__1xx7w:hover {
  font-weight: 900;
}
.MyBoard_saveButton__24UlO {
  width: 128px;
  height: 35px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  float: left;
  margin-top: 60px;
  /* margin-left: 30px; */
  font-size: 14px;
}
.MyBoard_createBoard__1wlSB {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.MyBoard_createBoardButton__1xx7w {
  width: 128px;
  height: 35px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  float: left;
  margin-top: 60px;
  /* margin-left: 30px; */
  font-size: 14px;
}
.MyBoard_modal__KFD73 {
  width: 35% !important;
}
.MyBoard_modalHeader__3KNF9 {
  background-color: #141414;
  height: 56px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.MyBoard_ShareModalHeader__2DxDN {
  height: 40px;
  color: #000000;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.MyBoard_modalRow__1J3dP {
  height: 300px;
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}

.MyBoard_createCol__2FDjK {
  /* height: 60%; */
  background-color: #f2feff;
  padding-left: 30px !important;
  padding-top: 49px;
}
.MyBoard_wholeBoards__n_Hqt {
  margin-top: auto;
  margin-bottom: auto;
}
.MyBoard_shareLink__z_vIA {
  position: relative;
}

.MyBoard_shareContent__2VSJ8 {
  border-radius: 20px;
  border: 1px solid #a5a5a5;
  font-size: small;
  width: 77%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  padding-left: 22px !important;
  color: #1c1c1c;
}

.MyBoard_shareContainer__1nS8p {
  height: 199px;
  padding: 20px 10px 0px 20px;
  width: 100%;
  background: url('https://d3izcfql7lesks.cloudfront.net/Mask_Group_24_tdsgnn.png');
  background-size: 100%;
}
.MyBoard_ShareModalHeader__2DxDN h4 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 27px;
}
.MyBoard_createCol__2FDjK h6 {
  margin-top: 5vh;
  font-weight: 100 !important;
}
.MyBoard_modalImage__yUMsS {
  background: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/socialShare.png');
  background-repeat: no-repeat;
  background-size: 32%;
}
.MyBoard_modalRow_share__35RMH {
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}

.MyBoard_cancelBtn__3HKDn {
  width: 123px;
  height: 34px;
  border: 1px solid #1a1818;
  background-color: transparent;
  border-radius: 20px;
  font-size: 15px;
}

.MyBoard_modalRow_delete__RxVOZ {
  background-color: #f2feff;
  height: 336px;
  max-height: 100%;
  padding-top: 13px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}

.MyBoard_deleteBtn__10VJm {
  width: 123px;
  height: 34px;
  border: 1px solid #1a1818;
  background-color: #000000;
  color: #ffffff;
  font-weight: 500;
  border-radius: 20px;
  font-size: 15px;
}

.MyBoard_modalRow_delete__RxVOZ h4 {
  font-weight: 800;
  width: 40%;
  text-align: center;
}

.MyBoard_copyLinkBtn__343I2 {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  right: 0;
  top: 0;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
  padding: 0px 18px;
  font-weight: 800;
}

.MyBoard_shareLink__z_vIA {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.MyBoard_shareFacebookBtn__36xpC {
  height: 50px !important;
  width: 214px;
  border: 1px solid #bfbfbf;
  background-color: #ffffff;
  border-radius: 30px;
}

.MyBoard_createCol__2FDjK input {
  margin-top: 10px;
  margin-left: 0px;
  font-weight: 600;
  background-color: transparent;
  width: 86%;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #707070;
  padding-bottom: 4px;
  color: #1a1818;
}
.MyBoard_header__zjRh2 {
  font-weight: 700;
  font-size: 17px;
  padding: 0px 10px;
}
.MyBoard_renderCol__2c6AD {
}
.MyBoard_imageCol__1iyB1 {
  justify-content: center;
  /* display: flex; */
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px !important;
}

.MyBoard_deleteConfirm__1P32q {
  font-weight: 800;
  /* width: 44%; */
  text-align: center;
  font-size: 19px;
  padding: 0px 26%;
  line-height: 27px;
}

.MyBoard_deleteInfo__1kNjl {
  font-size: 20px;
  font-family: 'Quicksand';
  font-weight: 600;
  padding: 10px;
}

.MyBoard_buttonWhite__2GwkP {
  border-radius: 20px;
  color: black;
  background-color: white;
  padding: 5px 3em 5px 3em;
  font-weight: 400;
  height: 40px;
  width: 100%;
  font-size: 13px;
}

.MyBoard_fullScreen__1wwPR {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  /* opacity: 1px; */
  top: 0;
  left: 0;
}
.MyBoard_fullScreen_top__K4sCM {
  bottom: 19vh;
  right: 0;
  margin-right: 3.8vw;
  position: fixed;
}
.MyBoard_fullScreen_bottom__3JcDO {
  bottom: 10vh;
  right: 0;
  margin-right: 3.2vw;
  position: fixed;
}

@media only screen and (max-width: 500px) {
  .MyBoard_myBoardContainer__2l8fL {
    max-width: 100% !important;
    min-height: 200px;
  }
  .MyBoard_container__1FsiJ {
    max-width: 95% !important;
    padding: 0 !important;
  }
  .MyBoard_spaceGirdImg__KWejl {
    width: 100%;
    /* border-radius: 5px; */
    /* padding: 0; */
  }
  .MyBoard_mesonry__3KM5i {
    padding: 0;
    width: 100%;
  }
  .MyBoard_modalHeader__3KNF9 h4 {
    font-size: 15px;
    margin-top: 25px !important;
    margin-left: 25px !important;
    display: inline-flex;
  }
  .MyBoard_imageCol__1iyB1 {
    max-height: 100%;
  }
  .MyBoard_createCol__2FDjK {
    /* height: 60%; */
    padding-bottom: 41px;
  }
  .MyBoard_createCol__2FDjK h6 {
    margin-top: 4vh;
  }
  .MyBoard_createCol__2FDjK input {
    width: 90%;
  }
  .MyBoard_deleteConfirm__1P32q {
    font-size: 16px;
    font-family: 'Quicksand';
    font-weight: 800;
    padding: 10px;
  }

  .MyBoard_deleteInfo__1kNjl {
    font-size: 16px;
    font-family: 'Quicksand';
    font-weight: 600;
    padding: 10px;
  }
  .MyBoard_shareContent__2VSJ8 {
    width: 61%;
  }
  .MyBoard_modalRow_delete__RxVOZ {
    /* background-color: #f2feff; */
    height: 281px;
  }
  .MyBoard_container2__1SObJ h4 {
    font-weight: 500;
    font-size: 19px;
  }
  .MyBoard_mesonry__3KM5i div:nth-child(2) div:last-child img {
    height: 65px;
  }
  .MyBoard_modalRow_delete__RxVOZ h4 {
    width: 100%;
    font-size: 17px;
    padding: 0px 25px;
  }
  .MyBoard_modalRow__1J3dP {
    height: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .MyBoard_mesonry__3KM5i div:nth-child(2) div:last-child img {
    height: 72px;
    object-fit: cover;
  }
}

.BoardDetail_itemContainer__wsgPH {
  /* width: 287px;
  height: 287px; */
  border: 0.5px solid rgba(112, 112, 112, 0.5);
}
.BoardDetail_shareLink__3ALWh {
  width: 81%;
}
.BoardDetail_shareBoards__2nWaW {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.BoardDetail_saveButton__3N2eC:hover,
.BoardDetail_copyLinkBtn__2pW5F:hover,
.BoardDetail_deleteBtn__2pvgd:hover,
.BoardDetail_cancelBtn__2H_xV:hover,
.BoardDetail_createBoardButton1__38saS:hover,
.BoardDetail_createBoardButton__yZvry:hover {
  font-weight: 900;
}
.BoardDetail_saveBoards__1VROz {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.BoardDetail_createBoard__2_dU6 {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.BoardDetail_overlay__tG_iF {
  background-color: #2c2c2c75;
  position: absolute;
  z-index: 1 !important;
  height: 100%;
  width: 100%;
}
.BoardDetail_prices__2w0R3 {
  font-size: 19px;
  margin-top: 14px;
}
.BoardDetail_imgsView__fNe4c {
  margin-top: 30px;
}
.BoardDetail_imageCol__sKRug {
  justify-content: center;
  /* display: flex; */
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px !important;
}
.BoardDetail_nameTitle__1Ty7q {
  font-size: 19px;
  font-weight: 400;
}
.BoardDetail_renderContainer__3MzHi {
  /* width: 287px;
  height: 287px; */
  border: 0.5px solid rgba(112, 112, 112, 0.5);
  margin-top: 41px;
  position: relative;
  height: 272px;
}
.BoardDetail_renderContainer__3MzHi img:last-child {
  height: 100%;
}
.BoardDetail_saveBtn__3DHjp {
  float: right;
  border-radius: 20px;
  opacity: 1;
  border: none;
  background-color: #ffffff;
  margin-top: 20px;
  margin-right: 10px;
  font-size: 14px;
  font-family: 'QuickSand';
  padding: 5px 15px 5px 15px;
}

.BoardDetail_deleteIcon__22LCe {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  background-color: white;
  padding: 0px 7px !important;
  width: 32px !important;
  height: 32px !important;
  border-radius: 30px;
  box-shadow: 4px 3px 6px #979797;
  margin-top: 18px;
}

.BoardDetail_buttonWhite__MaZLC {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: black;
  background-color: white;
  padding: 7px 0px;
  font-weight: 600;
  width: 90%;
  font-size: 13px;
}
.BoardDetail_modalImage__3Cvkk {
  background: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/socialShare.png);
  background-repeat: no-repeat;
  background-size: 32%;
}
.BoardDetail_ShareModalHeader__1dQcT {
  height: 40px;
  color: #000000;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.BoardDetail_ShareModalHeader__1dQcT h4 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 27px;
}
.BoardDetail_copyLinkBtn__2pW5F {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  right: 0;
  top: 0;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
  padding: 0px 18px;
  font-weight: 800;
}
.BoardDetail_shareContent__3qAUG {
  border-radius: 20px;
  border: 1px solid #a5a5a5;
  font-size: small;
  width: 100%;
  padding: 15px 0px !important;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  padding-left: 22px !important;
  color: #1c1c1c;
}
.BoardDetail_shareLink__3ALWh {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
/* .modalRow_share {
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
} */
.BoardDetail_modalContainer__2-8QH p {
  margin-bottom: 0px !important;
  font-size: 14px;
}
.BoardDetail_productImg__1ExRb {
  width: 100%;
  height: 236px;
  /* margin-top: 22px; */
}
.BoardDetail_saveExplore__2mUay {
  position: absolute;
  bottom: 0px;
  margin-left: 131px;
  margin-bottom: 10px;
  height: 25px;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 25px;
  padding-bottom: 25px;
  border: none;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  background-color: rgb(255, 255, 255);
  width: 21%;
}

.BoardDetail_copyLinkBtn__2pW5F {
  float: right;
  position: absolute;
  width: 20%;
  right: -98px;
  top: 2px;
  height: 35px;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
}
.BoardDetail_link__FjnZv {
  font-size: 16px;
}
.BoardDetail_buttonBlack__3SBJO {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: white;
  background-color: black;
  padding: 7px 0px;
  font-size: 13px;
  font-weight: 900;
  /* height: 40px; */
  width: 90%;
}

.BoardDetail_saveButton__3N2eC {
  width: 128px;
  height: 35px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  float: left;
  margin-top: 60px;
  /* margin-left: 30px; */
  font-size: 14px;
}
.BoardDetail_modalRows__2oI3j .BoardDetail_createCol__2OW-z {
  padding-top: 21px;
  max-height: 90vh;
  overflow-y: scroll;
}
.BoardDetail_viewBtn__2SvNl {
  border-radius: 20px;
  width: 146px;
  height: 34px;
  background-color: #ffffff;
  position: absolute;
  bottom: 10%;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  border: none;
}

.BoardDetail_button__2yVCd {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
}

.BoardDetail_container2__24EvT {
  background: url('https://d3izcfql7lesks.cloudfront.net/Group_3157_axoy65.png');
  max-width: 110%;
  /* height: 900px;  */
  background-color: #f5f0ec;
  padding-top: 55px;
  padding-bottom: 102px;
}
.BoardDetail_view__3jLjI {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: white;
  position: absolute;
  z-index: 1000;
  bottom: -21px;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 5px #2125295e;
}
.BoardDetail_container2__24EvT h4 {
  font-weight: 500;
  font-size: 22px;
}
.BoardDetail_header_btn__1o4Rj {
  border-radius: 20px;
  padding: 3px 21px;
  margin-left: 10px;
  border: 0.5px solid #a0a0a0;
  background-color: #ffffff;
  font-size: 14px;
  font-family: 'QuickSand';
  /* padding-left: 10px;
  padding-right: 10px; */
}
.BoardDetail_modalHeader__3B8v7 {
  background-color: #141414;
  height: 56px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.BoardDetail_modalRows__2oI3j .BoardDetail_createCol__2OW-z {
  padding-top: 21px;
  padding-bottom: 10%;
}
.BoardDetail_modalRow__2Bd0T {
  height: 300px;
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.BoardDetail_modalRows__2oI3j {
  /* height: 300px; */
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.BoardDetail_modalRow_share__2GQdi {
  background: url('https://d3izcfql7lesks.cloudfront.net/Mask_Group_24_tevtsk.png');
  /* height: 399px; */
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}

.BoardDetail_modalRow_delete__2q7s- {
  background-color: #f2feff;
  height: 336px;
  max-height: 100%;
  padding-top: 13px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}

.BoardDetail_modalRow_delete__2q7s- h4 {
  font-weight: 800;
  /* width: 44%; */
  text-align: center;
  font-size: 19px;
  padding: 0px 26%;
  line-height: 27px;
}
.BoardDetail_deleteTxt__o1dM_ {
  font-size: 13px;
}
.BoardDetail_createCol__2OW-z {
  /* height: 399px; */
  background-color: #f2feff;
  padding-left: 30px !important;
  padding-top: 49px;
}
.BoardDetail_wholeBoards__286RE {
  margin-top: auto;
  margin-bottom: auto;
}
.BoardDetail_createCol__2OW-z h6 {
  margin-top: 5vh;
  font-weight: 100 !important;
}
.BoardDetail_boardTiles__2jojC {
  margin-top: auto;
  margin-bottom: auto;
}
.BoardDetail_createBoardButton__yZvry {
  width: 128px;
  height: 31px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  font-size: 13px;
  margin-left: 30px;
  border: none;
  float: left;
  /* margin-top: 3vh; */
}
.BoardDetail_createBoardButton1__38saS {
  width: 128px;
  height: 35px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  float: left;
  margin-top: 60px;
  /* margin-left: 30px; */
  font-size: 14px;
}
.BoardDetail_createCol__2OW-z input {
  margin-top: 10px;
  margin-left: 0px;
  font-weight: 600;
  background-color: transparent;
  width: 86%;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #707070;
  padding-bottom: 4px;
  color: #1a1818;
}
.BoardDetail_createCol__2OW-z input:focus {
  outline: none;
}
.BoardDetail_shareFacebookBtn__3vQTO {
  height: 50px !important;
  width: 214px;
  border: 1px solid #bfbfbf;
  background-color: #ffffff;
  border-radius: 30px;
}

.BoardDetail_copyLinkBtn__2pW5F {
  float: right;
  position: absolute;
  width: 100px;
  right: -98px;
  top: 2px;
  height: 35px;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
}

.BoardDetail_shareLink__3ALWh {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 24px;
}

.BoardDetail_cancelBtn__2H_xV {
  width: 123px;
  height: 34px;
  border: 1px solid #1a1818;
  background-color: transparent;
  border-radius: 20px;
  font-size: 15px;
}

.BoardDetail_deleteBtn__2pvgd {
  width: 123px;
  height: 34px;
  border: 1px solid #1a1818;
  background-color: #000000;
  color: #ffffff;
  font-weight: 500;
  border-radius: 20px;
  font-size: 15px;
}

.BoardDetail_HoverContainer__1Pnth {
  position: absolute;
  text-align: left;
  z-index: 1;
  -webkit-transform: translate(150%, -80%);
          transform: translate(150%, -80%);
  font-size: 12px;
  background-color: #ffffff;
  border-radius: 20px;
  border: 2px solid #e2e2e2;
}

.BoardDetail_HoverContainer__1Pnth ul {
  list-style: none;
  margin-left: -30px;
  margin-top: 10px;
  margin-right: 10px;
}

.BoardDetail_shareContainer__1CJA9 {
  height: 199px;
  padding: 20px 10px 0px 20px;
  width: 100%;
  background: url('https://d3izcfql7lesks.cloudfront.net/Mask_Group_24_tdsgnn.png');
  background-size: 100%;
}

.BoardDetail_header__mvvYi {
  font-size: 14px;
  font-family: 'Quicksand';
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.BoardDetail_renderCol__24DfE {
  width: 25%;
}
.BoardDetail_price__34rcv {
  font-size: 15px;
  font-family: 'Quicksand';
  margin-top: -40px !important;
  font-weight: bold;
  padding-right: 0px;
}
.BoardDetail_image2__1h1bo {
  /* height: 70%; */
  width: 100%;
  border-radius: 10px;
}
.BoardDetail_save1__1kDup {
  font-size: 14px;
}
.BoardDetail_heart__3hVlt {
  font-size: 25px;
  color: red;
}
.BoardDetail_editBoard__1jajl {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.BoardDetail_header1__3QLwR {
  font-size: 22px;
  font-weight: 700;
}
.BoardDetail_heart__3hVlt {
  box-shadow: 1px 1px 6px #00000042;
  border-radius: 50%;
  cursor: pointer;
  height: 41px;
  width: 41px;
  background-image: url('https://res.cloudinary.com/lab10/image/upload/v1621063036/samples/heart-edit_lx314x.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 2900%;
}

.BoardDetail_heart__3hVlt:hover {
  background-position: right;
}

.BoardDetail_is_animating__2fpwz {
  -webkit-animation: BoardDetail_heart-burst__3THXb 0.8s steps(28) 1;
          animation: BoardDetail_heart-burst__3THXb 0.8s steps(28) 1;
}
@-webkit-keyframes BoardDetail_heart-burst__3THXb {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}
@keyframes BoardDetail_heart-burst__3THXb {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

@media only screen and (max-width: 500px) {
  .BoardDetail_renderCol__24DfE {
    width: 50% !important;
  }

  .BoardDetail_save1__1kDup {
    font-size: 11px;
  }
  .BoardDetail_renderCol__24DfE {
    margin-bottom: 20px;
  }
  .BoardDetail_imgOption__2Z8PU {
    width: 100% !important;
    -webkit-transform: none !important;
            transform: none !important;
  }
  .BoardDetail_createBoardButton__yZvry {
    /* width: 128px; */
    /* height: 35px; */
    background-color: #000000;
    border-radius: 20px;
    color: #ffffff;
    /* margin-left: 20px; */
    border: none;
    float: left;
    font-size: 11px;
    /* margin-top: 10px; */
    /* font-size: 10px; */
  }

  .BoardDetail_renderContainer__3MzHi {
    width: 100%;
    height: 100%;
  }
  .BoardDetail_header__mvvYi {
    font-size: 12px;
    font-family: 'Quicksand';
    font-weight: bold;
  }
  br {
    display: none;
  }
  .BoardDetail_modalHeader__3B8v7 h4 {
    font-size: 15px;
    margin-top: 25px !important;
    margin-left: 25px !important;
    display: inline-flex;
  }
  .BoardDetail_price__34rcv {
    font-size: 14px;
    font-family: 'Quicksand';
    margin-top: -40px !important;
    font-weight: bold;
  }
  .BoardDetail_HoverContainer__1Pnth {
    -webkit-transform: translate(120%, -70%);
            transform: translate(120%, -70%);
  }
  .BoardDetail_modalRow_delete__2q7s- h4 {
    width: 90%;
    font-size: 17px;
  }
  .BoardDetail_modalRow_delete__2q7s- h6 {
    font-size: 10px;
    margin-top: -30px;
  }
  .BoardDetail_container2__24EvT {
    padding-top: 50px;
    height: auto !important;
  }
  .BoardDetail_imageCol__sKRug {
    height: 40%;
  }
  .BoardDetail_createCol__2OW-z {
    /* min-height: 60vh; */
    /* height: 60%; */
    padding-bottom: 42px;

    background-color: #f2feff;
  }
  .BoardDetail_image2__1h1bo {
    /* height: 70%; */
    width: 100%;
    border-radius: 10px;
  }
  .BoardDetail_createCol__2OW-z h6 {
    margin-top: 4vh;
  }
  .BoardDetail_createCol__2OW-z input {
    width: 90%;
  }

  .BoardDetail_saveExplore__2mUay {
    position: absolute;
    bottom: 0px;
    margin-left: 131px;
    margin-bottom: 10px;
    height: 25px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 25px;
    padding-bottom: 25px;
    border: none;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    background-color: rgb(255, 255, 255);
    width: 21%;
  }

  .BoardDetail_copyLinkBtn__2pW5F {
    float: right;
    position: absolute;
    width: 20%;
    right: -98px;
    top: 2px;
    height: 35px;
    color: #ffffff;
    border-radius: 20px;
    background-color: black;
    font-size: 12px;
  }
  .BoardDetail_link__FjnZv {
    font-size: 11px;
  }

  .BoardDetail_shareLink__3ALWh {
    position: relative;
    width: 100%;
    height: 35px;
    display: inline-flex;
    border-radius: 20px;
    border: 1px solid #f0f0f0;
    padding: 5px;
    padding-left: 10px;
    padding-top: 10px;
  }
  .BoardDetail_modalRow__2Bd0T {
    height: auto;
    max-height: 100%;
  }
}

.Project_container__2zzCO {
  max-width: 100% !important;
  font-family: 'Quicksand';
  background-color: #ffffff;
}

.Project_renderContainer__2tXhl {
  width: 401px;
  height: 350px;
  border: 1px solid #cbcbcb;
  margin: 2%;
}

.Project_renderContainer3__3F-fy {
  width: 401px;
  height: 358px;
  border: 1px solid #cbcbcb;
  margin: 2%;
}

.Project_center__text__1-RHo {
  position: absolute;
  top: 40%;
  left: 20%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.Project_modalHeader__2amEv {
  background-color: #141414;
  height: 67px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Project_modalRow__1G67e {
  height: 400px;
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}

.Project_createCol__Fwbsl {
  height: 400px;
  background-color: #f2feff;
}

.Project_createCol__Fwbsl h6 {
  margin-top: 5vh;
  margin-left: 2vw;
  padding-left: 2px;
  font-weight: 100 !important;
}

.Project_createCol__Fwbsl h5 {
  margin-top: 5vh;
  margin-left: 2vw;
  padding-left: 2px;
  font-weight: 100 !important;
}

.Project_createCol__Fwbsl p {
  margin-top: 3vh;
  margin-left: 2vw;
  padding-left: 2px;
  font-weight: 100 !important;
}
.Project_textPadding__2O8qW {
  margin-top: 1vh;
  margin-left: 2vw;
  padding-left: 2px;
  /* font-weight: 100 !important; */
  text-align: center;
  font: normal normal bold 15px 'Quicksand';
  letter-spacing: 0px;
  color: #1a1818;
  opacity: 1;
}

.Project_imageCol__vyo1N h6 {
  margin-top: 5vh;
  margin-left: 0vw;
  font-size: 16px;
  padding-left: 2px;
  font-weight: 100 !important;
}

.Project_borderTop__1AnHX {
  border-top: 1px solid #cbcbcb;
}

.Project_createCol__Fwbsl input {
  margin-top: 10px;
  margin-left: 2vw;
  font-weight: 900;
  background-color: transparent;
  width: 70%;
  border: none;
  font-size: 18px;
  border-bottom: 2px solid;
}

.Project_imageCol__vyo1N select {
  width: 90%;
  margin-left: 0vw;
  padding: 5px;
  margin-top: 20px;
}

.Project_headerContainer__YofVx {
  /* height: 8%; */
  /* margin-top: 30px; */
  width: 100%;
  height: 100%;
  border-top: 1px solid #cbcbcb;
}

.Project_headerContainer1__3nQnR {
  width: 100%;
  height: 100%;
  border-top: 1px solid #cbcbcb;
}

.Project_previewImgContainer__3spbg {
  height: 290px;
  overflow: hidden;
}

.Project_headerContainer__YofVx span {
  font-size: 20px;
  padding: 0px 0px 0px 0px;
  font-weight: 800;
}

.Project_button__1yXJ5 {
  border: none;
  margin-top: 10px;
  /* margin-right: 30px; */
  background-color: #000000;
  padding-left: 20px;
  height: 30px;
  padding-right: 20px;
  color: #ffffff;
  border-radius: 20px;
}

.Project_button1__1bZMI {
  border: none;
  height: 28px;
  /* margin-right: 30px; */
  background-color: #000000;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  border-radius: 20px;
  /* padding: 12px 29px 11px 28px */
}
.Project_button3__1TNNT {
  border: none;
  height: 40px;
  /* margin-right: 30px; */
  background-color: red;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  /* border-radius: 20px; */
}

.Project_cancelBtn__28Ca- {
  /* width: 128px; */
  /* height: 35px; */
  border: 1px solid #1a1818;
  background-color: transparent;
  border-radius: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 10px 30px 10px 30px;
}

.Project_deleteBtn__1q4cD {
  /* width: 128px; */
  /* height: 35px; */
  border: 1px solid #1a1818;
  background-color: #000000;
  color: #ffffff;
  font-weight: 500;
  border-radius: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 10px 42px 10px 37px;
}

.Project_packageBtn__1K5Y_ {
  /* width: px; */
  height: 40px;
  border: 1px solid #1a1818;
  color: #000000;
  border-radius: 20px;
  background-color: white;
  text-align: center;
  font: normal normal bold 13px/11px 'Quicksand';
  letter-spacing: 0px;
  color: #1a1818;
  opacity: 1;
  padding: 10px 16px 12px 20px;
}

.Project_buttonContainer1__Ruu69 {
  /* width: 90%; */
  /* margin-top: 50px; */
  /* padding-left: 20px; */
  height: 8%;
  width: 100%;
  border-top: 1px solid #cbcbcb;
  margin-left: 0px;
  margin-right: 0px;
}

.Project_buttonContainer__3dL4Z {
  /* width: 90%; */
  /* margin-top: 50px; */
  padding-left: 20px;
  height: 8%;
  width: 100%;
  /* border-top: 1px solid #cbcbcb; */
}

.Project_buttonCreate__2XgQB {
  /* width: 140px; */
  padding: 10px;
  background-color: #ffce4a;
  border-radius: 10px;
  border: none;
  float: right;
  margin-top: 15vh;
  margin-right: 5vw;
}

.Project_editButton__32dYq {
  line-height: 12px;
  width: 18px;
  font-size: 8pt;
  font-family: tahoma;
  margin-top: 1px;
  margin-right: 2px;
}

@media only screen and (max-width: 500px) {
  .Project_container__2zzCO {
    width: 100%;
    background-color: #ffffff;
  }

  .Project_renderContainer1__3FnUJ {
    width: 401px;
    height: 380px;
    border: 1px solid #cbcbcb;
    margin: 2%;
    margin-bottom: 20px;
    margin-top: 100px;
  }
  /* .headerContainer {
    height: 0%;
    margin-top: 80px;
  } */
  /* .headerContainer1 {
    height: 100%;
    margin-top: 80px;
  } */
  .Project_headerContainer__YofVx span {
    font-size: 17px;
    padding-left: 20px;
  }
  .Project_button__1yXJ5 {
    margin-right: 0px;
    width: 195px;
    height: 45px;
  }
  .Project_modalHeader__2amEv h4 {
    font-size: 15px;
    margin-top: 25px !important;
    margin-left: 25px !important;
    display: inline-flex;
  }
  .Project_modalContent__7eBRV {
    height: 80%;
  }
  .Project_button2__vPY6N {
    border: none;
    height: 28px;
    /* margin-right: 30px; */
    background-color: #000000;
    padding-left: 20px;
    padding-right: 20px;
    color: #ffffff;
    border-radius: 20px;
  }
  .Project_button3__1TNNT {
    border: none;
    height: 40px;
    /* margin-right: 30px; */
    background-color: red;
    padding-left: 20px;
    padding-right: 20px;
    color: #ffffff;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    /* border-radius: 20px; */
    font-size: 12px;
  }
  .Project_imageCol__vyo1N {
    height: 60%;
    font-size: 15;
    font-weight: bold;
  }
  .Project_createCol__Fwbsl {
    height: 60%;
  }
  .Project_createCol__Fwbsl h6 {
    margin-top: 4vh;
    font-size: 12px;
  }
  .Project_createCol__Fwbsl input {
    width: 90%;
    font-size: 17px;
  }
  .Project_buttonContainer__3dL4Z {
    max-width: 100% !important;
    padding-top: 40px;
    margin: auto !important;
    padding-left: 0px;
  }
  .Project_imageCol__vyo1N h6 {
    margin-top: 20px;
  }

  .Project_buttonCreate__2XgQB {
    /* width: 140px;
    height: 45px; */
    padding: 10px;
    background-color: #ffce4a;
    border-radius: 10px;
    border: none;
    float: right;
    margin-top: 15vh;
    margin-right: 5vw;
    font-size: 15px;
    font-weight: 500;
  }

  .Project_overlay__2HC0N h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #fff;
  }

  .Project_center__text__1-RHo {
    position: absolute;
    top: 20%;
    left: 30%;
    font-size: 20px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .Project_cancelBtn__28Ca- {
    /* width: 128px; */
    /* height: 35px; */
    border: 1px solid #1a1818;
    background-color: transparent;
    border-radius: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 7px 20px 7px 20px;
    font-size: 14px;
  }
  .Project_deleteBtn__1q4cD {
    /* width: 128px; */
    /* height: 35px; */
    border: 1px solid #1a1818;
    background-color: #000000;
    color: #ffffff;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px 47px 10px 47px;
    font-size: 14px;
  }
}

@media screen and (min-width: 2000px) {
  .Project_container__2zzCO {
    max-width: 70% !important;
    background-color: #ffffff;
  }
}

.Cart_pageContainer__2H4Jz {
  /* margin-right: -15px !important; */
  overflow: hidden;
}
.Cart_mainContainer__2oRiN {
  max-width: 85% !important;
  margin-top: 100px;
}
.Cart_inputGst__Drrks {
  width: 100%;
}
.Cart_inputGst__Drrks input {
  width: 95%;
  padding: 5px 10px;
  font-size: 14px;
  height: 42px;
  border: 1px solid #a5a5a5;
  color: #000;
  margin-top: 15px;
}
.Cart_inputGst__Drrks input:focus {
  border: 1px solid #000000 !important;
}
.Cart_card__2tRZO {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 23px;
}
.Cart_gstTxt__kh9t_ {
  font-weight: 800;
  float: left;
  font-size: 17px;
}
.Cart_card__2tRZO h5 {
  font-size: 16px;
  font-weight: 900;
}
.Cart_applyBtnss__1hvLZ {
  border-radius: 4px;
  width: 30%;
  padding: 7px 0px;
  color: #1a1818;
  border: 1px solid #707070;
  font-weight: 700;
  background-color: transparent;
}
.Cart_promoIn__dFG8k {
  width: 70%;
  border: 1px solid #707070;
  padding: 7px 6px;
  border-right: 0.5px solid #707070;
  border-radius: 4px;
}
.Cart_promoIn__dFG8k:focus {
  width: 70%;
  border: 1px solid #707070 !important;
  padding: 7px 6px;
  border-right: 0.5px solid #707070;
  border-radius: 4px;
}
.Cart_qtyBtnss__bh5Xl {
  border-radius: 30px;
  border: 0.5px solid #707070;
  background-color: rgb(255, 255, 255);
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.Cart_cartData__195U5 {
  font-size: 14px;
  line-height: 23px;
}
.Cart_card__2tRZO span {
  font-size: 15px;
  font-weight: 100;
}

.Cart_card1__3GO5P {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 20px;
}

.Cart_card1__3GO5P h5 {
  font-size: 17px;
  font-weight: 900;
}

.Cart_card1__3GO5P span {
  font-size: 15px;
  font-weight: 100;
}

.Cart_priceContainer__3SVpF {
  display: flex;
  /* flex:1; */
  text-decoration: underline;
  margin-top: 5vh;
  /* margin-left: 10vw; */
  justify-content: space-between;

  position: absolute;
  /* justify-content: center; */
}
.Cart_editAddress__l_0Jd {
  font-size: 17px;
  font-weight: 400;
  cursor: pointer;
}

.Cart_summaryContainer__1BxXR {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  /* height: 80vh; */
  position: relative;
  background-color: #ffffff;
  margin-bottom: 50px;
  margin-top: 0px !important;
}

.Cart_summaryContainer__1BxXR h3 {
  font-size: 17px;
  padding-left: 30px;
  font-weight: 900;
  margin-bottom: 0px;
}
.Cart_myCart__2OcvT {
  font-weight: 600;
}
.Cart_couponBtn__13nM6 {
  width: 100%;
  height: 50px;
  color: black;
  background-color: white;
  border-radius: 5px;
  margin-top: 2vh;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  opacity: 1;
  font-size: 14px;
}

.Cart_summarySubContainer__o6SYx {
  width: 85%;
  /* height: 200px; */
  position: relative;
  margin: 18px auto;
  /* top: 20%; */
  /* left: 10%; */
}
.Cart_orderSum___IF43 {
  width: 100%;
  border-bottom: 1px solid #000;
  position: relative;
  padding: 16px 0px;
  padding-bottom: 23px;
}
.Cart_summary_headings__3j6C0 {
  border-bottom: 1px solid rgba(188, 188, 188, 0.5);
  padding-bottom: 8px;
  padding-top: 2vh;
}
.Cart_summary_headings__3j6C0 span {
  font-size: 13px;
  font-weight: 600;
}
.Cart_heading__3LpkV {
  font-size: 16px;
  font-family: 'Quicksand';
  font-weight: bold;
}

.Cart_checkoutBtn__22kQ7 {
  width: 100%;
  height: 50px;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  margin-top: 5vh;
  font-weight: 600;
  font-size: 14px;
}
.Cart_promoBtn__1Z4vM {
  text-align: center;
  width: 100%;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 500;
}
.Cart_qtyBtn__LVFWm {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 50%;
  background-color: transparent;
}

.Cart_deliveryBtn__7axTN {
  background-color: #000000;
  border-radius: 5px;
  padding: 13px 15px;
  /* width: 200px; */
  /* height: 46px; */
  font-weight: bold;
  font-size: 13px;
  font-family: 'QuickSand';
  color: #ffffff;
  margin-top: 26px;
}
.Cart_paymentBtn__2Ta0g {
  background-color: #000000;
  border-radius: 5px;
  padding: 7px;
  /* width: 211px;
  height: 46px; */
  font-weight: 900;
  color: #ffffff;
  font-size: 14px;
}
.Cart_modalHeads__19htg {
  font-weight: 600;
  margin-bottom: 36px;
}
.Cart_applyButton__34zwj {
  background-color: white;
  border: solid 1px;
  width: 84px;
  border-radius: 5px;
  padding: 3px 0px;
}

.Cart_buttonContainer__3REC7 {
  width: 211px;
  display: inline-flex;
  justify-content: between;
  margin-top: 10px;
}

.Cart_checkoutContainer__3lE7c {
  width: 100%;
  height: auto;
  margin-top: 3vh;
  background-color: #ffffff;
  padding: 40px !important;
}

.Cart_checkoutRecentContainer__4eey1 {
  width: 89%;
  height: auto;
  margin-top: 3vh;
  background-color: #ffffff;
  padding: 40px !important;
}

.Cart_shipmentContainer__cPgQJ {
  width: 100%;
  height: auto;
  padding: 40px;
  margin-top: 2vh;
  background-color: #ffffff;
}

.Cart_shipmentContainer__cPgQJ span {
  font-size: 15px;
  font-weight: 100;
}

.Cart_shipmentContainer__cPgQJ h4 {
  font-size: 21px;
  font-weight: 800;
}

.Cart_shipmentContainer__cPgQJ h5 {
  font-size: 20px;
  font-weight: 800;
}

.Cart_shipmentContainer__cPgQJ h6 {
  font-size: 19px;
  font-weight: 400;
}

.Cart_edit_btn__3vdGe {
  background-color: #ffffff;
  color: black;
  width: 100px;
  border: 0.5px solid #1a1818;
  border-radius: 5px;
}

.Cart_delete_btn__J-F9u {
  background-color: #ffffff;
  color: black;
  width: 100px;
  border-radius: 5px;
  margin-left: 10px;
  border: 0.5px solid #1a1818;
}

.Cart_card__2tRZO img {
  width: 100%;
}
.Cart_checkoutContainer__3lE7c h5 {
  font-size: 17px;
  font-weight: 500;
  /* margin-bottom: 25px; */
}

.Cart_checkoutRecentContainer__4eey1 p {
  font-size: 15px;
}

.Cart_checkoutInput__3S6a3 {
  width: 92%;
  height: 40px;
  border: 1px solid #a5a5a5;
  padding: 0px 9px;
  font-size: 14px;
}
.Cart_checkoutInput__3S6a3:focus {
  border: 1px solid #000000 !important;
}
.Cart_addBtn__3KX31 {
  font-size: 14px !important;
  margin-top: 25px !important;
  margin-bottom: 0px !important;
}
.Cart_checkoutContainer__3lE7c span {
  font-size: 13px;
  font-weight: 400;
}

.Cart_modalContainer__2YpVi {
  width: 100%;
  /* height: 60vh; */
  position: relative;
  margin-top: 5vh;
  text-align: center;
}

.Cart_modal_input_container__2e9fY {
  width: 90% !important;
  margin: auto !important;
  text-align: left;
  /* margin-top: 20px !important; */
}

.Cart_modal_input_container__2e9fY label {
  font-size: 1vw;
}

.Cart_modalContainer__2YpVi h4 {
  width: 100% !important;
  text-align: center;
  font-weight: 100;
}

.Cart_addresses_new__2k4jP {
  width: 100%;
  background-color: #d2edf5;
  padding: 20px;
  font-weight: 800;
  height: 70px;
}

.Cart_modal_input__3Awp6 {
  width: 95%;
  padding: 5px;
  font-size: 14px;
  height: 34px;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
  color: #000;
}

.Cart_modal_input__3Awp6:focus {
  border: 0.5px solid !important;
}

.Cart_couponTitle__32Nqu {
  font-family: 'QuickSand';
  font-size: 26px;
  font-weight: bold;
  text-decoration-thickness: 'from-font';
}
.Cart_couponDesc__S5YIo {
  font-family: 'QuickSand';
  font-size: 15px;
  font-weight: medium;
  text-decoration-thickness: 'from-font';
}

.Cart_applyBtn__3Yf8E {
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  border-radius: 4px;
}

.Cart_disableBtn__30zLv {
  background-color: #a8a8a8;
  color: #ffffff;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  border-radius: 4px;
}
.Cart_checkoutContainer__3lE7c select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png);
  background-repeat: no-repeat;
  background-size: 4%;
  background-position-x: 96%;
  background-position-y: 13px;
  border: 1px solid #a5a5a5;
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
  box-shadow: none;
  border-radius: 0px;
  opacity: 1;
}
@media only screen and (max-width: 500px) {
  .Cart_mainContainer__2oRiN {
    max-width: 100% !important;
  }
  .Cart_card__2tRZO {
    width: 100% !important;
  }
  .Cart_summaryContainer__1BxXR {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    /* height: 100vh; */
    position: relative;
    background-color: #ffffff;
  }

  .Cart_checkMainContainer__xzhEr {
    margin-top: 100px !important;
  }
  .Cart_card__2tRZO img {
    width: 100%;
  }
  .Cart_card__2tRZO h5 {
    font-size: 13px;
  }
  .Cart_card__2tRZO h6 {
    font-size: 12px;
  }
  .Cart_card__2tRZO span {
    font-size: 12px;
  }

  .Cart_card1__3GO5P {
    width: 100% !important;
    height: 200px;
  }

  .Cart_card1__3GO5P img {
    width: 160%;
  }
  .Cart_card1__3GO5P h5 {
    font-size: 13px;
  }
  .Cart_card1__3GO5P h6 {
    font-size: 12px;
  }
  .Cart_card1__3GO5P span {
    font-size: 12px;
  }

  .Cart_priceContainer__3SVpF {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .Cart_summaryContainer__1BxXR {
    margin-top: 12vh;
  }

  .Cart_checkoutRecentContainer__4eey1 {
    width: 100%;
  }

  .Cart_checkoutInput__3S6a3 {
    width: 100%;
    margin-top: 15px;
    padding-left: 10px;
    outline: none;
    box-shadow: none;
  }
  .Cart_checkoutInput__3S6a3:focus {
    box-shadow: none !important;
  }
  .Cart_modalContainer__2YpVi {
    width: 100%;
    height: 500px;
    position: relative;
    margin-top: 50px !important;
    /* margin-bottom: 200px !important; */
    text-align: center;
    padding-bottom: 5vh;
    /* overflow-y: auto; */
  }

  .Cart_modal_input_container__2e9fY {
    width: 90% !important;
    /* margin: auto !important; */
    text-align: left;
    margin-top: 0px !important;
  }

  .Cart_modal_input_container__2e9fY label {
    font-size: 14px;
  }

  .Cart_modalContainer__2YpVi h4 {
    width: 100% !important;
    text-align: center;
    font-weight: 100;
  }

  .Cart_modal_input__3Awp6 {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    height: auto;
    padding: 9px 13px;
    margin-top: 6px;
  }
  .Cart_modal__1M9IQ .Cart_modal-dialog__11i7h {
    width: 90% !important;
    position: relative !important;
    display: flex;
    /* top: -5%; */
    left: 7%;
    background-color: transparent !important;
    max-width: 80% !important;
    height: 100% !important;
    margin-top: 100px;
    margin-bottom: 200px;
  }
  .Cart_myCart__2OcvT {
    font-weight: 500;
    font-size: 17px;
  }
  .Cart_continueShop__Usm_J {
    margin-top: 15px;
    margin-left: 4px;
    font-size: 14px;
  }
  .Cart_heading__3LpkV {
    font-size: 14px;
  }
  .Cart_checkoutContainer__3lE7c {
    padding: 22px !important;
  }
  .Cart_checkoutContainer__3lE7c h5 {
    font-size: 16px;
  }
  .Cart_addressPart__3T4wh p {
    font-size: 13px !important;
  }
  .Cart_addNew__3OI_d {
    font-size: 16px;
  }
  .Cart_coupenDra__3zGTb {
    margin-left: 24px;
  }
}
a {
  color: #000;
}

.backConfirm {
  background-image: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/design_dairies/pattern_success.png);
  background-color: #d2edf5;
  /* height: 200px; */
}
.content {
  color: black;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  /* background-image: url("https://d3izcfql7lesks.cloudfront.net/Group_3158_wxbffq.png"); */
  /* height: 200px; */
}

.continue {
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  font-weight: 400;
  padding: 6px 20px 7px 20px !important;
  margin-top: 30px;
}
.placeOrder h5 {
  font-size: 16px;
}
.thanks {
  font-size: 23px;
  font-weight: 600;
}
.order {
  border-radius: 30px;
  background-color: transparent;
  color: black;
  padding: 8px;
  font-weight: 400;
  /* padding-left: 20px; */
  margin-top: 30px;
  /* padding-right: 20px; */
  border: solid 1px;
  padding: 5px 39px;
}
@media only screen and (max-width: 600px) {
  .padd {
    width: 30px;
  }

  .thanks {
    font-size: 20px;
    padding-left: 1%;

    text-align: center;
  }

  .check {
    margin-top: 25% !important;
    text-align: center;
  }

  .email {
    font-size: 14px;
    padding-left: 1%;

    text-align: center;
  }

  .container-order {
    text-align: center;
    min-width: 90% !important;
    max-width: 90% !important;
    background-color: white;
  }
  .getStartedButton {
    border-radius: 30px;

    max-height: 50px !important;
  }
}



.Signup_wholeContainer__MNCqq {
 
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
}

.Signup_otpInput__1QLPL {
  border: none;
  border-bottom: 1px solid #707070;
  text-align: center;
}

.Signup_signup_input__3TmHf::input[type='number']::-webkit-inner-spin-button,
.Signup_signup_input__3TmHf::input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.Signup_otpInput__1QLPL::-webkit-input-placeholder {
  color: red;
  padding: 10px;
}

.Signup_otpInput__1QLPL::placeholder {
  color: red;
  padding: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Signup_imgContainer__1hPhe {
  width: 100% !important;
  padding: 0px !important;
  height: 100vh;
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+4819.png');
  /* background-size: 150%; */
  background-size: cover;
  background-position: right;
  /* height: 760px; */
  /* margin-top: -10px;
  padding-left: -100px !important; */
  background-repeat: no-repeat;
  /* overflow-y: hidden !important;
  overflow-x: hidden !important; */
}

.Signup_signup_header__202W0 {
  font-size: 28px;
  color: #c7b7a5;
  margin-top: 11px;
  margin-bottom: 15px;
}

.Signup_container___0lxl {
  display: grid;
  place-items: center;
  height: calc(100vh-75px) !important;
  width: 100%;
  /* overflow: hidden;
  overflow-y: hidden !important;
  overflow-x: hidden !important; */
}

.Signup_container___0lxl::-webkit-scrollbar {
  display: none;
}

.Signup_signup_text__hQ3eZ {
  font-weight: 900;
  margin: 0;
}

.Signup_signup_input__3TmHf {
  width: 411px;
  height: 60px;
  border: 1px solid #cecece;
  padding-left: 20px !important;
  padding: 10px;
}

.Signup_signup_input__3TmHf:focus {
  border: 1px solid #000;
}

.Signup_signup_button__2X51D {
  width: 411px;
  margin-top: 20px;
  font-weight: 900;
  height: 55px;
  background-color: black;
  border: none;
  color: white;
}

.Signup_signupwithgoogle_button__eK8qD {
  width: 411px;
  margin-top: 20px;
  font-weight: 500;
  height: 55px;
  background-color: #ffffff;
  border: none;
  color: black;
  border: 1px solid #bfbfbf;
}

.Signup_verifyButton__23IPW {
  border: none;
  background-color: black;
  color: white;
  font-weight: 900;
  padding: 10px 50px 10px 50px;
  font-size: 14px;
}

.Signup_cancelButton__1VJNt {
  border: 1px solid #101010;
  background-color: white;
  color: black;
  margin-left: 20px;
  font-weight: 900;
  padding: 10px 50px 10px 50px;
  font-size: 14px;
}

.Signup_login_button__34BFu {
  width: 411px;
  height: 55px;
  border: 1px solid #bfbfbf;
  font-weight: 900;
  background-color: #ffffff;
  margin-top: 10px;
}

.Signup_container___0lxl span {
  font-size: 12px;
  color: #6a6a6a;
  font-weight: 500;
}

.Signup_innerContainer__2wfV5 {
  width: 70% !important;
  padding-top: 30px;
}

.Signup_innerContainer1__2jafJ {
  width: 40% !important;
}

.Signup_card1__1D1iM {
  display: none;
}

@media only screen and (max-width: 500px) {
  .Signup_mainContainer__rztUx {
    position: relative;
    max-width: 100% !important;
    /* overflow-y: scroll !important; */
    height: 84vh;
  }

  .Signup_wholeContainer__MNCqq {
    position: relative !important;
    background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+4819.png');
  }
  .Signup_container___0lxl {
    position: relative !important;
    margin-top: 20vh;
    top: 3%;
    left: 5%;
    padding: 10px 0px 30px 0px !important;
    width: 100% !important;
    max-width: 90% !important;
    background-color: #ffffff;
    height: auto;
    border-radius: 8px;
  }

  .Signup_imgContainer__1hPhe {
    height: 100vh !important;
    width: 100%;
    position: fixed;
    background-size: 150%;
    position: fixed !important;
    background-size: cover;
  }
  .Signup_card1__1D1iM {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .Signup_imgContainer__1hPhe img {
    position: fixed;
    width: 150%;
    height: 100%;
  }

  .Signup_signup_header__202W0 {
    font-size: 23px;
    /* margin-top: 25%; */
  }
  .Signup_signup_text__hQ3eZ {
    font-size: 25px;
  }
  .Signup_signup_input__3TmHf {
    width: 100%;
  }

  .Signup_signupwithgoogle_button__eK8qD {
    width: 100%;
  }

  .Signup_innerContainer__2wfV5 {
    width: 90% !important;
    max-width: 90vw;
    padding-top: 5px;
    height: auto !important;
    background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+4819.png');
    padding: 10%;
  }
  .Signup_signup_button__2X51D {
    width: 100%;
  }
  .Signup_login_button__34BFu {
    width: 100%;
  }
  .Signup_card__OpH6b {
    padding: 10px;
    color: #6a6a6a;
  }
}

@media only screen and (max-width: 800px) {
  .Signup_mainContainer__rztUx {
    position: relative;
    max-width: 100% !important;
    overflow-y: scroll !important;
    /* height: 84vh; */
  }

  .Signup_wholeContainer__MNCqq {
    position: relative !important;
    background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/Group+4819.png');
  }
  .Signup_container___0lxl {
    position: relative !important;
    margin-top: 20vh;
    top: 3%;
    left: 5%;
    padding: 10px 0px 30px 0px !important;
    width: 100% !important;
    max-width: 90% !important;
    background-color: #ffffff;
    height: auto;
    border-radius: 8px;
  }

  .Signup_imgContainer__1hPhe {
    height: 100vh !important;
    width: 100%;
    position: fixed;
    background-size: 150%;
    position: fixed !important;
    background-size: cover;
  }
  .Signup_card1__1D1iM {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .Signup_imgContainer__1hPhe img {
    position: fixed;
    width: 150%;
    height: 100%;
  }

  .Signup_signup_header__202W0 {
    font-size: 23px;
  }
  .Signup_signup_text__hQ3eZ {
    font-size: 25px;
  }
  .Signup_signup_input__3TmHf {
    width: 100%;
  }

  .Signup_signupwithgoogle_button__eK8qD {
    width: 100%;
  }

  .Signup_innerContainer__2wfV5 {
    width: 90% !important;
    max-width: 90vw;
    padding-top: 5px;
  }
  .Signup_signup_button__2X51D {
    width: 100%;
  }
  .Signup_login_button__34BFu {
    width: 100%;
  }
  .Signup_card__OpH6b {
    padding: 10px;
    color: #6a6a6a;
  }
}
@media only screen and (max-width: 360px) {
  .Signup_signup_header__202W0 {
    margin-top: 5% !important;
  }
}

.ExploreDetail_carouselContainer__vESVv {
  width: 100%;
  height: auto;
  position: relative;
}

.ExploreDetail_mainContainer__2Q4JP {
  max-width: 80% !important;
}
.ExploreDetail_textEliptic__1N_Xf {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
  font-size: 14px;
}
.ExploreDetail_saveBoards__3cs9M {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.ExploreDetail_createBoard__1AwzP {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.ExploreDetail_Icon3d__2JoJ6 {
  /* border-radius: 50px; */
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}

.ExploreDetail_Icon3d__2JoJ6:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.ExploreDetail_arIcon__2AQBP {
  background: none;

  width: 35px;

  transition: -webkit-transform 0.2s;

  transition: transform 0.2s;

  transition: transform 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.ExploreDetail_arIconbtn__2mo2j {
  width: 26px;
}
.ExploreDetail_arIcon__2AQBP:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

/* 
.shareLink {
  position: relative;
  width: 550px;
  height: 35px;
  display: inline-flex;
  border-radius: 20px;
  border: 1px solid #f0f0f0;
  padding: 5px;
  padding-left: 20px;
} */
.ExploreDetail_shareLink__2VXfW {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 24px;
}
.ExploreDetail_ShareModalHeader__3qNIg h4 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 27px;
}
.ExploreDetail_modal_share__3zfXd {
  width: 80%;
}
.ExploreDetail_shareContent__KO-kY {
  border-radius: 20px;
  border: 1px solid #a5a5a5;
  font-size: small;
  width: 79%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  padding-left: 22px !important;
  color: #1c1c1c;
}
.ExploreDetail_modalImage__3q8Ro {
  background: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/socialShare.png);
  background-repeat: no-repeat;
  background-size: 32%;
}
.ExploreDetail_modalRow_share__qIjIp {
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.ExploreDetail_ShareModalHeader__3qNIg {
  height: 40px;
  color: #000000;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.ExploreDetail_createCol__rSz1x {
  padding-top: 21px;
  max-height: 90vh;
  overflow-y: scroll;
}
.ExploreDetail_saveBtn__2UI6r {
  float: right;
  border-radius: 20px;
  opacity: 1;
  border: none;
  background-color: #ffffff;
  margin-top: 20px;
  margin-right: 10px;
  font-size: 14px;
  font-family: 'QuickSand';
  padding: 5px 15px 5px 15px;
  height: 30px;
}
.ExploreDetail_copyLinkBtn__Sqm0E {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  right: 0;
  top: 0;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
  padding: 0px 18px;
  font-weight: 800;
}

/* .copyLinkBtn {
  float: right;
  position: absolute;
  width: 100px;
  right: 0;
  top: 0;
  height: 35px;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
} */
.ExploreDetail_link__1xiPv {
  font-size: 16px;
}
.ExploreDetail_header__cKQ3N {
  font-size: 30px;
  font-weight: 500;
  width: 100%;
  text-align: center;
}
.ExploreDetail_overlay__YH30K {
  z-index: 1 !important;
  background-color: rgb(44 44 44 / 65%);
  position: absolute;
  width: 100%;
  height: 14em;
}
.ExploreDetail_boxImage__2wGZV {
  object-fit: cover;
  flex: 0 0 38%;
  height: 100%;
  text-align: center;
  border: 1px solid black;
  position: relative;
}
.ExploreDetail_boxWrapper__1eZMo {
  display: flex;
  justify-content: space-between;
  width: 12vmax;
  height: 12vmax;
  border: 1px solid black;
  position: relative;
}

.ExploreDetail_modalHeader__1lAfM {
  background-color: #141414;
  height: 56px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.ExploreDetail_viewBtn__2pdVn {
  border-radius: 20px;
  width: 146px;
  height: 34px;
  background-color: #ffffff;
  position: absolute;
  bottom: 40%;
  left: 0;
  border: none;
  margin-left: auto;
  margin-right: auto;
}

.ExploreDetail_buttonWhite__2nhkY {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: black;
  background-color: white;
  padding: 7px 0px;
  font-weight: 600;
  /* height: 40px; */
  width: 40%;
  font-size: 13px;
}

.ExploreDetail_buttonBlack__2XPxU {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: white;
  background-color: black;
  padding: 7px 0px;
  font-weight: 600;
  /* height: 40px; */
  width: 40%;
  font-size: 13px;
}

.ExploreDetail_modalContainer__1chOn p {
  margin-bottom: 0px !important;
  font-size: 14px;
}
.ExploreDetail_productImg__1wX-p {
  width: 100%;
  height: 236px;
  /* margin-top: 22px; */
}
.ExploreDetail_btnsStyle__12yS- {
  margin-top: 43px !important;
}
.ExploreDetail_heart__286e2 {
  box-shadow: 1px 1px 6px #00000042;
  border-radius: 50%;
  cursor: pointer;
  height: 41px;
  width: 41px;
  background-image: url('https://res.cloudinary.com/lab10/image/upload/v1621063036/samples/heart-edit_lx314x.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 2900%;
}
.ExploreDetail_nameTitle__14ggj {
  font-size: 19px;
  font-weight: 400;
}
.ExploreDetail_price__4UwW7 {
  font-size: 19px;
  margin-top: 14px;
}
.ExploreDetail_heart__286e2:hover {
  background-position: right;
}

.ExploreDetail_is_animating__3PQDe {
  -webkit-animation: ExploreDetail_heart-burst__2UxHf 0.8s steps(28) 1;
          animation: ExploreDetail_heart-burst__2UxHf 0.8s steps(28) 1;
}

@-webkit-keyframes ExploreDetail_heart-burst__2UxHf {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

@keyframes ExploreDetail_heart-burst__2UxHf {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

.ExploreDetail_renderColumns_container__XKrVO {
  display: flex;
  justify-content: center;
  width: 100% !important;
  /* width: 287px;
  height: 287px; */
  /* margin-top: 10vh;
    margin-left: 2vw; */

  border: 0.5px solid #707070;
}

.ExploreDetail_overlayEffect__myNHl {
  opacity: 0.5;
  background-color: black !important;
  border: 1px solid #7070701b !important;
}

.ExploreDetail_shopContainer__31rle {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2951_xexml1.png');
  width: 100%;
  margin-top: 5vh;
  border-radius: 10px;
  height: 170px;
}

.ExploreDetail_shop_button__3ce3g {
  /* width: 200px; */
  background-color: #000000;
  color: white;
  padding: 11px 28px;
  border-radius: 0px;
  font-weight: 800;
  font-size: 15px;
}
.ExploreDetail_shop_button__3ce3g:hover {
  color: white !important;
}
.ExploreDetail_container2Image__3LBeV {
  width: 90%;
}

.ExploreDetail_container3Image__b1Ded {
  width: 90%;
}
.ExploreDetail_container3Image__b1Ded :hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}
.ExploreDetail_container2__2u_uP {
  width: 100%;
  background-color: #f5f0ec;
  height: auto;
  margin-top: 100px;
  padding: 10vh 15vw 10vh 15vw;
}

.ExploreDetail_container3__u_3_7 {
  width: 100%;
  background-color: #f5f0ec;
  height: auto;
  margin-top: 100px;
  padding: 10vh 8vw 10vh 8vw;
  background-image: url(/static/media/pattern.8133c2fd.png);
  background-size: 100%;
}

.ExploreDetail_saveExplore__ovgOl {
  position: absolute;
  bottom: 0px;
  margin-left: 131px;
  margin-bottom: 10px;
  height: 25px;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 25px;
  padding-bottom: 25px;
  border: none;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  background-color: rgb(255, 255, 255);
}

.ExploreDetail_modalRow__2HYFS {
  /* height: 399px; */
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.ExploreDetail_imageCol__xNQI1 {
  /* height: 40%; */
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px !important;
}
/* .createCol {
  height: 60%;
} */
.ExploreDetail_image2__2OlJY {
  /* height: 70%; */
  width: 100%;
  border-radius: 10px;
}
.ExploreDetail_createCol__rSz1x h6 {
  margin-top: 4vh;
}
.ExploreDetail_createCol__rSz1x input {
  width: 90%;
}

.ExploreDetail_createCol__rSz1x {
  /* height: 399px; */
  background-color: #f2feff;
  padding-left: 30px !important;
  padding-bottom: 10%;
}

.ExploreDetail_createCol__rSz1x h6 {
  margin-top: 5vh;
  /* margin-left: 2vw; */
  /* padding-left: 2px; */
  font-weight: 100 !important;
}
.ExploreDetail_tabContainer__24fGS p {
  width: 50%;
}
.ExploreDetail_createCol__rSz1x input {
  margin-top: 10px;
  margin-left: 0px;
  font-weight: 600;
  background-color: transparent;
  width: 86%;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #707070;
  padding-bottom: 4px;
  color: #1a1818;
}
.ExploreDetail_createCol__rSz1x input:focus {
  outline: none;
}
.ExploreDetail_createBoardButton__2G2di {
  width: 128px;
  height: 31px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  font-size: 13px;
  margin-left: 30px;
  border: none;
  float: left;
  /* margin-top: 3vh; */
}
.ExploreDetail_createBoardButton1__22IA3 {
  width: 128px;
  height: 35px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  float: left;
  margin-top: 60px;
  /* margin-left: 30px; */
  font-size: 14px;
}
.ExploreDetail_createBoardButton1__22IA3:hover,
.ExploreDetail_createBoardButton__2G2di:hover {
  font-weight: 900;
}
.ExploreDetail_modalContainer__1chOn {
  overflow: scroll;
}
.ExploreDetail_viewBtnss__3CtCk {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: white;
  position: absolute;
  z-index: 1000;
  bottom: -21px;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 5px #2125295e;
}
@media only screen and (max-width: 500px) {
  .ExploreDetail_saveExplore__ovgOl {
    position: absolute;
    bottom: 0px;
    margin-left: 131px;
    margin-bottom: 10px;
    height: 25px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 25px;
    padding-bottom: 25px;
    border: none;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    background-color: rgb(255, 255, 255);
    width: 21%;
  }
  .ExploreDetail_renderColumns_container__XKrVO {
    width: 100% !important;
  }
  .ExploreDetail_headerSty__35-9P {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 24px;
  }
  .ExploreDetail_buttonWhite__2nhkY,
  .ExploreDetail_buttonBlack__2XPxU {
    font-size: 13px;
  }
  .ExploreDetail_buttonWhite__2nhkY {
    width: 55%;
  }
  .ExploreDetail_prePrice__3QZf5 {
    float: left;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
    padding-top: 5px;
    margin-left: 20px;
  }
  .ExploreDetail_copyLinkBtn__Sqm0E {
    width: 77%;
  }
  .ExploreDetail_shareContent__KO-kY {
    width: 100%;
  }
  /* .shareLink {
    position: relative;
    width: 100%;
    height: 35px;
    display: inline-flex;
    border-radius: 20px;
    border: 1px solid #f0f0f0;
    padding: 5px;
    padding-left: 10px;
    padding-top: 10px;
  } */
  /* 
  .copyLinkBtn {
    float: right;
    position: absolute;
    width: 20%;
    right: 0;
    top: 0;
    height: 35px;
    color: #ffffff;
    border-radius: 20px;
    background-color: black;
    font-size: 12px;
  } */
  .ExploreDetail_link__1xiPv {
    font-size: 11px;
  }

  .ExploreDetail_modalHeader__1lAfM h4 {
    font-size: 15px;
    margin-top: 25px !important;
    margin-left: 25px !important;
    display: inline-flex;
  }
  .ExploreDetail_createCol__rSz1x {
    height: 60%;
  }
  .ExploreDetail_image2__2OlJY {
    width: 100%;
    border-radius: 10px;
  }
  .ExploreDetail_createCol__rSz1x h6 {
    margin-top: 5vh;
    font-weight: 100 !important;
  }
  .ExploreDetail_createCol__rSz1x input {
    width: 90%;
  }
  .ExploreDetail_save1__16y7B {
    font-size: 11px;
  }
  .ExploreDetail_createBoardButton__2G2di {
    background-color: #000000;
    border-radius: 20px;
    color: #ffffff;
    border: none;
    float: left;
    font-size: 11px;
  }
  .ExploreDetail_shop_button__3ce3g {
    padding: 12px 43px 12px 43px;
  }
}

.Shopdetails_mainContainer__3viV9 {
  width: 80%;
  margin: auto;
  /* max-width: 1200px; */
  min-height: 100vh;
}

.Shopdetails_masonryContainer__Ym3xG {
  width: 100%;
  padding-top: 110px;
  margin: auto;
  padding-bottom: 30px;
}

.Shopdetails_typesSection__30Juk {
  width: 80%;
  margin: 0px auto;
  padding-top: 105px;
}

.Shopdetails_toggleImg__rFyIi {
  width: 23px;
  height: 19px;
  margin-top: 2px;
  margin-right: 9px;
}

@media (max-width: 640px) {
  .Shopdetails_typesSection__30Juk {
    width: 100%;
  }
}

.Shopdetails_uploadBtn__1yjct {
  padding: 8px 10px;
  background-color: #000000;
  color: #ffffff;
  font-size: 12px;
}
.Shopdetails_buttonWhite__34ejR {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: black;
  background-color: white;
  padding: 5px 10px 5px 10px;
  font-weight: 600;
  height: 40px;
  width: 100%;
  font-size: 14px;
}
.Shopdetails_buttonBlack__13Cjg {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: white;
  background-color: black;
  padding: 5px 10px 5px 10px;
  font-weight: 600;
  height: 40px;
  width: 100%;
  font-size: 14px;
}
.Shopdetails_arPart__13bGF {
  margin: 21px 0px;
  display: flex;
  align-items: center;
  background-color: white;
}
.Shopdetails_view__1N1iR {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
}
.Shopdetails_arImg__1lqvI {
  width: 16px;
  height: 19px;
  margin-right: 7px;
}
.Shopdetails_overlay__kDrAe {
  position: absolute;
  width: 100%;
  height: 200px;
  z-index: 1;
  bottom: 0;
  left: 0;
  /* background-color: rgb(0 0 0 / 60%); */
  justify-content: center !important;
  align-items: center !important;
}

.Shopdetails_budgetFilterBtn__1hCM6 {
  width: 50px;
  height: 39px;
  background: #ffffff;
  border: 1px solid #aeadad;
  margin-right: 0.5rem;
  padding: 4px;
  border-radius: 8px;
}

.Shopdetails_sliderBox__2KcBN {
  display: flex;
  align-items: center;
  width: 60px;
  height: 39px;
  background: #ffffff;
  border: 1px solid #000000;
  margin-right: 0.5rem;
  padding: 4px;
  border-radius: 8px;
}

.Shopdetails_sliderBoxInnerValue__1nvbo {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  color: #171717;
}

.Shopdetails_sliderInput__3RmMM {
  font-size: 13px;
  border: none;
  outline: none;
  width: 100%;
  line-height: 1;
}

.Shopdetails_typesSectionwom__2zkjp {
  width: 75%;
  padding-top: 110px;
}
.Shopdetails_roomType__2WVhQ {
  font-size: 15px;
  margin-top: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.Shopdetails_roomBox__1YPeO {
  border: 0.5px solid #dedede;
  height: 155px;
  box-shadow: 0px 0px 0px #dedede;
}
.Shopdetails_roomBoxbytype__3vFe8 {
  border: 0.5px solid #dedede;
  height: 130px;
  box-shadow: 0px 0px 0px #dedede;
}
.Shopdetails_roomTypeName__2VxIN {
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  margin-top: 6px;
  margin-bottom: 40px;
}
.Shopdetails_arIcon__2WPPn {
  position: absolute;
  bottom: 7px;
  width: 26px;
  right: 7px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.Shopdetails_arIconbtn__2MNOI {
  position: absolute;
  bottom: 7px;
  width: 26px;
  right: 7px;
}
.Shopdetails_arIcon__2WPPn:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.Shopdetails_selectStyle__3Mv2N {
  background-color: #232424;
  color: white !important;
  height: 45px;
  border-radius: 5px !important;
}
.Shopdetails_selectStyle__3Mv2N svg {
  color: white !important;
}
.Shopdetails_selectStyle__3Mv2N li {
  font-size: 14px !important;
}
.Shopdetails_bestSelller__AjKFv {
  width: 80%;
  margin: 0px auto;
}
.Shopdetails_shopbycategory__24f8l {
  width: 100%;
  margin: 0px auto;
}
.Shopdetails_shopbycategorybrand__69Dnt {
  width: 80%;
  margin: 0px auto;
}
.Shopdetails_mustHave__1UNuF {
  width: 80%;
  margin: 0px auto;
}
.Shopdetails_carouselBorder__8-eVR {
  border: 0.5px solid #c6c6c6;
  border-radius: 7px;
  background-color: #ffffff;
  /* height: 275px; */
}
.Shopdetails_carouselImg__2V6E1 {
  width: 100%;
  border-radius: 6px;
}
.Shopdetails_paraCard__1ImBH {
  font-family: Quicksand;
  font-size: 17px;
  width: 90%;
  margin: 0px auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.Shopdetails_bestTxt__33o7c {
  font-family: Quicksand;
  font-size: 18px;
  margin-top: 71px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 10px;
}
.Shopdetails_headerName__3mZNg {
  font-size: 22px;
}
.Shopdetails_priceTxt__2ZHn1 {
  font-size: 18px;
  margin-top: 15px;
}
.Shopdetails_TxtClr__3HOTk {
  font-size: 15px;
  margin-bottom: 0px;
}
.Shopdetails_getLookSection__2xSBO {
  width: 85%;
  margin: 0px auto;
  display: flex;
  margin-top: 57px;
  margin-bottom: 75px;
  cursor: pointer;
}
.Shopdetails_lookFirst__1LJGq {
  width: 60%;
}
.Shopdetails_lookFirst__1LJGq img {
  width: 122%;
  height: 100%;
}
.Shopdetails_lookLast__mBy6m {
  width: 40%;
  position: relative;
}
.Shopdetails_lookLast__mBy6m img {
  width: 100%;
}

.Shopdetails_look__15mpR {
  position: absolute;
  top: 43%;
  width: 100%;
  bottom: 0px;
  text-align: center;
  font-size: 31px;
  font-weight: 400;
  line-height: 47px;
}
.Shopdetails_modeIdea__NiCpC {
  width: 90%;
  margin: 0px auto;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.Shopdetails_gridSection__P8rAY img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.Shopdetails_ideaSecImg__1_lpG {
  width: 100%;
  margin-bottom: 26px;
  height: 173px;
}
.Shopdetails_ideaFirstImg__2AyHA {
  height: 375px;
}
.Shopdetails_imagefit__KGBxo {
  width: 100%;
  height: 427px !important;
  object-fit: contain;
}
.Shopdetails_QrLeft__2P2UU {
  height: 500px;
}
.Shopdetails_QrBottomMob__3uJpH {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/shop/Mask+Group+20.png');
}
.Shopdetails_closeBtn__1zy0y {
  position: absolute;
  right: 10px;
  z-index: 1;
  width: 27px;
  top: 5px;
}
.Shopdetails_txtSec__3BDhp {
  position: absolute;
  top: 35%;
  bottom: 0px;
  align-items: center;
  /* flex: 1; */
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0px 84px;
}
.Shopdetails_txtSec__3BDhp h4 {
  font-size: 18px;
  font-weight: 700;
}
.Shopdetails_txtSec__3BDhp p {
  font-size: 14px;
  font-weight: 500;
}
.Shopdetails_qrStyle__dmufC {
  margin-top: 38%;
  padding: 0px 21px;
}
@media only screen and (max-width: 500px) {
  .Shopdetails_ideaFirstImg__2AyHA,
  .Shopdetails_ideaSecImg__1_lpG {
    height: auto;
  }
  .Shopdetails_gridSection__P8rAY img {
    width: 100%;
    margin-bottom: 21px;
  }

  .Shopdetails_lookFirst__1LJGq {
    width: 100%;
  }
  .Shopdetails_lookFirst__1LJGq img {
    width: 100%;
    height: auto;
  }
  .Shopdetails_getLookSection__2xSBO {
    display: block;
  }
  .Shopdetails_lookLast__mBy6m {
    width: 100%;
    position: relative;
  }
  .Shopdetails_look__15mpR {
    position: absolute;
    top: 40%;
    width: 100%;
    bottom: 0px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    padding: 0px 31px;
  }
  .Shopdetails_morePart__13Wuc {
    padding: 0px 20px;
  }
}
.Shopdetails_ideaSecImg__1_lpG {
  width: 100%;
  margin-bottom: 26px;
  height: 173px;
}
.Shopdetails_ideaFirstImg__2AyHA {
  height: 375px;
}
.Shopdetails_imagefit__KGBxo {
  width: 100%;
  height: 427px !important;
  object-fit: contain;
}
.Shopdetails_priceData__1kLIW {
  margin-bottom: 51px;
}

.Shopdetails_dataName__28Qc9 {
  position: relative;
  top: -10px;
  /* white-space: nowrap; */
  width: 120px;
  /* overflow: hidden; */
  font-weight: 600;
  text-align: center;
  text-overflow: ellipsis;
  /* margin-bottom: 20px; */
}

.Shopdetails_item__fDWwK:hover {
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 25%);
}
.Shopdetails_item__fDWwK {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0.5rem auto;
  position: relative;
  /* border: 1px solid #f9fafb; */
  border-radius: 2px;
  transition: 0.3s ease;
  font-size: 0;
  background-color: white;
  border-radius: 4px;
}
.Shopdetails_item__fDWwK:hover .Shopdetails_content__2UJtO {
  opacity: 1;
  border-radius: 4px;
}
.Shopdetails_content__2UJtO {
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in-out;
  opacity: 0;
  /* border: 1px solid black; */
}
.Shopdetails_item__fDWwK:hover .Shopdetails_content__2UJtO div {
  opacity: 1;
  color: #f9fafb;
}
.Shopdetails_content__2UJtO div {
  opacity: 0;
  transition-delay: 1s;
  transition: 0.3s ease;
  font-size: 20px;
}

.Shopdetails_buttonImg__mR71q {
  margin-top: 10px;
  padding-right: 30px;
  padding-left: 30px;
}
.Shopdetails_imageFit__3ML8r {
  width: 100%;
  height: 200px;
}
.Shopdetails_modalImage__2yMnQ {
  background: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/socialShare.png);
  background-repeat: no-repeat;
  background-size: 32%;
  background-color: white;
  width: 550px;
  outline: none;
}
.Shopdetails_modalRow_share__19Udq {
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.Shopdetails_ShareModalHeader__2xv-P {
  height: 40px;
  color: #000000;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Shopdetails_ShareModalHeader__2xv-P h4 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 27px;
}
.Shopdetails_shareLink__KkS_K {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 24px;
}
.Shopdetails_ShareModalHeader__2xv-P h4 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 27px;
}
.Shopdetails_modal_share__iM4r9 {
  width: 80%;
}
.Shopdetails_shareContent__1795x {
  border-radius: 20px;
  border: 1px solid #a5a5a5;
  font-size: small;
  width: 79%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  padding-left: 22px !important;
  color: #1c1c1c;
}

.Shopdetails_copyLinkBtn__1XQEU {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  right: 0;
  top: 0;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
  padding: 0px 18px;
  font-weight: 800;
}
@media only screen and (max-width: 600px) {
  .Shopdetails_modalImage__2yMnQ {
    width: 94%;
  }
  .Shopdetails_arPart__13bGF {
    margin: 26px auto;
  }
  .Shopdetails_ideaFirstImg__2AyHA,
  .Shopdetails_ideaSecImg__1_lpG {
    height: auto;
  }
  .Shopdetails_gridSection__P8rAY img {
    width: 100%;
    margin-bottom: 21px;
  }

  .Shopdetails_overlay__kDrAe {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 1;
    top: 0;
    left: -10;
    /* background-color: rgb(0 0 0 / 60%); */
    justify-content: center !important;
    align-items: center !important;
  }
  .Shopdetails_lookFirst__1LJGq {
    width: 100%;
    /* height: 427px !important; */
    object-fit: contain;
  }
  .Shopdetails_lookFirst__1LJGq img {
    width: 100%;
    height: auto;
  }
  .Shopdetails_getLookSection__2xSBO {
    display: block;
  }
  .Shopdetails_lookLast__mBy6m {
    width: 100%;
    position: relative;
  }
  .Shopdetails_look__15mpR {
    top: 7px;
    bottom: 0px;
    font-size: 15px;
  }
  .Shopdetails_bestTxt__33o7c {
    font-family: Quicksand;
    font-size: 15px;
    margin-top: 53px;
    /* padding: 0px 17px; */
  }
  .Shopdetails_morePart__13Wuc {
    padding: 0px 20px;
  }
  .Shopdetails_typesSection__30Juk {
    text-align: center;
  }
  .Shopdetails_paraCard__1ImBH {
    font-size: 12px;
  }
  .Shopdetails_roomBox__1YPeO {
    border: 0.5px solid #dedede;
    height: 130px;
    box-shadow: 0px 0px 0px #dedede;
  }
  .Shopdetails_lookLast__mBy6m img {
    height: 62px;
    border-bottom-left-radius: 4px;
    object-fit: cover;
    border-bottom-right-radius: 4px;
  }
  .Shopdetails_bestSelller__AjKFv {
    width: 100%;
    margin: 0px auto;
  }

  .Shopdetails_typesSectionwom__2zkjp {
    width: 100%;
    text-align: center;
    padding-top: 0px;
  }
  .Shopdetails_leftFiter__CfDPh {
    display: none;
  }
  .Shopdetails_product__gSK5A {
    cursor: pointer;
    font-family: Quicksand;
    font-size: 14px !important;
    text-align: left;
    font-weight: 500 !important;
    margin: 0px !important;
    margin-bottom: 27px !important;
  }
  .Shopdetails_bannerImg__2ukAg {
    height: 170px;
    width: 100%;
  }

  .Shopdetails_dataNamess__3d2rK {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    font-weight: 700;
    text-align: left;
    text-overflow: ellipsis;
    font-size: 15px;
    margin-bottom: 9px !important;
    margin-top: 3px;
  }
  .Shopdetails_stock__3dnkp {
    white-space: nowrap;
    width: 100%;
    /* overflow: hidden; */
    font-weight: 700;
    text-align: left;
    text-overflow: ellipsis;
    font-size: 12px;
    margin-bottom: 9px !important;
    margin-top: 3px;
  }
  .Shopdetails_priceData__1kLIW {
    margin-bottom: 0px;
  }
  .Shopdetails_designIcon__2riC7 {
    height: 131px;
  }
  .Shopdetails_heartAlign__5jPiB {
    margin: 0px auto !important;
    width: 92%;
    padding: 5px 11px;
  }
  .Shopdetails_roomType__2WVhQ {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .Shopdetails_roomBoxbytype__3vFe8 {
    border: 0.5px solid #dedede;
    height: 93px;
    box-shadow: 0px 0px 0px #dedede;
    border-radius: 6px;
  }
  .Shopdetails_toggleImg__rFyIi {
    width: 23px;
    height: 19px;
    margin-top: 2px;
    margin-right: 9px;
  }
  .Shopdetails_buttonImg__mR71q {
    margin-top: 10px;
    padding-right: 0px;
    padding-left: 14px;
  }
  .Shopdetails_imageFit__3ML8r {
    width: 100%;
    height: 100px;
    object-fit: contain;
  }
}
.Shopdetails_filterButton__3Vhrk {
  width: 86px;
  height: 32px;
  padding: 0px;
  border-radius: 50px;
  margin-left: 10px;
  background-color: #f7f7f7;
  text-align: left;
  border-color: #f7f7f7;
}
.Shopdetails_similarButton1__2OxoK {
  /* margin-left: 30px;
  margin-right: 50px; */
  width: 110%;
  /* top:-15px; */
  font-weight: 400;
  text-align: center;
  background: #f4eae6 0% 0% no-repeat padding-box;
  border: 0.2px solid #000000;
  opacity: 1;
  bottom: -10px;
  font: normal normal 600 12px/35px 'Quicksand';
  letter-spacing: 0px;
  color: #272727;
  cursor: pointer;
  border-radius: 2px;
  position: absolute;
  margin-left: 10px;
}
.Shopdetails_cardWhole__3tUiN {
  width: 80%;
}
.Shopdetails_shopArrow__YHUYk {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #f4eae6;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  bottom: -21px;
}
.Shopdetails_noProducts__emvwh {
  text-align: center;
  margin-bottom: 40px;
  font-weight: 500;
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .Shopdetails_buttonImg__mR71q {
    margin-top: 10px;
    padding-right: 12px;
    padding-left: 12px;
  }
  .Shopdetails_imageFit__3ML8r {
    width: 100%;
    height: 175px;
  }
}
.Shopdetails_toggleImg__rFyIi {
  width: 23px;
  height: 19px;
  margin-top: 2px;
  margin-right: 9px;
}
.Shopdetails_designIconContainer1__d4Px0:hover {
  background-color: #e5f9ff;
}

/* shop by brand */

.Shopdetails_brand__3gMS_ {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
  background-color: #f1eeea;
  position: relative;
}

.Shopdetails_design__2PdxV {
  position: absolute;
  bottom: 0;
  left: 0;
  /* width: 30%; */
}
.Shopdetails_brandbanner__23HgY {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f1eeea;
  position: relative;
  justify-content: flex-start;
  /* align-items: center; */
}

.Shopdetails_brandLogo__73-Uw {
  width: 70%;
  height: 175px;
}

.Shopdetails_brandLogo__73-Uw img {
  width: 175px;
  height: 175px;
}

.Shopdetails_brandimage__2fdRP {
  position: relative;
  width: auto;
  height: 300px;
}

.Shopdetails_text__1pVRM {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 21px;
  bottom: 25px;
  position: relative;
}

.Shopdetails_shop_button__3eoIb {
  width: 125px;
  height: 40px;
  border-radius: 40px;
  background-color: #1a1818;
  color: white;
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
}
.Shopdetails_shop__2y3x1 {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.Shopdetails_ARbutton__31Zfm {
  position: relative; /* Changed to relative */
  cursor: pointer;
  width: 50px;
  right: 70px;
  bottom: 10px;
}

.Shopdetails_tooltip__3VPIo {
  visibility: hidden;
  position: absolute;
  text-align: center;
  justify-content: center;
  font-family: 'Quicksands', sans-serif;
  background-color: white; /* Added background color */
  border: 1px solid #ccc; /* Added border */
  border-radius: 4px; /* Added border radius */
  padding: 10px; /* Added padding */
  width: 200px; /* Added width */
  top: 84px; /* Adjusted positioning */
  left: calc(50%); /* Adjusted positioning */
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); /* Adjusted positioning */
}

.Shopdetails_tooltip__3VPIo::before {
  content: '';
  position: absolute;
  top: 100%; /* Changed to top */
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent; /* Changed border color */
}

.Shopdetails_tooltipimg__1NPDd {
  position: relative;
  bottom: 52px;
  left: 43px;
  width: 20px;
  height: 20px;
}

.Shopdetails_ARbutton__31Zfm:hover + .Shopdetails_tooltip__3VPIo {
  visibility: visible;
  opacity: 1;
}

.Shopdetails_brand1__ATmr_ {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 150px;
  background-color: #f1eeea;
  position: relative;
  overflow: hidden;
  top: 100px;
}

.Shopdetails_brand1__ATmr_ > div {
  position: relative;
}

.Shopdetails_brandbanner1__1XYk_ {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f1eeea;
  position: relative;
  justify-content: flex-start;
}

.Shopdetails_brandLogo1__2a2gO {
  width: 70%;
  height: 175px;
  position: absolute;
  bottom: 10px;
}

.Shopdetails_brandLogo1__2a2gO img {
  width: 70%;
  position: relative;
  /* bottom: 50%; */
}

.Shopdetails_brandimage1__P9D2_ {
  position: relative;
  width: auto;
  height: 300px;
  bottom: 50%;
  /* object-fit: contain; */
}

.Shopdetails_category_text__VMNWd {
  font-size: 28px;
}

@media (max-width: 640px) {
  /* Mobile devices */

  .Shopdetails_brand__3gMS_ {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
  }

  .Shopdetails_ARbutton__31Zfm {
    display: none;
  }

  .Shopdetails_brand1__ATmr_ {
    display: none;
  }

  .Shopdetails_design__2PdxV {
    height: 100px;
    z-index: -2;
  }

  .Shopdetails_brandbanner__23HgY {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
    /* background-color: #F1EEEA; */
    position: relative;
    justify-content: flex-start;
    align-items: center;
  }

  .Shopdetails_brandLogo__73-Uw {
    width: 200px;
    height: 100px;
  }

  .Shopdetails_brandLogo__73-Uw img {
    width: 125px;
    height: 100px;
    right: 160px;
    position: relative;
    top: -28px;
    background-color: #f1eeea;
    z-index: 1;
  }

  .Shopdetails_textcont__3W7r9 {
    width: 100vw;
    background-color: #f1eeea;
    z-index: 1;
    position: absolute;
    justify-content: center;
    top: 200px;
    right: 0px;
    height: 50px;
    opacity: 0.9;
  }

  br {
    display: block !important;
  }

  .Shopdetails_text__1pVRM {
    position: relative;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 15px;
    word-wrap: break-word;
    top: -10px;
    text-align: left;
    z-index: 1;
    padding: 1rem;
    color: black;
  }

  .Shopdetails_shop_button__3eoIb {
    width: 100px;
    height: 30px;
    border-radius: 40px;
    background-color: #1a1818;
    color: white;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    margin-top: 2rem;
    position: relative;
    left: 100px;
    top: 80px;
    z-index: 1;
  }

  .Shopdetails_ARbutton__31Zfm {
    position: relative;
    cursor: pointer;
    width: 40px;
    right: 185px;
    bottom: -15px;
  }

  .Shopdetails_tooltip__3VPIo {
    position: relative;
    display: inline-block;
    text-align: center;
  }

  .Shopdetails_tooltip__3VPIo span {
    visibility: hidden;
    width: 160px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .Shopdetails_tooltip__3VPIo img {
    width: 12px;
    height: 12px;
  }

  .Shopdetails_tooltip__3VPIo:hover span {
    visibility: visible;
    opacity: 1;
  }

  .Shopdetails_tooltipimg__1NPDd {
    position: absolute;
    bottom: -10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .Shopdetails_brandimage__2fdRP {
    width: 100vw;
    bottom: 300px;
    height: 300px;
    object-fit: cover;
    margin: 0 auto;
    left: -2px;
  }

  .Shopdetails_category_text__VMNWd {
    font-size: 28px;
    position: relative;
    left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  /* Tablet devices */
  .Shopdetails_brand__3gMS_ {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
  }

  .Shopdetails_design__2PdxV {
    height: 100px;
  }
  
  .Shopdetails_toggleImg__rFyIi {
    width: 23px;
    height: 19px;
    margin-top: 2px;
    margin-right: 9px;
  }
  .Shopdetails_brandbanner__23HgY {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
    background-color: #f1eeea;
    position: relative;
    justify-content: flex-start;
    align-items: center;
  }

  .Shopdetails_brandLogo__73-Uw {
    width: 200px;
    height: 100px;
  }

  .Shopdetails_brandLogo__73-Uw img {
    width: 200px;
    height: 150px;
    right: 200px;
    position: relative;
    top: -15px;
  }

  .Shopdetails_text__1pVRM {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 15px;
    right: 210px;
    top: 5px;
    text-align: center;
  }

  .Shopdetails_shop_button__3eoIb {
    width: 125px;
    height: 40px;
    border-radius: 40px;
    background-color: #1a1818;
    color: white;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    margin-top: 1rem;
    position: relative;
    right: 190px;
  }

  .Shopdetails_ARbutton__31Zfm {
    position: relative;
    cursor: pointer;
    width: 40px;
    right: 185px;
    bottom: -15px;
    z-index: -2;
  }

  .Shopdetails_tooltip__3VPIo {
    position: relative;
    display: inline-block;
    text-align: center;
  }

  .Shopdetails_tooltip__3VPIo span {
    visibility: hidden;
    width: 160px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .Shopdetails_tooltip__3VPIo img {
    width: 12px;
    height: 12px;
  }

  .Shopdetails_tooltip__3VPIo:hover span {
    visibility: visible;
    opacity: 1;
  }

  .Shopdetails_tooltipimg__1NPDd {
    position: absolute;
    bottom: -10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .Shopdetails_brandimage__2fdRP {
    width: 60%;
    bottom: 252px;
    height: 250px;
    object-fit: cover;
    margin: 0 auto;
    left: 304px;
  }

  .Shopdetails_brand1__ATmr_ {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 200px;
    background-color: #f1eeea;
    position: relative;
    top: 100px;
  }

  .Shopdetails_brandbanner1__1XYk_ {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f1eeea;
    position: relative;
    justify-content: flex-start;
  }

  .Shopdetails_brandLogo1__2a2gO {
    width: 100%;
    height: 175px;
    position: absolute;
  }

  .Shopdetails_brandLogo1__2a2gO img {
    width: 70%;
    position: relative;
    /* bottom: 50%; */
  }

  .Shopdetails_brandimage1__P9D2_ {
    height: 200px;
    object-fit: cover;
    margin: 0 auto;
  }
}

.Product_mainContainer__2UscF {
  max-width: 80%;
}

.Product_productContainer__2w9Yd {
  margin-bottom: 5vh;
  background-color: white;
  width: 100%;
  height: 50%;
}
.Product_saveBoards__2Zat9 {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.Product_subContainer__3rfp7 img {
  width: 90%;
}
.Product_MuiAccordion-root__3_B9i {
  border: 1px solid white !important;
  border-radius: 10px !important;
  background-color: #fff !important;
}
.Product_modalHeader__26GH1 {
  background-color: #141414;
  height: 56px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Product_container2__kKUQn {
  width: 100%;
  background-color: #f5f0ec;
  background-image: url(/static/media/pattern.8133c2fd.png);
  height: auto;
  margin-top: 20vh;
  padding: 78px 93px;
  background-size: 100%;
}
.Product_thumb__2kp79 {
  border: none;
}
.Product_arPart__z7Qee {
  margin: 21px 0px;
  display: flex;
  align-items: center;
  background-color: white;
}
.Product_view__3Ud2M {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
}
.Product_arImg__16RKd {
  width: 16px;
  height: 19px;
  margin-right: 7px;
}
.Product_QrLeft__2CJYj {
  height: 500px;
}
.Product_closeBtn__2DDuT {
  position: absolute;
  right: 0px;
  z-index: 1;
  width: 27px;
  top: 13px;
}
.Product_txtSec__3Jz_1 {
  position: absolute;
  top: 38%;
  bottom: 0px;
  align-items: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0px 84px;
}
.Product_txtSec__3Jz_1 h4 {
  font-size: 18px;
  font-weight: 700;
}
.Product_txtSec__3Jz_1 p {
  font-size: 14px;
  font-weight: 500;
}
.Product_firstRate__3SfXT {
  flex-direction: row;
  justify-content: start;
  display: flex;
}
.Product_ratingImg__34hrp {
  width: 20px;
  height: 20px;
  margin-left: 9px;
  margin-top: 6px;
}
.Product_raTxt__j0qiq {
  color: #a3a3a3;
  font-size: 12px !important;
  margin: 0px !important;
  margin-left: 0px !important;
  width: 67% !important;
  text-align: center;
  line-height: 18px;
}
.Product_listRate__38dy9 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.Product_ratingCount__3NAw4 {
  font-weight: 500;
  margin-right: 3px;
  font-size: 14px;
}
.Product_ratingSmall__3gwF1 {
  width: 11px;
  margin-right: 13px;
  margin-left: 2px;
}
.Product_wholeRate__1IhPq {
  margin: 0px auto;
  margin-top: 44px;
  width: 83%;
  padding-bottom: 24px;
}
.Product_rateCard__grAMA {
  width: 100%;
  padding-left: 59px;
  margin: 46px 0px;
}
.Product_wholeBox__3MfSH {
  display: flex;
}
.Product_rateimgss__1C0qe {
  width: 15px;
  margin-left: 2px;
}
.Product_great__3Om4e {
  color: #232424;
  margin-left: 8px;
  font-size: 16px;
}
.Product_greatTxt__184_P {
  color: #777777;
  font-size: 13px;
  margin-top: 9px;
}
.Product_blackBox__hA08F {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
  color: white;
  width: 38px;
  border-radius: 3px;
  text-align: center;
  justify-content: center;
}
.Product_rateBtn__2eZyk {
  border: 1px solid #707070;
  background-color: #ffffff;
  padding: 5px 20px;
  color: #777777;
  float: right;
  position: absolute;
  bottom: 13px;
  right: 20px;
}
.Product_ratingPeople__356qK {
  color: #a3a3a3;
}
ul {
  list-style: none;
}
.Product_rating__2OXl_ {
  font-size: 28px;
  font-weight: 400;
}
.Product_parasImg__wH7yK {
  font-size: 14px;
  font-weight: 600;
}
.Product_imageGalleryTitle__KGByr {
  font-weight: 300;
  font-size: 19px;
}
.Product_imageGalleryPara__3CCOW {
  font-weight: 300;
  font-size: 15px;
  text-decoration: underline;
}
.Product_galleryPrice__1nMdK {
  font-size: 20px;
  font-weight: 600;
}
.Product_addCart__3YLMq {
  margin-bottom: 15px;
  /* padding-left: 10px; */
  height: 41px;
  border-radius: 30px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  /* padding-right: 10px; */
  border: none;
  margin-right: 15px;
  padding: 0px 24px;
  font-weight: 500;
  font-size: 15px;
}
.Product_productDetails__3oXQX {
  font-size: 14px;
  line-height: 29px;
}
.Product_taxes__3m3v- {
  font-size: 14px;
}
/* .image-gallery
-thumbnail.active,
.image-gallery-thumbnail:focus {
 
} */
.Product_tabContainer__5a12w {
  margin-top: 53px;
}
.Product_tabContainer__5a12w h5 {
  height: 50px;
  margin-left: 50px;
  text-align: center;
  margin-bottom: -5px;
  padding-top: 10px;
  margin: 0px 23px;
  padding: 12px 38px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.Product_modalRow__1yCQT {
  /* height: 399px; */
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.Product_carouselImg__1QXKt {
  width: 100%;
  height: 272px;
  background-color: #ffffff;
}
.Product_wholeCarousel__ycIkZ {
  width: 92%;
  height: 313px;
  background-color: #ffffff;
  margin: 0px auto;
  background-color: #ffffff;
}
.Product_imageCol__qkv-u {
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px !important;
}
.Product_createCol__1ksrO {
  padding-top: 21px;
  max-height: 90vh;
  overflow-y: scroll;
  padding-bottom: 10%;
}
.Product_image2__1PPVN {
  width: 100%;
  border-radius: 10px;
}
.Product_createCol__1ksrO h6 {
  margin-top: 4vh;
}
.Product_createCol__1ksrO input {
  margin-top: 10px;
  margin-left: 0px;
  font-weight: 600;
  background-color: transparent;
  width: 86%;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #707070;
  padding-bottom: 4px;
  color: #1a1818;
}

.Product_createCol__1ksrO {
  background-color: #f2feff;
  padding-left: 30px !important;
}

.Product_createCol__1ksrO h6 {
  margin-top: 5vh;
  font-weight: 100 !important;
}
.Product_tabContainer__5a12w p {
  width: 50%;
  margin-left: 10% !important;
  margin-top: 44px;
  font-size: 15px;
  line-height: 27px;
}
.Product_createBoardButton__PAm19 {
  width: 128px;
  height: 31px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  font-size: 13px;
  margin-left: 30px;
  border: none;
  float: left;
}
.Product_createBoard__Mc_ny {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.Product_createBoardButton1__2AZvR {
  width: 128px;
  height: 35px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  float: left;
  margin-top: 60px;
  font-size: 14px;
}
.Product_MuiAccordion-root__3_B9i {
  border: 1px solid white !important;
  border-radius: 10px !important;
  background-color: white !important;
}
.Product_react-multi-carousel-item__2W8Q6 {
  margin: 0px 24px;
}
@media only screen and (max-width: 500px) {
  .Product_modalHeader__26GH1 h4 {
    font-size: 15px;
    margin-top: 25px !important;
    margin-left: 25px !important;
    display: inline-flex;
  }
  .Product_createCol__1ksrO {
    height: 60%;
  }
  .Product_image2__1PPVN {
    width: 100%;
    border-radius: 10px;
  }
  .Product_createCol__1ksrO h6 {
    margin-top: 4vh;
  }
  .Product_createCol__1ksrO input {
    width: 90%;
  }
  .Product_save1__1pOwx {
    font-size: 11px;
  }
  .Product_createBoardButton__PAm19 {
    background-color: #000000;
    border-radius: 20px;
    color: #ffffff;
    border: none;
    float: left;
    font-size: 11px;
  }
  .Product_container2__kKUQn {
    padding: 10px 28px;

    margin-top: 8vh;
  }

  .Product_addCart__3YLMq {
    width: 100%;
  }
  .Product_productContainer__2w9Yd p {
    word-break: break-all;
  }
  .Product_Product_wholeRate__1IhPq__1MJgr {
    margin: 0px auto;
    margin-top: 0px;
    width: 95%;
    padding-bottom: 24px;
  }
  .Product_raTxt__j0qiq {
    padding-bottom: 21px;
    text-align: left;
  }
  .Product_rateBtn__2eZyk {
    border: 1px solid #707070;
    background-color: #ffffff;
    padding: 5px 20px;
    color: #777777;
    float: left;
    position: relative;
    bottom: 13px;
    right: 0px;
    margin-top: 27px;
  }
  .Product_wholeRate__1IhPq {
    margin-top: 0px;
  }
}

.image-gallery-thumbnail:hover {
  border: 2px solid black !important;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 2px solid black !important;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails {
  margin-left: 0px !important;
}
.image-gallery-slide-wrapper.left {
  display: inline-block;
  width: 75% !important;
  margin-left: 4% !important;
}
.galleryPrice {
  font-size: 20px;
  font-weight: 600;
}
.addCart {
  margin-bottom: 15px;
  /* padding-left: 10px; */
  height: 41px;
  border-radius: 30px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  /* padding-right: 10px; */
  border: none;
  margin-right: 15px;
  padding: 0px 24px;
  font-weight: 500;
  font-size: 15px;
}
.wholeBox {
  display: flex;
  flex-direction: row;
}
.rateCard {
  width: 100%;
  height: 20px;
}
.image-gallery-icon {
  color: #fff;
  transition: all 0.3s ease-out;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  -webkit-filter: drop-shadow(0 2px 2px #1a1a1a);
  filter: drop-shadow(0 2px 2px #1a1a1a);
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-icon:hover {
    color: #337ab7;
  }
  .image-gallery-icon:hover .image-gallery-svg {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.image-gallery-icon:focus {
  outline: 2px solid #337ab7;
}
.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}
.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}
.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 28px;
  width: 28px;
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 15px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}
@media (max-width: 480px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 10px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}
.image-gallery-fullscreen-button {
  right: 0;
}
.image-gallery-play-button {
  left: 0;
}
.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}
@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}
@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}
.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: 0.6;
  pointer-events: none;
}
.image-gallery-left-nav {
  left: 0;
}
.image-gallery-right-nav {
  right: 0;
}
.image-gallery {
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
}
.image-gallery.fullscreen-modal {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}
.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}
.image-gallery-content.fullscreen {
  background: #000;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
}
.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}
.image-gallery-slide-wrapper {
  position: relative;
}
.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(100% - 110px);
}
@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: calc(100% - 87px);
  }
}
.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}
.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}
.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.image-gallery-slide.center {
  position: relative;
}
.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain;
}
.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
}
@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: 0.8em;
    padding: 8px 15px;
  }
}
.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
}
.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}
.image-gallery-bullets .image-gallery-bullet {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0 2px 2px #1a1a1a;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  transition: all 0.2s ease-out;
}
@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}
@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}
.image-gallery-bullets .image-gallery-bullet:focus {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  background: #337ab7;
  border: 1px solid #337ab7;
}
.image-gallery-bullets .image-gallery-bullet.active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  border: 1px solid #fff;
  background: #fff;
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-bullets .image-gallery-bullet:hover {
    background: #337ab7;
    border: 1px solid #337ab7;
  }
  .image-gallery-bullets .image-gallery-bullet.active:hover {
    background: #337ab7;
  }
}
.image-gallery-thumbnails-wrapper {
  position: relative;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  touch-action: pan-y;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
  touch-action: pan-x;
}
.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}
.image-gallery-thumbnails-wrapper.left
  .image-gallery-thumbnails
  .image-gallery-thumbnail
  + .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right
  .image-gallery-thumbnails
  .image-gallery-thumbnail
  + .image-gallery-thumbnail {
  margin-left: 0;
  margin-top: 2px;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    margin: 0 3px;
  }
}
.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}
.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 81px;
  }
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  display: block;
  position: relative;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #337ab7;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:focus {
    border: 3px solid #337ab7;
  }
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    outline: none;
    border: 4px solid #337ab7;
  }
}
@media (hover: hover) and (pointer: fine) and (max-width: 768px) {
  .image-gallery-thumbnail:hover {
    border: 3px solid #337ab7;
  }
}
.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: #fff;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 0 2px 2px #1a1a1a;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: 0.8em;
    line-height: 0.8em;
  }
}
.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
@media (max-width: 768px) {
  .image-gallery-index {
    font-size: 0.8em;
    padding: 5px 10px;
  }
}

.Account_pageContainer__2tkqn {
  overflow-y: hidden;
  margin-top: 50px
}

.Account_container__3AvUE {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2440_gztd1o.png');
  width: 120%;
  height: 100vh;
  display: flex;
  position: fixed;
  overflow-y: auto;
  background-size: contain;
  background-color: #f5f0ec;
}

.Account_button__14Yp3 {
  display: flex;
  position: relative;
  justify-content: center;
}



.Account_updateBtn__27iTQ:hover {
  font-weight: 900;
}

.Account_updateBtn__27iTQ {
  background-color: #000000;
  border-radius: 30px;
  color: #f5f0ec;
  padding: 10px 30px 10px 30px;
  font-weight: 400;
  margin-top: 40px;
}

.Account_sidebar_content__3a8a_ {
  width: 60%;
  margin: auto;
  margin-top: 75px;
}

.Account_sidebar_content__3a8a_ h5 {
  text-align: left;
  font-size: 16px;
  padding-top: 30px;
  cursor: pointer;
}

.Account_card_input__13y8U {
  border: none !important;
  width: 100% !important;
  border-bottom: 1px solid #707070 !important;
  font-weight: 700;
  opacity: 0.6;
}

.Account_label__2yfQ4 {
  font-size: 14px;
  font-weight: 500;
  /* margin-bottom: 20px; */
}

.Account_paymentBtn__10-hx {
  background-color: #000000;
  border-radius: 30px;
  color: #f5f0ec;
  padding: 10px 25px 10px 25px;
  font-weight: 400;
}

.Account_paymentBtn__10-hx:hover {
  font-weight: 900;
}

.Account_modalContainer__1PDk6 {
  width: 100%;
  position: relative;
  margin-top: 5vh;
  text-align: center;
}

.Account_modal_input_container__2sFen {
  width: 90% !important;
  margin: auto !important;
  text-align: left;
  margin-top: 20px !important;
  font-family: 'QuickSand' !important;
}

.Account_modal_input_container__2sFen label {
  font-size: 0.8vw;
}

.Account_modalContainer__1PDk6 h4 {
  width: 100% !important;
  text-align: center;
  font-weight: 100;
}

.Account_addresses_new__34kx2 {
  width: 100%;
  background-color: #d2edf5;
  padding: 20px;
  font-weight: 800;
  height: 70px;
}
.Account_modal_input__13u6J:focus {
  border: 1px solid black !important;
}
.Account_modal_input__13u6J {
  width: 220px;
  padding: 5px;
  font-size: 0.8vw;
  height: 35px;
  border: 1px solid black;
}

.Account_active__2xniU {
  text-decoration: underline;
}

.Account_cardHeader__Ec79e {
  background-color: #000000 !important;
  color: #ffffff;
  font-weight: 800;
  padding: 0.75rem 2.25rem !important;
}

.Account_cardHeader1__12J8x {
  background-color: #e5e5e5 !important;
  color: #000000;
  font-weight: 800;
}
.Account_divider__3NpOr {
  width: 100%;
  height: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Account_sidebar__1tZtO {
  position: fixed;
  left: 0%;
  width: 20%;
  height: 100%;
  z-index: 10;
  background-color: white;
}

.Account_cardDiv__2XuiZ {
  width: 500px;
  padding-left: '300px';
  height: auto;
  margin: auto;
}

.Account_mobileBackground__3f20d {
  min-height: 100vh;
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2440_gztd1o.png');
}

/* ------------------------------------------------------- */
.cancleItemCard {
  padding: 10px;
  background-color: #ffffff;
  position: relative;
  margin-top: 0;
}
.page__header {
  margin: 50px 0px;
  font-size: 30px;
  font-weight: 500;
}
.order__tab__active {
  border-bottom: 3px solid #5f5d5d;
}
.order__tab {
  border-bottom: 1px solid #7372726b;
}
.order__tab > div {
  margin-right: 20px;
  font-weight: 400;
}
.cancelContainer {
  background-color: white;
  max-width: 85%;
}
.cancelBody {
  background-color: white;
}
.bordered {
  border: 1px solid;
}

.cancelBody1 {
  background-color: white;
  color: black;
  padding: 15px 15px 15px 15px;
}
.select {
  border: none;
  border-bottom: 1px solid #a8a8a8;
}
.vl {
  border-left: 2px solid grey;
  height: 10px;
}
.textBox {
  height: 100px;
  width: 100%;
  border: 1px solid #a8a8a8;
  border-radius: 5px;
  padding: 5px;
}

.textBox2 {
  height: 150px;
  width: 100%;
}
.textBox3 {
  height: 85px;
  width: 100%;
}

.headerCancelation {
  background-color: #141414;
  padding: 15px 15px 15px 15px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.header {
  background-color: #141414;
  /* height: 67px; */
  color: #ffffff;
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
}

.order__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.order__actions {
  display: flex;
}
.order__actions > div:first-child {
  margin-right: 20px;
}
.order__actions > div > span {
  border-bottom: 1px solid black;
}
.order__container {
  margin: 10px 0px;
}
/* p{
    text-align: center;
    letter-spacing: NaNpx;
    color: #020202;
    opacity: 1;
  } */

.MuiDrawer-paper {
  width: 50%;
  padding: 20px;
}

.drawer1Title {
  font-size: 16px;
  font-weight: 800;
  font-family: 'Quicksand';
}

.drawer1Content {
  font-size: 15px;
  font-weight: 600;
  font-family: 'Quicksand';
}

.continueButton {
  background-color: black;
  color: white;
  font-weight: 400;
  height: 50px !important;
  padding: 4px 20px 4px 20px;
  border: none;
  font-size: small;
  cursor: pointer;
}

.modalRow {
  /* height: 399px;
  max-height: 100%; */
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
  background-color: #f4f4f4;
}

.modalHeader {
  background-color: #d0ecf4;
  height: 67px;
  color: black;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.changeButton {
  color: black;
  background-color: white;
  font-weight: 400;
  font-size: small;
  padding: 4px 20px 4px 20px;
  border: 1px solid;
  cursor: pointer;
}
.container-order {
  text-align: center;
  padding: 20px;

  background-color: white;
  max-width: 40% !important;

  border-radius: 20px;
  padding-left: 50px;
  padding-right: 50px;
}
.getStartedButton2 {
  padding: 18px 35px 18px 35px;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .container-order {
    text-align: center;
    min-width: 90% !important;
    max-width: 90% !important;
    background-color: white;
    margin-top: 70px;
  }

  .cancleItemCard {
    margin-top: 1rem;
  }
  .thanks {
    font-size: 20px;
    padding-left: 1%;

    text-align: center;
  }
  .email {
    font-size: 14px;
    padding-left: 1%;

    text-align: center;
  }
  .padd {
    width: 30px;
  }
}

/* span{
    text-decoration: underline;
    
    
  } */
.getStartedButton2 {
  border-radius: 30px;
  background-color: black;
  color: white;
  font-weight: 700;
  font-size: 15px;
  height: 60px !important;
  /* padding: 7px 25px 12px 25px; */
  border: none;
  cursor: pointer;
}
.continueButton {
  background-color: black;
  color: white;
  font-weight: 400;
  height: 50px !important;
  padding: 4px 20px 4px 20px;
  border: none;
  cursor: pointer;
}

.modalRow {
  /* height: 399px;
  max-height: 100%; */
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
  background-color: #f4f4f4;
}

.modalHeader {
  background-color: #d0ecf4;
  height: 67px;
  color: black;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.changeButton {
  color: black;
  background-color: white;
  font-weight: 400;
  padding: 4px 20px 4px 20px;
  border: 1px solid;
  cursor: pointer;
}
.getStartedButton1 {
  border-radius: 30px;
  background-color: black;
  color: white;
  margin-top: 5px;
  width: 220px;
  height: 60px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-21) / var(--unnamed-line-spacing-30)
    var(--unnamed-font-family-quicksand);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 21px/30px Quicksand;
  letter-spacing: 0px;
  color: #f5f0ec;
  opacity: 1;
  margin-left: 20px;
  border: none;
  cursor: pointer;
}
.order__items {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background: white;
  border-bottom: 0.5px solid #7372726b;
}
.order__content {
  background: white;
  margin: 0;
  padding: 20px;
}
.order__content1 {
  background: white;
  margin: 0;
}
.order__name {
  color: #d02424;
  font-size: 19px;
  font-weight: 900;
}
.order__desc {
  font-size: 15px;
  font-weight: 800;
}
.order__sold {
  font-size: 15px;
  font-weight: 800;
  margin: 5px 0;
}
.order__price {
  font-size: 15px;
  font-weight: 800;
  font-family: 'QuickSand';
}
.order__id {
  margin-top: 20px;
  font-size: 12px;
  color: #767676;
  font-weight: 400;
}

.order__act__btns {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
}
.drawer1 {
  /* padding: 20px;
  font-size: 11px;
  font-weight: 800;
  font-family: "Quicksand"; */
  background-color: #2c2c2c;
}
.MuiDrawer-paper {
  padding: 20px;
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 14%);
  -webkit-tap-highlight-color: transparent;
}

.borderBottom {
  border-bottom: 2px solid grey;
  /* height: 10px; */
}
.order__act__btn1 {
  width: 100%;
  background: black;
  color: white;
  padding: 6px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px;
}
.order__act__btn2 {
  width: 100%;
  color: black;
  border: 1px solid;
  background: white;
  padding: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
}
.black {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_3158_wxbffq.png');
  color: white;
  height: 200px;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
}
.black1 {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2952_2x_benqqj.png');
  color: black;
  height: 250px;
  text-align: center;
  padding-top: 80px;
  font-size: 16px;
  font-weight: 800;
}
/* h3{
    text-align: center;
font: normal normal medium 25px/35px Quicksand;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
  }
  h6{
    text-align: center;
font: normal normal normal 20px/55px Quicksand;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
  } */
.home-card-view {
  height: 500px;
}
.will {
  background: black;
  color: white;

  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
}
.padd {
  padding-top: 40px;
  padding-bottom: 10px;
}
.order__act__btn {
  padding: 6px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  border: 1px solid #1a1818;
  margin-top: 10px;
  border-radius: 2px;
}
/* -------------------------------------------------------- */

@media only screen and (max-width: 500px) {
  .page__header {
    font-size: 20px;
  }
  .order__tab {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
  /* .order__items {
    font-size: 9px;
  }
  .order__name {
    font-size: 10px;
  } */
  .order__desc,
  .order__sold,
  .order__price {
    font-size: 14px;
    font-family: 'Quicksand';
  }
  .order__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .order__id {
    font-size: 12px;
    color: #767676;
  }
  .order__act__btns,
  .order__act__btn1,
  .order__act__btn {
    font-size: 14px;
  }
  .MuiDrawer-paper {
    width: 75%;
    padding: 20px;
  }
  .drawer1Title {
    font-size: 14px;
    font-weight: 800;
    font-family: 'Quicksand';
  }
  .drawer1Content {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Quicksand';
  }
}

.Concept_button__13pTA {
  padding: 6px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  color: black;
  font-weight: 500;
  border: none;
  background-color: #d2edf5;
  min-width: 125px;
  font-weight: 400;
}

.Concept_submitButton__6n6v9 {
  margin-bottom: 50px;
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  padding: 6px;
  font-weight: 400;
  padding-left: 25px;
  margin-top: 30px;
  padding-right: 25px;
}

.Concept_submitButton__6n6v9:hover {
  font-weight: 900;
}
.Concept_button1__cuA5s {
  padding: 6px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  color: black;
  font-weight: 500;
  border: 1px solid #d3d3d3;
  min-width: 125px;
  cursor: pointer;
  background-color: #fafafa;
}

.Concept_button1__cuA5s:hover {
  font-weight: 900;
  background-color: #d2edf5;
}

.Concept_container2Image__13sKz {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.Concept_container2__1S3ij {
  width: 100%;
  background-color: #f5f0ec;
  height: auto;
  margin-top: 100px;
  padding: 10vh 15vw 10vh 15vw;
}

.Concept_topContainer__1RnhA {
  -webkit-filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 6%));
          filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 6%));
}

@media only screen and (max-width: 500px) {
  .Concept_button__13pTA {
    min-width: 120px;
  }
  .Concept_button1__cuA5s {
    min-width: 120px;
  }
}

.Review_imageClass__1pCmC {
  max-height: '200px' !important;
}

.Review_placeholderContainer__3xP8I {
  display: grid;
  /* flex-direction: column; */
  /* justify-content: center; */
  place-items: center;
  /* align-items: center; */
  width: 100%;
  height: 90vh;
  background-color: #f5f0ec;
}

.Review_placeholderCentre__3aZ98 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 60%;
  height: 70%;
}

.Review_innerCont__1l977 {
  padding: 20px;
  width: 50%;
  display: grid;
  place-content: center;
}

.Review_innerContText__3EIvA {
  padding: 20px;
  width: 50%;
  /* display: grid;
  place-content: center; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Review_paginationDot__2-LKc {
  height: 12px;
  width: 12px;
  border-radius: 50px;
  background-color: #e5dcd5;
  cursor: pointer;
}

.Review_paginationDotActive__Jhf5B {
  height: 12px;
  width: 12px;
  border-radius: 50px;
  background-color: #e5cdb9;
  cursor: pointer;
}

.Review_ellipsis__IZUHN {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  height: 30px; /* Fallback for non-webkit */
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
}
.Review_arPart__2Ic2w {
  margin: 21px 0px;
  display: flex;
  align-items: center;
  background-color: white;
}
.Review_view__1XnZy {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
}
.Review_arImg__3fZJC {
  width: 16px;
  height: 19px;
  margin-right: 7px;
}
.Review_modalImage__3aKx6 {
  background: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/socialShare.png);
  background-repeat: no-repeat;
  background-size: 32%;
  background-color: white;
  width: 550px;
  outline: none;
}
.Review_modalRow_share__2B41l {
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.Review_ShareModalHeader__wHm3A {
  height: 40px;
  color: #000000;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Review_ShareModalHeader__wHm3A h4 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 27px;
}
.Review_shareLink__2hXpe {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 24px;
}
.Review_ShareModalHeader__wHm3A h4 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 27px;
}
.Review_modal_share__CJtwz {
  width: 80%;
}
.Review_shareContent__3DVXL {
  border-radius: 20px;
  border: 1px solid #a5a5a5;
  font-size: small;
  width: 79%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  padding-left: 22px !important;
  color: #1c1c1c;
}

.Review_copyLinkBtn__1adzZ {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  right: 0;
  top: 0;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
  padding: 0px 18px;
  font-weight: 800;
}
.Review_cardHoverScale__1bwM1 {
  transition: all 0.3s ease-in-out;
}
.Review_cardHoverScale__1bwM1:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: drop-shadow(0px 3px 6px rgb(0, 0, 0, 16%));
          filter: drop-shadow(0px 3px 6px rgb(0, 0, 0, 16%));
}
.Review_container__3npvR {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_3158_kwoclf.png');
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #d0ecf4;
  /* height: 30vh; */
  border-radius: 20px;
  /* padding-left: 100px;
  padding-top: 50px;
  padding-right: 100px; */
  padding: 35px;
}

.Review_topContainer__2mOP9 {
  -webkit-filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 6%));
          filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 6%));
}

.Review_container1__3oq3F {
  background: #d0ecf4 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 30vh;
  border-radius: 20px;
  padding-left: 100px;
  padding-top: 50px;
  padding-right: 100px;
}

.Review_btn1__2hSc5 {
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  width: 25%;
}

.Review_upgradebtn__1aky8 {
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
}

.Review_warning__32C3Z {
  background-color: #ffc400;
  border-radius: 30px;
  color: black;
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  min-height: 40px;
}
.Review_closeBtn__2Yxbt {
  position: absolute;
  right: -23px;
  top: -13px;
  background: white;
  border-radius: 100px;
}

.Review_textBox__7EM2X {
  color: black;
  font-size: 16px;
  font-weight: 600;
  font-family: 'QuickSand';
  text-align: center;
}

.Review_container3__3nE0f {
  width: 100%;
  background: #f4eae6 0% 0% no-repeat padding-box;
  height: auto;
  /* margin-top: 100px; */
  /* padding: 10vh 8vw 10vh 8vw; */
}

.Review_tryProductBtn__qWZ4Q {
  background-color: black;
  color: white;
  /* border-radius: 30px; */
  padding: 5px 5px 5px 15px;
  border: none;
}

.Review_shopButton__2dHBL {
  background-color: black;
  color: white;
  /* border-radius: 30px; */
  padding: 10px 30px 10px 30px;
  border: none;
}
.Review_hovered1__3krvF {
  height: 200px;
  width: 200px;
}
.Review_overlay__3dUuX {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  bottom: 0;
  background-color: rgb(0 0 0 / 30%);
  justify-content: center !important;
  align-items: center !important;
}
.Review_overlay1__3FPu8 {
  /* background-color: #2c2c2cb5;
  justify-content: center !important;
  align-items: center !important; */

  opacity: 0.4;
  filter: alpha(opacity=40);
  background-color: #2c2c2cb5;
}
.Review_detailButton__2-Bdm {
  margin-left: 35%;
  background: transparent;
  color: white;
  margin-top: 30%;
}

.Review_sidebar__xW3eB {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 60px;
  height: 100vh;
}
.Review_similarButton__DS7la {
  background-color: white;
  color: black;
  border: none;
  font-size: 14px;
  border-radius: 20px;
  padding: 8px 15px 8px 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 8px 8px -5px, rgba(0, 0, 0, 0.3) 0px 8px 8px -8px;
}

.Review_similarButton1__1HfcF {
  /* margin-left: 30px;
  margin-right: 50px; */
  width: 100%;
  font-weight: 400;
  text-align: center;
  background: #f4eae6 0% 0% no-repeat padding-box;
  border: 0.2px solid #000000;
  opacity: 1;
  font: normal normal 600 12px/35px 'Quicksand';
  letter-spacing: 0px;
  color: #272727;
  cursor: pointer;
  border-radius: 2px;
}
.Review_productOnline__12PvB {
  margin-top: 6px;
  width: 9px;
  height: 10px;
  border-radius: 6px;
  background-color: #3aff0b;
}
.Review_numberOne__1qq1o {
  margin-bottom: -13px;
  margin-right: 9px;
  z-index: 10;
  width: 22px;
  border-radius: 12px;
  background-color: white;
  text-align: center;
  height: 23px;
  padding: 2px;
}

.Review_totalValue__9xjSq {
  background-color: #000000;
  font-size: 14px;
  color: #ffffff;
  padding: 15px;
  text-align: center;
  border-radius: 0 25px 25px 0;
}

.Review_totalValueMobile__22OjD {
  background-color: #000000;
  color: #ffffff;
  padding: 15px;
  text-align: center;
}

.Review_total__23tBL {
  min-height: 55px;
  background-color: #def8ff;
  font-size: 23px;
  color: #000000;
  font-weight: bold;
  padding: 2%;
  text-align: center;
}
.Review_totalshop__3WZ6a {
  min-height: 55px;
  background-color: #def8ff;
  /* font-size: 17px; */
  color: #000000;
  font-weight: bold;
  text-align: center;
  /* padding: 1%; */
}
.Review_confirmMoodboard__pnOI6 {
  background-color: #def8ff;
  font-size: 14px;
  color: #000000;
  padding: 10px;
  text-align: center;
  border-radius: 25px 0 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Review_confirmBtn__1_BAU {
  padding: 5px 20px;
  background-color: #000000;
  color: #ffffff;
  margin-right: 0.5rem;
  border-radius: 2px;
}

.Review_confirmBtn__1_BAU:disabled {
  background-color: #00000029;
  color: #000000;
  border: none;
  cursor: not-allowed;
}

.Review_declineBtn__6HPZP:disabled {
  background-color: #00000029;
  color: #000000;
  border: none;
  cursor: not-allowed;
}

.Review_confirmMoodboardMobile__1iHnO {
  background-color: #def8ff;
  color: #000000;
  padding: 25px 10px 25px 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Review_shopNowFinalize__2RF40 {
  font-size: 14px;
  color: #000000;
  padding: 10px;
  text-align: center;
  border-radius: 25px 0 0 25px;
  display: flex;
  justify-content: center;

  align-items: center;
  background: transparent linear-gradient(270deg, #ffede6 0%, #ffb598 100%) 0% 0% no-repeat
    padding-box;
}

.Review_shopNowFinalizeMobile__2rNF- {
  color: #000000;
  padding: 25px 10px 25px 10px;
  text-align: center;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: transparent linear-gradient(270deg, #ffede6 0%, #ffb598 100%) 0% 0% no-repeat
    padding-box;
}

.Review_declineBtn__6HPZP {
  padding: 5px 20px;
  color: #000000;
  border: 0.5px solid #191919;
  border-radius: 2px;
  background-color: #def8ff;
}

/* width */
.Review_customScroll__1r3F-::-webkit-scrollbar {
  width: 6px;
  background: #e5dcd5;
}

/* Track */
.Review_customScroll__1r3F-::-webkit-scrollbar-track {
  border-radius: 8px;
}

/* Handle */
.Review_customScroll__1r3F-::-webkit-scrollbar-thumb {
  background: #e5cdb9;
  border-radius: 10px;
}

.Review_total1__397m4 {
  min-height: 55px;
  background: #def8ff;
  font-size: 23px;
  color: #000000;
  text-align: center;
  margin: 10px;
  border-radius: 30px;
  width: 100%;
}
.Review_totalnew__2PTXZ {
  min-height: 45px;
  background: #def8ff;
  font-size: 23px;
  color: #000000;
  margin: 10px;
  width: 100%;
  padding-left: 5%;
  text-align: start;
}
.Review_totalnew1__ResVJ {
  min-height: 45px;
  background: #ffede6;
  font-size: 23px;
  color: #000000;
  margin: 10px;
  width: 100%;
  padding-left: 5%;
  text-align: start;
}
.Review_dialogWidth__15tH3 {
  width: auto !important;
  height: auto !important;
}
.Review_modalWidth__2JylZ {
  width: -webkit-fill-available !important;
  height: -moz-available !important;
  /* height:auto !important */
}
.Review_modalContainer1__3eCf5 {
  width: 60%;
  height: -webkit-max-content;
  height: max-content;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
}
.Review_modalContainer3__2VSzX {
  width: 60%;
  height: auto;
  /* flex-direction: column;
  display: flex;
  align-items: center; */
  /* justify-content: flex-start; */
  background-color: white;
}
.Review_box1__1NEYL {
  width: 100%;
  padding: 3%;
  border: 1px solid #c1c1c1;
}
.Review_box2__JXnPe {
  width: 70%;
  padding: 3%;
  border: 1px solid #c1c1c1;
}
.Review_modalContainer2__2Ctwh {
  width: 40%;
  height: 50%;
  flex-direction: column;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: white;
}
.Review_reasonButton__1Evfl {
  padding: 6px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  color: black;
  font-weight: 500;
  border: none;
  min-width: 125px;
  font-weight: 400;

  border: 0.5px solid #dedede;
  color: black;
  background-color: white;

  margin: 1%;
}

.Review_reasonButton1__Kl48- {
  padding: 6px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  color: black;
  font-weight: 500;
  border: none;
  min-width: 125px;
  font-weight: 400;

  border: 0.5px solid #dedede;
  color: black;
  background-color: white;

  margin: 1%;
}

.Review_boardImage__358Vq {
  width: 100%;
  height: 100%;
}

.Review_boardImage1__6DiNz {
  max-width: 70%;
  height: 30%;
  /* height: 250px; */
}
.Review_shareLink__2hXpe {
  /* position: relative;
  width: 550px;
  height: 35px;
  display: inline-flex;
  border-radius: 20px;
  border: 1px solid #f0f0f0;
  padding: 5px;
  padding-left: 20px; */
}
.Review_modalHeader__1VY6J {
  /* background-color: #141414; */
  /* height: 67px; */
  /* color: #ffffff; */
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
}
.Review_back__33XqT {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/thank-you/thanksmodal.png');
  height: 100%;
}

.Review_modalHeader__1VY6J h4 {
  font-size: 15px;
  margin-top: 25px !important;
  margin-left: 25px !important;
  display: inline-flex;
}
.Review_thanksText__3NLHz {
  font-size: 24px;
  font-family: 'QuickSand';
  color: black;
}

.Review_heart__ECwov {
  font-size: 25px;
  color: red;
}

.Review_text__IU3P5 {
  border: 3px solid white;
  color: white;
  font-size: 8px;
  padding: 5px 5px;
}

.Review_cartButton1__2uQsB {
  width: 50%;
  height: 52px;
  border-radius: 3px;
  margin-top: 3vh;
  border: 0.5px solid #dedede;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: black;
  margin-bottom: 40px;
}

.Review_button__2kliu {
  margin-bottom: 50px;
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  padding: 6px;
  font-weight: 400;
  padding-left: 25px;
  margin-top: 30px;
  padding-right: 25px;
}

.Review_button__2kliu:hover {
  font-weight: 900;
}
.Review_buttonReason__2rEKL {
  margin-bottom: 50px;
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  padding: 6px;
  font-weight: 400;
  padding-left: 25px;
  margin-top: 30px;
  padding-right: 25px;
}

.Review_cartButton__3KTgG {
  /* margin-left: 30px;
  margin-right: 50px; */
  width: 100%;
  background-color: #000000 !important;
  border-color: black;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 1px;
  cursor: pointer;
  border-radius: 2px;
}

.Review_heart__ECwov {
  box-shadow: 1px 1px 2px black;
  border-radius: 50%;
  cursor: pointer;
  height: 41px;
  width: 41px;
  background-image: url('https://res.cloudinary.com/lab10/image/upload/v1621063036/samples/heart-edit_lx314x.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 2900%;
}

.Review_heart__ECwov:hover {
  background-position: right;
}
.Review_modalRow_delete__1kKE0 {
  background-color: #f2feff;
  height: 399px;
  max-height: 100%;
  padding-top: 80px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.Review_modalRow_delete__1kKE0 h4 {
  font-weight: 800;
  width: 40%;
  text-align: center;
}
.Review_deleteConfirm__1wfXo {
  font-size: 24px;
  font-family: 'Quicksand';
  font-weight: 800;
  padding: 10px;
}

.Review_text1__2EztU {
  width: '100%';
  height: '15vh';
  margin-top: '20px';
}

.Review_buttonWhite__3pLLa {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: black;
  background-color: white;
  padding: 5px 10px 5px 10px;
  font-weight: 900;
  height: 40px;
  width: 100%;
}

.Review_buttonBlack__24UPv {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: white;
  background-color: black;
  padding: 5px 10px 5px 10px;
  font-weight: 900;
  height: 40px;
  width: 100%;
}

.Review_buttonBlack2__2URHQ {
  border: 0.5px solid #000;
  color: white;
  background-color: black;
  height: 40px;
  font-size: 16px;
  text-align: center;
  margin: 10px;
  border-radius: 30px;
  width: 50%;
}

.Review_containerText__MEMRY {
  position: relative;
}
.Review_containerText__MEMRY textarea {
  display: block;
  width: 100% !important;
  resize: vertical;
  color: #000000;
}

.Review_containerText__MEMRY .Review_microphoneIco1__1NHiW {
  /* padding: 10px 20px; */
  font-size: 20px;
  position: absolute;
  right: 20px;
  top: 10px; /* <==== */
}

.Review_containerText__MEMRY .Review_Trash__1Psyd {
  /* padding: 10px 20px; */
  font-size: 20px;
  position: absolute;
  right: 40px;
  top: 10px; /* <==== */
}
.Review_containerText__MEMRY .Review_textField__2HZtw {
  display: block;
  width: 100% !important;
  resize: vertical;
}
.Review_containerText__MEMRY .Review_audio__2MGqj {
  padding: 10px 20px;
  font-size: 20px;
  position: absolute;
  right: 55px;
  top: -5px; /* <==== */
}

.Review_wishlistButton__lJ7AS {
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 800;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  margin-bottom: 50px;
}

.Review_audioContainer__Ao5Tj {
  width: 35vw;
  /* height: 70vh; */
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding-bottom: 10px;
}
@-webkit-keyframes Review_heart-burst__1A1ic {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}
@keyframes Review_heart-burst__1A1ic {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

.Review_modalContainer__2CKHN {
  width: 35vw;
  /* height: 70vh; */
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding-bottom: 10px;
  outline: none;
}
.Review_modalContainer__2CKHN p {
  margin-bottom: 0px !important;
}

.Review_saveExplore__S-2In {
  position: absolute;
  bottom: 0px;
  margin-left: 131px;
  margin-bottom: 10px;
  height: 25px;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 25px;
  padding-bottom: 25px;
  border: none;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  background-color: rgb(255, 255, 255);
  width: 21%;
}
.Review_cancelBtn__1G3fC {
  width: 128px;
  height: 35px;
  border: 1px solid #1a1818;
  background-color: transparent;
  border-radius: 20px;
}

.Review_content__2Qclr {
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in-out;
  opacity: 0;
  border: 1px solid black;
}

.Review_content__2Qclr div {
  opacity: 0;
  transition-delay: 1s;
  transition: 0.3s ease;
  font-size: 20px;
}

.Review_item__3q_Pr:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}
.Review_item__3q_Pr:hover .Review_content__2Qclr {
  opacity: 1;
}
.Review_item__3q_Pr:hover .Review_content__2Qclr div {
  opacity: 1;
  color: #f9fafb;
}

.Review_item__3q_Pr {
  /* display: inline-block; */
  width: 70%;
  text-align: center;
  margin: 0.5rem auto;
  position: relative;
  border: 1px solid #f9fafb;
  border-radius: 2px;
  transition: 0.3s ease;
  font-size: 0;
  margin-left: 15px;
  margin-right: 15px;
}
.Review_deleteBtn__qeZxJ {
  width: 128px;
  height: 35px;
  border: 1px solid #1a1818;
  background-color: #000000;
  color: #ffffff;
  font-weight: 500;
  border-radius: 20px;
}

.Review_saveBtn__alyGB {
  float: right;
  border-radius: 20px;
  opacity: 1;
  border: none;
  background-color: #ffffff;
  margin-top: 20px;
  margin-right: 10px;
  font-size: 14px;
  font-family: 'QuickSand';
  padding: 5px 15px 5px 15px;
}

.Review_link__2BC6R {
  font-size: 16px;
}

.Review_deleteInfo__nLYDF {
  font-size: 20px;
  font-family: 'Quicksand';
  font-weight: 600;
  padding: 10px;
}
.Review_modal__1ECSw {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.Review_modal1__2M5yt {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.Review_modalBody__3zjof {
  background-color: 'white';
}
.Review_thanks__38IF7 {
  /* width: 40%; */
  height: 65%;
  background-color: white;
}

.Review_textAreabox__1fOUw {
  width: 100%;
  padding: 10px;
  border-style: ridge;
  justify-content: center;
}
.Review_textArea1__3Fr4n {
  width: 100%;
  padding: '3%';
  border-style: 'ridge';
}

.Review_drawerBackground__22AUU {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/coming-soon/coming-soon3.png');
  /* height: 200px; */
}

.Review_upgradeText__2jjuY {
  color: black;
  font-family: 'QuickSand';
  font-size: 29px;
  text-align: center;
}

.Review_packageTitle__uJs7w {
  font-family: 'QuickSand';
  font-size: 35px;
  font-weight: bold;
  -webkit-text-decoration-line: 'underline';
          text-decoration-line: 'underline';
  text-decoration-thickness: 'from-font';
}

.Review_packageSubTitle__2mboW {
  font-family: 'QuickSand';
  font-size: 25px;
  /* font-weight: bold; */
}
.Review_packageDesc__2nAq4 {
  font-family: 'QuickSand';
  font-size: 14px;
}
.Review_packageFeatures__1xNgW {
  font-family: 'QuickSand';
  font-size: 13px;
}
.Review_black__mZRsh {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_3158_wxbffq.png');
  color: white;
  height: 20%;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  padding-top: 10px;
  /* padding-bottom: 5%; */
}

.Review_padd__JFBTR {
  /* padding-top: 10px;
  padding-bottom: 10px; */
}

@media only screen and (max-width: 500px) {
  .Review_modalContainer__2CKHN {
    width: 80%;
    /* height: 70vh; */
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    padding: 10px;
    outline: none;
  }
  .Review_deleteConfirm__1wfXo {
    font-size: 16px;
    font-family: 'Quicksand';
    font-weight: 800;
    padding: 10px;
  }

  .Review_black__mZRsh {
    background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_3158_wxbffq.png');
    color: white;
    height: 20%;
    text-align: center;
    font-size: 12px;
    /* font-weight: 800; */
    padding-top: 10px;
    /* padding-bottom: 5%; */
  }
  .Review_padd__JFBTR {
    width: 30px;
  }
  .Review_thanks__38IF7 {
    font-size: 20px;
    padding-left: 1%;

    text-align: center;
  }
  .Review_email__3xaZa {
    font-size: 14px;
    padding-left: 1%;

    text-align: center;
  }

  .Review_total1__397m4 {
    min-height: 55px;
    background: #def8ff;
    font-size: 14px;
    color: #000000;
    text-align: center;
    margin: 10px;
    border-radius: 30px;
    width: 100%;
  }
  .Review_totalnew__2PTXZ {
    min-height: 40px;
    background: #def8ff;
    font-size: 14px;
    color: #000000;
    text-align: start;
    margin: 10px;
    width: 100%;
    padding-left: 5%;
  }

  .Review_buttonBlack2__2URHQ {
    border: 0.5px solid #000;
    color: white;
    background-color: black;
    height: 40px;
    font-size: 14px;
    text-align: center;
    margin: 10px;
    border-radius: 30px;
    width: 50%;
  }

  .Review_deleteInfo__nLYDF {
    font-size: 16px;
    font-family: 'Quicksand';
    font-weight: 600;
    padding: 10px;
  }
  .Review_textBox__7EM2X {
    color: black;
    font-size: 14px;
    font-family: 'QuickSand';
    text-align: center;
  }
  .Review_modalContainer1__3eCf5 {
    width: 80%;
    height: 75%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
  }
  .Review_modalContainer3__2VSzX {
    width: 80%;
    /* height: 75%; */
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
  }
  .Review_thanks__38IF7 {
    height: 65%;
    background-color: white;
  }

  .Review_boardImage__358Vq {
    width: 80%;
    height: 100%;
  }
  .Review_reasonButton__1Evfl {
    /* width: 50%;
  height: 52px; */
    border-radius: 20px;
    margin-top: 3%;
    border: 0.5px solid #dedede;
    color: black;
    background-color: white;
    padding: 2%;
    margin: 2%;
    font-size: 12px;
  }

  .Review_reasonButton1__Kl48- {
    border-radius: 20px;
    margin-top: 3%;
    border: 0.5px solid #dedede;
    color: black;
    background-color: white;
    padding: 2%;
    margin: 2%;
    font-size: 12px;
  }
  .Review_box1__1NEYL {
    width: 80%;
    padding: 3%;
    border: 1px solid #c1c1c1;
  }
  .Review_box2__JXnPe {
    width: 90%;
    padding: 10%;
    border: 1px solid #c1c1c1;
  }

  .Review_textAreabox__1fOUw {
    width: 80%;
    padding: 3%;
    border-style: ridge;
    justify-content: center;
  }
  .Review_container__3npvR {
    background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_3158_kwoclf.png');
    background-position: right;
    background-color: #d0ecf4;
    background-repeat: no-repeat;
    background-size: contain;
    /* height: 30vh; */
    border-radius: 20px;
    /* padding-left: 10px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 10px; */
    /* margin: 5%; */
    font-size: 14px;
    padding: 20px;
  }
  .Review_btn1__2hSc5 {
    background-color: #000000;
    color: #ffffff;
    padding: 8px;
    font-weight: 400;
    padding-left: 20px;
    margin-top: 30px;
    padding-right: 20px;
    width: 50%;
  }
  .Review_shopButton__2dHBL {
    /* padding: 12px 43px, 12px, 43px; */
    border-radius: 30px;
  }
  .Review_aligncenter__3GqRR {
    text-align: center;
  }
}

.Shop_bannerCarousel__3pJA2 {
  height: auto;
  max-width: 100%;
  /* background-image: url("https://d3izcfql7lesks.cloudfront.net/Group_3159_obwbmr.png"); */
  background-size: 100%;
  background-repeat: no-repeat;

  background-size: 100%;
  overflow-x: hidden;
}

.Shop_container3__3lgXR {
  width: 100%;
  background: #f4eae6 0% 0% no-repeat padding-box;
  height: auto;
  margin-top: 5px;
  margin-bottom: 10px;
  position: relative;
}
/* .container3::after{
  height:50px;
  width:50px;
  transform:rotate(45deg);
  content:'';
position:absolute;
background: #F4EAE6;
left:700px;
z-index: 1;
top:520px
  } */
.Shop_bestBorder__1gEyy {
  bottom: 0px;
  padding: 16px 20px;
  position: absolute;
  width: 100%;
  height: 88px;
  line-height: 26px;
  font-size: 19px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
  bottom: 0px;
}
/* .expandable {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  width: 110%;
  right: 0;
  left: -5%;
  padding: 0 30px 20px 30px;
  background: #fff;
  z-index: 1002;
  box-shadow: 0 -20px 0 white, 0 2px 16px 0 rgba(0, 0, 0, 0.06);
}
.productWrapper {
  overflow: hidden;
} */

/* .productWrapper::before {
  display: block;
  background: transparent;
  position: absolute;
  opacity: 0;
  z-index: 1001;
  
}
.productWrapper :hover {
  overflow: visible;
}
.productWrapper ::before {
  background: #fff;
  opacity: 1;
}
*:not(.expandable) {
  position: relative;
  z-index: 1002;
}
.expandable {
  opacity: 1;
  visibility: visible;
} */

.Shop_mainContainer__1D7II {
  /* margin-top: 10vh; */
  max-width: 80% !important;
  background-color: white;
  margin-top: 80px;
}

.Shop_dropDown__10SgQ {
  background-color: #f5f0ec;
  border-radius: 20px;
  width: 224px;
  color: black;
}

.Shop_shareLink__dy5ON {
  position: relative;
  width: 550px;
  height: 35px;
  display: inline-flex;
  border-radius: 20px;
  border: 1px solid #f0f0f0;
  padding: 5px;
  padding-left: 20px;
}

.Shop_filterButton__2FAC7 {
  width: 86px;
  height: 32px;
  padding: 0px;
  border-radius: 50px;
  margin-left: 10px;
  background-color: #f7f7f7;
  text-align: left;
  border-color: #f7f7f7;
}

.Shop_listButton__2j-54 {
  background-color: #f7f7f7;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 6px 13px;
}

.Shop_listButton1__Tqqj4 {
  /* background-color: pink; */
  font-weight: bold;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 6px;
}

.Shop_filterHead__Tc0yK {
  font-size: 16px;
  font-family: 'Quicksand';
  font-weight: bold;
}

.Shop_searchInput__32w_Z {
  width: 300px;
  border: none;
  font-size: 15px;
  opacity: 0.7;
  background-color: white;
  border-bottom: 0.5px solid #707070;
  margin-top: 30px;
}

/* .renderColumn_container {
    height: 150px;
    width: 150px;
    border-radius: 3px;
    margin-top: 3vh;
    border: 0.5px solid #000000;
  } */

.Shop_renderColumn_container__32m-L {
  width: 268px;
  height: 100%;
  border-radius: 10px;
  border: 3px solid rgba(163, 163, 163, 0.2);
}
.Shop_star__1cDua {
  font-size: 25px;
  color: gold;
}

.Shop_pillCircle__1xJ5c {
  width: 80px;
  height: auto;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  font-family: 'Quicksand';
  /* border: 2px solid #3498db; */
  color: white;
  background: #f03f3f;
  margin: 0 auto;
  z-index: 1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  line-height: 40px;
  padding: 0px;
  /* position: absolute; */
}

.Shop_heart__2Y1yf {
  font-size: 25px;
  color: red;
}
* {
  padding: 0px;
  margin: 0px;
  /* overflow-x: hidden; */
}

/* .img {
    object-fit: cover;
    object-position: top;
    display: block;
    height: 40;
    width: 40;
    padding-top: 20px;
    border: solid;
    transition: 0.5s ease;
  } */

.Shop_cardcontainer1__RMOkR {
  position: relative;
}
.Shop_container1__1LAwF {
  position: relative;
}

.Shop_middle__1iiwC {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  height: 20;
  padding: 0%;
  color: 'black';
}

.Shop_container1__1LAwF:hover {
  opacity: 0.5;
  z-index: 10;
}
.Shop_img1__wQJ9g :hover {
  background-color: #707070;
}
.Shop_card1__1t3yT {
  width: '17rem';
  height: 'auto';
  border-radius: '10px';
}
.Shop_card__2dicL :hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
.Shop_container1__1LAwF:hover .Shop_middle__1iiwC {
  opacity: 1;
  position: absolute;
}
.Shop_hovered__1jnjm {
  /* position: absolute; */
  z-index: 1;
  /* display: block; */
  /* opacity: ; */
}

.Shop_text__3PHws {
  border: 3px solid white;
  color: white;
  font-size: 8px;
  padding: 5px 5px 5px 5px;
}

.Shop_cartButton1__2ypth {
  width: 50%;
  height: 52px;
  border-radius: 3px;
  margin-top: 3vh;
  border: 0.5px solid #dedede;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: black;
  margin-bottom: 40px;
}

.Shop_nav_container__3zSBT {
  max-width: 100%;
  height: 50px;
  background-color: #f2feff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 10px; */
}
.Shop_shop_room__3Tn4p {
  padding-left: 18px;
}
.Shop_button__2RtVl {
  background-color: #000000 !important;
  /* padding: 20px 30px 20px 30px; */
  border: solid;
  /* border-radius: 30px; */
  font-weight: 800;
  width: 100%;
}

.Shop_button1__3T0n6 {
  padding: 20px 30px 20px 30px;
  border: groove;
  border-radius: 30px;
  font-weight: 800;
  width: 100%;
}

.Shop_cartButton__Tp3Q9 {
  /* margin-left: 30px;
  margin-right: 50px;
  width: 80%;
  color: #000000 !important;
  font-weight: 400;
  margin-bottom: 1px;
  position: absolute;
  bottom: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border: 0.5px solid #dedede;
  border-radius: 2px;
  opacity: 1; */
  margin-left: auto;
  margin-right: auto;
  width: 55%;
  left: 0px;
  right: 0px;
  color: #000000 !important;
  font-weight: 400;
  margin-bottom: 1px;
  position: absolute;
  bottom: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border: 0.5px solid #dedede;
  border-radius: 2px;
  opacity: 1;
  justify-content: center;
  font-size: 15px;
}
.Shop_cartButton__Tp3Q9:hover,
.Shop_cartButton__Tp3Q9:active,
.Shop_cartButton__Tp3Q9:focus {
  box-shadow: black;
  border: 0.5px solid #dedede !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
}

.Shop_buyNowButton__1mpIP {
  margin-left: auto;
  margin-right: auto;
  width: 55%;
  left: 0px;
  right: 0px;
  color: #ffffff !important;
  font-weight: 400;
  margin-bottom: 1px;
  position: absolute;
  bottom: 0px;
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #ffffff14;
  border: 0.5px solid #dedede;
  border-radius: 2px;
  opacity: 1;
  justify-content: center;
  font-size: 15px;
}

.Shop_buyNowButton__1mpIP:hover,
.Shop_buyNowButton__1mpIP:active,
.Shop_buyNowButton__1mpIP:focus {
  box-shadow: black;
  border: 0.5px solid #dedede !important;
  background: #000000 0% 0% no-repeat padding-box !important;
}

.Shop_similarButton__1MOol {
  margin-left: 30px;
  margin-right: 50px;
  width: 80%;
  font-weight: 800;
  text-align: center;
  background-color: white;
  border: 1px solid;
  border-color: black;
  border-radius: 0px;
}

.Shop_similarButton1__1v54F {
  width: 85%;
  /* top: -15px; */
  font-weight: 400;
  text-align: center;
  background: #f4eae6 0% 0% no-repeat padding-box;
  border: 0.2px solid #000000;
  opacity: 1;
  bottom: -10px;
  font: normal normal 600 12px/35px 'Quicksand';
  letter-spacing: 0px;
  color: #272727;
  cursor: pointer;
  border-radius: 2px;
  position: absolute;
  /* margin-left: 10px; */
  z-index: 100;
  left: 0;
  right: 0;
  margin: 0px auto;
}
.Shop_similarButton2__1VvN1 {
  width: 40vh;
  /* top: -15px; */
  font-weight: 400;
  text-align: center;
  background-color: #f4eae6;
  border: 0.5px solid #000000;
  /* opacity: 1; */
  bottom: -43px;
  /* font: normal normal 600 12px/35px 'Quicksand'; */
  letter-spacing: 0px;
  color: #272727;
  cursor: pointer;
  border-radius: 2px;
  position: absolute;
  /* margin-left: 10px; */
  z-index: 1;
  left: -10;
  right: 0;
  font-size: 13px;
  padding: 6px 0px;
}
.Shop_drawerContainer__1ZKsw {
  width: 280px;
}
.Shop_heart__2Y1yf {
  box-shadow: 0px 1px 4px rgb(138 121 121 / 92%);
  border-radius: 50%;
  cursor: pointer;
  height: 41px;
  width: 41px;
  background-image: url('https://res.cloudinary.com/lab10/image/upload/v1621063036/samples/heart-edit_lx314x.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 2900%;
}

.Shop_heart__2Y1yf:hover {
  background-position: right;
}

.Shop_is_animating__Sn5NZ {
  -webkit-animation: Shop_heart-burst__1TBFS 0.8s steps(28) 1;
          animation: Shop_heart-burst__1TBFS 0.8s steps(28) 1;
}

.Shop_overlay__2gPHQ {
  position: absolute;
  width: 100%;
  height: 200px;
  z-index: 1;
  bottom: 0;
  left: 0;
  /* background-color: rgb(0 0 0 / 60%); */
  justify-content: center !important;
  align-items: center !important;
}

.Shop_buttonWhite__eSqMr {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: black;
  background-color: white;
  padding: 5px 10px 5px 10px;
  font-weight: 900;
  height: 40px;
  width: 100%;
}

.Shop_buttonBlack__pPXsh {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: white;
  background-color: black;
  padding: 5px 10px 5px 10px;
  font-weight: 900;
  height: 40px;
  width: 100%;
}

.Shop_pill__2Oldj {
  background-color: #ddd;
  border: none;
  color: black;
  /* padding: 10px 20px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* margin: 4px 2px; */
  cursor: pointer;
  border-radius: 16px;
}
.Shop_bannerGet__JiSFj {
  width: 96%;
  cursor: pointer;
}
@-webkit-keyframes Shop_heart-burst__1TBFS {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}
@keyframes Shop_heart-burst__1TBFS {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

.Shop_modalContainer__1JJff p {
  margin-bottom: 0px !important;
}

.Shop_saveExplore__3iq8B {
  position: absolute;
  bottom: 0px;
  margin-left: 131px;
  margin-bottom: 10px;
  height: 25px;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 25px;
  padding-bottom: 25px;
  border: none;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  background-color: rgb(255, 255, 255);
  width: 21%;
}

.Shop_copyLinkBtn__2b6VU {
  float: right;
  position: absolute;
  width: 20%;
  right: 0;
  top: 0;
  height: 35px;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
}
.Shop_link__1HjCX {
  font-size: 16px;
}
.Shop_bestSell__2IUro {
  font-family: Quicksand;
  font-size: 20px;
  padding-right: 20px;
  /* margin-top: 100px; */
  text-transform: uppercase;
  font-weight: 6400;
}
.Shop_bestBuy__371Ad {
  width: 100%;
  position: relative;
}
.Shop_bestBuy__371Ad img {
  width: 100%;
}
.Shop_bestRoom__6rOSF {
  font-family: Quicksand;
  font-size: 18px;
  padding-left: 15px;
  text-transform: capitalize;
  font-weight: 400;
  border-left: 1px solid rgb(0, 0, 0);
}
.Shop_txtoverimg__1xlU9 {
  position: absolute;
  top: 60px;
  -webkit-transform: translate(-50%, -50%);
  transform: none;
  font-family: 'quicksand';
  font-size: 24px;
  width: 57%;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
}
.Shop_txtoverimgright__2gZbE {
  position: absolute;
  bottom: 0;
  /* left: 0; */
  right: 0;
  top: 0;
  margin: auto 0;
  width: 41%;
  height: 140px;
  float: right;
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: none; */
  font-family: 'quicksand';
  font-size: 29px;
  font-weight: 500;
  /* text-align: initial; */
  /* position: absolute; */
  /* left: 0; */
  /* right: 0; */
  /* top: 0; */
  /* bottom: 0; */
  /* margin: auto; */
  text-align: left;
}
.Shop_txtoverimgrightlist___sCgQ {
  font-weight: 500;
  position: absolute;
  top: 71px;
  bottom: 0px;
  right: 45px;
  margin-top: 0;
  margin-bottom: 0;
  width: 33%;
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  font-family: 'quicksand';
  font-size: 26px;
  text-align: initial;
}
.Shop_imgtxtcontainer__1B5Cp {
  position: relative;
  text-align: center;
}
.Shop_txtoverimgrightlist1__2MHzT {
  top: 71px;
  right: 45px;
  margin-top: 0;
  margin-bottom: 0;
  width: 33%;
  font-size: 26px;
  text-align: initial;
  position: absolute;
}
@media only screen and (max-width: 600px) {
  .Shop_txtoverimgrightlist1__2MHzT {
    top: 47px;
    left: auto;
    -webkit-transform: inherit;
            transform: inherit;
    width: 42%;
    float: right;
    right: 16px;
    text-align: center;
  }
  .Shop_mainContainer__1D7II {
    max-width: 100% !important;
    width: 100% !important;
    padding: 0px !important;
    /* margin: 0px !important; */
    margin-top: 73px;
  }
  .Shop_renderColumn_container__32m-L {
    width: 162px;
    height: 265px;
  }
  .Shop_renderColumn_container__32m-L img {
    margin-top: 0px;
    width: 30%;
  }
  .Shop_searchInput__32w_Z {
    width: 90%;
    font-size: 14px;
  }
  .Shop_imgtxtcontainer__1B5Cp {
    margin-bottom: 30px !important;
  }
  .Shop_shareLink__dy5ON {
    position: relative;
    width: 100%;
    height: 35px;
    display: inline-flex;
    border-radius: 20px;
    border: 1px solid #f0f0f0;
    padding: 5px;
    padding-left: 10px;
    padding-top: 10px;
  }

  .Shop_saveExplore__3iq8B {
    position: absolute;
    bottom: 0px;
    margin-left: 131px;
    margin-bottom: 10px;
    height: 25px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 25px;
    padding-bottom: 25px;
    border: none;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    background-color: rgb(255, 255, 255);
    width: 21%;
  }

  .Shop_copyLinkBtn__2b6VU {
    float: right;
    position: absolute;
    width: 20%;
    right: 0;
    top: 0;
    height: 35px;
    color: #ffffff;
    border-radius: 20px;
    background-color: black;
    font-size: 12px;
  }
  .Shop_link__1HjCX {
    font-size: 11px;
  }
  .Shop_txtoverimgrightlist___sCgQ {
    position: absolute;
    top: 50px;
    left: auto;
    -webkit-transform: translate(-50%, -50%);
    transform: inherit;
    font-family: 'quicksand';
    font-size: 17px;
    width: 50%;
    float: right;
    right: 10px;
    text-align: center;
    height: -webkit-max-content;
    height: max-content;
  }
  .Shop_txtoverimg__1xlU9 {
    position: absolute;
    top: 7px;
    -webkit-transform: translate(-50%, -50%);
    transform: initial;
    font-family: 'quicksand';
    font-size: 17px;
    bottom: 0px;
    width: 100%;
    right: 0px;
    left: 0px;
    padding: 19px 50px;
  }
  .Shop_txtoverimgright__2gZbE {
    top: 86px;
    font-size: 13px;
    text-align: initial;
    -webkit-transform: inherit;
            transform: inherit;
    left: auto;
    right: 7px;
    bottom: 0px;
    padding: 0px 11px;
    width: 45%;
    text-align: center;
  }
  .Shop_bestBorder__1gEyy {
    bottom: 0px;
    padding: 24px 11px;
    position: absolute;
    margin: 0px auto;
    width: 100%;
    left: 0px;
    right: 0px;
    height: 76px;
    line-height: 22px;
    font-size: 16px !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
  }
  .Shop_bestSell__2IUro {
    font-family: Quicksand;
    font-size: 18px !important;
    padding-right: 16px !important;
  }
  .Shop_bestRoom__6rOSF {
    font-size: 18px !important;
  }
  .Shop_cartButton__Tp3Q9 {
    width: 100%;
    color: #000000 !important;
    font-weight: 400;
    margin-bottom: 1px;
    /* position: absolute; */
    position: relative;
    margin: 0px auto;
    padding: 4px 0px;
    text-transform: uppercase;
    font-size: 13px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    border: 0.5px solid #dedede;
    border-radius: 2px;
    opacity: 1;
    margin-top: 5px;
    font-weight: 600;
    bottom: 0;
    /* margin-left: 12px; */
  }

  .Shop_buyNowButton__1mpIP {
    width: 100%;
    font-weight: 400;
    margin-bottom: 1px;
    /* position: absolute; */
    position: relative;
    margin: 0px auto;
    padding: 4px 0px;
    text-transform: uppercase;
    font-size: 13px;
    box-shadow: 0px 3px 6px #00000014;
    border: 0.5px solid #dedede;
    border-radius: 2px;
    opacity: 1;
    margin-top: 5px;
    font-weight: 600;
    bottom: 0;
  }
  .Shop_bestSell__2IUro {
    font-family: Quicksand;
    font-size: 18px !important;
    padding-right: 16px !important;
  }
  .Shop_bestRoom__6rOSF {
    font-size: 18px !important;
  }
}

.Shop_sort__3gAyR {
  font-weight: 600;
  font-size: 16px;
  margin: 0px;
  margin-right: 11px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.Shop_selectSort__3ha4R {
  width: 85%;
  border-radius: 20px;
  color: rgb(0, 0, 0);
  /* font-size: 14px; */
  /* height: 32px; */
  font-family: quicksand;
  background-color: white;
  /* padding: 0px 13px; */
  float: right;
}

.Shop_displayrow__2JPG1 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.Shop_brands__33zDg {
  font-family: 'Quicksand' sans-serif;
  font-size: '20px';
  margin-bottom: '39px';
  text-align: 'center';
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .Shop_cartButton__Tp3Q9 {
    width: 53%;
    font-size: 14px;
  }
  .Shop_buyNowButton__1mpIP {
    width: 53%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .Shop_similarButton2__1VvN1 {
    width: 100%;
    left: -64%;
    right: 0;
    font-size: 12px;
    margin: 0px auto;
  }
}

body {
  background: #fff;
}

.card.product .btn {
  bottom: 1.5rem;
  max-width: 80%;
}

.price {
  font-size: 1.75rem;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  /* background-color: black ;
  border-color: black ; */
  color: black;
  text-decoration: underline;
}
.pagination > li > a {
  /* border: 1px solid black ; */
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: black;
  border-color: black;
  outline: none;
  color: white;
}
.pagination > li > a,
.pagination > li > span {
  color: black;
  border-radius: 20px;
  margin-right: 5px;
  padding: '10px';
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  /* border: 1px solid black;
  border-radius: 20px; */
}

@media (max-width: 768px) {
  .cardContainer {
    margin-bottom: 2rem;
  }
}

/* JSFiddle layout display fix only, don't use */
body {
  min-height: 620px;
}

.star {
  font-size: 25px;
  color: gold;
}

.heart {
  font-size: 25px;
  color: red;
}
.tagname {
  font-size: 16px;
  margin: 0px auto;
  margin-top: 6px;
  width: 90%;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .tagname {
    font-size: 12px;
    width: 90%;
    margin: 0px auto;
    margin-top: 6px;
  }
}
.hr {
  border-top: 0.5px solid #707070;
  padding-top: 20px;
  width: 60%;
}
.styledbtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 2px;
  opacity: 1;
}

.Shop_bannerContainer__1Wk0D {
  /* background-image: url("https://pixel-mortar.s3.ap-south-1.amazonaws.com/Mask+Group+54.png"); */
  height: 55vh;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
}

.Shop_bannerContainerMask__3s9zn {
  position: absolute;
  background: rgba(35, 36, 35, 80%);
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  /* filter: blur(1px);
    -webkit-filter: blur(1px) */
}

.Shop_bannerButton__9Ndnv {
  padding: 6px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-top: 15px;
  border-radius: 25px;
  color: black;
  font-weight: 600;
  border: none;
  width: 155px;
  border: 0.5px solid #dedede;
  color: black;
  background-color: #f5f0ec;
}

.Shop_bannerCarousel__Il2F7 {
  height: auto;
  max-width: 100%;
  /* background-image: url("https://d3izcfql7lesks.cloudfront.net/Group_3159_obwbmr.png"); */
  background-size: 100%;
  background-repeat: no-repeat;

  background-size: 100%;
  overflow-x: hidden;
}

.Shop_cardBorder__3I2-8 {
  border: '1px solid';
}

.Shop_hovered1__321y2 {
  height: 200px;
  width: 200px;
}

.Shop_back__3q7lo {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/coming-soon/coming-soon3.png');
  /* height: 200px; */
}
.Shop_content__3tWFc {
  color: black;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  /* background-image: url("https://d3izcfql7lesks.cloudfront.net/Group_3158_wxbffq.png"); */
  /* height: 200px; */
}

.Shop_productOnline__10oBZ {
  margin-top: 6px;
  width: 9px;
  height: 10px;
  border-radius: 6px;
  background-color: #3aff0b;
}
.Shop_numberOne__2BOZS {
  margin-bottom: -13px;
  margin-right: 9px;
  z-index: 10;
  width: 22px;
  border-radius: 12px;
  background-color: white;
  text-align: center;
  height: 23px;
  padding: 2px;
}
.Shop_mainContainer__267kq {
  max-width: 80% !important;
  background-color: white;
  margin-top: 80px;
  margin-right: 5px;
  margin: 0px auto;
}
.Shop_mainContainer1__3YzIO {
  max-width: 80% !important;
  background-color: white;
  margin-top: 30px;
  margin-right: 5px;
}
.Shop_dropDown__1foZ5 {
  background-color: #f5f0ec;
  border-radius: 20px;
  width: 224px;
  color: black;
}

.Shop_shareLink__1KC0k {
  position: relative;
  width: 550px;
  height: 35px;
  display: inline-flex;
  border-radius: 20px;
  border: 1px solid #f0f0f0;
  padding: 5px;
  padding-left: 20px;
}

.Shop_container3__1sPqh {
  width: 100%;
  background: #f4eae6 0% 0% no-repeat padding-box;
  height: auto;
  margin-top: 30px;
}
.Shop_container3__1sPqh::after {
  height: 50px;
  width: 50px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  content: '';
  position: absolute;
  background: #f4eae6;
  left: 700px;
  z-index: 1;
  top: 520px;
}

.Shop_shopButton__Oo-IR {
  background-color: black;
  color: white;
  border-radius: 30px;
  padding: 3px 10px 2px 10px;
  border: none;
}

.Shop_cardHoverScale__17Umm {
  transition: all 0.3s ease-in-out;
}
/* .cardHoverScale:hover{
  transform: scale(1.1);
  filter:drop-shadow(0px 3px 6px rgb(0,0,0,16%));
} */
.Shop_overlay__2EyH4 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  bottom: 0;
  /* background-color: rgb(0 0 0 / 30%); */
  justify-content: center !important;
  align-items: center !important;
}
.Shop_overlay1__3xH_j {
  /* background-color: #2c2c2cb5;
  justify-content: center !important;
  align-items: center !important; */
  opacity: 0.4;
  filter: alpha(opacity=40);
  background-color: #2c2c2cb5;
}
.Shop_listButton1__2knyz {
  /* background-color: pink; */
  font-weight: bold;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 6px;
}

.Shop_filterButton__3-bZA {
  width: 86px;
  height: 32px;
  padding: 0px;
  border-radius: 50px;
  margin-left: 10px;
  background-color: #f7f7f7;
  text-align: left;
  border-color: #f7f7f7;
}

.Shop_listButton__2gknW {
  background-color: #f7f7f7;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 6px;
}

.Shop_listButton1__2knyz {
  /* background-color: pink; */
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 6px;
  font-weight: bold;
}

.Shop_filterHead__qbLjD {
  font-size: 16px;
  font-family: 'Quicksand';
  font-weight: bold;
}

.Shop_searchInput__2YgXt {
  width: 300px;
  border: none;
  font-size: 15px;
  opacity: 0.7;
  background-color: white;
  border-bottom: 0.5px solid #707070;
  margin-top: 30px;
  outline: none;
}

/* .renderColumn_container {
      height: 150px;
      width: 150px;
      border-radius: 3px;
      margin-top: 3vh;
      border: 0.5px solid #000000;
    } */

.Shop_renderColumn_container__1OcR5 {
  width: 268px;
  height: 100%;
  border-radius: 10px;
  border: 3px solid rgba(163, 163, 163, 0.2);
}
.Shop_star__fQyZx {
  font-size: 25px;
  color: gold;
}

.Shop_pillCircle__1pT8k {
  width: 80px;
  height: auto;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  font-family: 'Quicksand';
  /* border: 2px solid #3498db; */
  color: white;
  background: #f03f3f;
  margin: 0 auto;
  z-index: 1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  line-height: 40px;
  padding: 0px;
  /* position: absolute; */
}

.Shop_heart__30tfk {
  font-size: 25px;
  color: red;
}

/* .img {
      object-fit: cover;
      object-position: top;
      display: block;
      height: 40;
      width: 40;
      padding-top: 20px;
      border: solid;
      transition: 0.5s ease;
    } */

.Shop_container1__lnfFc {
  position: relative;
}
.Shop_img__26BFQ {
}
.Shop_middle__1hUOx {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  height: 20;
  padding: 0%;
}

.Shop_container1__lnfFc:hover .Shop_img__26BFQ {
  opacity: 0.3;
  /* background-color: Black; */
  /* position: absolute; */
  z-index: 10;
}
.Shop_card1__kL55n {
  width: '17rem';
  height: 'auto';
  border-radius: '10px';
}
.Shop_card__XwW50 :hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
.Shop_container1__lnfFc:hover .Shop_middle__1hUOx {
  opacity: 1;
  position: absolute;
}
.Shop_hovered__8r5-q {
  /* position: absolute; */
  z-index: 1;
  /* display: block; */
  /* opacity: ; */
}

.Shop_text__q3sDv {
  border: 3px solid white;
  color: white;
  font-size: 8px;
  padding: 5px 5px;
}

.Shop_cartButton1__2gXhv {
  width: 50%;
  height: 52px;
  border-radius: 3px;
  margin-top: 3vh;
  border: 0.5px solid #dedede;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: black;
  margin-bottom: 40px;
}
.Shop_nav_container__g6I3R {
  max-width: 100%;
  height: 50px;
  background-color: #f2feff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 10px; */
}

.Shop_button__3s6CO {
  background-color: #000000 !important;
  /* padding: 20px 30px 20px 30px; */
  border: solid;
  /* border-radius: 30px; */
  font-weight: 800;
  width: 100%;
}

.Shop_button1__2X_IT {
  padding: 20px 30px 20px 30px;
  border: groove;
  border-radius: 30px;
  font-weight: 800;
  width: 100%;
}

.Shop_cartButton__IusR5 {
  /* margin-left: 30px;
  margin-right: 50px;
  width: 80%;
  color: #000000 !important;
  font-weight: 400;
  margin-bottom: 1px;
  position: absolute;
  bottom: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border: 0.5px solid #dedede;
  border-radius: 2px;
  opacity: 1; */
  margin-left: auto;
  margin-right: auto;
  width: 55%;
  left: 0px;
  right: 0px;
  color: #000000 !important;
  font-weight: 400;
  margin-bottom: 1px;
  position: absolute;
  /* bottom: 0px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border: 0.5px solid #dedede;
  border-radius: 2px;
  opacity: 1;
  justify-content: center;
  font-size: 15px;
}
.Shop_cartButton__IusR5:hover,
.Shop_cartButton__IusR5:active,
.Shop_cartButton__IusR5:focus {
  box-shadow: black;
  border: 0.5px solid #dedede !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
}

.Shop_cartSimilarButton__1-HpT {
  color: #000000 !important;
  font-weight: 400;
  margin-bottom: 1px;
  /* bottom: 0px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border: 0.5px solid #dedede;
  border-radius: 2px;
  opacity: 1;
  justify-content: center;
  font-size: 15px;
}
.Shop_cartSimilarButton__1-HpT:hover,
.Shop_cartSimilarButton__1-HpT:active,
.Shop_cartSimilarButton__1-HpT:focus {
  box-shadow: black;
  border: 0.5px solid #dedede !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
}

.Shop_similarButton__3C0Dh {
  margin-left: 30px;
  margin-right: 50px;
  width: 80%;
  font-weight: 800;
  text-align: center;
  background-color: white;
  border: 1px solid;
  border-color: black;
  border-radius: 0px;
}

.Shop_similarButton1__3fVI8 {
  /* margin-left: 30px;
  margin-right: 50px; */
  width: 100%;
  margin-top: 100px;
  font-weight: 400;
  text-align: center;
  background: #f4eae6 0% 0% no-repeat padding-box;
  opacity: 1;
  bottom: 0px;
  font: normal normal 600 12px/35px 'Quicksand';
  letter-spacing: 0px;
  color: #272727;
  cursor: pointer;
  border-radius: 2px;
}

.Shop_drawerContainer__2_VUv {
  width: 280px;
}
.Shop_heart__30tfk {
  box-shadow: 1px 1px 2px black;
  border-radius: 50%;
  cursor: pointer;
  height: 41px;
  width: 41px;
  background-image: url('https://res.cloudinary.com/lab10/image/upload/v1621063036/samples/heart-edit_lx314x.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 2900%;
}

.Shop_heart__30tfk:hover {
  background-position: right;
}

.Shop_is_animating__3YMEW {
  -webkit-animation: Shop_heart-burst__3MSRU 0.8s steps(28) 1;
          animation: Shop_heart-burst__3MSRU 0.8s steps(28) 1;
}

.Shop_buttonWhite__3lDj1 {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: black;
  background-color: white;
  padding: 5px 10px 5px 10px;
  font-weight: 900;
  height: 40px;
  width: 100%;
}

.Shop_buttonBlack__1H6pr {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: white;
  background-color: black;
  padding: 5px 10px 5px 10px;
  font-weight: 600;
  height: 40px;
  width: 100%;
  font-size: 14px;
}

.Shop_pill__3-rwD {
  background-color: #ddd;
  border: none;
  color: black;
  /* padding: 10px 20px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* margin: 4px 2px; */
  cursor: pointer;
  border-radius: 16px;
}

@-webkit-keyframes Shop_heart-burst__3MSRU {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

@keyframes Shop_heart-burst__3MSRU {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

.Shop_modalContainer__6SucR p {
  margin-bottom: 0px !important;
}

.Shop_saveExplore__FWRG1 {
  position: absolute;
  bottom: 0px;
  margin-left: 131px;
  margin-bottom: 10px;
  height: 25px;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 25px;
  padding-bottom: 25px;
  border: none;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  background-color: rgb(255, 255, 255);
  width: 21%;
}

.Shop_copyLinkBtn__1KADC {
  float: right;
  position: absolute;
  width: 20%;
  right: 0;
  top: 0;
  height: 35px;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
}
.Shop_link__3oKvm {
  font-size: 16px;
}

@media only screen and (max-width: 500px) {
  .Shop_mainContainer__267kq {
    /* min-width: 100% !important; */
    width: 82% !important;
    padding: 0px !important;
    margin: 0px !important;
    max-width: 100% !important;
    margin: 0px auto !important;
  }
  .Shop_renderColumn_container__1OcR5 {
    width: 162px;
    height: 265px;
  }
  .Shop_renderColumn_container__1OcR5 img {
    margin-top: 0px;
    width: 30%;
  }
  .Shop_searchInput__2YgXt {
    width: 90%;
    font-size: 14px;
  }

  .Shop_shareLink__1KC0k {
    position: relative;
    width: 100%;
    height: 35px;
    display: inline-flex;
    border-radius: 20px;
    border: 1px solid #f0f0f0;
    padding: 5px;
    padding-left: 10px;
    padding-top: 10px;
  }

  .Shop_saveExplore__FWRG1 {
    position: absolute;
    bottom: 0px;
    margin-left: 131px;
    margin-bottom: 10px;
    height: 25px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 25px;
    padding-bottom: 25px;
    border: none;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    background-color: rgb(255, 255, 255);
    width: 21%;
  }

  .Shop_copyLinkBtn__1KADC {
    float: right;
    position: absolute;
    width: 20%;
    right: 0;
    top: 0;
    height: 35px;
    color: #ffffff;
    border-radius: 20px;
    background-color: black;
    font-size: 12px;
  }
  .Shop_link__3oKvm {
    font-size: 11px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .Shop_mainContainer__267kq {
    max-width: 96% !important;
    background-color: white;
    margin-top: 80px;
    margin-right: 5px;
  }
  .Shop_cartButton__IusR5 {
    width: 65%;
    font-size: 12px;
  }
}

@media only screen and (min-width: 310px) and (max-width: 800px) {
  .Shop_mainContainer__267kq {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .Shop_mainContainer__267kq {
    max-width: 96% !important;
    background-color: white;
    margin-top: 80px;
    margin-right: 5px;

    margin: 0px auto;
  }
}

@media only screen and (max-width: 500px) {
  .Shop_similarButton2__32dBb {
    width: 80%;
    left: 13px;
    right: 0;
  }
}

.RoomProfile_sideBar__1o8_Y {
  padding: 2% !important;
  background-color: #f7f7f7;
  /* height: 60vh; */
  height: 100%;
  position: fixed !important;
  left: 0%;
  /* margin-right: 100px; */
  /* margin-top: 3%; */
}

.RoomProfile_attachFileButton__3r0so {
  border-radius: 30px;
  font-size: 15px;
  font-weight: 800;
  border: none;
  color: white;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  background-color: #1a1818;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.RoomProfile_priceButton__NlHRF {
  padding: '5px 10px 5px 10px';
  border: 2px solid #000000;
  /* border-radius: 30px; */
  width: 30%;
}
.RoomProfile_buttonStyle__3WmPS {
  width: 42px;
  height: 41px;
  font-size: 28px;
  background-color: #f5f5f5;
}
.RoomProfile_wholeBtn__3sGrB {
  justify-content: center;
  width: 134px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 37px;
  align-items: center;
  display: flex;
}
.RoomProfile_inputPeople__3qXNz {
  width: 48px;
  height: 31px;
  /* margin-top: 20px; */
  border: none;
  justify-content: center;
}
.RoomProfile_perfectStyle__3Tz1k {
  text-align: center;
  font-size: 25px;
  position: absolute;
  width: 25%;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  z-index: 1;
  top: 26px;
}
.RoomProfile_priceButton1__3Ru8g {
  padding: '5px 10px 5px 10px';
  border: 2px solid #000000;
  /* border-radius: 30px; */
  width: 30%;

  border: 1px solid red;
}

.RoomProfile_priceButton1__3Ru8g:focus {
  border: 2px solid #000000;
}

.RoomProfile_priceButton__NlHRF:focus {
  border: 2px solid #000000;
}

.RoomProfile_wishlistButton__2mSzC {
  padding: 0.8vw 1.5vw;
  border-radius: 30px;
  background-color: #000000;
  color: #f5f0ec;
  font-weight: 400;
}

.RoomProfile_wishlistButton__2mSzC:hover {
  font-weight: 900;
}

.RoomProfile_button__1K4K3 {
  padding: 8px 12px 8px 12px;
  border-radius: 30px;
  background-color: #000000;
  color: #f5f0ec;
  font-weight: 400;
}

.RoomProfile_button__1K4K3:hover {
  font-weight: 900;
}

.RoomProfile_saveButton__2Ua2R {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0.8vw 1.5vw;
  border-radius: 30px;
  background-color: #000000;
  color: #f5f0ec;
  font-weight: 400;
}

.RoomProfile_saveButton__2Ua2R:hover {
  font-weight: 900;
}

.RoomProfile_error__2C744 {
  border: 1px solid red;
}
.RoomProfile_error1__fUadu {
  color: red;
}

.RoomProfile_modalContainer__3K6Xz {
  width: 35vw;
  /* height: 70vh; */
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding-bottom: 10px;
  outline: none;
}

.RoomProfile_audio-react-recorder__1-Zjs canvas {
  height: 10px;
}

.RoomProfile_containerText__3vihZ {
  position: relative;
  width: 70%;
}
.RoomProfile_containerText__3vihZ textarea {
  display: block;
  width: 100% !important;
  resize: vertical;
  border: 1.5px solid #e0e0e0;
  border-radius: 3px;
}

.RoomProfile_containerText__3vihZ .RoomProfile_microphoneIco1__MW0NB {
  /* padding: 10px 20px; */
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 10px; /* <==== */
}

.RoomProfile_time__1ekKv {
  font-size: 8rem;
  margin-bottom: 1rem;
  color: white;
}
.RoomProfile_containerText__3vihZ .RoomProfile_Trash__3mKcl {
  /* padding: 10px 20px; */
  font-size: 20px;
  position: absolute;
  right: 30px;
  top: 10px; /* <==== */
}
.RoomProfile_containerText__3vihZ .RoomProfile_textField__2QX-R {
  display: block;
  width: 100% !important;
  resize: vertical;
}
.RoomProfile_containerText__3vihZ .RoomProfile_audio__2ftqX {
  padding: 10px 20px;
  font-size: 20px;
  position: absolute;
  right: 45px;
  top: -5px; /* <==== */
}

.RoomProfile_audioContainer__3bpGy {
  width: 35vw;
  /* height: 70vh; */
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding-bottom: 10px;
}

.RoomProfile_modalContainer1__BzMj4 {
  width: 70%;
  /* height: 70%; */
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f4f4f4;
  /* margin-top:10% */
}
.RoomProfile_imgcmt__3U9GR {
  margin-left: 20%;
  width: 50%;
}

.RoomProfile_modal_container__3ClmR {
  height: 15vh;
  width: 100%;
  background-color: #d2edf5;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .annotateImg{
  width: 100%;
  height: 100px;
} */

.RoomProfile_modalContainer__3K6Xz p {
  font-size: 14px;
  margin-top: 10%;
}
.RoomProfile_modal__3uN0d {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.RoomProfile_modalAnnotate__1TFNJ {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.RoomProfile_container__2bfO0 {
  height: 100% !important;
  margin-top: 1vh;
  margin-left: 15vw;
  background-color: #ffffff;
}

.RoomProfile_container1__2mmaj {
  min-height: 100vh !important;
  margin-left: 15vw;
  padding-left: 80px !important;
  background-color: #ffffff;
}

.RoomProfile_scrollableContainer__1gVvh {
  width: '75%';
}

.RoomProfile_active__1SUPj {
  font-weight: 800;
}

.RoomProfile_sideBar__1o8_Y h6 {
  margin-right: 0px;
}

.RoomProfile_container__2bfO0 {
  padding-left: 50px !important;
}

.RoomProfile_dropBox__16Kk6 {
  /* width: 0px !important; */
  height: 200px;
  /* background-color: #f5f0ec; */
  background-color: #d0ecf4;
  border: 1.5px solid #d0ecf4;
  border-radius: 6px;
  /* margin-left: 10px; */
}
.RoomProfile_dropTxt__Pkv7Y {
  font-weight: 500;
  font-size: 14px;
}
.RoomProfile_borderImg__2x2wg {
  padding-top: 0px !important;
  height: 200px;
  border: 1px dashed #ffffff;
  width: 100%;
  justify-content: center;
}
.RoomProfile_thumbImg__uCnfH {
  width: 55px;
  height: 55px;
  margin-bottom: 12px;

  transition: -webkit-transform 0.2s;

  transition: transform 0.2s;

  transition: transform 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.RoomProfile_thumbImg1__3X3hD {
  width: 80px;
  height: 55px;
  margin-bottom: 12px;
  display: none;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.RoomProfile_borderImg__2x2wg:hover .RoomProfile_thumbImg1__3X3hD {
  display: block;
  /* transform: scale(1.2); */
}
.RoomProfile_borderImg__2x2wg:hover .RoomProfile_thumbImg__uCnfH {
  display: none;
  /* transform: scale(/1.2); */
}
.RoomProfile_imageRow__2pgOA {
  height: 200px;
  width: 95%;
  /* background-image: url('https://d3izcfql7lesks.cloudfront.net/minh-pham-OtXADkUh3-I-unsplash_ikfunq.png'); */
  /* margin-left: 20px; */
  margin-bottom: 10px;
}

.RoomProfile_microphoneIco__2VKyD {
  position: absolute;
  right: 275px;
  /* top: 8px; */
}

.RoomProfile_designClick__1H2E4 {
  position: absolute;
  top: 5%;
  right: 10%;
}

.RoomProfile_materialClick__1fsjy {
  position: absolute;
  bottom: 7%;
  right: 5%;
  width: 15px;
  z-index: 1;
}
.RoomProfile_itemsBox__1ZzZT {
  width: 100%;
  height: 160px;
  margin-top: 30px;
  border: 1px solid #dedede;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-size: 14px;
  font-weight: 800;
  position: relative;
}

.RoomProfile_dot__3Mlzu {
  padding: 10px;
}
.RoomProfile_hoverImg__3NShc {
  display: none;
}
.RoomProfile_ArtsBox__3t_HA:hover .RoomProfile_oriImg__iZ6hb {
  display: none;
}

.RoomProfile_ArtsBox__3t_HA:hover .RoomProfile_hoverImg__3NShc {
  display: block;
}
.RoomProfile_overLayerRoom__K8YLs {
  position: absolute;
  background-color: #00000061;
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1500px) {
  .RoomProfile_itemsBox__1ZzZT {
    width: 130px;
    height: 130px;
    margin-top: 30px;
    font-size: 12px;
    font-weight: 800;
    box-shadow: 0px 3px 6px #cccccc29;
    border: 0.5px solid #dedede;
    border-radius: 5px;
  }
}

.RoomProfile_ArtsBox__3t_HA {
  width: 93%;
  height: -webkit-max-content;
  height: max-content;
  margin-top: 30px;
  border-radius: 5px;
  padding: 12px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 3px 6px #0000000f;
  position: relative;
  margin-bottom: 23px;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.RoomProfile_imgMaterial__1_er6 {
  object-fit: cover;
  max-width: 100%;
  height: 250px;
  border-radius: 5px;
}
.RoomProfile_titleTxt__3pL2F {
  color: #151515;
  font-weight: 600;
  margin: 4px 0px;
  float: left;
  margin-top: 10px !important;
}
.RoomProfile_ArtsBox2__20G8z {
  width: 97% !important;
  height: 314px;
  /* position: relative; */
  border-radius: 5px;
  /* margin-right: 30px;
  margin-left: 20px; */
  margin-top: 30px;
  border: 1px solid #dedede;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.RoomProfile_uploadButton__1R6iB {
  margin-top: 10px;
  border-radius: 30px;
  font-size: 11px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 800;
  height: 40px;
  background-color: #ffffff;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.RoomProfile_textArea__34FRR {
  width: 80%;
  height: 300px;
  margin-top: 30px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.RoomProfile_main__desktop__viewer__3dT7N {
  display: contents;
  /* background-color: #ffffff; */
}

.RoomProfile_main__mobile__viewer__2nUsu {
  display: none !important;
}

.RoomProfile_slider__info__3p0r1 {
  color: black;
}

@media only screen and (max-width: 600px) {
  .RoomProfile_main__desktop__viewer__3dT7N {
    display: none !important;
  }

  .RoomProfile_main__mobile__viewer__2nUsu {
    display: block !important;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 4000px) {
  .RoomProfile_container1__2mmaj {
    height: 100% !important;
    margin-left: 25vw;
    padding-left: 80px !important;
    background-color: #ffffff;
  }
  .RoomProfile_container__2bfO0 {
    height: 100% !important;
    margin-top: 5vh;
    margin-left: 25vw;
    padding-left: 50px !important;
    background-color: #ffffff;
  }
}

@media only screen and (max-width: 500px) {
  .RoomProfile_modalContainer__3K6Xz {
    width: 90%;
    /* height: 70vh; */
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    padding-bottom: 10px;
  }

  .RoomProfile_modalContainer1__BzMj4 {
    width: 60vw;
    height: auto;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #f4f4f4;
  }

  .RoomProfile_modal_container__3ClmR {
    height: 15vh;
    width: 100%;
    background-color: #d2edf5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .RoomProfile_modalContainer__3K6Xz p {
    font-size: 14px;
    margin-top: 10%;
  }
  .RoomProfile_modal__3uN0d {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.RoomProfile_deleteIcon__2Q6Ar {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  background-color: white;
  padding: 0px 7px !important;
  width: 32px !important;
  height: 32px !important;
  border-radius: 30px;
  box-shadow: 4px 3px 6px #979797;
  margin-top: 18px;
}
.RoomProfile_deleteTxt__2o-uE {
  font-size: 13px;
}

.RoomProfile_cancelBtn__3h71e {
  width: 60px;
  height: 34px;
  border: 1px solid #1a1818;
  background-color: transparent;
  color: #ffffff;
  border-radius: 20px;
  font-size: 15px;
}

.RoomProfile_deleteBtn__2__L8 {
  width: 60px;
  height: 34px;
  border: 1px solid #1a1818;
  background-color: #ffffff;
  color: #000000;
  font-weight: 500;
  border-radius: 20px;
  font-size: 15px;
}

@media only screen and (max-width: 600px) {
  .RoomProfileXl_main__cont__zDjqQ {
    padding: 0px 20px;
  }

  .RoomProfileXl_about__cont__2KWYD {
    background-color: whitesmoke;
    padding: 15px 10px;
    margin-top: 68px;
  }
  .RoomProfileXl_headerTxt__2mQMG {
    font-size: 18px;
    font-weight: bold;
  }
  .RoomProfileXl_about__text__3DWl7 {
    font-weight: 700;
  }

  .RoomProfileXl_saveButton__3qFls {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0.8vw 1.5vw;
    border-radius: 30px;
    background-color: #000000;
    color: #f5f0ec;
    font-weight: 400;
  }

  .RoomProfileXl_saveButton__3qFls:hover {
    font-weight: 900;
  }

  .RoomProfileXl_error__1ned5 {
    border: 1px solid red;
  }
  .RoomProfileXl_error1__1XRXs {
    color: red;
  }
  .RoomProfileXl_pagediv__margin__1CqLV {
    margin: 27px 0px;
  }

  .RoomProfileXl_pagediv__margin2__1JW-s {
    margin: 40px 0px 0px 0px;
  }

  .RoomProfileXl_photos__text2__3T8Mv {
    font-size: 13px;
    font-weight: 500;
    color: #101010;
  }

  .RoomProfileXl_priceButton__21m8Z {
    text-align: center;
    padding: '5px 10px 5px 10px';
    border: 2px solid #000000;
    border-radius: 30px;
    width: 40%;
    margin-left: 50px;
  }

  .RoomProfileXl_photos__div__31Vg7 {
    max-width: 48% !important;
    height: 160px;
    /* width: 100%; */
    background-color: #d0ecf4;
    border: 1.5px solid #d0ecf4;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
    padding: 9px !important;
  }

  .RoomProfileXl_photos__div__31Vg7:nth-child(odd) {
    margin-right: 10px;
  }
  .RoomProfileXl_borderImg__Tm7sC {
    padding-top: 0px !important;
    height: 100%;
    border: 1px dashed #ffffff;
    width: 100%;
    justify-content: center;
  }
  .RoomProfileXl_photos__btn__1VqNZ {
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    /* width: 90%;
    font-size: 12px;
    text-align: center;
    background: white;
    padding: 10px 6px;
    border-radius: 50px;
    box-shadow: 2px 4px 12px #0000004f;
    font-weight: 700; */
  }

  .RoomProfileXl_photos__div2__3_uHt {
    height: 20vh;
    max-width: 48% !important;
    max-width: 48% !important;
    height: 200px;
  }

  .RoomProfileXl_photos__div2__3_uHt:nth-child(1) {
    margin-right: 10px;
  }
  .RoomProfileXl_thumbImg__f2n7h {
    width: 50px;
    height: 50px;
    margin-bottom: 12px;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.2s;
    -webkit-transition: transform 0.2s;
  }
  .RoomProfileXl_thumbImg1__25uQv {
    width: 75px;
    height: 50px;
    margin-bottom: 12px;
    display: none;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.2s;
    -webkit-transition: transform 0.2s;
  }
  .RoomProfileXl_borderImg__Tm7sC:hover .RoomProfileXl_thumbImg1__25uQv {
    display: block;
    /* transform: scale(1.2); */
  }
  .RoomProfileXl_borderImg__Tm7sC:hover .RoomProfileXl_thumbImg__f2n7h {
    display: none;
    /* transform: scale(/1.2); */
  }
  .RoomProfileXl_photos__div3__1TaBz {
    max-width: 48% !important;
    height: 150px;
    width: 100%;
    background: #ffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
  }

  .RoomProfileXl_photos__div3__1TaBz:nth-child(odd) {
    margin-right: 10px;
  }

  .RoomProfileXl_photos__btn2__1swpb {
    width: 100%;
    border-radius: 0px;
    position: absolute;
    bottom: 0;
    background: #000000c2;
    color: white;
    font-weight: 700;
    font-size: 12px;
    padding: 10px 6px;
  }

  .RoomProfileXl_roomwise__heading__8WQbj {
    font-size: 17px;
    font-weight: bold;
  }

  .RoomProfileXl_wishlist__btn__2M9th {
    border-radius: 30px;
    background-color: #000000;
    color: #ffffff;
    padding: 16px;
    font-weight: 800;
    padding-left: 20px;
    margin-top: 30px;
    padding-right: 20px;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
  }
  .RoomProfileXl_dropTxt__L_D4e {
    font-weight: 500;
    font-size: 14px;

    text-align: center;
  }
  .RoomProfileXl_thumbImg__f2n7h {
    width: 55px;
    height: 55px;
    margin-bottom: 12px;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.2s;
    -webkit-transition: transform 0.2s;
  }

  .RoomProfileXl_thumbImg1__25uQv {
    width: 80px;
    height: 55px;
    margin-bottom: 12px;
    display: none;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.2s;
    -webkit-transition: transform 0.2s;
  }
  .RoomProfileXl_hoverImg__2W8hl {
    display: none;
  }

  .RoomProfileXl_art__img__166XY:hover .RoomProfileXl_oriImg__yEsmA {
    display: none;
  }

  .RoomProfileXl_art__img__166XY:hover .RoomProfileXl_hoverImg__2W8hl {
    display: block;
  }
  .RoomProfileXl_wishlist__item__2g-w7 {
    height: 20vh;
    /* border: 1px solid #8280805e; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 15px;
    max-width: 45% !important;
    margin-bottom: 25px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #cccccc29;
    border: 0.5px solid #dedede;
    border-radius: 5px;
    opacity: 1;
    /* box-shadow: 1px 1px 3px #0000006e; */
  }

  .RoomProfileXl_wishlist__item__2g-w7:nth-child(odd) {
    margin-right: 25px;
  }

  .RoomProfileXl_art__img__166XY {
    max-width: 48% !important;
    padding: 10px;
    text-align: center;
    margin-bottom: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000f;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    opacity: 1;
  }
  .RoomProfileXl_titleTxt__3ENb5 {
    color: #151515;
    margin: 4px 0px;
    float: left;
    margin-top: 10px !important;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    width: 100%;
  }
  .RoomProfileXl_art__img__166XY:nth-child(odd) {
    margin-right: 10px;
  }

  .RoomProfileXl_materialClick__21bE3 {
    position: absolute;
    bottom: 7%;
    right: 5%;
    width: 15px;
    z-index: 1;
  }
  .RoomProfileXl_overLayerRoom__1FqMC {
    position: absolute;
    background-color: #00000061;
    width: 100%;
    height: 100%;
  }
}

.RoomProfileXl_attachFileButton__2R_fs {
  border-radius: 30px;
  font-size: 15px;
  font-weight: 800;
  border: none;
  color: white;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  background-color: #1a1818;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.RoomProfileXl_filename__7P0Fw {
  position: relative;
  width: 140px;
  left: 100px;
  top: 15px;
}

.RoomProfileXl_UploadButton__3Rz6_ {
  position: relative;
  left: 90px;
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 800;
  padding-left: 20px;
  margin-top: 35px;
  padding-right: 20px;
}

.RoomProfileXl_wishlistButton__2xK0O {
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 800;
  padding-left: 20px;
  margin-top: 10px;
  padding-right: 20px;
}

.RoomProfileXl_modalContainer__223cI {
  /* width: 35vw; */
  /* height: 70vh; */
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding: 10px;
}

.RoomProfileXl_modalContainer1__UBjB3 {
  width: 90%;
  height: 95%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f4f4f4;
}
.RoomProfileXl_containerText__W10bZ {
  position: relative;
}
.RoomProfileXl_containerText__W10bZ textarea {
  display: block;
  width: 100% !important;
  resize: vertical;
  border: 1px solid #e0e0e0;
  margin-top: 22px;
}

.RoomProfileXl_buttonStyle__YKFLX {
  width: 42px;
  height: 41px;
  font-size: 28px;
  background-color: #f5f5f5;
}
.RoomProfileXl_wholeBtn__19K25 {
  justify-content: center;
  width: 134px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 37px;
  align-items: center;
  display: flex;
}
.RoomProfileXl_containerText__W10bZ .RoomProfileXl_microphoneIco1__2Fb6B {
  /* padding: 10px 20px; */
  font-size: 20px;
  position: absolute;
  right: 5px;
  top: 10px; /* <==== */
}
.RoomProfileXl_containerText__W10bZ .RoomProfileXl_textField__3wxSJ {
  display: block;
  width: 100% !important;
  resize: vertical;
}
.RoomProfileXl_containerText__W10bZ .RoomProfileXl_audio__1lM20 {
  padding: 10px 20px;
  font-size: 20px;
  position: absolute;
  right: 30px;
  bottom: 0px; /* <==== */
}
.RoomProfileXl_containerText__W10bZ .RoomProfileXl_Trash__1omRl {
  font-size: 20px;
  position: absolute;
  /* left: 313px;
  bottom: 91px; */
  right: 25px;
  top: 10px;
}

.RoomProfileXl_audioContainer__3KZIw {
  width: 35vw;
  /* height: 70vh; */
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding-bottom: 10px;
}

.RoomProfileXl_modal_container__3m7BD {
  height: 15vh;
  width: 100%;
  background-color: #d2edf5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RoomProfileXl_modalContainer__223cI p {
  font-size: 14px;
  margin-top: 10%;
}
.RoomProfileXl_modal__2Yfpz {
  display: flex;
  justify-content: center;
  align-items: center;
}
.RoomProfileXl_designClick__3iP6x {
  position: absolute;
  bottom: 7%;
  right: 5%;
  width: 15px;
  z-index: 1;
}
/* .materialClick{
  position: relative;
  left: 30px;
} */

.Pricing_container__18suV {
  padding-top: 13vh !important;
  font-family: 'Quicksand';
  background-color: #f7f7f7;
  max-width: 100% !important;
  margin: 0px !important;
}

.Pricing_header__3m5iP {
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 10px;
}

.Pricing_card__1WjQo {
  width: auto;
  height: 840px;
  background-color: #ffffff !important;
  border-radius: 0px !important;
  border-right: none;
  border-left: none;
  border-bottom: none;
  -webkit-filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 18%));
          filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 18%));
}
.Pricing_cardSubText__ff9Gn {
  font-size: 14px;
  font-weight: normal;
  font-family: 'Quicksand';
  color: black;
}

.Pricing_typoAccordian__1eXKp {
  font-size: 12px !important;
}

.Pricing_card_title__1OHof {
  font-size: 1.5rem !important;
  font-weight: bolder !important;
  text-transform: capitalize !important;
}

.Pricing_price__37YOU {
  font-family: 'Sofia Pro', sans-serif;
  width: 100% !important;
  font-size: 1rem;
}

.Pricing_sub__181i8 {
  width: 50% !important;
  margin-top: 3vh !important;
  font-weight: 500;
}

.Pricing_button__1YX7z {
  width: 182px;
  height: 48px;
  color: #f5f0ec;
  background-color: #171717;
  border-radius: 30px;
  font-weight: 400;
}
.Pricing_button__1YX7z:hover {
  font-weight: 900;
}
.Pricing_faqSection__3UolZ {
  background-color: #ffffff;
  display: inline-block;
  width: 100%;
}
.Pricing_Enhanced__1Rcls {
  position: relative;
  margin-right: 1rem;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}
.Pricing_Enhanced__1Rcls:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  border: solid 3px #eab146;
  border-color: #eab146 transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
.Pricing_Exclusive__39tNX {
  position: relative;
  margin-right: 1rem;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.Pricing_textSub__oxb5n {
  text-align: center;
}

.Pricing_Exclusive__39tNX:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;

  border: solid 3px #a1cfdb;
  border-color: #a1cfdb transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  transition-delay: 0.5s;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition-property: -webkit-transform 0.25s ease-out;
  transition-property: transform 0.25s ease-out;
  transition-property: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.Pricing_Express__2FRKg {
  position: relative;
  margin-right: 1rem;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.Pricing_Express__2FRKg:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  /* transform: scaleX(0); */
  border: solid 3px #d99fa6;
  border-color: #d99fa6 transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.Pricing_header3__1fgFL {
  font-weight: 400;
  font-size: 24px;
}
.Pricing_prices__IvwMX {
  align-items: center;
  display: flex;
  justify-content: center;
}
.Pricing_strick__2zAUY {
  color: #cbcbcb;
  font-weight: 600;
  text-decoration: line-through;
}
.Pricing_original__35h0d {
  color: #1a1818;
  font-weight: 600;
  position: relative;
}
.Pricing_prices__IvwMX div {
  padding: 0px 20px;
  font-size: 35px;
}
.Pricing_original__35h0d:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 100%;
  border: solid 3px #e6caca;
  border-color: #e6caca transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
.Pricing_designButton__SGCLH {
  padding: 10px 25px;
  font-size: medium;
  border-radius: 25px;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  color: #f5f0ec;
  background-color: black;
  font-weight: 400;
  /* font-size: 1vw; */
}
@media only screen and (max-width: 500px) {
  .Pricing_subParaText__3ZhhC {
    margin-top: 12px;
    font-size: 15px;
  }
  .Pricing_card__1WjQo {
    width: 100% !important;
    max-width: 100% !important;
    height: auto;
    padding-bottom: 20px;
    box-shadow: 1px 2px 8px #e6dfdf;
    margin-bottom: 20px;
  }
  .Pricing_container__18suV {
    max-width: 100% !important;
    padding-bottom: 20px;
  }
  .Pricing_container__18suV {
    margin-top: 2vh !important;
  }
  .Pricing_button__1YX7z {
    font-size: 14px;
    width: 168px;
    height: 41px;
  }
  .Pricing_cardSubText__ff9Gn {
    width: 95% !important;
    font-size: 13px;
    margin-top: 3px;
  }
  .Pricing_needHelp__13PXk {
    padding: 0px 19px;
  }
  .Pricing_typoHeader__2vt-2 {
    font-weight: 100;
    font-size: 14px;
  }
  .Pricing_prices__IvwMX div {
    padding: 0px 13px;
    font-size: 18px;
  }
}

/* .container{
    
    font-family: 'Quicksand';
    background-color: #F7F7F7;
    max-width: 100% !important;
    margin: 0px !important;
    
} */

.newPackage_header__2lUC5 {
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 10px;
}

.newPackage_card__2DeAJ {
  width: 100%;
  /* height: 280px; */
  background-color: #ffffff !important;
  border-radius: 0px !important;
  border-right: none;
  border-left: none;
  border-bottom: none;
  box-shadow: 0px 3px 10px #0000002e;
  /* -webkit-filter: drop-shadow(0px 2px 3px rgb(0,0,0,18%));
  filter: drop-shadow(0px 2px 3px rgb(0,0,0,18%)); */
  padding: 20px;
  min-height: 305px;
}
.newPackage_cardSubText__3ty5Z {
  font-size: 14px;
  font-weight: normal;
  font-family: 'Quicksand';
  color: black;
}

.newPackage_card_title__xRvvA {
  font-size: 1.5rem !important;
  font-weight: bolder !important;
  text-transform: capitalize !important;
}

.newPackage_price__8DuUN {
  font-family: 'Sofia Pro', sans-serif;
  width: 100% !important;
  font-size: 1rem;
}

.newPackage_sub__caYz2 {
  width: 50% !important;
  margin-top: 3vh !important;
  font-weight: 500;
}

.newPackage_button__1IRYW {
  width: 182px;
  height: 48px;
  color: #f5f0ec;
  background-color: #171717;
  border-radius: 30px;
  font-weight: 400;
}

.newPackage_button__1IRYW:hover {
  font-weight: 900;
}

.newPackage_Enhanced__2U3aE {
  position: relative;
  margin-right: 1rem;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.newPackage_Enhanced__2U3aE:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  /* transform: scaleX(0); */
  border: solid 3px #eab146;
  border-color: #eab146 transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.newPackage_Exclusive__35aJ1 {
  position: relative;
  margin-right: 1rem;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.newPackage_Exclusive__35aJ1:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  border: solid 3px #a1cfdb;
  border-color: #a1cfdb transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  transition-delay: 0.5s;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition-property: -webkit-transform 0.25s ease-out;
  transition-property: transform 0.25s ease-out;
  transition-property: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.newPackage_Express__3MwIe {
  position: relative;
  margin-right: 1rem;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.newPackage_Express__3MwIe:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 110%;
  /* transform: scaleX(0); */
  border: solid 3px #d99fa6;
  border-color: #d99fa6 transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
.newPackage_included__1KT3S {
  width: 100%;
  text-align: center;
  color: #101010;
  font-weight: 600;
  font-size: 30px;
  margin-top: 55px;
  margin-bottom: 30px;
  width: 100%;
}
/* .underline:hover:after{
    transform: scaleX(1);
    transform-origin:bottom left;
  } */

.newPackage_cardss__2doOs {
  width: 17.5%;
  margin-right: 30px;
}
.newPackage_cardss__2doOs:last-child {
  margin-right: 0px;
}
.newPackage_images__3Hc4A {
  width: 40px;
}
.newPackage_title__2ouVo {
  text-align: left;
  color: #101010;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.newPackage_content__tB7di {
  text-align: left;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
}
.newPackage_designButton__3FXp0 {
  padding: 10px 25px;
  font-size: medium;
  border-radius: 25px;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  color: #f5f0ec;
  background-color: black;
  font-weight: 400;
  font-size: 1vw;
}
.newPackage_textSub__V78u- {
  text-align: center;
}
.newPackage_prices__8YUVN {
  align-items: center;
  display: flex;
  justify-content: center;
}
.newPackage_strick__qoZiv {
  color: #cbcbcb;
  font-weight: 600;
  text-decoration: line-through;
}
.newPackage_original__2wrM8 {
  color: #1a1818;
  font-weight: 600;
  position: relative;
}
.newPackage_prices__8YUVN div {
  padding: 0px 20px;
  font-size: 35px;
}
.newPackage_original__2wrM8:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 5px;
  width: 100%;
  /* transform: scaleX(0); */
  border: solid 3px #e6caca;
  border-color: #e6caca transparent transparent transparent;
  border-radius: 35%;
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

@media only screen and (max-width: 500px) {
  .newPackage_card__2DeAJ {
    width: 90% !important;
    max-width: 100% !important;
    height: -webkit-max-content;
    height: max-content;
  }
  .newPackage_container__2Rj-J {
    max-width: 100% !important;
  }
  .newPackage_container__2Rj-J {
    margin-top: 2vh !important;
  }
  .newPackage_newPackage_included__1KT3S__1-zCZ {
    font-size: 24px;
    margin-top: 27px;
  }
  .newPackage_cardss__2doOs {
    width: 90%;
    margin-right: 0px;
  }
  .newPackage_designButton__3FXp0 {
    font-size: 14px;
  }
  .newPackage_images__3Hc4A {
    margin: 0px auto;
  }
  .newPackage_title__2ouVo {
    text-align: center;
  }
  .newPackage_content__tB7di {
    text-align: center;
  }
  .newPackage_included__1KT3S {
    margin-top: 35px;
    font-size: 25px;
  }
  .newPackage_cardss__2doOs .newPackage_card__2DeAJ {
    max-height: auto;
    height: 250px;
    min-height: auto;
  }
  .newPackage_prices__8YUVN div {
    padding: 0px 13px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .newPackage_packages__3aNrm {
    width: 85% !important;
  }
  .newPackage_card__2DeAJ {
    min-height: 325px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 850px) {
  .newPackage_cardss__2doOs {
    width: 35%;
    margin-right: 30px;
  }
}

.Review_container__1ZRdd {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2947_rqo8h9.png');
  background-size: 100%;
  background-repeat: no-repeat;
}

.Review_container2__1ztUy {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2948_f7urh6.png');
  background-size: 100%;
  margin-top: 80px;
  background-repeat: none !important;
}
.Review_container2__1ztUy div {
  padding-top: 200px;
  padding-bottom: 200px;
  padding-left: 100px;
}

.Review_container3__2m8SV {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2948_ldtipl.png');
  background-size: 100%;
}

.Review_cardHover__1E47w {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  /* transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12); */
  cursor: pointer;
}

.Review_cardHover__1E47w:hover {
  /* transform: scale(1.1); */
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  text-decoration: underline;
  -webkit-filter: drop-shadow(0px 3px 6px rgb(0, 0, 0, 16%));
          filter: drop-shadow(0px 3px 6px rgb(0, 0, 0, 16%));
}
.Review_pagination__GLs0J {
  margin: 0px;
  float: right;
}
.Review_pagesPart__1lbuq {
  float: right;
  width: 100%;
  margin-right: 10%;
}
.Review_pagination__GLs0J .Review_page-item__E96Nq .Review_page-link__1MAYF {
  min-width: 23px !important;
}
.Review_reviewHeader__2qXzk {
  font-size: 35px;
  margin-bottom: 14px;
}
.Review_subText__26vc9 {
  color: #101010;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.Review_viewProject__3McVK {
  font-size: 15px;
  margin-left: 5px;
}
.Review_reviewTitle__2sgbd {
  font-size: 15px;
}
.Review_name__1NWXX {
  font-weight: 500;
  margin-left: -3px;
}
.Review_cardContent__2sdz4 {
  margin-top: 36px;
}
.Review_spaceTxt__2Z0fx {
  margin-bottom: 42px;
}
.Review_packageTxt__1y94U {
  font-weight: 500;
  font-size: 13px;
  color: #5e605f;
  margin-top: 25px;
}
.Review_reviewContainer__E5Pe6 {
  width: 500px;
  height: auto;
  background-color: #fafafa;
  border-radius: 10px;
  margin: 20px;
  padding: 20px 30px;
}
.Review_sort__1YlD0 {
  font-weight: 600;
  font-size: 16px;
  margin: 0px;
  margin-right: 11px;
}
.Review_selectSort__1vsNI {
  width: 123px;
  border-radius: 5px;
  border: 0.5px solid #242424;
  color: rgb(0, 0, 0);
  font-size: 14px;
  height: 30px;
}
select:focus {
  outline: none;
  border: 0.5px solid #242424;
}
.Review_designButton__kL4Ns {
  border-radius: 5vw;
  padding: 7px 28px;
  color: #f5f0ec;
  background-color: black;
  font-weight: 400;
  font-size: 14px;
}
.Review_wholeReview__3MtRM {
  /* padding-top: 200px;
    padding-bottom: 200px; 
    padding-left: 100px; */
  padding-top: 200px;
  height: 500px;
  width: 70%;
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
}
/*.subContainer2{
    background-image:none;
}*/
.Review_mw__1gE2n {
  max-width: 90%;
}
.Review_container3__2m8SV div {
  padding-top: 300px;
  padding-bottom: 200px;
  padding-left: 100px;
}
.Review_dropdown__3M6n- button {
  background-color: white !important;
  color: black !important;
  border-color: #242424 !important;
  border-radius: 3px;
}

.Review_dropdown-item__2XV44 .Review_show__316pd {
  width: 50% !important;
}
.Review_pagination__GLs0J span {
  border-color: white !important;
  background-color: white !important;
  color: black !important;
}
.Review_pagination__GLs0J a {
  border-color: white !important;
  background-color: white !important;
  color: black !important;
}
.Review_coloums__2NwF5 {
}
@media only screen and (max-width: 600px) {
  .Review_container2__1ztUy {
    background-image: none;
    margin-top: 0px;
  }
  .Review_container2__1ztUy div {
    padding-top: 30px;
    padding-bottom: 0px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .Review_subContainer2__j3EZC {
    background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/UI+images/');
  }
  .Review_container3__2m8SV {
    background-image: none;
  }
  .Review_container3__2m8SV div {
    padding-top: 30px;
    padding-bottom: 0px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .Review_imageBanner__185VJ {
    width: 100%;
    height: 314px;
    object-fit: cover;
  }
  .Review_wholeBannerPart__NuNIj {
    position: relative;
  }
  .Review_wholeTxtPart__2mGBW {
    position: absolute;
    top: 34%;
    padding: 7px 22px;
    bottom: 0px;
    height: auto;
  }
  .Review_reviewHeader__2qXzk {
    font-size: 22px;
    margin-bottom: 14px;
  }
  .Review_subText__26vc9 {
    font-size: 13px;
    line-height: 17px;
  }
  .Review_columns__2sTZy {
    width: 100%;
  }
  .Review_waveImg__3kSBQ {
    width: 25px !important;
  }
  .Review_reviewContainer__E5Pe6 {
    padding: 20px 25px;
  }
  .Review_viewProject__3McVK {
    font-size: 11px;
  }
  .Review_reviewContainer__E5Pe6 span {
    font-size: 12px;
  }
  .Review_spaceTxt__2Z0fx {
    margin-bottom: 42px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .Review_mw__1gE2n {
    margin-top: 0px !important;
  }
  .Review_Review_container2__1ztUy__1x25v div {
    padding-bottom: 150px;
  }
}

.Giftcard_container__2aEfx {
  position: relative;
  background-image: url('https://d3izcfql7lesks.cloudfront.net/shutterstock_1065510131_bgagx5.png');
  height: 10vh !important;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 350px;
  padding-left: 200px;
  padding-bottom: 400px;
}

.Giftcard_bannerImage__1q47B {
  position: absolute;
}

.Giftcard_card__1NR4- {
  width: 420px;
  height: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 20px;
  padding: 50px;
}

.Giftcard_giftButton__3VzEv {
  border: none;
  background-color: black;
  color: white;
  margin-top: 10px;
  padding: 5px 10px 5px 10px;
  border-radius: 30px;
}
@media only screen and (max-width: 500px) {
  .Giftcard_card__1NR4- {
    margin: 0px !important;
    padding-left: 55px;
    justify-content: center;
  }
  .Giftcard_tgf__2bn8S {
    margin-left: 20px;
  }
  .Giftcard_container__2aEfx {
    background: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/UI+images/shutterstock_1065510131_bgagx5.png')
      no-repeat center center fixed;
    background-size: cover;
    background-color: black;
    opacity: 0.9;
  }
}

.Aboutus_aboutUsContainer1__2GO1p {
  background-color: #ffffff;
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutusBackground.png');
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 75px 50px 0 50px;
}

.Aboutus_slider__31HQC {
  position: relative !important;
  bottom: -180px;
  max-width: 1500px;
  width: 75%;
  padding: 10px;
  margin: auto;
}

.Aboutus_aboutUsContainer2__1r2wy {
  color: #ffffff;
  height: 110vh;
  background-color: #1a1a1a;
  padding: 50px;
  display: flex;
  justify-content: center;
}

.Aboutus_aboutUsContainer3__2vsQV {
  height: auto;
  background-color: #ffffff;
  padding: 50px;
}
.Aboutus_aboutUsContainer4__2zeqv {
  height: auto;
  background-color: #ffffff;
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/aboutus2.1.png');
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 50px;
}

.Aboutus_designButton__14V-l {
  padding: 10px 25px;
  font-size: medium;
  border-radius: 25px;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  background-color: black;
  color: #ffffff;
}

.Aboutus_designButton__14V-l:hover {
  font-weight: 900;
}

.Aboutus_aboutUsContainer3Text__3Wttc {
  font-weight: 600;
  font-size: 14px;
  width: 70%;
}

.Aboutus_innerContainer__1MRgP {
  max-width: 1500px;
  width: 75%;
  padding: 10px;
  margin: 75px auto auto auto;
}

@media screen and (min-width: 1500px) {
  .Aboutus_aboutUsContainer3Text__3Wttc {
    width: 45%;
  }
}

@media screen and (max-width: 1300px) and (min-width: 500px) {
  .Aboutus_slider__31HQC {
    bottom: -130px;
  }
  .Aboutus_aboutUsContainer3__2vsQV {
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .Aboutus_innerContainer__1MRgP {
    width: 95%;
  }

  .Aboutus_aboutUsContainer1__2GO1p {
    height: auto;
    padding: 10px;
  }

  .Aboutus_aboutUsContainer2__1r2wy {
    padding: 50px 10px 50px 10px;
  }

  .Aboutus_aboutUsContainer3__2vsQV {
    padding: 10px;
    height: auto;
  }

  .Aboutus_aboutUsContainer4__2zeqv {
    padding: 10px;
  }

  .Aboutus_aboutUsContainer3Text__3Wttc {
    width: 90%;
  }
  .Aboutus_aboutUsContainer2__1r2wy {
    height: auto;
  }

  .Aboutus_slider__31HQC {
    width: 90%;
    bottom: -75px;
  }
}

.Careers_container__1Yt1y {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2949_dt6guc.png');
  background-size: 100%;
}

.Careers_positionButton__2R9hs {
  border-radius: 30px;
  color: black;
  margin-top: 1%;
  background-color: white;
  font-size: 14px;
  padding: 4px 10px 5px 10px;
  font-weight: 900;
  border: none;
}

.Careers_applyButton__2B8Oc {
  border-radius: 30px;
  background-color: white;
  color: black;
  font-size: 0.8vw;
  font-weight: 800;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 3px 20px 3px 20px;
  margin-right: 20px;
}

.Payment_container__2nU12 {
  margin-top: 130px !important;
  font-family: 'Quicksand';
}
.Payment_pageContainer__1cGEm {
 
  overflow: hidden;
}
.Payment_roomContainer__3pUuf {
  background-color: white;
  margin-bottom: 10px;
  padding: 20px;

}
.Payment_roomtype1__3xz8w {
  margin-bottom: 10px;
 
}
.Payment_error__qFuxK {
  border: 1px solid red;
}
.Payment_error1__11yDP {
  color: red;
}
.Payment_headerRow__2g8LP {
  padding-bottom: 20px;
 
}
.Payment_applyButton__137XV {
  background-color: #fff;
  border: 1px solid;
  width: 84px;
  height: 40px;
  border-radius: 5px;
  padding: 3px 0;
}
.Payment_roomtype2__322mM {
  margin-bottom: 10px;
  padding: 20px;
}

.Payment_heading__3kyht {
  font-size: 16px;
  font-family: 'Quicksand';
  font-weight: bold;
}

.Payment_heading1__2Qwq- {
  font-size: 23px;
  font-family: 'Quicksand';
  font-weight: 600;
  padding-left: 0px;
  margin-bottom: 11px;
  margin-left: 12px;
}
.Payment_roomtype__hMA76 {
  position: relative;
  width: 100px;
  left: -15px;
  text-align: center;
}
.Payment_inputGst__2YMe7 {
  width: 100%;
}
.Payment_inputGst__2YMe7 input {
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  height: 42px;
  border: 1px solid #a5a5a5;
  color: #000;
  margin-top: 15px;
}
.Payment_gstTxt__3e4IU {
  font-weight: 800;
  float: left;
  font-size: 17px;
}
.Payment_inputGst__2YMe7 input:focus {
  border: 1px solid #000000 !important;
}
.Payment_checkoutInput__3B5J0 {
  width: 95%;
  padding: 5px;
  font-size: 14px;
  height: 40px;
  border: 1px solid #a5a5a5;
  color: #000;
  margin-bottom: 13px;
}
.Payment_modal_input_container__1LOCM .Payment_checkoutInput__3B5J0 {
  width: 95%;
  padding: 5px;
  font-size: 14px;
  height: 34px;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
  color: #000;
  margin-bottom: 3px;
}
.Payment_modal_input_container__1LOCM .Payment_modalContainer__3fjHf {
  padding: 0px 20px;
}
.Payment_checkoutInput__3B5J0:focus {
  border: 1px solid #000000 !important;
}
.Payment_modal_input_container__1LOCM span {
  font-size: 13px;
  color: #000000;
  padding-bottom: 8px;
}
.Payment_checkoutBtn__3Vq2p {
  width: 100%;
  height: 50px;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  margin-top: 5vh;
  font-weight: 600;
  font-size: 14px;
}
.Payment_promoBtn__2t-vo {
  text-align: center;
  width: 100%;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 500;
}
.Payment_nameContainer__o8A_Z form {
  width: 90%;
}
.Payment_couponBtn__AJs8o {
  width: 100%;
  height: 50px;
  color: #000;
  background-color: #fff;
  margin-top: 2vh;
  border: 1px solid #aaa;
  border-radius: 5px;
  opacity: 1;
  font-size: 14px;
}

.Payment_summary_headings__3Hjts {
  border-bottom: 1px solid rgba(188, 188, 188, 0.5);
  padding-bottom: 8px;
  padding-top: 4px;
}

.Payment_summaryContainer__A-Wl_ {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  margin-bottom: 50px;
  margin-top: 0px;
}
.Payment_roomContainer__3pUuf h5 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 13px;
}
.Payment_summaryContainer__A-Wl_ h3 {
  font-size: 17px;
  padding-left: 30px;
  font-weight: 900;
  margin-bottom: 0px;
}
.Payment_card__3fH8k {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 23px;
}
.Payment_gstTxt__3e4IU {
  font-weight: 800;
  float: left;
  font-size: 17px;
}
.Payment_card__3fH8k h5 {
  font-size: 16px;
  font-weight: 900;
}
.Payment_editAddress__1_C-r {
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.Payment_summarySubContainer__YhxiP {
  width: 85%;
  position: relative;
  margin: 18px auto;
 
}
.Payment_orderSum__cv2_G {
  width: 100%;
  border-bottom: 1px solid #000;
  position: relative;
  padding: 16px 0px;
  padding-bottom: 23px;
}
.Payment_summary_headings__3Hjts {
  border-bottom: 1px solid rgba(188, 188, 188, 0.5);
  padding-bottom: 8px;
  padding-top: 2vh;
}
.Payment_summary_headings__3Hjts span {
  font-size: 13px;
  font-weight: 600;
}
.Payment_subHeading__2nTCC {
  font-size: 14px;
  font-family: 'Quicksand';
  font-weight: 600;
}

.Payment_checkoutRecentContainer__2Rime {
  width: 89%;
  height: auto;
  background-color: #ffffff;
  padding: 20px !important;
}

.Payment_checkoutRecentContainer__2Rime p {
  font-size: 15px;
}

.Payment_deliveryBtn__1pfSO {
  background-color: #000000;
  border-radius: 5px;
  padding: 7px;
  width: 211px;
  height: 46px;
  font-weight: 900;
  color: #ffffff;
}

.Payment_paymentBtn__2u6gj {
  background-color: #000000;
  border-radius: 5px;
  padding: 7px;
  font-weight: 900;
  color: #ffffff;
}
.Payment_buttonContainer__1yntu {
  width: 211px;
  display: inline-flex;
  justify-content: between;
  margin-top: 10px;
}

.Payment_edit_btn__3YphO {
  background-color: #ffffff;
  color: black;
  width: 100px;
  border: 0.5px solid #1a1818;
  border-radius: 5px;
}

.Payment_delete_btn__1bnTb {
  background-color: #ffffff;
  color: black;
  width: 100px;
  border-radius: 5px;
  margin-left: 10px;
  border: 0.5px solid #1a1818;
}
.Payment_couponTitle__3iNSX {
  font-family: 'QuickSand';
  font-size: 26px;
  font-weight: bold;
  text-decoration-thickness: 'from-font';
}
.Payment_couponDesc__3vcn_ {
  font-family: 'QuickSand';
  font-size: 15px;
  font-weight: medium;
  text-decoration-thickness: 'from-font';
}

.Payment_applyBtn__3rLuI {
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  border-radius: 4px;
}

.Payment_disableBtn__WFbop {
  background-color: #a8a8a8;
  color: #ffffff;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  border-radius: 4px;
}

.Payment_modalContainer__3fjHf {
  width: 100%;
  position: relative;
  margin-top: 5vh;
  padding: 0px 20px;
}

.Payment_modalContainer1__2I-kf {
  width: 50%;
  height: 20vh;
  position: relative;
  margin-top: 5vh;
  text-align: center;
}
.Payment_modal_input_container__1LOCM {
  width: 95% !important;
  margin: auto !important;
  text-align: left;
  align-items: center;
}

.Payment_modal_input_container__1LOCM label {
  font-size: 1vw;
}

.Payment_modalContainer__3fjHf h4 {
  width: 100% !important;
  text-align: center;
  font-weight: 100;
}

.Payment_modal_input__2VGzY {
  width: 95%;
  padding: 5px;
  font-size: 14px;
  height: 34px;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
  color: #000;

}
.Payment_modal_input1__zxA0O {
  width: 270px;
  padding: 5px;
  font-size: 1vw;
  height: 35px;
  border: 1px solid red;
}
.Payment_modal__2YaWz .Payment_modal-dialog__2qm5B {
  width: 90% !important;
  position: relative !important;
  display: flex;
  left: 7%;
  background-color: transparent !important;
  max-width: 80% !important;
  height: 100% !important;
  margin-top: 100px;
  margin-bottom: 200px;
}
.Payment_addNew__1DuAA {
  font-size: 18px;
  text-align: center;
  margin-left: 9px;
  margin-bottom: 16px;
}
.Payment_modal_input__2VGzY:focus {
  border: 0.5px solid !important;
}
.Payment_modal_input1__zxA0O:focus {
  border: 0.5px solid !important;
}

.Payment_deleteConfirm__3RPC8 {
  font-size: 24px;
  font-family: 'Quicksand';
  font-weight: 800;
  padding: 10px;
}

.Payment_deleteInfo__3loa- {
  font-size: 20px;
  font-family: 'Quicksand';
  font-weight: 600;
  padding: 10px;
}

.Payment_cancelBtn__29DNV {
  width: 128px;
  height: 35px;
  border: 1px solid #1a1818;
  background-color: transparent;
  border-radius: 20px;
}

.Payment_deleteBtn__1TlOC {
  width: 128px;
  height: 35px;
  border: 1px solid #1a1818;
  background-color: #000000;
  color: #ffffff;
  font-weight: 500;
  border-radius: 20px;
}

.Payment_modalRow_delete__thEMw {
  background-color: #f2feff;
  height: 399px;
  max-height: 100%;
  padding-top: 80px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.Payment_rooms__3DLNM {
  font-weight: 600;
  align-items: center;
}
.Payment_price__3E0Lh {
  margin-top: 18px;
}
.Payment_textTrans__7ro1h {
  text-transform: uppercase;
  font-size: 13px;
}
.Payment_editStyle__-qerN small {
  font-weight: 600;
  font-size: 14px;
}
.Payment_selectTypes__wrYlY {
  word-wrap: normal;
  color: #383838;
  border: 0.5px solid #b9b9b9;
}
.Payment_addRoom__-jJNj {
  font-weight: 500;
  font-size: 15px;
}
@media only screen and (max-width: 500px) {
  .Payment_modalContainer__3fjHf {
    width: 100%;
    height: auto;
    position: relative;
    margin-top: 50px !important;
    margin-bottom: 200px !important;
    text-align: center;
    padding-bottom: 5vh;
  }

  .Payment_modal_input_container__1LOCM {
    width: 90% !important;
    text-align: left;
    margin-top: 0px !important;
  }

  .Payment_modal_input_container__1LOCM label {
    font-size: 2vw;
  }

  .Payment_modalContainer__3fjHf h4 {
    width: 100% !important;
    text-align: center;
    font-weight: 100;
  }

  .Payment_modal_input__2VGzY {
    font-size: 15px;
    font-weight: 700;
    height: auto;
    padding: 10px 14px;
  }
  .Payment_paymentBtn__2u6gj {
    margin-bottom: 16px;
  }

  .Payment_paymentMethod__nCFfZ {
    background-color: #f5f5f5;
    width: 100%;
  }

  .Payment_paymentMethod__nCFfZ:hover {
    background-color: black;
    color: white;
  }

  .Payment_dropDownStyled__2U6r1 {
    -webkit-appearance: none;
            appearance: none;
    color: #383838;
    font-weight: 500;
    background-image: url(/static/media/dropDown.87e86a63.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
    border-radius: 5px;
    width: 100%;
    height: calc(1.5em + 0.75rem + 3px);
    padding: 0.5rem 1rem;
    background-color: #ffffff;
    border: 1px solid #ced4da !important;
  }

  .Payment_dropDownStyled2__34iwP {
    width: 96%;
    padding: 0px 10px;
    height: 50px;
    margin-top: 5px;
    -webkit-appearance: none;
            appearance: none;
    color: #383838;
    font-weight: 500;
    background-image: url(/static/media/dropDown.87e86a63.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
    border-radius: 5px;
    border: 1px solid #a5a5a5 !important;
  }

  .Payment_dropDownStyled2__34iwP:focus,
  .Payment_dropDownStyled__2U6r1:focus {
    border: 1px solid #000000 !important;
  }
  .Payment_modalContainer__3fjHf {
    margin-bottom: 0px !important;
  }
  .Payment_paymentBtn__2u6gj {
    font-size: 11px;
  }
}

.CareerApply_container__8lX9Q {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2950_vyovf3.png');
  background-size: 100%;
}

.CareerApply_mainContainer__3cDY_ h5 {
  font-weight: 900 !important;
}

.CareerApply_divider__sg0s1 {
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
}

.CareerApply_input__1cXRp {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  width: 500px;
  background-color: #fafafa;
}

.CareerApply_underline__10gYw {
  text-decoration: underline;
}
.CareerApply_submitButton__1zuRY {
  border: none;
  background-color: black;
  color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 30px;
  margin-top: 40px;
  font-size: 14px;
  margin-bottom: 100px;

  width: 150px;
}

.GiftCheckout_nextButton__1jHqO {
  border-radius: 30px;
  background-color: black;
  color: white;
  width: 150px;
  height: 40px;
  padding: 3px 20px 3px 20px;
  font-weight: 700;
  border: none;
}

.GiftCheckout_summaryContainer__1nWZA {
  width: 400px;
  height: auto;
  background-color: white;
  /* border: 2px solid black; */
}

.GiftCheckout_personaliseInput__2Abc1 {
  width: 100%;
  margin: 20px;
  padding: 10px;
  border: 1px solid #b7b7b7;
  font-weight: 600;
}

.GiftCheckout_divider__3rxIs {
  width: 80%;
  height: 1px;
  margin-bottom: 10px;
  margin-top: -5px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.GiftCheckout_continueButton__hqTTO {
  width: 80%;
  height: 50px;
  font-weight: 800;
  background-color: black;
  color: white;
  padding: 4px 10px 4px 10px;
  border: none;
}

.Referral_leftContainer__18t1F {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/client-side-assets/refferal+page+bg+image.png');
  height: 100vh;
  background-image: 100%;
}

.Referral_input__OvudI {
  margin: 10px;
  padding: 10px;
  color: #b9b9b9;
  border: 0.5px solid #a5a5a567;
  margin-left: 0px;
}

.Referral_textArea__iIHGc {
  margin: 10px;
  padding: 10px;
  color: #b9b9b9;
  border: 0.5px solid #a5a5a567;
  height: 180px;
  margin-left: 0px;
}

.Referral_sendButton__Y3_7q {
  border: none;
  background-color: black;
  color: white;
  border-radius: 30px;
  padding: 5px 20px 5px 20px;
}
@media only screen and (max-width: 600px) {
  /* .leftContainer {
    display: none !important;
  } */
}
.Referral_wholeContainer__2oEiP {
  /* height: calc(100vh-50px) !important; */
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
}

.DesignDairies_center__text__3-2iX {
  position: absolute;
  top: 45%;
  left: 6%;
  /* transform: translate(-50%, -50%); */
  font-size: 50px;
}
.DesignDairies_center__text__3-2iX h1 {
  font-size: 70px;
  font-weight: 400;
}
.DesignDairies_overlay__2aBEu h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #fff;
}
.DesignDairies_center__text__3-2iX p {
  font-size: 18px;
  font-weight: 600;
  width: 41%;
  margin-top: 25px;
}
.DesignDairies_designTxt__2kvB0 {
  position: relative;
  padding: 8% 10%;
  z-index: 1;
}
.DesignDairies_designTxt__2kvB0 p {
  font-size: 18px;
  font-weight: 600;
  width: 41%;
  margin-top: 25px;
}
.DesignDairies_designTxt__2kvB0 h1 {
  font-size: 70px;
  font-weight: 400;
}
.DesignDairies_designBg__1oiIc {
  height: auto;
  position: relative;
}
.DesignDairies_imgBgs__4OPYP {
  width: 100%;
}
.DesignDairies_patternIm__2rtgr {
  position: absolute;
  right: 0px;
  top: -87%;
  width: 66%;
}
.DesignDairies_container_design__Wjl7z {
  width: 100%;
  margin: 0px auto;
  padding: 8% 5%;
  background-color: #f5f0ec;
}
.DesignDairies_container_design__Wjl7z .DesignDairies_designTxt__2kvB0 p {
  font-size: 27px;
  font-weight: 500;
  width: 80%;
  margin-top: 25px;
}
.DesignDairies_patternChair__2k-nW {
  position: absolute;
  /* width: 63%; */
  z-index: 1;
  right: 0px;
  top: -72px;
  height: 93vh;
}
.DesignDairies_mainImg__dkJmx {
  width: 95%;
  margin: 0px auto;
  display: block;
}
.DesignDairies_underDesignTxt__12B-T h1 {
  font-size: 28px;
  font-weight: 600;
}
.DesignDairies_underDesignTxt__12B-T p {
  font-size: 18px;
  font-weight: 500;
  width: 80%;
  margin-top: 14px;
}
.DesignDairies_underDesignTxt__12B-T button {
  color: #000000;
  border: 1px solid #707070;
  padding: 5px 25px;
  border-radius: 18px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  margin-top: 3px;
}
@media screen and (max-width: 500px) {
  .DesignDairies_overlay__2aBEu h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #fff;
  }

  .DesignDairies_center__text__3-2iX {
    position: absolute;
    top: 20%;
    left: 30%;
    font-size: 20px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .DesignDairies_center__text__3-2iX h1 {
    font-size: 25px;
    font-weight: 700;
    padding-top: 109px;
  }
  .DesignDairies_center__text__3-2iX p {
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    margin-top: 22px;
    padding: 0px 0px;
  }
  .DesignDairies_center__text__3-2iX {
    position: relative;
    top: 0px;
    left: 0px;
    font-size: 20px;
    text-align: center;
    padding: 0px 20px;
    -webkit-transform: none;
            transform: none;
    height: 100vh;
    background: #d2edf5;
  }
  .DesignDairies_container__2IvJO {
    overflow: hidden;
  }
  .DesignDairies_layerImg__1luVK {
    width: 153% !important;
    position: absolute;
    bottom: 0px;
    z-index: 1;
    overflow: hidden;
    right: 0%;
  }
  .DesignDairies_designTxt__2kvB0 h1 {
    font-size: 25px;
    text-align: center;
    font-weight: 600;
  }
  .DesignDairies_designTxt__2kvB0 {
    position: relative;
    padding: 101px 10%;
    background-color: #f5f0ec;
  }
  .DesignDairies_designTxt__2kvB0 p {
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    margin-top: 19px;
    text-align: center;
  }
  .DesignDairies_patternIm__2rtgr {
    top: -47px;
  }

  .DesignDairies_mainImg__dkJmx {
    width: 79%;
    margin: 0px auto;
    display: block;
  }
  .DesignDairies_container_design__Wjl7z .DesignDairies_patternIm__2rtgr {
    top: -68px;
    width: 82%;
  }
  .DesignDairies_container_design__Wjl7z .DesignDairies_designTxt__2kvB0 p {
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    margin-top: 19px;
    text-align: center;
  }
  .DesignDairies_underDesignTxt__12B-T {
    margin-top: 78px;
    text-align: center;
  }
  .DesignDairies_underDesignTxt__12B-T p {
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    margin-top: 14px;
  }
  .DesignDairies_underDesignTxt__12B-T button {
    margin-bottom: 76px;
  }
}

@media screen and (min-width: 1900px) {
  .DesignDairies_patternChair__2k-nW {
    top: -109px;
    height: 87vh;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .DesignDairies_patternChair__2k-nW {
    top: -31px;
    height: 89vh;
  }
}

.back {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/common-images/Order+cancellation.png');
  /* height: 200px; */
}
.content {
  color: black;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  background-image: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/design_dairies/pattern_success.png);
  /* height: 200px; */
  background-color: #e9dbd2;
}
.failedOrder {
  margin: 31px 0px;
}
.failedOrder h5 {
  font-size: 15px;
  line-height: 12px;
}
.button1 {
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
}

.button2 {
  border-radius: 30px;
  background-color: transparent;
  color: black;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  border: solid 1px;
}
@media only screen and (max-width: 600px) {
  .padd {
    width: 30px;
  }

  .thanks {
    font-size: 20px;
    padding-left: 1%;

    text-align: center;
  }

  .check {
    margin-top: 10%;
    text-align: center;
  }

  .email {
    font-size: 14px;
    padding-left: 1%;

    text-align: center;
  }

  .container-order {
    text-align: center;
    min-width: 90% !important;
    max-width: 90% !important;
    background-color: white;
  }
  .getStartedButton {
    border-radius: 30px;

    max-height: 50px !important;
  }
  .failedOrder h5 {
    line-height: 22px;
  }
}


.HireADesigner_mainContainer__3iiOU{
  padding-bottom: 100px;
}

.HireADesigner_videoPlayerContainer__3AaZL{
  background-color: #f0f0f0;
  position: relative;
  height: 90vh;
}

.HireADesigner_videoPlayerHeading__2yVbQ{
  padding-top: 110px;
  font-size: 40px;
  font-weight: 700;
  color: #171717
}

.HireADesigner_videoPlayerSubHeading__1wt6I{
  font-size: 16px;
  font-weight: 400;
  color: #171717;
  margin-bottom: 1.5rem;

}

.HireADesigner_videoPlayerRow__QKrVZ{
  position: absolute;
  bottom:-80px;
}

.HireADesigner_videoPlayerWrapper__1vka9{
  height: 70vh;
}

.HireADesigner_stepsRow__hI7pD{
  padding-top: 120px;
  display: flex;
  flex-direction: column;
}

.HireADesigner_stepsContainer__2Rh3d{
  display: flex;
  justify-content: space-between;
}

.HireADesigner_steps__QCtZA{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HireADesigner_step__1qWfE{
  position: relative;
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #D8D6D6;;

}

.HireADesigner_stepLeft__ouqbw{
  width: 20%;
  padding-right: 0.5rem;
}

.HireADesigner_stepNoBox__7Qwyl{
  display: flex;
  justify-content: center;
  align-items:center ;
  height: 44px;
  width: 44px;
  padding: 10px;
  border-radius: 4px;
  background-color: #F1EEEA;
  color: #6E6D6B;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.HireADesigner_stepNoBoxActive___-TTg{
  display: flex;
  justify-content: center;
  align-items:center ;
  height: 44px;
  width: 44px;
  padding: 10px;
  border-radius: 4px;
  background-color: #171717;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.HireADesigner_stepAnimationLine__SZvnp{
  height: 1px;
  background-color: #171717;
  position: absolute;
  bottom: 0;
}

.HireADesigner_stepRight__1How0{
  width: 80%;
  display: flex;
  flex-direction: column;
}

.HireADesigner_stepTitle__23mYN{
  color: #171717;
  opacity: 0.6;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.HireADesigner_stepTitleActive__3HoiM{
  color: #171717;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.HireADesigner_stepSubTitle__3IYE1{
  color: #393939;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.HireADesigner_stepsImage__3lOfl{
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1000px){

  .HireADesigner_mainContainer__3iiOU{
    padding: 0px 15px 100px 15px;
  }

  .HireADesigner_videoPlayerContainer__3AaZL{
    background-color: #ffffff;
  }
  .HireADesigner_videoPlayerContainer__3AaZL{
    height: 100%;
  }

  .HireADesigner_videoPlayerRow__QKrVZ{
    position: relative;
    bottom: 0;
  }

  .HireADesigner_videoPlayerWrapper__1vka9{
    height: 50vh;
  }

  .HireADesigner_stepsRow__hI7pD{
    padding-top: 50px;
  }

  .HireADesigner_stepsContainer__2Rh3d{
    flex-direction: column-reverse;
  }

  .HireADesigner_steps__QCtZA{
    width: 100%;
    margin-top: 2rem;
  }

  .HireADesigner_stepsImage__3lOfl{
    width: 100%;
    
  }
}

@media only screen and (max-width: 600px){
.HireADesigner_videoPlayerWrapper__1vka9{
  height: 40vh;
}
}



.Button_filled__2F7zV{
    background: #1A1818;
    border-radius: 39px;
   
    color:#ffffff;
   
    font-weight: 700;
    cursor: pointer;
    width: -webkit-max-content;
    width: max-content;
    line-height: 1;
    text-align: center;
}



.Button_outlined__3n1_G{
    border-radius: 39px;
    border: 1px solid #000000;
    color:#000000;
    font-size:16px;
    font-weight: 700;
    cursor: pointer;
    width: -webkit-max-content;
    width: max-content;
    line-height: 1;
    text-align: center;

}

.Button_small__ZySFV{
    height: 36px;
    padding: 10px 20px;
    font-size:13px;
}

.Button_large__7rUb1{
    height: 50px;
    padding: 16px 40px;
    font-size:16px;
}

.Button_filledDisabled__31nm9{
    background-color: rgb(0 0 0 / 41%);
    cursor: not-allowed;
}

.Button_outlinedDisabled__2bYq9{
    border: 1px solid rgb(0 0 0 / 41%);
    color:rgba(26, 24, 24, 0.4);
    cursor: not-allowed;
}
.App {
  text-align: center;
}
/* body {
  background-color: grey;
} */
body {
  display: flex;
  font-family: 'Quicksand', sans-serif !important;
  flex-direction: column;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Homepage_homeContainer__1D5WS{
    /* margin-top: 65px; */
    width: 100%;
    background-color: #F5F5F5;
}

.Homepage_homeContainer2__24qCk{
    width: 80%;
    margin: 8rem auto 0 auto;
    
}

.Homepage_homeContainer3__1Jgjs{
    width: 100%;
    background-color: #ffffff;
}

.Homepage_homeContainer4__55Duz{
    width: 100%;
    
}

.Homepage_homeContainer5__1vo6l{
    width: 80%;
    background-color: #F5F5F5;
    margin: auto;
}

.Homepage_categoryContainer__2mppH{
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin: auto;
}

.Homepage_newArrivalContainer__3yzsm{
    width: 80%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin: auto;
    
}



.Homepage_banner__1377a{
    width: 100%;
    position: relative;
}

.Homepage_bannerVideo__1TLgA{
    height: 100vh;
}

.Homepage_bannerOverlay__DyXRW{
    display: grid;
    place-content: center;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background:
      linear-gradient(180deg, rgba(7, 11, 19, 0.92) 10.14%, rgba(7, 11, 19, 0.34) 88.84%)
}

.Homepage_bannerHeading__3hkuA{
 font-size: 56px;
 font-weight: 700;
 color:#FFFFFF;
 line-height: 64px;
 margin-bottom: 1rem;
}

.Homepage_bannerSubHeading__2_NuK{
    font-size: 18px;
    font-weight: 500;
    color:#DFD9D6
}

.Homepage_filterRowWeb__3c-ql{
    display: flex;
}
.Homepage_filterRowMob__2OrpP{
    display: none;
}

.Homepage_filterCol__1CPIk{
    background-color: #F1EEEA;
    position: absolute;
    background-image: url('https://d3izcfql7lesks.cloudfront.net/semiCirclesVectorBg.svg');
    background-position: center;
    background-repeat: no-repeat;
    transition: bottom 0.5s ease-out;
}

.Homepage_filterInner__hHI8L{
    width: 100%;
    padding: 25px 15px;
    position: relative;
}

.Homepage_badgeContainer__2BSyt{
    display: flex;
    flex-wrap: wrap;
    /* margin-bottom: 1.5rem; */
}

.Homepage_sliderBox__wNur9{
    width: 120px;
    height: 39px;
    background: #FFFFFF;
    border: 0.5px solid #A5A5A5;
    margin-right: 0.5rem;
    padding: 4px;
}

.Homepage_filterText__uk5-M{
    position: absolute;
    background-color: #F8F4F0;
    color: #171717;
    padding: 6px 20px;
    bottom:0;
    right:10px;
    font-size: 11px;
    font-weight: 500;

}

.Homepage_sliderBoxInner__3Ori_{
    margin-bottom:2.5px;
    line-height:1;
    font-size: 11px;
    font-weight: 400;
    color:#A5A5A5;
}

.Homepage_sliderBoxInnerValue__3n2ds{
    margin:0;
    font-size: 13px;
    font-weight: 500;
    color: #171717;
}

.Homepage_budgetContainer__3kATI{
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease-out;
}

.Homepage_budgetBtnContainer__1-XiS{
    justify-content: center;
}

.Homepage_filterInnerHeading__37FGN{
    font-weight: 700;
    font-size: 28px;
    color: #171717;
    margin-bottom: 1rem
}



.Homepage_hireDesignerBanner__SFo72{
    display: flex;
    align-items: center;
    background-color: #D9EFF5;
    color: #171717;
    width: 100%;
    height: 240px;
    /* margin-top: 12rem; */
}

.Homepage_hireDesignerBannerMob__Q1pNK{
    display: none;
}

.Homepage_stackedCarouselContainer__22dvr{
    width: 100%;
    /* background-color:  #F1EEEA; */
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background: linear-gradient(to bottom, #F1EEEA 70%, #ffffff 70%);
    background-image: url(https://d3izcfql7lesks.cloudfront.net/semiCirclesVectorBg.svg);
    background-size: 100%;

    /* height: 500px; */
}

.Homepage_stackedCarouselContTittle__3GJ6B{
    color: #171717;
    font-size: 28px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.Homepage_stackedCarouselBadgeContainer__10446{
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: 2rem;
    
}



.Homepage_stackedCarouselContainerInner__V-G2C{
    width: 80%;
    margin:auto
}

.Homepage_stackedCarouselContainerInnerMost__2nar0{
    width: 80%;
    margin:auto
}

.Homepage_partnersContainer__17Y5J{
 display: flex;
 flex-direction: row;
 margin: auto;
 padding-top: 4rem;
 padding-bottom: 4rem;
}

.Homepage_partnerLeft__3G351{
    display: flex;
    flex-direction: column;
    flex: 0.5 1;
    justify-content: center;
}

.Homepage_partnerRight__31id4{
    display: flex;
    flex-direction: column;
    flex: 0.5 1;
}
.Homepage_partnerHeader__3f4z-{
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    width: 90%;
}

.Homepage_partnerSubHeader__3wgbe{
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    width: 90%;
}

.Homepage_vendorAutoScroll__v3dis{
    height: 480px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 10px;
    gap: 10px;
    overflow-y: scroll;     
     
}

.Homepage_vendorAutoScroll__v3dis::-webkit-scrollbar {
    width: 6px;
   
  }
  
  /* Track */
  .Homepage_vendorAutoScroll__v3dis::-webkit-scrollbar-track {
    background: #F5F5F5; 
  }
   
  /* Handle */
  .Homepage_vendorAutoScroll__v3dis::-webkit-scrollbar-thumb {
    background: #999; 
    border-radius: 4px;
  }

.Homepage_sliderInput__10xWU{
    font-size: 13px;
    border: none;
    outline: none;
    width: 100%;
    line-height: 1
}

.Homepage_moodboardCont__14hY-{
    width: 100%;
    max-width: 1250px;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 9rem;

}

.Homepage_moodboardContTitle__UCsu5{
    font-size: 18px;
    font-weight: 700;
    color: #171717;
    margin-bottom: 0.5rem;
    text-align: center;
}

.Homepage_moodboardContSubTitle__1MGBb{
    font-size: 15px;
    font-weight: 600;
    color: #171717;
    margin-bottom: 1rem;
    text-align: center;
   
}

.Homepage_shop_button__3RAt4 {
    width:  125px;
    height: 40px;
    border-radius: 40px;
    background-color: #1A1818;
    color: white;
    font-weight: bold;
    font-family: "Quicksand", sans-serif;
    margin-top: 10px;
    z-index: 50;
  }

@media only screen and (max-width: 1000px){

    .Homepage_bannerVideo__1TLgA{
        height: 60vh;
    }

    .Homepage_bannerOverlay__DyXRW{
        height: 60vh;
    }

    .Homepage_filterRowWeb__3c-ql{
        display: none;
    }
    
    .Homepage_filterRowMob__2OrpP{
        display: flex;
    }
    .Homepage_filterCol__1CPIk{
        position: relative;
    }

    .Homepage_homeContainer2__24qCk{
        width: 100%;
        margin: 2rem auto 0 auto;
    }

    /* .moodboardCont{
        width: 90%;
    } */


    .Homepage_hireDesignerBanner__SFo72{
        display: none;
    }

    .Homepage_hireDesignerBannerMob__Q1pNK{
        height: 450px;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        background-color: #D9EFF5;
        padding:20px 20px 0 20px;
        text-align: center;
    }

    .Homepage_categoryContainer__2mppH{
        width: 90%;
    }

    .Homepage_stackedCarouselContainerInner__V-G2C{
        width: 90%;
     
    }

    
    .Homepage_stackedCarouselContainerInnerMost__2nar0{
        width: 90%;
     
    }

    .Homepage_partnersContainer__17Y5J{
        flex-direction: column;
    }

    .Homepage_partnerLeft__3G351{
        flex:1 1;
        align-items: center;
    }

    .Homepage_partnerRight__31id4{
        flex:1 1;
        margin-top:2rem
    }

    .Homepage_partnerHeader__3f4z-{
        text-align: center;
        width: 100%;
    }
    .Homepage_partnerSubHeader__3wgbe{
        text-align: center;
        width: 100%;
    }

    
}

@media only screen and (max-width: 600px) {

   

    .Homepage_bannerHeading__3hkuA{
        font-size: 28px;
        line-height: 36px;
    }

    .Homepage_bannerSubHeading__2_NuK{
        font-size: 16px;
        padding: 0 10px 0 10px;
    }

    .Homepage_filterCol__1CPIk{
        width: 90%;
    }

    .Homepage_filterInnerHeading__37FGN{
        font-weight: 700;
        font-size: 16px;
        color: #171717;
        margin-bottom: 1rem
    }

    .Homepage_newArrivalContainer__3yzsm{
        width: 100%;  
    }

    .Homepage_stackedCarouselContainer__22dvr{
        width: 100%;
        /* background-color:  #F1EEEA; */
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;
        background: linear-gradient(to bottom, #F1EEEA 70%, #ffffff 70%);
        background-image: url(https://d3izcfql7lesks.cloudfront.net/semiCirclesVectorBg.svg);
        background-size: 100%;
        height: 560px;
    }
    

    .Homepage_budgetBtnContainer__1-XiS{
        justify-content: end;
        margin-top: 0.5rem;
       
    }



    .Homepage_filterInner__hHI8L{
        padding: 30px 15px;
    }

    .Homepage_moodboardCont__14hY-{
        /* width: 100%; */
        padding: 10px;
    }

    .Homepage_homeContainer5__1vo6l{
        width: 90%;
    }

   

    .Homepage_partnersContainer__17Y5J{
        flex-direction: column;
    }

    .Homepage_partnerHeader__3f4z-{
        text-align: center;
        width: 100%;
        font-size: 20px;
    }
    .Homepage_partnerSubHeader__3wgbe{
        display: none;
        width: 100%;
    }

    .Homepage_partnerLeft__3G351{
        flex:1 1;
        align-items: center;
    }

    .Homepage_partnerRight__31id4{
        flex:1 1;
        margin-top:2rem
    }
}


.FilterBadge_filterBadge__38sVi{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: -webkit-max-content;
    width: max-content;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding: 10px 18px;
    border: 1px solid #D1C9C4;
    border-radius: 24px;
    font-weight: 500;
    font-size: 14px;
    color:  #171717;
    margin: 0 1rem 1rem 0;
    cursor: pointer;
}

.FilterBadge_filterBadgeActive__3kxof{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: -webkit-max-content;
    width: max-content;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding: 10px 18px;
    border-radius: 24px;
    font-weight: 500;
    font-size: 14px;
    color:  #ffffff;
    margin: 0 1rem 1rem 0;
    background-color:#E29525;
    cursor: pointer;
    
}

@media only screen and (max-width: 600px) {
 .FilterBadge_filterBadge__38sVi{
    margin: 0 0.5rem 0.5rem 0;
    font-size: 12px;
 }
 .FilterBadge_filterBadgeActive__3kxof{
    margin: 0 0.5rem 0.5rem 0;
    font-size: 12px;
 }
}
.RoomCard_roomCard__2D6XO{
    width:100%;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 15px;
    padding:5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

/* .roomCardNoHover{
    width:100%;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 15px;
    padding:5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
} */

/* .roomCard:hover{
    background-color: #949A86;
} */

.RoomCard_cardImage__bfOa_{
    width: 70%;
    height: 70%;
    object-fit: contain;
    margin-bottom: 0.5rem;
}
.Productcard_productCard__11PBx{
    /* min-width: 250px;
    min-height: 330px; */
    width: 295px;
    height: 370px;
    border: 1px solid #DEE3EC;
    padding:6px;
    box-shadow: 0px 0px 3px 0px #00000014;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
}

.Productcard_topContainer__3P435{
    height: 60%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.Productcard_productImage__3KPHO{
    height: 90%;
    width: 80%;
    object-fit: contain;
    cursor: pointer;
}


  

.Productcard_arContainer__1lSoA{
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: max-content;
    background-color: #F6F6F6;
    padding: 4px 12px;
    font-size: 11px;
    font-weight: 700;
    cursor: pointer;
    z-index: 2;
}

.Productcard_btnContainer__GeQFU{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}


.Productcard_productCardTag__3s2iE{
    background-color: #7B8569;
    height: 21px;
    color: #ffffff;
    font-size: 11px;
    font-weight: 700;
    position: absolute;
    top:16px;
    left:-6px;
    padding:3px 5px;
}

.Productcard_productCardHeart__3HqKI{
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    top:5px;
    right:5px;
    padding:0px 10px;
    border-radius: 50%;
    background-color: #F6F6F6;
    z-index: 2;
}

.Productcard_bottomContainer__3pzoj{
    height: 40%;
    background-color: #F1EEEA;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Productcard_vendorName__2iVbf{
    color:  #E39625;
    font-size: 12px;
    font-weight: 700;
    
  

}

.Productcard_productName__3H77G{
    color:  #171717;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.Productcard_productName__3H77G:hover{
   text-decoration: underline;
}

.Productcard_priceCont__2fcob{
    display: flex;
    justify-content: start;
    align-items: center;
}

.Productcard_pnmPrice__2G6OW{
    color:  #171717;
    font-size: 18px;
    font-weight: 700;
    margin-right: 0.5rem;
}

.Productcard_price__1KEEh{
    color:  #171717;
    font-size: 14px;
    font-weight: 500;
}

@media only screen and (max-width: 600px) {
    /* .productCard{
        width: 255px;
    } */
    .Productcard_productName__3H77G{
        text-decoration: underline;
    }
}
.Carousel_leftArr__1ULzp{
    position: absolute;
    top: 40%;
    left: -55px !important;
    border: 1px solid #DFD9D6;
    padding: 14px !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    z-index: 1;
    object-fit: none;
    cursor: pointer;

}

.Carousel_rightArr__2tkBh{
    position: absolute;
    top: 40%;
    right: -55px !important;
    border: 1px solid #DFD9D6;
    padding: 14px !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    z-index: 1;
    object-fit: none;
    cursor: pointer;

}
.Customstackedcarousel_leftArr__394wG{
    position: absolute;
    top: 40%;
    left: -30px !important;
    border: 1px solid 
    #171717;
    padding: 14px !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    z-index: 1;
    object-fit: none;
    cursor: pointer;
    background-color: #ffffff;

}

.Customstackedcarousel_rightArr__Ad9yf{
    position: absolute;
    top: 40%;
    right: -30px !important;
    border: 1px solid 
    #171717;
    padding: 14px !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    z-index: 1;
    object-fit: none;
    cursor: pointer;
    background-color: #ffffff;

}

.Customstackedcarousel_stackedCarouselDetails__3Y8-L{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
    color: #171717;
    font-size: 18px;
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
    .Customstackedcarousel_leftArr__394wG{
       display: none;
    }
    .Customstackedcarousel_rightArr__Ad9yf{
        display: none;
    }
   }
.ExploreTags_mobileBannerContainer__3o36Q {
  width: 100%;
  background-color: #f9f9f9;
  padding: 15px;
}
.ExploreTags_heading__3XQI_ {
  font-size: 22px;
  font-weight: 500;
  margin-top: 100px;
}

.ExploreTags_findStyle__2MkFb {
  position: absolute;
  top: 30px;
  left: 0px;
  bottom: 0;
  right: 0;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  text-align: center;
}

.ExploreTags_headingImg__25Aby {
  width: 160px;
  height: 160px;
}

.ExploreTags_headingImgTittle__3HcCZ {
  font-size: 14px;
  font-weight: 600;
}

.ExploreTags_displayrow__1nN8- {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.ExploreTags_BannerImg__3wL8Z {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background: linear-gradient(180deg, #000 50%, transparent);
  border-radius: 8px;
  /* visibility: hidden; */
}

.ExploreTags_banner__2kjOs {
  /* background-image: url("../../assets/images/Explore/exploreBanner.png");
        background-size: contain;
        background-repeat: no-repeat; */
  width: 100%;
  height: 100%;
  /* padding-top: 49%; */
  position: relative;
  border-radius: 8px;
}

.ExploreTags_container__1YsbV {
  margin-top: 5vh;
  font-family: 'Quicksand';
  padding-bottom: 50px;
}

.ExploreTags_mainHeader__1Vw-W {
  text-align: center;
  justify-content: center !important;
  font-family: 'Quicksand';
}

.ExploreTags_mainHeader__1Vw-W h2 {
  font-size: 30px;
}

.ExploreTags_mainHeader__1Vw-W h6 {
  margin-top: 1vh;
  font-size: 17px;
  opacity: 1;
  font-weight: 400;
}
.ExploreTags_filterImg__2a3Jx {
  margin-bottom: 50px;
}
.ExploreTags_modal__1jLZt {
  border: none !important;
  height: 100% !important;
  overflow: scroll;
  width: 70% !important;
}

.ExploreTags_modal__1jLZt .ExploreTags_modal-content__1ud6L {
  width: 70% !important;
}

.ExploreTags_modal_backdrop__YFzdJ {
  background-color: rgba(250, 253, 250, 0.4) !important;
}
.ExploreTags_productImg__3SQMD {
  width: 100%;
  height: 330px;
}

.ExploreTags_modalRow__1AAqW {
  padding: 20px 50px 20px 50px;
  height: 600px;
  position: relative;
}

.ExploreTags_arIcon__2wtmz {
  position: absolute;
  bottom: 7px;
  width: 26px;
  right: 7px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.ExploreTags_arIconbtn__3qK68 {
  position: absolute;
  bottom: 7px;
  width: 26px;
  right: 7px;
}
.ExploreTags_arIcon__2wtmz:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.ExploreTags_filterButton__wuZxO {
  border-radius: 30px;
  margin-top: 10px;
  padding: 5px 20px 5px 20px;
  background-color: #000000;
  color: #ffffff;
  display: inline-flex;
}

.ExploreTags_tagBtns__3hOHg {
  padding: 6px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  background-color: #ffffff;
  color: black;
  font-weight: 500;
  border: none;
  border: 1px solid #a0a0a0;
  min-width: 125px;
  text-transform: capitalize;
}

.ExploreTags_tagBtnss__1P67H {
  padding: 3%;
  /* padding-left: 20px;
    padding-right: 20px; */
  margin-top: 10px;
  /* font-size: 0.6rem; */
  margin-right: 10px;
  /* border-radius: 50%; */
  background-color: #ffffff;
  color: black;
  font-weight: 600;
  /* height: 30px; */
  /* border: none; */
  border: 1px solid #a0a0a0;
  font-size: 14px;
  font-family: 'Quicksand';
  min-width: 40%;
}

.ExploreTags_tagBtnsss__3x7z1 {
  padding: 7px 24px;
  margin-top: 32px;
  margin-left: 23px;
  background-color: #ffffff;
  color: black;
  border: 1px solid #1a1818;
  font-size: 13px;
  font-family: 'Quicksand';
  margin-bottom: 50px;
  font-weight: 600;
  height: 40px;
}
button:focus {
  box-shadow: none !important;
}
.ExploreTags_tagBtnsss__3x7z1:hover {
  background-color: black;
  color: white;
}
.ExploreTags_text1__3b8VU {
  font-weight: 600;
  font-size: '14px';
  font-family: 'QuickSand';
}

.ExploreTags_overlay__3Wca- {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  bottom: 0;
  background-color: #2c2c2cb5;
}

.ExploreTags_footerButton__uwIXl {
  border-radius: 30px;
  background-color: #ffffff;
  color: #000000;
  border: 0.5px solid #707070;
  padding: 8px 10px 8px 10px;
  width: 150px;
  margin-bottom: 10px;
}

.ExploreTags_buttonWhite__1qMiS {
  border-radius: 25px;
  color: black;
  background-color: white;
  padding: 5px 3em 5px 3em;
  font-weight: 400;
  height: 40px;
  width: 100%;
  font-size: 13px;
}

.ExploreTags_button__1NJYh {
  width: 131px;
  height: 42px;
  border-radius: 10px 10px 0 0;
  border: none;
  float: right;
  margin-top: -7.5vh;
  margin-right: 5vw;
  position: -webkit-sticky;
  position: sticky;
  bottom: -6px;
  right: 0px;
  z-index: 100;
  background-image: linear-gradient(to right, #ffe5a0 0%, #ffce4a 100%);
  font-size: 16px;
  font-weight: 700;
}

.ExploreTags_createButton__rZ6Dq {
  width: 131px;
  height: 42px;
  border-radius: 10px 10px 0 0;
  border: none;
  position: absolute;
  top: -42px;
  right: 50px;
  z-index: 100;
  background-image: linear-gradient(to right, #ffe5a0 0%, #ffce4a 100%);
  font-size: 16px;
  font-weight: 700;
}

.ExploreTags_fullScreen__esYEu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: #2c2c2c;
  top: 0;
  left: 0;
  opacity: 0.89;
}
.ExploreTags_fullScreen_top__2DBAk {
  bottom: 19vh;
  right: 0;
  margin-right: 3.8vw;
  position: fixed;
}
.ExploreTags_fullScreen_bottom__1qpvx {
  bottom: 10vh;
  right: 0;
  margin-right: 3.2vw;
  position: fixed;
}
.ExploreTags_modal__1jLZt .ExploreTags_modal-dialog__3xeiE {
  width: 50% !important;
}
.ExploreTags_whileBtns__2BmTb {
  width: 100%;
}

/* Grid css */
.ExploreTags_item__1V_Wf {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0.5rem auto;
  position: relative;
  /* border: 1px solid #f9fafb; */
  border-radius: 2px;
  transition: 0.3s ease;
  font-size: 0;
  background-color: white;
  border-radius: 4px;
}

.ExploreTags_item__1V_Wf img {
  max-width: 100%;
}

.ExploreTags_content__ms1t9 {
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in-out;
  opacity: 0;
  /* border: 1px solid black; */
}

.ExploreTags_content__ms1t9 div {
  opacity: 0;
  transition-delay: 1s;
  transition: 0.3s ease;
  font-size: 20px;
}

.ExploreTags_item__1V_Wf:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}
.ExploreTags_item__1V_Wf:hover .ExploreTags_content__ms1t9 {
  opacity: 1;
  border-radius: 4px;
}
.ExploreTags_item__1V_Wf:hover .ExploreTags_content__ms1t9 div {
  opacity: 1;
  color: #f9fafb;
}

.ExploreTags_gridContainer__t6u2_ {
  margin: 3rem auto;
}

.ExploreTags_gridRow__1Uyv5 {
  column-width: 18em;
  -moz-column-width: 20em;
  -webkit-column-width: 20em;

  grid-column-gap: 1rem;

  column-gap: 1rem;
  -moz-column-gap: 1rem;
  -webkit-column-gap: 1rem;
}

.ExploreTags_my_masonry_grid__2I314 { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
  margin-top: 50px;
}
.ExploreTags_my_masonry_grid_column__27_Oh {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.ExploreTags_my-masonry_grid_column__3DGeY > div {
  margin-bottom: 30px;
}

.ExploreTags_text1__3b8VU {
  font-weight: 600;
  font-size: '12px';
  font-family: 'QuickSand';
  margin-top: 20px;
}

.ExploreTags_tagBtnss2__3ZZ3N {
  padding: 2% 3%;
  margin-top: 18px;
  margin-right: 10px;
  background-color: #ffffff;
  color: black;
  font-weight: 600;
  border: 1px solid #a0a0a0;
  font-size: 12px;
  font-family: 'Quicksand';
  min-width: 30%;
}
.ExploreTags_tagBtnss1__1DPOm {
  padding: 2% 3%;
  margin-top: 18px;
  margin-right: 10px;
  background-color: white;
  color: black;
  font-weight: 600;
  border: 1px solid #f4a293;
  font-size: 12px;
  font-family: 'Quicksand';
  min-width: 30%;
}
/* .tagBtnss1 {
    padding: 3%;
    margin-top: 10px;
    margin-right: 10px;
    background-color: white;
    color: black;
    font-weight: 600;
   
    border: 1px solid #F4A293;
    font-size: 12px;
    font-family: "Quicksand";
    min-width: 40%;
  } */
@media screen and (max-width: 500px) {
  .ExploreTags_mainHeader__1Vw-W {
    padding: 30px;
    text-align: left;
  }
  .ExploreTags_mainHeader__1Vw-W h6 {
    opacity: 1;
  }
  .ExploreTags_modalRow__1AAqW {
    padding: 19px 0px 50px 0px;
  }
  .ExploreTags_modalRow1__3IsVG {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
  }
  .ExploreTags_modalRow__1AAqW {
    height: auto;
    /* margin: 20px; */
  }
  .ExploreTags_filterImg__2a3Jx {
    margin-bottom: 30px;
  }
  .ExploreTags_my_masonry_grid_column__27_Oh {
    padding-bottom: 35px;
  }
  .ExploreTags_button__1NJYh {
    width: 126px;
    height: 37px;
    font-size: 14px;
  }
  .ExploreTags_text1__3b8VU {
    margin-top: 19px;
  }
  .ExploreTags_mainHeader__1Vw-W h2 {
    font-size: 25px;
  }
  .ExploreTags_tagBtnss2__3ZZ3N {
    min-width: 29%;
    padding: 2% 1%;
  }
}
@media screen and (max-width: 2000px) {
  .ExploreTags_text1__3b8VU {
    font-weight: 600;
    font-size: '20px';
    font-family: 'QuickSand';
  }
}

@media screen and (min-width: 1500px) {
  .ExploreTags_container__1YsbV {
    max-width: 1640px;
  }
}

.Explore_container__1s4fI {
  margin-top: 120px;
  font-family: 'Quicksand';
  padding-bottom: 50px;
}

.Explore_menu__EtUEr {
  font-size: small;
  font-weight: 600;
  cursor: pointer;
}

.Explore_categoryHeading__2Yvro {
  margin: 1.5rem 0 1.5rem 0;
  font-weight: 700;
  font-size: larger;
}

.Explore_mainHeader__2YExY {
  text-align: center;
  justify-content: center !important;
  font-family: 'Quicksand';
}

.Explore_mainHeader__2YExY h2 {
  font-size: 30px;
}

.Explore_mainHeader__2YExY h6 {
  margin-top: 1vh;
  font-size: 17px;
  opacity: 1;
  font-weight: 400;
}
.Explore_filterImg__3xnAg {
  margin-bottom: 50px;
}
.Explore_modal__2_NF8 {
  border: none !important;
  height: 100% !important;
  overflow: scroll;
  width: 70% !important;
}

.Explore_modal__2_NF8 .Explore_modal-content__gSYOc {
  width: 70% !important;
}

.Explore_modal_backdrop__UPjne {
  background-color: rgba(250, 253, 250, 0.4) !important;
}
.Explore_productImg__1sgI8 {
  width: 100%;
  height: 330px;
}

.Explore_modalRow__3MqU4 {
  padding: 20px 50px 20px 50px;
  height: 600px;
  position: relative;
}

.Explore_Model3DIconbtn__3XHE3 {
  position: absolute;
  /* margin-left: '7px'; */
  bottom: 10px;
  border-radius: 5px;
  justify-content: center;
  align-content: center;
  text-align: center;
  width: 90px;
  height: 25px;
  left: 15px;
}

.Explore_arIcon__yPJ5G {
  position: absolute;
  bottom: 7px;
  width: 26px;
  right: 7px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}

.Explore_Icon3d__192Ke {
  position: absolute;
  bottom: 0px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}

.Explore_Icon3d__192Ke:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.Explore__3dIconbtn__eJGIp {
  position: absolute;
  bottom: 7px;
  width: 26px;
  height: 26px;
  left: 10px;
  background: none;
}
.Explore_arIconbtn__1mrPe {
  position: absolute;
  bottom: 7px;
  width: 26px;
  right: 7px;
}
.Explore_arIcon__yPJ5G:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.Explore_filterButton__1-y4a {
  border-radius: 30px;
  margin-top: 10px;
  padding: 5px 20px 5px 20px;
  background-color: #000000;
  color: #ffffff;
  display: inline-flex;
}

.Explore_tagBtns__ugUf5 {
  padding: 6px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  background-color: #ffffff;
  color: black;
  font-weight: 500;
  border: none;
  border: 1px solid #a0a0a0;
  min-width: 125px;
  text-transform: capitalize;
}

.Explore_tagBtnss__1BqbC {
  padding: 3%;
  /* padding-left: 20px;
  padding-right: 20px; */
  margin-top: 10px;
  /* font-size: 0.6rem; */
  margin-right: 10px;
  /* border-radius: 50%; */
  background-color: #ffffff;
  color: black;
  font-weight: 600;
  /* height: 30px; */
  /* border: none; */
  border: 1px solid #a0a0a0;
  font-size: 14px;
  font-family: 'Quicksand';
  min-width: 40%;
}

.Explore_tagBtnsss__2XADD {
  padding: 7px 24px;
  margin-top: 32px;
  margin-left: 23px;
  background-color: #ffffff;
  color: black;
  border: 1px solid #1a1818;
  font-size: 13px;
  font-family: 'Quicksand';
  margin-bottom: 50px;
  font-weight: 600;
  height: 40px;
}
button:focus {
  box-shadow: none !important;
}
.Explore_tagBtnsss__2XADD:hover {
  background-color: black;
  color: white;
}
.Explore_text1__2r5Ec {
  font-weight: 600;
  font-size: '14px';
  font-family: 'QuickSand';
}

.Explore_overlay__hBq_y {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  bottom: 0;
  background-color: #2c2c2cb5;
}

.Explore_footerButton__14EeM {
  border-radius: 30px;
  background-color: #ffffff;
  color: #000000;
  border: 0.5px solid #707070;
  padding: 8px 10px 8px 10px;
  width: 150px;
  margin-bottom: 10px;
}

.Explore_buttonWhite__3TST5 {
  border-radius: 25px;
  color: black;
  background-color: white;
  padding: 5px 3em 5px 3em;
  font-weight: 400;
  height: 40px;
  width: 100%;
  font-size: 13px;
}

.Explore_button__lz3BY {
  width: 131px;
  height: 42px;
  border-radius: 10px 10px 0 0;
  border: none;
  float: right;
  margin-top: -7.5vh;
  margin-right: 5vw;
  position: -webkit-sticky;
  position: sticky;
  bottom: -6px;
  right: 0px;
  z-index: 100;
  background-image: linear-gradient(to right, #ffe5a0 0%, #ffce4a 100%);
  font-size: 16px;
  font-weight: 700;
}

.Explore_createButton__3fq3- {
  width: 131px;
  height: 42px;
  border-radius: 10px 10px 0 0;
  border: none;
  position: absolute;
  top: -42px;
  right: 50px;
  z-index: 100;
  background-image: linear-gradient(to right, #ffe5a0 0%, #ffce4a 100%);
  font-size: 16px;
  font-weight: 700;
}

.Explore_fullScreen__3-tiR {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: #2c2c2c;
  top: 0;
  left: 0;
  opacity: 0.89;
}
.Explore_fullScreen_top__3Cb-4 {
  bottom: 19vh;
  right: 0;
  margin-right: 3.8vw;
  position: fixed;
}
.Explore_fullScreen_bottom__1rZVc {
  bottom: 10vh;
  right: 0;
  margin-right: 3.2vw;
  position: fixed;
}
.Explore_modal__2_NF8 .Explore_modal-dialog__KTaRY {
  width: 50% !important;
}
.Explore_whileBtns__2tV4e {
  width: 100%;
}

/* Grid css */
.Explore_item__36bcr {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0.5rem auto;
  position: relative;
  /* border: 1px solid #f9fafb; */
  border-radius: 2px;
  transition: 0.3s ease;
  font-size: 0;
  background-color: white;
  border-radius: 4px;
}

.Explore_item__36bcr img {
  max-width: 100%;
}

.Explore_content__3OqL0 {
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in-out;
  opacity: 0;
  /* border: 1px solid black; */
}

.Explore_content__3OqL0 div {
  opacity: 0;
  transition-delay: 1s;
  transition: 0.3s ease;
  font-size: 20px;
}

.Explore_item__36bcr:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}
.Explore_item__36bcr:hover .Explore_content__3OqL0 {
  opacity: 1;
  border-radius: 4px;
}
.Explore_item__36bcr:hover .Explore_content__3OqL0 div {
  opacity: 1;
  color: #f9fafb;
}

.Explore_gridContainer__1mkQJ {
  margin: 3rem auto;
}

.Explore_gridRow__17LcA {
  column-width: 18em;
  -moz-column-width: 20em;
  -webkit-column-width: 20em;

  grid-column-gap: 1rem;

  column-gap: 1rem;
  -moz-column-gap: 1rem;
  -webkit-column-gap: 1rem;
}

.Explore_my_masonry_grid__2l0AH { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
  margin-top: 50px;
}
.Explore_my_masonry_grid_column__AUZtQ {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.Explore_my-masonry_grid_column__2xzkG > div {
  margin-bottom: 30px;
}

.Explore_text1__2r5Ec {
  font-weight: 600;
  font-size: '12px';
  font-family: 'QuickSand';
  margin-top: 20px;
}

.Explore_tagBtnss2__29zal {
  padding: 2% 3%;
  margin-top: 18px;
  margin-right: 10px;
  background-color: #ffffff;
  color: black;
  font-weight: 600;
  border: 1px solid #a0a0a0;
  font-size: 12px;
  font-family: 'Quicksand';
  min-width: 30%;
}
.Explore_tagBtnss1__3SGST {
  padding: 2% 3%;
  margin-top: 18px;
  margin-right: 10px;
  background-color: white;
  color: black;
  font-weight: 600;
  border: 1px solid #f4a293;
  font-size: 12px;
  font-family: 'Quicksand';
  min-width: 30%;
}
/* .tagBtnss1 {
  padding: 3%;
  margin-top: 10px;
  margin-right: 10px;
  background-color: white;
  color: black;
  font-weight: 600;
 
  border: 1px solid #F4A293;
  font-size: 12px;
  font-family: "Quicksand";
  min-width: 40%;
} */
@media screen and (max-width: 500px) {
  .Explore_mainHeader__2YExY {
    padding: 30px;
    text-align: left;
  }
  .Explore_mainHeader__2YExY h6 {
    opacity: 1;
  }
  .Explore_modalRow__3MqU4 {
    padding: 19px 0px 50px 0px;
  }
  .Explore_modalRow1__1VY5E {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
  }
  .Explore_modalRow__3MqU4 {
    height: auto;
    /* margin: 20px; */
  }
  .Explore_filterImg__3xnAg {
    margin-bottom: 30px;
  }
  .Explore_my_masonry_grid_column__AUZtQ {
    padding-bottom: 35px;
  }
  .Explore_button__lz3BY {
    width: 126px;
    height: 37px;
    font-size: 14px;
  }
  .Explore_text1__2r5Ec {
    margin-top: 19px;
  }
  .Explore_mainHeader__2YExY h2 {
    font-size: 25px;
  }
  .Explore_tagBtnss2__29zal {
    min-width: 29%;
    padding: 2% 1%;
  }
}
@media screen and (max-width: 2000px) {
  .Explore_text1__2r5Ec {
    font-weight: 600;
    font-size: '20px';
    font-family: 'QuickSand';
  }
}

@media screen and (min-width: 1500px) {
  .Explore_container__1s4fI {
    max-width: 1640px;
  }
}

.ProductReframe_image_gallery_thumbnail__1WIHq:hover {
    border: 2px solid black !important;
}
.ProductReframe_image_gallery_thumbnail__1WIHq.ProductReframe_active__3M_ZC,
.ProductReframe_image_gallery_thumbnail__1WIHq:focus {
    border: 2px solid black !important;
}
.ProductReframe_image-gallery-slide__1bOUV.ProductReframe_center__RRae3 {
    position: relative;
    object-fit: contain !important;
}

.ProductReframe_breadCrumbs__11frZ{
    padding: 10px;
    position: relative;
    display: flex;
    margin: 0px auto;
    width: 80%;
    margin-top: 20px;
    flex-direction: row;
    grid-gap: 2px;
    gap: 2px;
    padding-left: 5px;
    cursor: pointer;
}
.ProductReframe_productWeb__1gC0u{
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    padding: 4px 4px 4px 4px;
}
.ProductReframe_productBodyWeb__2dCk4{
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 80%;
    height: -webkit-max-content;
    height: max-content;
    margin: 0px auto;
    flex-direction: row;
    top: 20px;
    grid-gap: 10px;
    gap: 10px;
    bottom: 10px;
}
.ProductReframe_productDescriptionContainer__2O5gf{
    position: relative;
    margin: 0px auto;
    width: 80%;
    padding: 20px 0px 0px 0px;
}
.ProductReframe_productImagesWeb__oG_KC{
    position: relative;
    padding: 0px 4px 4px 4px;
    height: 500px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    overflow: scroll;
    width: -webkit-max-content;
    width: max-content;
}
.ProductReframe_variantImageWeb__1zfTk{
    position: relative;
    width: 100px;
    height: 100px;
}
.ProductReframe_image_gallery_web__3dUO3{
    position: relative;
    padding: 0px 4px 4px 4px;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    overflow-y: scroll;
    width: -webkit-max-content;
    width: max-content;
}

.ProductReframe_ImageWeb__pDQts{
    width: 35%;
    /* width: 500px; */
    height: 500px;
    background-color: white;
}
.ProductReframe_Image__zP0vx{
    position: relative;
    height: 500px;
}
.ProductReframe_renderImage__3nJn8{
    position: relative;
    width: 100% !important;
    height: 500px !important;
}
.ProductReframe_arImageWeb__2ffj8{
    position: relative;
    padding: 4px 4px 4px 4px;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    bottom: 0;
    object-fit: contain;
}
.ProductReframe_arIconWeb__3L-5D:hover {
    border: 2px solid black !important;
  }
  .ProductReframe_arIconWeb__3L-5D.ProductReframe_active__3M_ZC,
  .ProductReframe_arIconWeb__3L-5D:focus {
    border: 2px solid black !important;
  }
.ProductReframe_arIconWeb__3L-5D{
    position: relative;
    width: 100px;
    height: 100px;
    bottom: 0;
    background-color: #FFFFFF;
    object-fit: contain;
}
.ProductReframe_productDetailsLeft___XT79{
    position: relative;
    display: flex;
    max-width: 40%;
    padding: 2px 2px 2px 2px;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}
.ProductReframe_productNameWeb__3DSP8{
    position: relative;
    width: 100%;
    font-size: larger;
    font-weight: bold;
}
.ProductReframe_vendorNameWeb__3hT5H{
    position: relative;
    color: #E39625;
    font-weight: bold;
    font-size: 18px;
    text-decoration: underline;
}
.ProductReframe_vendorNameWeb__3hT5H:hover{
    color: #E39625;
}
.ProductReframe_pricesWeb__2Clv5{
    position: relative;
    display: flex;
    flex-direction: row;
    grid-gap: 16px;
    gap: 16px;
}
.ProductReframe_productDescriptionWeb__hnLYH{
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
    height: auto;
}
.ProductReframe_productDescriptionWeb__hnLYH .ProductReframe_description__3BHjQ{
    margin: 0;
    padding: 0;
}
.ProductReframe_productDescriptionWeb__hnLYH .ProductReframe_readmore__1jBzR{
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    color: lightblue;
}
.ProductReframe_productVariantsWebList__1hNh2{
    position: relative;
    display: flex;
    padding: 2px 2px 2px 2px;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    max-width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    height: -webkit-max-content;
    height: max-content;
}
/* width */
::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
    background: #e5dcd5;
}
  
  /* Track */
::-webkit-scrollbar-track {
    border-radius: 50vh;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #e5cdb9;
    border-radius: 50vh;
}
.ProductReframe_buttonsWeb__1vCZ2{
    position: relative;
    display: flex;
    flex-direction: row;
    grid-gap: 16px;
    gap: 16px;
}
.ProductReframe_addWeb__2Fc9q{
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    padding-right: 40px;
    border: 2px solid black;
    background-color: white;
    font-size: large;
    border-radius: 50vh;
}
.ProductReframe_buyWeb__3wJbw{
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: black;
    font-size: large;
    color: white;
    border-radius: 50vh;
}
.ProductReframe_productDetailsRight__3cYz8{
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
    width: 18%;
    background-color: #FFFFFF;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
}
.ProductReframe_shareWeb__3Gmzw{
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 4px 4px 4px 12px;
    align-items: center;
    background-color: #F1EEEA;
}
.ProductReframe_shareWeb__3Gmzw:hover{
    cursor: pointer;
}
.ProductReframe_shareImage__3jTNZ{
    position: relative;
    display: flex;
    align-items:end;
    justify-items:right;
    width: 20px;
    height: 20px;
}
.ProductReframe_specifications__31ZRm{
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
    padding: 4px 4px 4px 4px;
    background-color: #F1EEEA;
}
.ProductReframe_specHeading__1fao9{
    position: relative;
    width: 100%;
    font-size: large;
    font-weight: 600;
    padding: 2px 2px 8px 10px;
}
.ProductReframe_bottomBorder__l6GkS{
    position: relative;
    width: 100%;
    border-bottom: 1px solid lightgray;
}
.ProductReframe_roomViewButtonWeb__21bd7{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F1EEEA;
    width: 100%;
    padding: 4px 4px 4px 12px;
}
.ProductReframe_vendorHouse__3Uqo9{
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    background-color: #F1EEEA;
    width: 100%;
    padding: 4px 4px 4px 2px;
}
.ProductReframe_returnPolicy__1tK1H{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F1EEEA;
    width: 100%;
    padding: 4px 4px 4px 4px;
}
.ProductReframe_returnPolicy__1tK1H:hover{
    cursor: pointer;
}
.ProductReframe_variantsHeading__1AYCB{
    font-weight: 700;
    font-size:large;
    width: 80%;
    position:'relative';
    top:'5px';
    margin: 0px auto;
}
.ProductReframe_variantsWeb__2soKZ{
    width: 80%;
    margin: 0px auto;
}
.ProductReframe_completeRoomWeb__2wSxc{
    margin: 0px auto;
    width: 80%;
    display: flex;
    margin-bottom: 25px;
    flex-direction: row;
}
.ProductReframe_roomImageContainerWeb__neis9{
    margin: auto;
    padding:6px;
    display: flex;
    flex-direction: column;
}
.ProductReframe_roomImageWeb__ScMu9{
    margin: auto;
    /* width: 100%; */
    /* height: 100%; */
    border: 1px solid #DEE3EC;
    background-color: #ffffff;
    box-shadow: 0px 0px 3px 0px #00000014;
}
.ProductReframe_roomList__147fi{
    position: relative;
    display: flex;
    margin: auto;
    width: 100%;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    overflow: scroll;
}



.ProductReframe_header__OHLfH{
    position: relative;
    height: 80px;
    display: flex;
    flex-direction: column;
}
.ProductReframe_curations__nuRt6{
    position: relative;
    display: flex;
    flex-direction: row;
}
.ProductReframe_productBody__2fUjP{
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 4px;
    padding-right: 8px;
    grid-gap: 10px;
    gap: 10px;
}
.ProductReframe_path__2HeA3{
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    /* padding-left: 5px; */
    font-size: small;
}
.ProductReframe_brandName__1jicd{
    position: relative;
    font-weight: bold;
    width: 100%;
    text-decoration: underline;
    font-size: medium;
    cursor: pointer;
}
.ProductReframe_arImage__3Zbke{
    position: relative;
    /* width: 100%; */
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    object-fit: contain;   
}
.ProductReframe_arIcon__2YDfD{
    position: relative;
    width: 75px;
    height: 75px;
    bottom: 0;
}
.ProductReframe_arMob__19kO8{
    position: relative;
    -webkit-animation: ProductReframe_animate__3aklL 1.5s alternate infinite ease-in;
            animation: ProductReframe_animate__3aklL 1.5s alternate infinite ease-in;
}
@-webkit-keyframes ProductReframe_animate__3aklL{
    0%{
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
    }
    100% {
        -webkit-transform:scale(1.3);
                transform:scale(1.3) 
    }
}
@keyframes ProductReframe_animate__3aklL{
    0%{
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
    }
    100% {
        -webkit-transform:scale(1.3);
                transform:scale(1.3) 
    }
}
.ProductReframe_productImages__1aom5{
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    gap: 6px;
    width: -webkit-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: max-content;
}
.ProductReframe_productImageBody__1xbHS{
    position: relative;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: row;
    grid-gap: 2px;
    gap: 2px;
}
.ProductReframe_image_gallery_thumbnails_container__3kORa{
    position: relative;
    padding: 0px 4px 4px 4px;
    height: 220px !important;
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
    overflow-y: scroll;
    width: -webkit-max-content;
    width: max-content;
}
.ProductReframe_arButton__1AytT{
    position: relative;
    /* display: flex; */
    bottom: 0;
}
.ProductReframe_productVarients__3iHuN{
    position: relative;
    display: flex !important;
    grid-gap: 8px;
    gap: 8px;
    flex-direction: row;
    overflow-x: scroll;
    scroll-behavior:smooth;
}
.ProductReframe_card__1KwCa{
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.ProductReframe_varientImage__2fNzI{
    /* position: relative; */
    display: flex !important;
    flex: 0 0 auto;
    margin-right: 10px;
    width: 150px !important;
    height: 150px;
}
#ProductReframe_image_gallery_content__k6SfM{
    position: relative;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}
.ProductReframe_price__3FkC2{
    position: relative;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}
.ProductReframe_pixelprice__ByEV-{
    position: relative;
    font-weight: bold;
}
.ProductReframe_roomtag__3iiwj{
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}
.ProductReframe_roomview__3cBii{
    position: relative;
    font-weight: bold;
    text-decoration: underline;
}
.ProductReframe_buttons__1vmHi{
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    padding-left: 8px;
    padding-right: 8px;
}
.ProductReframe_button__3rEE_{
    position: relative;
    padding: 10px 10px 10px 10px;
    border: 2px solid black;
    border-radius: 50vh;
    font-size: larger;
    font-weight: bold;
    background-color: white;
}
.ProductReframe_buy__3pQK5{
    position: relative;
    padding: 10px 10px 10px 10px;
    border-radius: 50vh;
    background-color: black;
    font-size: larger;
    font-weight: bold;
    color: white;
}
.ProductReframe_dropdowns__1alXe{
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}
.ProductReframe_share__2WC1u{
    position: relative;
    display: flex;
    font-size: larger;
    padding-left: 2px;
    font-weight: bolder;
    flex-direction: row;
}
.ProductReframe_share__2WC1u:hover{
    cursor: pointer;
}
.ProductReframe_varientHeading__BOgio{
    position: relative;
    display: flex;
    font-size: larger;
    padding-left: 2px;
    font-weight: bolder;
    flex-direction: row;
}
.ProductReframe_variantDetails__2k-fk{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 4px 4px 4px 4px;
}
.ProductReframe_varient_Image__3O4Wo{
    position: relative;
    width: 100%;
    height: 240px;
}
.ProductReframe_varientProductDetails__2Flzj{
    position: relative;
    background-color: #F1EEEA;
    padding: 4px;
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}
.ProductReframe_varientprices__b7dch{
    position: relative;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    font-size: large;
}
.ProductReframe_completeRoom__Kk9So{
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
}
.ProductReframe_roomHead__1xNOh{
    position: relative;
    display: flex;
    font-size: large;
    padding-left: 2px;
    font-weight: bold;
}
.ProductReframe_roomImage__3zI7c{
    position: relative;
    width: 100%;
    height: 200px;
    background-color: white;
}
.ProductReframe_relatedProducts__1TZA_{
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
}
.ProductReframe_relatedProductsHead__ffT24{
    position: relative;
    display: flex;
    font-size: large;
    padding-left: 2px;
    font-weight: bold;
}
.ProductReframe_choose__1wXSm{
    position: relative;
    width: 100%;
    font-weight: bold;
}
.ProductReframe_relatedProductDetails__KYc8-{
    position: relative;
    background-color: #F1EEEA;
    padding: 4px;
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}
@media (max-width: 640px) {
    .ProductReframe_accordion-item__3Ch4J .ProductReframe_accordion-collapse__3djA8 {
      display: none;
    }
    .ProductReframe_completeRoomWeb__2wSxc{
        margin: 0px auto;
        margin-bottom: 25px;
        width: 100%;
    }
    .ProductReframe_variantsHeading__1AYCB{
        font-weight: 700;
        font-size:large;
        width: 100%;
        position:'relative';
        top:'5px';
        margin: 0px auto;
    }
    .ProductReframe_productNameMob__1p2Ae{
        position: relative;
        margin-top: 10px;
    }
    .ProductReframe_header__OHLfH{
        position: relative;
        height: 100px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .ProductReframe_roomIcon__22uRp{
        position: relative;
        display: flex;
        flex-direction: row;
    }
    .ProductReframe_productImage__wGr9O{
        position: relative;
        padding-right: 4px !important;
        width: 100%;
        height: 300px;
        object-fit: contain;
    }
    .ProductReframe_arImage__3Zbke{
        position: relative;
        /* width: 100%; */
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        object-fit: contain;   
    }
    .ProductReframe_mobImage__2BhZH{
        position: relative;
        width: 280px;
        height: 300px;
    }
    .ProductReframe_model__SPfv6{
        position: relative;
        width: 100%;
        height: 100%;
    }
  }

@media (min-width : 768px) and (max-width: 1023px) {
    .ProductReframe_productBody__2fUjP{
        position: relative;
        display: flex;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        padding-left: 4px;
        padding-right: 8px;
        grid-gap: 10px;
        gap: 10px;
    }
    .ProductReframe_model__SPfv6{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .ProductReframe_productNameMob__1p2Ae{
        position: relative;
        width: 80%;
        margin: 0px auto;
        margin-top: 40px;
    }
    .ProductReframe_brandName__1jicd{
        position: relative;
        width: 100%;
        margin: 0px auto;
    }
    .ProductReframe_roomIcon__22uRp{
        position: relative;
        margin: 0px auto;
        width: 80%;
        display: flex;
        flex-direction: row;
    }
    .ProductReframe_choose__1wXSm{
        position: relative;
        margin: 0px auto;
        width: 80%;
        font-weight: bold;
    }
    .ProductReframe_roomtag__3iiwj{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: end;
    }
    .ProductReframe_price__3FkC2{
        position: relative;
        margin: 0px auto;
        width: 80%;
    }
    .ProductReframe_buttons__1vmHi{
        position: relative;
        margin: 0px auto;
        width: 80%;
    }
    .ProductReframe_dropdowns__1alXe{
        position: relative;
        margin: 0px auto;
        width: 80%;
    }
    .ProductReframe_productVarients__3iHuN{
        position: relative;
        margin: 0px auto;
        width: 80%;
    }
    .ProductReframe_variantDetails__2k-fk{
        position: relative;
        margin: 0px auto;
        width: 80%;
    }
    .ProductReframe_relatedProducts__1TZA_{
        position: relative;
        margin: 0px auto;
        width: 80%;
    }
    .ProductReframe_productImage__wGr9O{
        position: relative;
        display: flex;
        padding-right: 4px !important;
        width: 100%;
        background-color: #FFFFFF;
        height: 400px;
        object-fit: contain;
    }
    .ProductReframe_productImages__1aom5{
        position: relative;
        display: flex;
        flex-direction: column;
        grid-gap: 6px;
        gap: 6px;
        width: -webkit-max-content;
        width: max-content;
        height: 400px;
    }
    .ProductReframe_productImageBody__1xbHS{
        position: relative;
        width: 80%;
        margin: 0px auto;
        height: -webkit-max-content;
        height: max-content;
        display: flex;
        flex-direction: row;
        grid-gap: 36px;
        gap: 36px;
    }
    .ProductReframe_image_gallery_thumbnails_container__3kORa{
        position: relative;
        padding: 0px 4px 4px 4px;
        height: -webkit-max-content !important;
        height: max-content !important;
        display: flex;
        flex-direction: column;
        grid-gap: 2px;
        gap: 2px;
        overflow-y: scroll;
        max-width: -webkit-max-content;
        max-width: max-content;
    }
    .ProductReframe_arImage__3Zbke{
        position: relative;
        /* width: 100%; */
        /* height: 100%; */
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        object-fit: contain;   
    }
    .ProductReframe_image_gallery_image__1shaZ{
        position: relative;
        width: 400px;
        height: 350px;
    }
    .ProductReframe_mobImage__2BhZH{
        position: relative;
        width: 400px;
        height: 400px;
    }
}

@media (min-width: 1024px) and (max-width: 1380px) {
    .ProductReframe_productBodyWeb__2dCk4{
        position: relative;
        display: flex;
        width: 90%;
        margin: 0px auto;
        flex-direction: row;
        top: 20px;
        bottom: 20px;
        grid-gap: 10px;
        gap: 10px;
    }
    .ProductReframe_ImageWeb__pDQts{
        width: 35%;
        object-fit: contain !important;
        height: 500px;
        background-color: white;
    }
    .ProductReframe_image-gallery-slide__1bOUV.ProductReframe_center__RRae3 {
        position: relative;
        height: 450px !important;
        object-fit: contain !important;
    }
    .ProductReframe_renderImage__3nJn8{
        position: relative;
        width: 100% !important;
        height: 450px !important;
    }
    .ProductReframe_productDetailsLeft___XT79{
        position: relative;
        display: flex;
        max-width: 35%;
        padding: 2px 2px 2px 2px;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
    }
    .ProductReframe_productDetailsRight__3cYz8{
        position: relative;
        display: flex;
        flex-direction: column;
        grid-gap: 12px;
        gap: 12px;
        width: 20%;
        background-color: #FFFFFF;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        padding-right: 8px;
    }
    .ProductReframe_addWeb__2Fc9q{
        position: relative;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 20px;
        padding-right: 20px;
        border: 2px solid black;
        background-color: white;
        font-size: large;
        border-radius: 50vh;
    }
    .ProductReframe_buyWeb__3wJbw{
        position: relative;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: black;
        font-size: large;
        color: white;
        border-radius: 50vh;
    }
    .ProductReframe_productDescriptionContainer__2O5gf{
        position: relative;
        margin: 0px auto;
        width: 90%;
    }
    .ProductReframe_variantsHeading__1AYCB{
        font-weight: 700;
        font-size: 'large';
        width: 90%;
        position:'relative';
        top:'5px';
        margin: 0px auto;
    }
    .ProductReframe_variantsWeb__2soKZ{
        width: 90%;
        margin: 0px auto;
    }
    .ProductReframe_completeRoomWeb__2wSxc{
        margin: 0px auto;
        margin-bottom: 25px;
        width: 90%;
    }
    .ProductReframe_breadCrumbs__11frZ{
        position: relative;
        display: flex;
        margin: 0px auto;
        margin-top: 22px;
        width: 90%;
        flex-direction: row;
        grid-gap: 2px;
        gap: 2px;
        padding-left: 5px;
    }
}
  
.Shoppage_shopMainCont__Yi_Wv{
    margin-top: 75px;
    width: 100%;
    padding-bottom: 80px;
   
}

.Shoppage_filterCont__TQ8uI{
    width: 100%;
    background-color: #F1EEEA;
    box-shadow: 0px 4px 12px 0px #0000000F;
    
}

.Shoppage_filterInnerCont__2nJmI{
    display: flex;
    flex-direction: row;
    width: 80%;
    padding: 20px 10px;
    margin: auto;
    justify-content: space-between;
   

}

.Shoppage_filterInnerHeading__1RQD1{
    font-weight: 700;
    font-size: 24px;
    color: #171717;
    margin-bottom: 1rem
}

.Shoppage_badgeContainer__3RWDi{
    display: flex;
    flex-wrap: wrap;
}

.Shoppage_budgetContainer__1MRkG{
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 1rem
}

.Shoppage_sliderBox__vTFrJ{
    width: 120px;
    height: 39px;
    background: #FFFFFF;
    border: 0.5px solid #A5A5A5;
    margin-right: 0.5rem;
    padding: 4px;
}



.Shoppage_sliderBoxInner__3bVHq{
    margin-bottom:2.5px;
    line-height:1;
    font-size: 11px;
    font-weight: 400;
    color:#A5A5A5;
}

.Shoppage_sliderBoxInnerValue__9pxQ0{
    margin:0;
    font-size: 13px;
    font-weight: 500;
    color: #171717;
}

.Shoppage_sliderInput__ujpse{
    font-size: 13px;
    border: none;
    outline: none;
    width: 100%;
    line-height: 1
}

.Shoppage_filterBtnCont__21N4W{
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 1rem;
    align-items: end;
}

.Shoppage_shopCont2__wOF7J{
    background-color: #F5F5F5;
    width: 100%;
    padding-top: 2rem;
}
.Shoppage_moodboardCont__2quTD{
    width: 80%;
    max-width: 1250px;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 4rem;

}

.Shoppage_moodboardContTitle__1Z3mU{
    font-size: 18px;
    font-weight: 700;
    color: #171717;
    margin-bottom: 0.5rem;
    text-align: center;
}

.Shoppage_moodboardContSubTitle__1wS7E{
    font-size: 15px;
    font-weight: 600;
    color: #171717;
    margin-bottom: 1rem;
    text-align: center;
   
}
.Shoppage_shopCont4__1UuKF{
    background-color: #ffffff;
    width: 100%;
}

.Shoppage_categoryContainer__1i4Ct{
    width: 80%;
    margin:  auto;
    padding-bottom: 4rem;
    padding-top: 2rem;
  
}

.Shoppage_bannerCont__3KLNH{
    width: 90%;
    margin: 2rem auto;
    position: relative;
    
}

.Shoppage_bannerImage__2AnJC{
    width:100%;
    height: 100%;
    object-fit: contain;
}

/* .bannerHotspot{
    height: 24px;
    width: 24px;
    background-color: #ffffff;
    border-radius: 100%;
    position: absolute;
} */

.Shoppage_bannerContent__d9Acq{
    height: 100%;
    width: 40%;
    position: absolute;
    top:0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 15px;
}

.Shoppage_bannerText__3ucDk{
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;
    line-height: 1;
}

.Shoppage_newArrivalContainer__2_dD6{
    width: 80%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin: auto;
    
}

.Shoppage_bestSellersContainer__1rskH{
    width: 80%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin: auto;
}

.Shoppage_hireDesignerBanner__1pFMG{
    display: flex;
    align-items: center;
    background-color: #D9EFF5;
    color: #171717;
    width: 100%;
    height: 240px;
    margin: 4rem auto 2rem auto
    /* margin-top: 12rem; */
}

.Shoppage_hireDesignerBannerMob__2DJi5{
    display: none;
}

@media only screen and (max-width: 1000px) {
    .Shoppage_filterInnerCont__2nJmI{
        flex-direction: column;
        width:100%;
        justify-content: unset;
    }

    .Shoppage_filterBtnCont__21N4W{
        justify-content: start;
    } 

    .Shoppage_bannerCont__3KLNH{
        width: 95%;
    }
    .Shoppage_bannerContent__d9Acq{
        width: 55%;
       
    }

    .Shoppage_bannerText__3ucDk{
        font-size: 28px;
    }
     
    .Shoppage_hireDesignerBanner__1pFMG{
        display: none;
    }

    .Shoppage_hireDesignerBannerMob__2DJi5{
        height: 450px;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        background-color: #D9EFF5;
        padding:20px 20px 0 20px;
        text-align: center;
    }

    .Shoppage_moodboardCont__2quTD{
        width: 90%;
       
    }

}

@media only screen and (max-width: 600px) {
    .Shoppage_filterInnerHeading__1RQD1{
        padding-top: 15px;
        font-size: 16px;
    }

    .Shoppage_categoryContainer__1i4Ct{
        width: 90%;
    }

    .Shoppage_bannerCont__3KLNH{
        width: 100%;
        left: 0;
        justify-content: start;
    }

    .Shoppage_bannerText__3ucDk{
        font-size: 24px;
    }

    .Shoppage_bannerCont__3KLNH{
        height: 60vh;
    }

    .Shoppage_bannerContent__d9Acq{
        width: 90%;
        left: 0;
        justify-content: start;
        padding: 25px 15px 15px 15px;
    }

    .Shoppage_bannerImage__2AnJC{
        object-fit: cover;
    }

    .Shoppage_newArrivalContainer__2_dD6{
        width: 100%;  
    }
    .Shoppage_moodboardCont__2quTD{
        width: 90%;
        padding: 10px;
    }

    .Shoppage_bestSellersContainer__1rskH{
        width: 100%;  
    }
}
.Partner_wholeContainer__3PUiE {
  width: 100%;
  height: auto;
}
.Partner_bannerBg__3N7eO {
  width: 100%;
  text-align: center;
  padding: 100px 0px;
  background-image: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/partner/Group-81.png);
  background-size: 100%;
  padding-bottom: 25%;
  background-repeat: no-repeat;
}
.Partner_headerTitle__3OwnF {
  text-align: center;
  font-weight: 600;
  font-size: 50px;
}
.Partner_buttonBlack__2snTh {
  border-radius: 30px;
  margin-top: 35px;
  padding: 11px 40px;
  background-color: #000000;
  color: #ffffff;
  display: inline-flex;
  font-size: 16px;
}
.Partner_bgImages__9IoE6 {
  background-color: #848b73;
  text-align: center;
  position: relative;
  margin-bottom: 63px;
}
.Partner_imgBan__35en7 {
  width: 80%;
  margin-top: -274px;
}
.Partner_bgImages__9IoE6 p {
  width: 70%;
  margin: 0px auto;
  margin-top: 58px;
  color: #ffffff;
  padding-bottom: 80px;
  font-size: 30px;
  font-weight: 500;
}
.Partner_pathLeft__3rYEF {
  position: absolute;
  bottom: 8%;
  left: 12%;
  width: 17%;
}
.Partner_pathRight__2HL5T {
  position: absolute;
  bottom: 7%;
  right: 0px;
  width: 10%;
}
.Partner_innerContainer__2kjf9 {
  width: 80%;
  margin: 0px auto;
}
.Partner_greySection__ZDUCx {
  background-color: #fafafa;
  padding: 90px 0px;
  margin-bottom: 30px;
}
.Partner_secImg__3UXO- {
  width: 100%;
}
.Partner_firstLeft__3NFOi {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 90px;
  width: 79%;
}
.Partner_firstLeft__3NFOi h2 {
  color: #101010;
  font-size: 37px;
  font-weight: 600;
}
.Partner_firstLeft__3NFOi p {
  color: #101010;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}
.Partner_showcase__4eV20 {
  text-align: center;
  font-weight: 600;
  font-size: 35px;
}
.Partner_logos__3l-RU {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: 0px auto;
  margin-top: 7%;
}
.Partner_logos__3l-RU img {
  width: 100%;
  padding: 0px 22px;
}
.Partner_logos__3l-RU img:nth-child(2) {
  width: 412px;
  height: 22px;
}
.Partner_formSection__1c1Cs {
  width: 100%;
  height: auto;
  background-image: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/partner/Group+3231png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.Partner_container5__2QNxt {
  width: 80%;
  /* background-color: #f5f0ec; */
  /* height: auto; */
  display: flex;
  justify-content: space-evenly;
  padding: 5vh 5vw 5vh 5vw;
  overflow: hidden;
}

.Partner_innerForm__nXoee {
  width: 50%;
  margin: 0px auto;

  text-align: center;
}
.Partner_innerForm__nXoee h3 {
  color: #101010;
  text-align: center;
  padding-top: 73px;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 61px;
}
.Partner_inputStyle__377vF {
  width: 100%;
  padding: 14px 12px;
  border-radius: 8px;
  border: 0.5px solid #b7b7b7;
  margin-bottom: 19px;
}
.Partner_form__3on0b {
  width: 50%;
  margin: 0px auto;
}
.Partner_submitTxt__vAvHd {
  color: #0d0909;
  font-size: 13px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 52px;
}

@media only screen and (max-width: 500px) {
  .Partner_headerTitle__3OwnF {
    font-size: 22px;
    padding: 0px 21px;
  }
  .Partner_imgBan__35en7 {
    margin-top: -43px;
  }
  .Partner_bgImages__9IoE6 p {
    margin-top: 35px;
    color: #ffffff;
    padding-bottom: 80px;
    font-size: 19px;
    font-weight: 500;
    width: 90%;
  }
  .Partner_pathRight__2HL5T {
    right: 1px;
    width: 30%;
  }
  .Partner_pathLeft__3rYEF {
    display: none;
  }
  .Partner_innerContainer__2kjf9 {
    width: 85%;
    margin: 0px auto;
  }
  .Partner_firstLeft__3NFOi {
    width: 100%;
    text-align: left;
    margin: 0px auto;
    padding: 0px 24px;
  }
  .Partner_Partner_secImg__3UXO-__1Besg {
    align-items: center;
  }
  .Partner_greySection__ZDUCx {
    padding: 39px 0px;
  }
  .Partner_firstLeft__3NFOi h2 {
    color: #101010;
    font-size: 25px;
    font-weight: 600;
    word-break: break-all;
  }
  .Partner_logos__3l-RU img {
    padding: 0px 22px;
    margin-top: 33px;
  }
  .Partner_showcase__4eV20 {
    text-align: center;
    font-weight: 600;
    font-size: 27px;
    padding: 0px 15px;
  }
  .Partner_innerForm__nXoee {
    width: 85%;
    margin: 0px auto;
    text-align: center;
  }
  .Partner_innerForm__nXoee h3 {
    color: #101010;
    text-align: left;
    padding-top: 73px;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 61px;
  }
  .Partner_form__3on0b {
    width: 95%;
    margin: 0px auto;
  }
  .Partner_logos__3l-RU {
    flex-wrap: wrap;
  }
}

.InteriorDesign_container__5bnkO {
  width: 100%;
  height: 100vh;
}

.InteriorDesign_button1__1HpvA {
  height: 50px;
  padding: 3px 30px 3px 30px;
  width: 12vw;
  background-color: white;
  border: 1px solid #000;
  border-radius: 30px;
  font-size: 1vw;
}

.InteriorDesign_button2__iw_nq {
  height: 50px;
  margin-left: 2vw;
  padding: 3px 1vh 3px 1vh;
  width: 12vw;
  background-color: black;
  color: white;
  border: 1px solid #000;
  border-radius: 30px;
  font-size: 1vw;
}

.back1 {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/common-images/Group+3095.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #d2edf5;
  display: grid;
}

.searchInput {
  width: 80%;
  border: none;
  outline: none;
  font-size: 15px;
  opacity: 0.7;
  background-color: white;
}

.sideBar {
  padding: 3% !important;
  background-color: #ffffff;
  height: 100vh;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 20px;
  width: 20%;
}

.faqContainer {
  width: 80%;
  padding: 50px;
}

.bannerContainer {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/faqbanner.png');
  height: 55vh;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 75px;
  background-position: right;
}

.mobBannerContainer {
  margin-top: 75px;
  width: 100%;
  background-color: #f5f0ec;
}

.mobButton {
  padding: 12px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  background-color: #f5f0ec;
  color: #ce8c95;
  font-weight: 500;
  border: none;
  border: 1.5px solid #ce8c95;
  min-width: 125px;
}

.mobButtonActive {
  padding: 12px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  background-color: #ce8c95;
  color: #ffffff;
  font-weight: 900;
  border: none;
  border: 1.5px solid #ce8c95;
  min-width: 125px;
}

.bannerContainerCol1 {
  background-color: #f5f0ec;
}

.text {
  font-size: xx-large;
  font-weight: 400;
  font-family: 'Quicksand';
}

.text1 {
  font-size: medium;
  font-weight: 500;
  font-family: 'Quicksand';
}

.queryForm {
  width: 65%;
}

.formInputs {
  width: 100%;
  color: black;
  border-color: #a5a5a5;
  border: 1px solid #a5a5a5 !important;
  outline-color: #a5a5a5;
  font-size: small;
}

.formInputs1 {
  width: 100%;
  font-size: small;
  color: black;
  border-color: #a5a5a5;
  border: 1px solid #a5a5a5 !important;
  outline-color: #a5a5a5;
}

.formInputs:focus {
  border: 0.5px solid !important;
}

.queryText {
  width: 80%;
}

.queryImageContainer {
  padding: 30px;
}

.mainContainer {
  background-color: rgb(245 240 236 / 35%);
}

.header {
  font-weight: 600;
  width: 200%;
  font-size: 2vw;
  margin-bottom: 4%;
}

.designButton {
  border-radius: 5vw;
  padding: 0.5vw 1vw 0.5vw 1vw;
  color: #ffffff;
  background-color: black;
  float: left;
  font-weight: 800;
  font-size: 1vw;
}

.container1 {
  width: 100%;
  height: 70vh;
  background-color: #ffffff;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.button1 {
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  font-weight: 400;
  padding: 5px 20px 5px 20px !important;
  margin-top: 5px;
}

.button1:hover {
  font-weight: 900;
  background-color: #000000 !important;
}

.button2 {
  border-radius: 30px;
  background-color: transparent;
  color: black;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  border: solid 1px;
}
@media only screen and (max-width: 600px) {
  .container1 {
    height: auto;
  }

  .queryImageContainer {
    padding: 15px;
  }

  .queryForm {
    width: 90%;
    padding-top: 30px;
  }

  .faqContainer {
    width: 100%;
    padding: 15px;
  }
}

.ProductFilter_filterBudgetCont__3ASzz{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 0.5rem;

}

.ProductFilter_filterBudgetHeader__7jd3v{
    font-size: 16px;
    font-weight: 400;
    color: #171717;
    margin-bottom: 0.5rem;
}

.ProductFilter_filterBudgetSliderCont__3P7uS{
    width: 95%;
}

.ProductFilter_filterBudgetRangeCont__wV4Mn{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.ProductFilter_sliderBox__TmdGR{
    max-width: 120px;
    width: 48%;
    height: 39px;
    background: #FFFFFF;
    border: 0.5px solid #A5A5A5;
    /* margin-right: 0.5rem; */
    padding: 4px;
}

/* .filterText{
    position: absolute;
    background-color: #F8F4F0;
    color: #171717;
    padding: 6px 20px;
    bottom:0;
    right:10px;
    font-size: 11px;
    font-weight: 500;

} */

.ProductFilter_sliderBoxInner__Q51ua{
    margin-bottom:2.5px;
    line-height:1;
    font-size: 11px;
    font-weight: 400;
    color:#A5A5A5;
}

.ProductFilter_sliderInput__1vouZ{
    font-size: 13px;
    border: none;
    outline: none;
    width: 100%;
    line-height: 1
}

.ProductFilter_sliderBoxInnerValue__3rJQQ{
    margin:0;
    font-size: 13px;
    font-weight: 500;
    color: #171717;
}

.ProductFilter_filterCheckboxCont__2WKZa{
    margin-top: 1.5rem;
}

.ProductFilter_filterButtonsCont__1VthF{
    display: flex;
    width: 100%;
    margin-top: 1.5rem;
    justify-content: space-between
}

.ProductFilter_filterClearBtn__2MCXE{
    max-width: 120px;
    width: 46%;
    height: 39px;
    padding: 10px 6px;
    cursor: pointer;
    background-color: #FFF;
    color: #171717;
    border: 1px solid #DEE3EC;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
}

.ProductFilter_filterApplyBtn__1nqpY{
    max-width: 120px;
    width: 46%;
    height: 39px;
    padding: 10px 6px;
    cursor: pointer;
    background-color: #171717;
    color: #ffffff;
    border: 1px solid #DEE3EC;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
}
.Curatedpage_curatedMainCont__2Hz24{
    width: 100%;
    margin-top: 75px;
    min-height: 100vh;
    /* padding-bottom: 200px; */
}

.Curatedpage_filterCont__GMRma{
    padding-top: 20px;
    width: 100%;
    background-color: #F1EEEA;
    box-shadow: 0px 4px 12px 0px #0000000F;
    
}

.Curatedpage_filterInnerCont__1012z{
    display: flex;
    flex-direction: row;
    width: 80%;
    padding: 20px 10px;
    margin: auto;
    justify-content: space-between;
    

}


.Curatedpage_filterInnerHeading__1SWmy{
    font-weight: 700;
    font-size: 24px;
    color: #171717;
    margin-bottom: 1rem
}

.Curatedpage_badgeContainer__3iTCN{
    display: flex;
    flex-wrap: wrap;
}

.Curatedpage_budgetContainer__2w49M{
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 1rem
}


.Curatedpage_sliderBox__1qHKM{
    width: 120px;
    height: 39px;
    background: #FFFFFF;
    border: 0.5px solid #A5A5A5;
    margin-right: 0.5rem;
    padding: 4px;
}



.Curatedpage_sliderBoxInner__d0DXc{
    margin-bottom:2.5px;
    line-height:1;
    font-size: 11px;
    font-weight: 400;
    color:#A5A5A5;
}

.Curatedpage_sliderBoxInnerValue__2T5y4{
    margin:0;
    line-height:1;
    font-size: 13px;
    font-weight: 500;
    color: #171717;
}

.Curatedpage_filterBtnCont__2yMPb{
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 1rem;
    align-items: end;
}

.Curatedpage_trendingThemesContainer__3ings{
    width: 80%;
    padding-top: 4rem;
    padding-bottom: 2rem;
    margin: auto;
}

.Curatedpage_trendingThemesContTitle__1oFGa{
    font-size: 28px;
    font-weight: 700;
    color: #171717;
}

.Curatedpage_moodboardCont__1r9hN{
    width: 80%;
    max-width: 1250px;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 4rem;

}

.Curatedpage_moodboardContTitle__15_kc{
    font-size: 16px;
    font-weight: 600;
    color: #171717;
    margin-left: 8px;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.Curatedpage_moodboardContTitle__15_kc:hover{
    text-decoration: underline;
}

.Curatedpage_moodBoardCard__1oS-s{
    width: 100%;
    max-width: 1250px;
}

.Curatedpage_stackedCarouselContainer__23Nru{
    width: 100%;
    /* background-color:  #F1EEEA; */
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background: linear-gradient(to bottom, #F1EEEA 70%, #ffffff 70%);
    background-image: url(https://d3izcfql7lesks.cloudfront.net/semiCirclesVectorBg.svg);
    background-size: 100%;

    /* height: 500px; */
}

.Curatedpage_stackedCarouselContTittle__2At4_{
    color: #171717;
    font-size: 28px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.Curatedpage_stackedCarouselBadgeContainer__2hSyF{
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: 2rem;
    
}



.Curatedpage_stackedCarouselContainerInner__1TKiK{
    width: 80%;
    margin:auto
}

.Curatedpage_stackedCarouselContainerInnerMost__3B6IH{
    width: 80%;
    margin:auto
}

.Curatedpage_sliderInput__2nIDX{
    font-size: 13px;
    border: none;
    outline: none;
    width: 100%;
    line-height: 1
}

@media only screen and (max-width: 1000px) {
    .Curatedpage_filterInnerCont__1012z{
        flex-direction: column;
        width:100%;
        justify-content: unset;
    }

    .Curatedpage_filterBtnCont__2yMPb{
        justify-content: start;
    } 

    .Curatedpage_moodboardCont__1r9hN{
        width: 90%;
    }

    .Curatedpage_moodboardContTitle__15_kc{
        text-decoration: underline;
    }

    .Curatedpage_stackedCarouselContainerInner__1TKiK{
        width: 90%;
     
    }

    .Curatedpage_stackedCarouselContainerInnerMost__3B6IH{
        width: 90%;
     
    }

    /* .stackedCarouselContainer{
        height: 450px;
    } */

}

@media only screen and (max-width: 600px) {
    .Curatedpage_filterInnerHeading__1SWmy{
        font-size: 16px;
    }

    .Curatedpage_moodboardCont__1r9hN{
        width: 100%;
        padding: 10px;
    }

    .Curatedpage_trendingThemesContainer__3ings{
        width: 100%;
        padding: 25px 10px 10px 10px;
    }

    .Curatedpage_stackedCarouselContainer__23Nru{
        width: 100%;
        /* background-color:  #F1EEEA; */
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;
        background: linear-gradient(to bottom, #F1EEEA 70%, #ffffff 70%);
        background-image: url(https://d3izcfql7lesks.cloudfront.net/semiCirclesVectorBg.svg);
        background-size: 100%;
        height: 560px;
    }
    /* .stackedCarouselContainer{
        height: 350px;
    } */

}
.categories_category__9Hoq5 {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 0px 160px
}

.categories_categoryImageContainer__2JOT5 img {
  width: 300px; 
  height: 300px;
}

.categories_categoryImage__1NX-O {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.categories_subcategory__1pfV8 { 
}

.categories_subcategoryImage__3wjpi {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.categories_subcategories__U6SbL {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.categories_subcategory__1pfV8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(16.666% - 10px);
  display: flex;
  flex-direction :column;
  align-items: center;
}

.categories_subcategoryContainer__3UUpm {
    position: relative;
    margin-top: 15px;
}

.categories_subcategoryImage__3wjpi {
  width: 150px; 
  height: auto;
  text-align: center;
  object-fit: cover;
}

.categories_categoryTitle__KGnim {
  text-align: center;
}

.categories_subcategoryTitle__2LHLb{
  text-align: center;
  display: grid;
}

.categories_typesSection__1Y2I- {
  width: 80%;
  margin: 0px auto;
  padding-top: 105px;
}

.categories_roomType__2jANr {
  font-size: 15px;
  margin-top: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width: 640px) {

  .categories_category__9Hoq5 {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-left: 0;
  }
  .categories_categoryImageContainer__2JOT5 img {
    width: 160px;
    height: 160px;
  }

  .categories_subcategoryContainer__3UUpm {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    max-width: 350px; 
  }

  .categories_subcategoryContainer__3UUpm {
    position: relative;
    margin-top: -4px;
}

  .categories_subcategoryImage__3wjpi {
    width: 100px;
  }

  .categories_subcategories__U6SbL {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    /* grid-gap: 8px;  */
    margin-top: 15px;
  }

  .categories_categoryTitle__KGnim {
    font-size: 18px;
  }

  .categories_subcategoryTitle__2LHLb {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

@media (min-width : 768px) and (max-width: 1023px) { 

  .categories_category__9Hoq5 {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: 0px 60px
  }

  .categories_categoryImageContainer__2JOT5 img {
    width: 300px;
    height: 300px;
  }

  .categories_subcategoryContainer__3UUpm {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center; 
  }

  .categories_subcategory__1pfV8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 10px); 
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: normal;
    word-wrap: break-word; 
  }

  .categories_subcategoryImage__3wjpi {
    width: 130px;
  }

  .categories_subcategories__U6SbL {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-gap: 18px;
  }

  .categories_categoryTitle__KGnim {
    font-size: 18px;
  }

  .categories_subcategoryTitle__2LHLb {
    text-align: center  ;
  }

}

@media (min-width: 1536px) {
  .categories_categoryImageContainer__2JOT5 img {
    width: 400px;
    height: 400px;
  }

  .categories_subcategory__1pfV8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(14.285% - 10px); 
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: normal;
    word-wrap: break-word; 
  }

  .categories_subcategoryImage__3wjpi {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  .categories_subcategories__U6SbL {
    display: grid;
    grid-template-columns: repeat(6, 1fr); 
    margin-top: 30px;
  }

  .categories_categoryTitle__KGnim {
    font-size: 18px;
  }

  .categories_subcategoryTitle__2LHLb {
    text-align: center;
  }

  .categories_subcategoryContainer__3UUpm {
    position: relative;
    margin-top: -4px;
    width: 100%;
}
}





.headTags_headTagContainer__10xAL{
    padding: 10px;
    width: 100%;
    height: 53px
}

@media only screen and (max-width: 600px){
    .headTags_headTagContainer__10xAL{
        height:auto;
        width:100%
    }
}

