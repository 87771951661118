.mesonry {
  text-align: center;
  width: 90%;
  margin-top: 20%;
}

.mesonryDesign {
  text-align: center;
  width: 65% !important;
  /* margin-top: 35vh; */
}

.nameContainer {
  /* text-align: center; */
  width: 70%;
  margin-top: 0px;
}

/* .mesonry div div:nth-child(2){
  text-align: center;
}
.mesonry div div:last-child{
  text-align: left;
}
.mesonry div:nth-of-type(even){
  
}
.mesonry div:nth-of-type(odd){
  
}
.mesonry div div{

} */
.nameContainer input {
  width: 80%;
  border-color: #707070;
  border: none;
  border-bottom: 1px solid;
  background-color: transparent;
  font-size: 24px;
}
.nameContainer input:focus-visible {
  outline: none;
}
.canvasjs-chart-credit {
  display: none;
}
.emailTextStyle {
  width: 80%;
}
.mesonryRooms {
  text-align: center;
  width: 85%;
  /* height: 100%; */
}
.welcomeLottie {
  /* width: 470px; */
  margin: 0px auto;
  text-align: center;
  font-size: 33px;
  font-weight: 600;
  /* margin-top: 0px; */
  z-index: 100;
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 55px; */
}

.fewMore {
  position: absolute;
  bottom: 30px;
  width: 22%;
  margin: 0px auto;
  left: -17%;
  right: 0px;
  bottom: 32%;
  font-weight: 400;
  font-size: 21px;
}

.fewMoreMobile {
  width: 90%;
  margin: 0px auto;
  font-weight: 400;
  font-size: 21px;
}

.mesonryInitalRoom {
  text-align: center;
  width: 60%;
  margin-top: 0px;
}

.spaceGirdImg {
  width: 95%;
  margin-bottom: 10px;
  border-radius: 10px;
}

.styleContainer {
  margin-top: 10vh;
  margin-right: 38px;
}

.styleImg {
  width: 100%;
  border-radius: 4px;
}
.imageAlignment {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.container {
  position: relative;
  top: 0;
  left: 0;
  font-family: 'Quicksand';
  width: 100%;
  overflow-x: hidden;
  background-color: white;
  margin-bottom: 76px;
}

.container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.styleTick {
  position: absolute;
  height: 18px;
  top: 8px;
  right: 13px;
  z-index: 10;
}

.designClick {
  position: absolute;
  z-index: 1;
  top: 5%;
  right: 15% !important;
}

.resultPadding {
  padding-left: 20px;
}

.header {
  width: 30%;
  position: fixed;
  top: 40%;
}

.roomDesignClick {
  position: absolute;
  z-index: 10;
  top: 5%;
  right: 15%;
}
.shop_room {
  padding-left: 18px;
}
.designIconContainer {
  width: 115px;
  height: 115px;
  position: relative !important;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.designIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  padding: 20px;
  transform: translate(-50%, -50%);
}

.imageContainer {
  height: 100% !important;
}

.locationSelect {
  width: 200px;
  border-radius: 20px;
  border-style: solid;
  border-color: #2424245e;
  padding: 4px 20px;
  margin: auto;
  padding-right: 20px;
  margin-top: 30px;
}

.roomInput {
  width: 200px;
  border-radius: 20px;
  border-style: solid;
  border-color: #2424245e;
  padding: 4px 20px;
  padding-right: 20px;
  margin-left: -23vw;
  margin-top: 5vh;
}

.designIconContainerActive {
  background-color: #e5f9ff;
  border: 1px solid #2b95b3;
}

.designIconContainer:hover {
  background-color: #e5f9ff;
}

.resultContainer {
  width: 70%;
  height: 100%;
  margin-top: 5vh;
}

.resultContainer1 {
  width: 100%;
  margin-top: 6vh;
  overflow: hidden;
  height: 100%;
}

.styleAcive {
  background-color: #d8f1ff;
  border-radius: 20px;
  margin-top: 50px;
  position: relative;
}

/* .styleContainer{
    margin-top: 20%;
} */

.colorContainer {
  margin-top: 28vh;
}

@media only screen and (max-width: 500px) {
  .header {
    width: 80%;
    position: absolute;
    margin-top: 5vh !important;
  }
  .mesonry div div {
    margin-bottom: 13px;
  }
  .resultContainer1 {
    width: 100%;
    margin-top: 7vh;
    /* height: 150vh */
  }

  .header h1 {
    font-size: 18px;
    /* font-weight: 400; */
    line-height: 29px;
  }

  .mesonry {
    width: 85% !important;
    height: 100%;
    margin-top: 10vh !important;
  }

  .spaceGirdImg {
    width: 90%;
    height: 100%;
  }

  .mesonryDesign {
    /* height: 100%; */
    /* margin-top: 10vh; */
    width: 95% !important;
  }
  .mesonryRooms {
    width: 95% !important;
  }
  .mesonryRooms p {
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 13px;
  }
  .mesonryInitialRoom {
    width: 95% !important;
  }
  .mesonryInitialRoom p {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .mesonryDesign p {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .colorContainer p {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .mesonryInitalRoom {
    margin-top: 53px;
  }
  .mesonryInitalRoom p {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .styleContainer {
    height: 100%;
    margin-top: 2vh !important;
    width: 100% !important;
    margin: 0px auto;
  }

  /* .styleImg{
        width: 90%;
        height: 100%;
    } */
  .styleContainer h4 {
    font-size: 18px;
  }

  .styleContainer p {
    font-size: 16px;
  }

  .colorContainer {
    width: 90%;
    margin-top: 53px !important;
    height: 100%;
  }

  .colorImg {
    width: 90%;
    height: 100%;
  }

  .mesonryRooms {
    height: 100%;
    margin-top: 45px !important;
    width: 85%;
  }

  .mesonryInitalRoom {
    height: 100%;
    width: 95%;
  }

  .nameContainer {
    height: 47vh;
    margin-top: 20vh;
    width: 95%;
  }

  .nameContainer input {
    font-size: 18px;
    width: 85%;
  }
  .emailTextStyle {
    width: 90%;
  }
  .basedHead {
    width: 90%;
  }
  .basedHead h4 {
    font-size: 18px !important;
    font-weight: 500;
    line-height: 28px;
  }
  .subBanner {
    width: 100% !important;
  }
  .moreTxt {
    font-size: 13px;
  }
  .boxImg {
    text-align: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .boxImg img {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .headDomain {
    font-size: 18px;
  }
  .paraTxt {
    text-align: center !important;
  }
  .mx5 {
    text-align: center;
  }
  .span {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 15px;
  }
  .resultContainer {
    height: 100%;
    width: 80%;
    margin-top: 30vh;
  }

  .resultContainer h1 {
    font-size: 20px;
  }

  .resultContainer h5 {
    font-size: 17px;
  }

  .resultContainer p {
    font-size: 16px;
  }

  .resultImage {
    height: 100%;
  }

  .resultList h5 {
    font-size: 16px;
  }

  .resultList p {
    font-size: 12px;
    text-align: center !important;
    margin-bottom: 31px;
  }
  .imageContainer {
    height: auto !important;
  }
  .shareContent {
    width: 100%;
  }

  .copyLinkBtn {
    width: 77%;
  }
}

.resultContainer1 {
  width: 100%;
  margin-top: 6vh;
  /* overflow: hidden; */
}
.w3ProgressContainer {
  width: 100%;
  height: 1.5em;
  position: relative;
  background-color: #f1f1f1;
}
.w3Progressbar {
  background-color: #757575;
  height: 100%;
  position: absolute;
  line-height: inherit;
}
.w3RoundXlarge {
  border-radius: 16px !important;
}
.progressBar {
  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.progress1 {
  border-radius: 40px !important;
  background-color: white !important;

  /* Changes below */
  -webkit-box-shadow: inset 0 0 0 2px #337ab7 !important;
  -moz-box-shadow: inset 0 0 0 2px #337ab7 !important;
  box-shadow: inset 0 0 0 2px #337ab7 !important;
  border: none;
}
.progress {
  height: 10px;
  border-radius: 4.25rem;
  width: 30%;
}
progress[value]::-webkit-progress-bar {
  background-color: #ededed;
  border-radius: 40px;
}

progress[value]::-webkit-progress-value {
  border-radius: 40px;
  background-color: brown;
}

.progress2 {
  height: 10px;
  border-radius: 4.25rem;
  width: 30%;
}
.progress2[value]::-webkit-progress-bar {
  background-color: #ededed;
  border-radius: 40px;
}

.progress2[value]::-webkit-progress-value {
  border-radius: 40px;
  background-color: rgb(42, 126, 165);
}

.progress3 {
  height: 10px;
  border-radius: 4.25rem;
  width: 30%;
}
.progress3[value]::-webkit-progress-bar {
  background-color: #ededed;
  border-radius: 40px;
}

.progress3[value]::-webkit-progress-value {
  border-radius: 40px;
  background-color: rgb(42, 126, 165);
}
/* @media only screen and (min-height: 900px){
    .styleContainer{
        margin-top: 300vh !important ;
    }
} */

@media only screen and (max-width: 1000px) {
  .mesonry {
    margin-top: 35vh;
  }

  .header {
    position: absolute;
    /* margin-top: 10vh;
    top: 50%; */
    width: 80%;
    /* text-align: center; */
  }

  .styleContainer {
    margin-top: 30%;
    width: 100%;
    height: 100%;
  }

  .styleContainer h4 {
    margin-top: 1vh;
  }

  .colorContainer {
    margin-top: 50%;
  }

  .mesonryRooms {
    margin-top: 50vh;
    /* z-index: 10; */
  }
}

@media only screen and (min-width: 1600px) {
  .mesonryDesign {
    width: 52% !important;
  }
  .mesonryRooms {
    width: 57% !important;
  }
  .mesonryInitalRoom {
    width: 65%;
  }
}
.modalRow_share {
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.modal_share {
  width: 80%;
}
.shareLink {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 24px;
}
.shareContent {
  border-radius: 20px;
  border: 1px solid #a5a5a5;
  font-size: small;
  width: 79%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  padding-left: 22px !important;
  color: #1c1c1c;
}
.copyLinkBtn {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  right: 0;
  top: 0;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
  padding: 0px 18px;
  font-weight: 800;
}
