.carouselContainer {
  width: 100%;
  height: auto;
  position: relative;
}

.mainContainer {
  max-width: 80% !important;
}
.textEliptic {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
  font-size: 14px;
}
.saveBoards {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.createBoard {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.Icon3d {
  /* border-radius: 50px; */
  transition: transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}

.Icon3d:hover {
  transform: scale(1.2);
}

.arIcon {
  background: none;

  width: 35px;

  transition: transform 0.2s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
}
.arIconbtn {
  width: 26px;
}
.arIcon:hover {
  transform: scale(1.2);
}

/* 
.shareLink {
  position: relative;
  width: 550px;
  height: 35px;
  display: inline-flex;
  border-radius: 20px;
  border: 1px solid #f0f0f0;
  padding: 5px;
  padding-left: 20px;
} */
.shareLink {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 24px;
}
.ShareModalHeader h4 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 27px;
}
.modal_share {
  width: 80%;
}
.shareContent {
  border-radius: 20px;
  border: 1px solid #a5a5a5;
  font-size: small;
  width: 79%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  padding-left: 22px !important;
  color: #1c1c1c;
}
.modalImage {
  background: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/socialShare.png);
  background-repeat: no-repeat;
  background-size: 32%;
}
.modalRow_share {
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.ShareModalHeader {
  height: 40px;
  color: #000000;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.createCol {
  padding-top: 21px;
  max-height: 90vh;
  overflow-y: scroll;
}
.saveBtn {
  float: right;
  border-radius: 20px;
  opacity: 1;
  border: none;
  background-color: #ffffff;
  margin-top: 20px;
  margin-right: 10px;
  font-size: 14px;
  font-family: 'QuickSand';
  padding: 5px 15px 5px 15px;
  height: 30px;
}
.copyLinkBtn {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  right: 0;
  top: 0;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
  font-size: 12px;
  padding: 0px 18px;
  font-weight: 800;
}

/* .copyLinkBtn {
  float: right;
  position: absolute;
  width: 100px;
  right: 0;
  top: 0;
  height: 35px;
  color: #ffffff;
  border-radius: 20px;
  background-color: black;
} */
.link {
  font-size: 16px;
}
.header {
  font-size: 30px;
  font-weight: 500;
  width: 100%;
  text-align: center;
}
.overlay {
  z-index: 1 !important;
  background-color: rgb(44 44 44 / 65%);
  position: absolute;
  width: 100%;
  height: 14em;
}
.boxImage {
  object-fit: cover;
  flex: 0 0 38%;
  height: 100%;
  text-align: center;
  border: 1px solid black;
  position: relative;
}
.boxWrapper {
  display: flex;
  justify-content: space-between;
  width: 12vmax;
  height: 12vmax;
  border: 1px solid black;
  position: relative;
}

.modalHeader {
  background-color: #141414;
  height: 56px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.viewBtn {
  border-radius: 20px;
  width: 146px;
  height: 34px;
  background-color: #ffffff;
  position: absolute;
  bottom: 40%;
  left: 0;
  border: none;
  margin-left: auto;
  margin-right: auto;
}

.buttonWhite {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: black;
  background-color: white;
  padding: 7px 0px;
  font-weight: 600;
  /* height: 40px; */
  width: 40%;
  font-size: 13px;
}

.buttonBlack {
  border-radius: 20px;
  border: 0.5px solid #000;
  color: white;
  background-color: black;
  padding: 7px 0px;
  font-weight: 600;
  /* height: 40px; */
  width: 40%;
  font-size: 13px;
}

.modalContainer p {
  margin-bottom: 0px !important;
  font-size: 14px;
}
.productImg {
  width: 100%;
  height: 236px;
  /* margin-top: 22px; */
}
.btnsStyle {
  margin-top: 43px !important;
}
.heart {
  box-shadow: 1px 1px 6px #00000042;
  border-radius: 50%;
  cursor: pointer;
  height: 41px;
  width: 41px;
  background-image: url('https://res.cloudinary.com/lab10/image/upload/v1621063036/samples/heart-edit_lx314x.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 2900%;
}
.nameTitle {
  font-size: 19px;
  font-weight: 400;
}
.price {
  font-size: 19px;
  margin-top: 14px;
}
.heart:hover {
  background-position: right;
}

.is_animating {
  animation: heart-burst 0.8s steps(28) 1;
}

@keyframes heart-burst {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

.renderColumns_container {
  display: flex;
  justify-content: center;
  width: 100% !important;
  /* width: 287px;
  height: 287px; */
  /* margin-top: 10vh;
    margin-left: 2vw; */

  border: 0.5px solid #707070;
}

.overlayEffect {
  opacity: 0.5;
  background-color: black !important;
  border: 1px solid #7070701b !important;
}

.shopContainer {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2951_xexml1.png');
  width: 100%;
  margin-top: 5vh;
  border-radius: 10px;
  height: 170px;
}

.shop_button {
  /* width: 200px; */
  background-color: #000000;
  color: white;
  padding: 11px 28px;
  border-radius: 0px;
  font-weight: 800;
  font-size: 15px;
}
.shop_button:hover {
  color: white !important;
}
.container2Image {
  width: 90%;
}

.container3Image {
  width: 90%;
}
.container3Image :hover {
  transform: scale(1.3);
}
.container2 {
  width: 100%;
  background-color: #f5f0ec;
  height: auto;
  margin-top: 100px;
  padding: 10vh 15vw 10vh 15vw;
}

.container3 {
  width: 100%;
  background-color: #f5f0ec;
  height: auto;
  margin-top: 100px;
  padding: 10vh 8vw 10vh 8vw;
  background-image: url(../../assets/pattern.png);
  background-size: 100%;
}

.saveExplore {
  position: absolute;
  bottom: 0px;
  margin-left: 131px;
  margin-bottom: 10px;
  height: 25px;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 25px;
  padding-bottom: 25px;
  border: none;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  background-color: rgb(255, 255, 255);
}

.modalRow {
  /* height: 399px; */
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.imageCol {
  /* height: 40%; */
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px !important;
}
/* .createCol {
  height: 60%;
} */
.image2 {
  /* height: 70%; */
  width: 100%;
  border-radius: 10px;
}
.createCol h6 {
  margin-top: 4vh;
}
.createCol input {
  width: 90%;
}

.createCol {
  /* height: 399px; */
  background-color: #f2feff;
  padding-left: 30px !important;
  padding-bottom: 10%;
}

.createCol h6 {
  margin-top: 5vh;
  /* margin-left: 2vw; */
  /* padding-left: 2px; */
  font-weight: 100 !important;
}
.tabContainer p {
  width: 50%;
}
.createCol input {
  margin-top: 10px;
  margin-left: 0px;
  font-weight: 600;
  background-color: transparent;
  width: 86%;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #707070;
  padding-bottom: 4px;
  color: #1a1818;
}
.createCol input:focus {
  outline: none;
}
.createBoardButton {
  width: 128px;
  height: 31px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  font-size: 13px;
  margin-left: 30px;
  border: none;
  float: left;
  /* margin-top: 3vh; */
}
.createBoardButton1 {
  width: 128px;
  height: 35px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  float: left;
  margin-top: 60px;
  /* margin-left: 30px; */
  font-size: 14px;
}
.createBoardButton1:hover,
.createBoardButton:hover {
  font-weight: 900;
}
.modalContainer {
  overflow: scroll;
}
.viewBtnss {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: white;
  position: absolute;
  z-index: 1000;
  bottom: -21px;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 5px #2125295e;
}
@media only screen and (max-width: 500px) {
  .saveExplore {
    position: absolute;
    bottom: 0px;
    margin-left: 131px;
    margin-bottom: 10px;
    height: 25px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 25px;
    padding-bottom: 25px;
    border: none;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    background-color: rgb(255, 255, 255);
    width: 21%;
  }
  .renderColumns_container {
    width: 100% !important;
  }
  .headerSty {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 24px;
  }
  .buttonWhite,
  .buttonBlack {
    font-size: 13px;
  }
  .buttonWhite {
    width: 55%;
  }
  .prePrice {
    float: left;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
    padding-top: 5px;
    margin-left: 20px;
  }
  .copyLinkBtn {
    width: 77%;
  }
  .shareContent {
    width: 100%;
  }
  /* .shareLink {
    position: relative;
    width: 100%;
    height: 35px;
    display: inline-flex;
    border-radius: 20px;
    border: 1px solid #f0f0f0;
    padding: 5px;
    padding-left: 10px;
    padding-top: 10px;
  } */
  /* 
  .copyLinkBtn {
    float: right;
    position: absolute;
    width: 20%;
    right: 0;
    top: 0;
    height: 35px;
    color: #ffffff;
    border-radius: 20px;
    background-color: black;
    font-size: 12px;
  } */
  .link {
    font-size: 11px;
  }

  .modalHeader h4 {
    font-size: 15px;
    margin-top: 25px !important;
    margin-left: 25px !important;
    display: inline-flex;
  }
  .createCol {
    height: 60%;
  }
  .image2 {
    width: 100%;
    border-radius: 10px;
  }
  .createCol h6 {
    margin-top: 5vh;
    font-weight: 100 !important;
  }
  .createCol input {
    width: 90%;
  }
  .save1 {
    font-size: 11px;
  }
  .createBoardButton {
    background-color: #000000;
    border-radius: 20px;
    color: #ffffff;
    border: none;
    float: left;
    font-size: 11px;
  }
  .shop_button {
    padding: 12px 43px 12px 43px;
  }
}
