.mainContainer {
  max-width: 80%;
}

.productContainer {
  margin-bottom: 5vh;
  background-color: white;
  width: 100%;
  height: 50%;
}
.saveBoards {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.subContainer img {
  width: 90%;
}
.MuiAccordion-root {
  border: 1px solid white !important;
  border-radius: 10px !important;
  background-color: #fff !important;
}
.modalHeader {
  background-color: #141414;
  height: 56px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.container2 {
  width: 100%;
  background-color: #f5f0ec;
  background-image: url(../../assets/pattern.png);
  height: auto;
  margin-top: 20vh;
  padding: 78px 93px;
  background-size: 100%;
}
.thumb {
  border: none;
}
.arPart {
  margin: 21px 0px;
  display: flex;
  align-items: center;
  background-color: white;
}
.view {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
}
.arImg {
  width: 16px;
  height: 19px;
  margin-right: 7px;
}
.QrLeft {
  height: 500px;
}
.closeBtn {
  position: absolute;
  right: 0px;
  z-index: 1;
  width: 27px;
  top: 13px;
}
.txtSec {
  position: absolute;
  top: 38%;
  bottom: 0px;
  align-items: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0px 84px;
}
.txtSec h4 {
  font-size: 18px;
  font-weight: 700;
}
.txtSec p {
  font-size: 14px;
  font-weight: 500;
}
.firstRate {
  flex-direction: row;
  justify-content: start;
  display: flex;
}
.ratingImg {
  width: 20px;
  height: 20px;
  margin-left: 9px;
  margin-top: 6px;
}
.raTxt {
  color: #a3a3a3;
  font-size: 12px !important;
  margin: 0px !important;
  margin-left: 0px !important;
  width: 67% !important;
  text-align: center;
  line-height: 18px;
}
.listRate {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.ratingCount {
  font-weight: 500;
  margin-right: 3px;
  font-size: 14px;
}
.ratingSmall {
  width: 11px;
  margin-right: 13px;
  margin-left: 2px;
}
.wholeRate {
  margin: 0px auto;
  margin-top: 44px;
  width: 83%;
  padding-bottom: 24px;
}
.rateCard {
  width: 100%;
  padding-left: 59px;
  margin: 46px 0px;
}
.wholeBox {
  display: flex;
}
.rateimgss {
  width: 15px;
  margin-left: 2px;
}
.great {
  color: #232424;
  margin-left: 8px;
  font-size: 16px;
}
.greatTxt {
  color: #777777;
  font-size: 13px;
  margin-top: 9px;
}
.blackBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
  color: white;
  width: 38px;
  border-radius: 3px;
  text-align: center;
  justify-content: center;
}
.rateBtn {
  border: 1px solid #707070;
  background-color: #ffffff;
  padding: 5px 20px;
  color: #777777;
  float: right;
  position: absolute;
  bottom: 13px;
  right: 20px;
}
.ratingPeople {
  color: #a3a3a3;
}
ul {
  list-style: none;
}
.rating {
  font-size: 28px;
  font-weight: 400;
}
.parasImg {
  font-size: 14px;
  font-weight: 600;
}
.imageGalleryTitle {
  font-weight: 300;
  font-size: 19px;
}
.imageGalleryPara {
  font-weight: 300;
  font-size: 15px;
  text-decoration: underline;
}
.galleryPrice {
  font-size: 20px;
  font-weight: 600;
}
.addCart {
  margin-bottom: 15px;
  /* padding-left: 10px; */
  height: 41px;
  border-radius: 30px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  /* padding-right: 10px; */
  border: none;
  margin-right: 15px;
  padding: 0px 24px;
  font-weight: 500;
  font-size: 15px;
}
.productDetails {
  font-size: 14px;
  line-height: 29px;
}
.taxes {
  font-size: 14px;
}
/* .image-gallery
-thumbnail.active,
.image-gallery-thumbnail:focus {
 
} */
.tabContainer {
  margin-top: 53px;
}
.tabContainer h5 {
  height: 50px;
  margin-left: 50px;
  text-align: center;
  margin-bottom: -5px;
  padding-top: 10px;
  margin: 0px 23px;
  padding: 12px 38px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.modalRow {
  /* height: 399px; */
  max-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 10;
}
.carouselImg {
  width: 100%;
  height: 272px;
  background-color: #ffffff;
}
.wholeCarousel {
  width: 92%;
  height: 313px;
  background-color: #ffffff;
  margin: 0px auto;
  background-color: #ffffff;
}
.imageCol {
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px !important;
}
.createCol {
  padding-top: 21px;
  max-height: 90vh;
  overflow-y: scroll;
  padding-bottom: 10%;
}
.image2 {
  width: 100%;
  border-radius: 10px;
}
.createCol h6 {
  margin-top: 4vh;
}
.createCol input {
  margin-top: 10px;
  margin-left: 0px;
  font-weight: 600;
  background-color: transparent;
  width: 86%;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #707070;
  padding-bottom: 4px;
  color: #1a1818;
}

.createCol {
  background-color: #f2feff;
  padding-left: 30px !important;
}

.createCol h6 {
  margin-top: 5vh;
  font-weight: 100 !important;
}
.tabContainer p {
  width: 50%;
  margin-left: 10% !important;
  margin-top: 44px;
  font-size: 15px;
  line-height: 27px;
}
.createBoardButton {
  width: 128px;
  height: 31px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  font-size: 13px;
  margin-left: 30px;
  border: none;
  float: left;
}
.createBoard {
  margin-left: 22px !important;
  font-size: 16px;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.createBoardButton1 {
  width: 128px;
  height: 35px;
  background-color: #000000;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  float: left;
  margin-top: 60px;
  font-size: 14px;
}
.MuiAccordion-root {
  border: 1px solid white !important;
  border-radius: 10px !important;
  background-color: white !important;
}
.react-multi-carousel-item {
  margin: 0px 24px;
}
@media only screen and (max-width: 500px) {
  .modalHeader h4 {
    font-size: 15px;
    margin-top: 25px !important;
    margin-left: 25px !important;
    display: inline-flex;
  }
  .createCol {
    height: 60%;
  }
  .image2 {
    width: 100%;
    border-radius: 10px;
  }
  .createCol h6 {
    margin-top: 4vh;
  }
  .createCol input {
    width: 90%;
  }
  .save1 {
    font-size: 11px;
  }
  .createBoardButton {
    background-color: #000000;
    border-radius: 20px;
    color: #ffffff;
    border: none;
    float: left;
    font-size: 11px;
  }
  .container2 {
    padding: 10px 28px;

    margin-top: 8vh;
  }

  .addCart {
    width: 100%;
  }
  .productContainer p {
    word-break: break-all;
  }
  .Product_wholeRate__1IhPq {
    margin: 0px auto;
    margin-top: 0px;
    width: 95%;
    padding-bottom: 24px;
  }
  .raTxt {
    padding-bottom: 21px;
    text-align: left;
  }
  .rateBtn {
    border: 1px solid #707070;
    background-color: #ffffff;
    padding: 5px 20px;
    color: #777777;
    float: left;
    position: relative;
    bottom: 13px;
    right: 0px;
    margin-top: 27px;
  }
  .wholeRate {
    margin-top: 0px;
  }
}
