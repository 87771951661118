@media only screen and (max-width: 600px) {
  .main__cont {
    padding: 0px 20px;
  }

  .about__cont {
    background-color: whitesmoke;
    padding: 15px 10px;
    margin-top: 68px;
  }
  .headerTxt {
    font-size: 18px;
    font-weight: bold;
  }
  .about__text {
    font-weight: 700;
  }

  .saveButton {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0.8vw 1.5vw;
    border-radius: 30px;
    background-color: #000000;
    color: #f5f0ec;
    font-weight: 400;
  }

  .saveButton:hover {
    font-weight: 900;
  }

  .error {
    border: 1px solid red;
  }
  .error1 {
    color: red;
  }
  .pagediv__margin {
    margin: 27px 0px;
  }

  .pagediv__margin2 {
    margin: 40px 0px 0px 0px;
  }

  .photos__text2 {
    font-size: 13px;
    font-weight: 500;
    color: #101010;
  }

  .priceButton {
    text-align: center;
    padding: '5px 10px 5px 10px';
    border: 2px solid #000000;
    border-radius: 30px;
    width: 40%;
    margin-left: 50px;
  }

  .photos__div {
    max-width: 48% !important;
    height: 160px;
    /* width: 100%; */
    background-color: #d0ecf4;
    border: 1.5px solid #d0ecf4;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
    padding: 9px !important;
  }

  .photos__div:nth-child(odd) {
    margin-right: 10px;
  }
  .borderImg {
    padding-top: 0px !important;
    height: 100%;
    border: 1px dashed #ffffff;
    width: 100%;
    justify-content: center;
  }
  .photos__btn {
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    /* width: 90%;
    font-size: 12px;
    text-align: center;
    background: white;
    padding: 10px 6px;
    border-radius: 50px;
    box-shadow: 2px 4px 12px #0000004f;
    font-weight: 700; */
  }

  .photos__div2 {
    height: 20vh;
    max-width: 48% !important;
    max-width: 48% !important;
    height: 200px;
  }

  .photos__div2:nth-child(1) {
    margin-right: 10px;
  }
  .thumbImg {
    width: 50px;
    height: 50px;
    margin-bottom: 12px;
    transition: transform 0.2s;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.2s;
    -webkit-transition: transform 0.2s;
  }
  .thumbImg1 {
    width: 75px;
    height: 50px;
    margin-bottom: 12px;
    display: none;
    transition: transform 0.2s;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.2s;
    -webkit-transition: transform 0.2s;
  }
  .borderImg:hover .thumbImg1 {
    display: block;
    /* transform: scale(1.2); */
  }
  .borderImg:hover .thumbImg {
    display: none;
    /* transform: scale(/1.2); */
  }
  .photos__div3 {
    max-width: 48% !important;
    height: 150px;
    width: 100%;
    background: #ffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
  }

  .photos__div3:nth-child(odd) {
    margin-right: 10px;
  }

  .photos__btn2 {
    width: 100%;
    border-radius: 0px;
    position: absolute;
    bottom: 0;
    background: #000000c2;
    color: white;
    font-weight: 700;
    font-size: 12px;
    padding: 10px 6px;
  }

  .roomwise__heading {
    font-size: 17px;
    font-weight: bold;
  }

  .wishlist__btn {
    border-radius: 30px;
    background-color: #000000;
    color: #ffffff;
    padding: 16px;
    font-weight: 800;
    padding-left: 20px;
    margin-top: 30px;
    padding-right: 20px;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
  }
  .dropTxt {
    font-weight: 500;
    font-size: 14px;

    text-align: center;
  }
  .thumbImg {
    width: 55px;
    height: 55px;
    margin-bottom: 12px;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.2s;
    -webkit-transition: transform 0.2s;
  }

  .thumbImg1 {
    width: 80px;
    height: 55px;
    margin-bottom: 12px;
    display: none;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.2s;
    -webkit-transition: transform 0.2s;
  }
  .hoverImg {
    display: none;
  }

  .art__img:hover .oriImg {
    display: none;
  }

  .art__img:hover .hoverImg {
    display: block;
  }
  .wishlist__item {
    height: 20vh;
    /* border: 1px solid #8280805e; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 15px;
    max-width: 45% !important;
    margin-bottom: 25px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #cccccc29;
    border: 0.5px solid #dedede;
    border-radius: 5px;
    opacity: 1;
    /* box-shadow: 1px 1px 3px #0000006e; */
  }

  .wishlist__item:nth-child(odd) {
    margin-right: 25px;
  }

  .art__img {
    max-width: 48% !important;
    padding: 10px;
    text-align: center;
    margin-bottom: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000f;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    opacity: 1;
  }
  .titleTxt {
    color: #151515;
    margin: 4px 0px;
    float: left;
    margin-top: 10px !important;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    width: 100%;
  }
  .art__img:nth-child(odd) {
    margin-right: 10px;
  }

  .materialClick {
    position: absolute;
    bottom: 7%;
    right: 5%;
    width: 15px;
    z-index: 1;
  }
  .overLayerRoom {
    position: absolute;
    background-color: #00000061;
    width: 100%;
    height: 100%;
  }
}

.attachFileButton {
  border-radius: 30px;
  font-size: 15px;
  font-weight: 800;
  border: none;
  color: white;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  background-color: #1a1818;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.filename {
  position: relative;
  width: 140px;
  left: 100px;
  top: 15px;
}

.UploadButton {
  position: relative;
  left: 90px;
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 800;
  padding-left: 20px;
  margin-top: 35px;
  padding-right: 20px;
}

.wishlistButton {
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  font-weight: 800;
  padding-left: 20px;
  margin-top: 10px;
  padding-right: 20px;
}

.modalContainer {
  /* width: 35vw; */
  /* height: 70vh; */
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding: 10px;
}

.modalContainer1 {
  width: 90%;
  height: 95%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f4f4f4;
}
.containerText {
  position: relative;
}
.containerText textarea {
  display: block;
  width: 100% !important;
  resize: vertical;
  border: 1px solid #e0e0e0;
  margin-top: 22px;
}

.buttonStyle {
  width: 42px;
  height: 41px;
  font-size: 28px;
  background-color: #f5f5f5;
}
.wholeBtn {
  justify-content: center;
  width: 134px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 37px;
  align-items: center;
  display: flex;
}
.containerText .microphoneIco1 {
  /* padding: 10px 20px; */
  font-size: 20px;
  position: absolute;
  right: 5px;
  top: 10px; /* <==== */
}
.containerText .textField {
  display: block;
  width: 100% !important;
  resize: vertical;
}
.containerText .audio {
  padding: 10px 20px;
  font-size: 20px;
  position: absolute;
  right: 30px;
  bottom: 0px; /* <==== */
}
.containerText .Trash {
  font-size: 20px;
  position: absolute;
  /* left: 313px;
  bottom: 91px; */
  right: 25px;
  top: 10px;
}

.audioContainer {
  width: 35vw;
  /* height: 70vh; */
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding-bottom: 10px;
}

.modal_container {
  height: 15vh;
  width: 100%;
  background-color: #d2edf5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer p {
  font-size: 14px;
  margin-top: 10%;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.designClick {
  position: absolute;
  bottom: 7%;
  right: 5%;
  width: 15px;
  z-index: 1;
}
/* .materialClick{
  position: relative;
  left: 30px;
} */
