.imageContainer {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Card-mockup_h0ltd3.png');
  height: 100vh;
  width: 100%;
  padding: 0px;
  position: relative;
  background-size: 100%;
  margin-bottom: -100px;
  z-index: -10;
  padding-top: 5px !important;
}

.leftContainer {
  padding: 5vw !important;
}

.leftContainer h2 {
  font-size: 22px;
}

.stepperContainer {
  z-index: 100 !important;
}

.dotted {
  width: 100px;
  height: 10px;
  margin-top: -25px;
  margin-left: -10px;
  background-color: transparent;
}

.designCompletedDialog {
  width: '100%';
  height: 120px;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #1a1818;
}
@media only screen and (max-width: 1240px) {
  .leftContainer {
    width: 90% !important;
  }
  .imageContainer {
    width: 100%;
    height: 100vh !important;
    background-size: 100%;
    background-image: url('https://d3izcfql7lesks.cloudfront.net/Card-mockup_dvmeak.png');
    background-position: left;
    background-repeat: no-repeat;
  }

  .stepCountCircle:before {
    content: '';
    position: absolute;
    border: 1px dashed black;
    width: 100%;
    top: 15px;
    right: 0px;
    left: 0px;
    margin-left: calc(-50% - 20px - 15px);
    width: 100vw;
  }

  .stepCountCircless:before {
    content: '';
    position: absolute;
    border: 1px dashed black;
    width: 100%;
    top: 15px;
    right: 0px;
    left: 0px;
    /* margin-left: calc(-50% - 20px - 15px);
    width: 100vw; */
  }
  .firstStep:before {
    content: '';
    position: absolute;
    border: 1px dashed black;
    width: 100%;
    top: 15px;
    right: 0px;
    left: 179px;
    z-index: 1;
    margin-left: 0px;
  }
  .lastStep:before {
    content: '';
    position: absolute;
    border: 1px dashed black;
    width: 100%;
    top: 15px;
    left: 16px;
    right: 196px;
    z-index: 1;
  }
  .conceptCarouselContainer .carousel-inner {
    overflow: visible !important;
  }
}
/* Get Started Screen */

.pageContentContainer {
  height: 90vh;
}
.rightSideContainer {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
  background-color: #f5f0ec;
  z-index: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.rightSideContentWrapper {
  max-width: 1500px;
}

/* left sidebar */

.leftSlidebarContainer {
  position: relative;
  height: 100%;
  padding: 20px;
}
.desktopSidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.desktopSidebar img {
  max-width: 60px;
  margin-bottom: 15px;
}
.desktopSidebar h4,
.mobileSidebar h4 {
  text-decoration: underline;
  font-weight: bold;
}
.desktopSidebar h5,
.mobileSidebar h5 {
  font-weight: 600;
  font-size: 1.2rem;
}
.mobileSidebar {
  display: flex;
  padding: 20px;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .mobileSidebar {
    min-height: 500px;
  }
}
.mobileSidebar img {
  max-width: 60px;
  margin-right: 15px;
}
.mobileSidebarSec1 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

/* Cards Desktop Styles*/
.cardImageDesktop img {
  margin-top: -60px;
}
.cardsRowDesktop {
  width: 100%;
  align-items: flex-start;
  justify-content: space-evenly;
}
.cardContainerDesktop,
.stepCountContainerDesktop,
.cardContentsDesktop {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.stepCountContainerDesktop h5 {
  text-decoration: underline;
  margin: 0px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
}
.stepCountCircle {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  border: 2px solid black;
  background-color: #f5f0ec;
  z-index: 2;
}

.stepCountCircless {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  border: 2px solid black;
  background-color: #f5f0ec;
}
.activeStepCircle {
  background-color: #ff4e00;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  z-index: 1;
}
/* .active .numberCount{
  display: none;
}
.active{

} */
.cardContentWrapperDesktop {
  background-color: #f3e6db;
  border-radius: 10px;
  margin-top: 80px;
  height: 305px;
  padding: 20px;
}
.cardContentsDesktop p {
  text-align: center;
  font-size: 0.8rem;
  margin-top: 20px;
}
.desktopCardImage1 img {
  margin-bottom: 8px;
}

/* Cards Mobile Styles*/
.conceptCarouselContainer {
  padding: 20px;
  /* height: 760px; */
  height: 100%;
  /* overflow: hidden; */
  overflow-x: visible;
  padding-bottom: 20px;
}
.carouselCardImageContainer {
  height: 100%;
}
.carouselCardImageContainer img {
  height: 100%;
  object-fit: contain;
}
.carouselCardContainer p {
  margin: 50px 0px;
  max-width: 500px;
  text-align: center;
}
.carouselCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.conceptCarouselItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.stepCountContainerMobile {
  display: flex;
  align-items: center;
  margin: 40px 0px;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100vw;
}
@media screen and (max-width: 767px) {
  .pageContentContainer {
    height: 100%;
  }
}

/* dottedLink */

.dottedLink {
  position: absolute;
  border: 1px dotted black;
  width: 280px;
  top: 15px;
  right: -140px;
  z-index: 1;
}

@media screen and (max-width: 1500px) {
  .dottedLink {
    width: 220px;
    right: -110px;
  }
}
/* .dottedLinkMobileLeft,
.dottedLinkMobileRight {
  position: absolute;
  border: 1px dotted black;
  z-index: 1;
}
.dottedLinkMobileLeft {
  width: 0px;
  top: 15px;
  left: -200px;
}
.dottedLinkMobileRight {
  width: 600px;
  top: 15px;
  right: -600px;
} */
/* @media screen and (max-width: 1412px) {
  .carousel-inner {
    overflow: visible;
  }
} */
