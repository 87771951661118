.container {
  background-image: url('https://d3izcfql7lesks.cloudfront.net/Group_2949_dt6guc.png');
  background-size: 100%;
}

.positionButton {
  border-radius: 30px;
  color: black;
  margin-top: 1%;
  background-color: white;
  font-size: 14px;
  padding: 4px 10px 5px 10px;
  font-weight: 900;
  border: none;
}

.applyButton {
  border-radius: 30px;
  background-color: white;
  color: black;
  font-size: 0.8vw;
  font-weight: 800;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 3px 20px 3px 20px;
  margin-right: 20px;
}
