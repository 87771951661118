.nextButton {
  border-radius: 30px;
  background-color: black;
  color: white;
  width: 150px;
  height: 40px;
  padding: 3px 20px 3px 20px;
  font-weight: 700;
  border: none;
}

.summaryContainer {
  width: 400px;
  height: auto;
  background-color: white;
  /* border: 2px solid black; */
}

.personaliseInput {
  width: 100%;
  margin: 20px;
  padding: 10px;
  border: 1px solid #b7b7b7;
  font-weight: 600;
}

.divider {
  width: 80%;
  height: 1px;
  margin-bottom: 10px;
  margin-top: -5px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.continueButton {
  width: 80%;
  height: 50px;
  font-weight: 800;
  background-color: black;
  color: white;
  padding: 4px 10px 4px 10px;
  border: none;
}
