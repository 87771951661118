.filterBadge{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: max-content;
    min-width: fit-content;
    padding: 10px 18px;
    border: 1px solid #D1C9C4;
    border-radius: 24px;
    font-weight: 500;
    font-size: 14px;
    color:  #171717;
    margin: 0 1rem 1rem 0;
    cursor: pointer;
}

.filterBadgeActive{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: max-content;
    min-width: fit-content;
    padding: 10px 18px;
    border-radius: 24px;
    font-weight: 500;
    font-size: 14px;
    color:  #ffffff;
    margin: 0 1rem 1rem 0;
    background-color:#E29525;
    cursor: pointer;
    
}

@media only screen and (max-width: 600px) {
 .filterBadge{
    margin: 0 0.5rem 0.5rem 0;
    font-size: 12px;
 }
 .filterBadgeActive{
    margin: 0 0.5rem 0.5rem 0;
    font-size: 12px;
 }
}