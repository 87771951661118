.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators li {
  background-color: #000000 !important;
}

.react-player__shadow {
  background: radial-gradient(rgb(0 0 0), rgb(0 0 0 / 66%) 60%) !important;
  transition: transform 0.2s;
}

.react-player__shadow:hover {
  background: radial-gradient(rgb(0 0 0), rgb(0 0 0 / 66%) 60%) !important;
  transform: scale(1.2);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html {
  scroll-behavior: smooth;
}
iframe {
  display: none;
}
.slick-next,
.slick-prev {
  padding: 14px !important;
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
  border: none;
  border-radius: 50px !important;
  background-color: #ffffff;
  z-index: 1 !important;
  filter: drop-shadow(0px 3px 6px rgb(0, 0, 0, 16%));
  object-fit: none !important;
  /* background: #ffffff !important; */
}
.slick-prev {
  left: -43px !important;
}
.slick-next {
  right: -43px !important;
}
.bghjHX {
  margin: 0px !important;
  overflow-x: hidden;
  overflow-y: visible;
  height: 230px;
}
.css-1gsv261 {
  border: none;
  border-color: transparent !important;
}
.css-ciie9r-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
  background-color: #000000 !important;
  border-radius: 11px;
}
.userInput {
  z-index: 1000;
}
.MuiTabs-indicator {
  background-color: transparent !important;
}
.makeStyles-paper-2 {
  overflow: scroll;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: #fff;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 0px) !important;
}
.image-gallery-slides div {
  display: none;
}
.image-gallery-slides div:first-child {
  display: block;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails {
  overflow: scroll;
  scrollbar-width: none;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails::-webkit-scrollbar {
  width: 0px;
  /* height: 2em */
}
input:focus {
  outline: none;
  box-shadow: none;
}
.iVQili:hover:enabled,
.iVQili:focus:enabled {
  color: #000 !important;
  background-color: white !important;
  box-shadow: none !important;
}
.bdKNMg {
  padding: 0px 10px;
}
.iVQili {
  background-color: white !important;
  box-shadow: none !important;
}
/* }
.carousel-inner {
  overflow: visible !important;
} */
@media screen and (max-width: 767px) {
  .mXmYO {
    padding-left: 0px !important;
  }
  .makeStyles-paper-2 {
    width: 100%;
  }
  /* .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: #fff;
} */
  /* .css-1m4ljmh-MuiTypography-root{
  width: 76% !important;
}
.MuiAccordion-root {
  margin: 0px 18px !important;
  margin-bottom: 10px !important;
}   */

  .productContainer .MuiAccordion-root {
    background-color: #fff !important;
  }
}
.card-deck .card {
  padding-bottom: 23px;
}
@media screen and (min-width: 1550px) {
  .bghjHX {
    height: 287px;
  }
}

@media only screen and (max-width: 500px) {
  .slick-prev {
    left: -40px !important;
  }
  .slick-next {
    right: -40px !important;
  }

  .react-tel-input .form-control {
    width: 100% !important;
  }
  .react-tel-input .country-list {
    width: 260px !important;
  }
}
