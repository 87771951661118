.back1 {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/common-images/Group+3095.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #d2edf5;
  display: grid;
}

.searchInput {
  width: 80%;
  border: none;
  outline: none;
  font-size: 15px;
  opacity: 0.7;
  background-color: white;
}

.sideBar {
  padding: 3% !important;
  background-color: #ffffff;
  height: 100vh;
  position: sticky !important;
  top: 20px;
  width: 20%;
}

.faqContainer {
  width: 80%;
  padding: 50px;
}

.bannerContainer {
  background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/faqbanner.png');
  height: 55vh;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 75px;
  background-position: right;
}

.mobBannerContainer {
  margin-top: 75px;
  width: 100%;
  background-color: #f5f0ec;
}

.mobButton {
  padding: 12px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  background-color: #f5f0ec;
  color: #ce8c95;
  font-weight: 500;
  border: none;
  border: 1.5px solid #ce8c95;
  min-width: 125px;
}

.mobButtonActive {
  padding: 12px;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 15px;
  border-radius: 25px;
  background-color: #ce8c95;
  color: #ffffff;
  font-weight: 900;
  border: none;
  border: 1.5px solid #ce8c95;
  min-width: 125px;
}

.bannerContainerCol1 {
  background-color: #f5f0ec;
}

.text {
  font-size: xx-large;
  font-weight: 400;
  font-family: 'Quicksand';
}

.text1 {
  font-size: medium;
  font-weight: 500;
  font-family: 'Quicksand';
}

.queryForm {
  width: 65%;
}

.formInputs {
  width: 100%;
  color: black;
  border-color: #a5a5a5;
  border: 1px solid #a5a5a5 !important;
  outline-color: #a5a5a5;
  font-size: small;
}

.formInputs1 {
  width: 100%;
  font-size: small;
  color: black;
  border-color: #a5a5a5;
  border: 1px solid #a5a5a5 !important;
  outline-color: #a5a5a5;
}

.formInputs:focus {
  border: 0.5px solid !important;
}

.queryText {
  width: 80%;
}

.queryImageContainer {
  padding: 30px;
}

.mainContainer {
  background-color: rgb(245 240 236 / 35%);
}

.header {
  font-weight: 600;
  width: 200%;
  font-size: 2vw;
  margin-bottom: 4%;
}

.designButton {
  border-radius: 5vw;
  padding: 0.5vw 1vw 0.5vw 1vw;
  color: #ffffff;
  background-color: black;
  float: left;
  font-weight: 800;
  font-size: 1vw;
}

.container1 {
  width: 100%;
  height: 70vh;
  background-color: #ffffff;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.button1 {
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  font-weight: 400;
  padding: 5px 20px 5px 20px !important;
  margin-top: 5px;
}

.button1:hover {
  font-weight: 900;
  background-color: #000000 !important;
}

.button2 {
  border-radius: 30px;
  background-color: transparent;
  color: black;
  padding: 8px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: 30px;
  padding-right: 20px;
  border: solid 1px;
}
@media only screen and (max-width: 600px) {
  .container1 {
    height: auto;
  }

  .queryImageContainer {
    padding: 15px;
  }

  .queryForm {
    width: 90%;
    padding-top: 30px;
  }

  .faqContainer {
    width: 100%;
    padding: 15px;
  }
}
