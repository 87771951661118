.backConfirm {
  background-image: url(https://pixel-mortar.s3.ap-south-1.amazonaws.com/design_dairies/pattern_success.png);
  background-color: #d2edf5;
  /* height: 200px; */
}
.content {
  color: black;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  /* background-image: url("https://d3izcfql7lesks.cloudfront.net/Group_3158_wxbffq.png"); */
  /* height: 200px; */
}

.continue {
  border-radius: 30px;
  background-color: #000000;
  color: #ffffff;
  font-weight: 400;
  padding: 6px 20px 7px 20px !important;
  margin-top: 30px;
}
.placeOrder h5 {
  font-size: 16px;
}
.thanks {
  font-size: 23px;
  font-weight: 600;
}
.order {
  border-radius: 30px;
  background-color: transparent;
  color: black;
  padding: 8px;
  font-weight: 400;
  /* padding-left: 20px; */
  margin-top: 30px;
  /* padding-right: 20px; */
  border: solid 1px;
  padding: 5px 39px;
}
@media only screen and (max-width: 600px) {
  .padd {
    width: 30px;
  }

  .thanks {
    font-size: 20px;
    padding-left: 1%;

    text-align: center;
  }

  .check {
    margin-top: 25% !important;
    text-align: center;
  }

  .email {
    font-size: 14px;
    padding-left: 1%;

    text-align: center;
  }

  .container-order {
    text-align: center;
    min-width: 90% !important;
    max-width: 90% !important;
    background-color: white;
  }
  .getStartedButton {
    border-radius: 30px;

    max-height: 50px !important;
  }
}
