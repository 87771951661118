.center__text {
  position: absolute;
  top: 45%;
  left: 6%;
  /* transform: translate(-50%, -50%); */
  font-size: 50px;
}
.center__text h1 {
  font-size: 70px;
  font-weight: 400;
}
.overlay h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.center__text p {
  font-size: 18px;
  font-weight: 600;
  width: 41%;
  margin-top: 25px;
}
.designTxt {
  position: relative;
  padding: 8% 10%;
  z-index: 1;
}
.designTxt p {
  font-size: 18px;
  font-weight: 600;
  width: 41%;
  margin-top: 25px;
}
.designTxt h1 {
  font-size: 70px;
  font-weight: 400;
}
.designBg {
  height: auto;
  position: relative;
}
.imgBgs {
  width: 100%;
}
.patternIm {
  position: absolute;
  right: 0px;
  top: -87%;
  width: 66%;
}
.container_design {
  width: 100%;
  margin: 0px auto;
  padding: 8% 5%;
  background-color: #f5f0ec;
}
.container_design .designTxt p {
  font-size: 27px;
  font-weight: 500;
  width: 80%;
  margin-top: 25px;
}
.patternChair {
  position: absolute;
  /* width: 63%; */
  z-index: 1;
  right: 0px;
  top: -72px;
  height: 93vh;
}
.mainImg {
  width: 95%;
  margin: 0px auto;
  display: block;
}
.underDesignTxt h1 {
  font-size: 28px;
  font-weight: 600;
}
.underDesignTxt p {
  font-size: 18px;
  font-weight: 500;
  width: 80%;
  margin-top: 14px;
}
.underDesignTxt button {
  color: #000000;
  border: 1px solid #707070;
  padding: 5px 25px;
  border-radius: 18px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  margin-top: 3px;
}
@media screen and (max-width: 500px) {
  .overlay h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }

  .center__text {
    position: absolute;
    top: 20%;
    left: 30%;
    font-size: 20px;
    transform: translate(-50%, -50%);
  }
  .center__text h1 {
    font-size: 25px;
    font-weight: 700;
    padding-top: 109px;
  }
  .center__text p {
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    margin-top: 22px;
    padding: 0px 0px;
  }
  .center__text {
    position: relative;
    top: 0px;
    left: 0px;
    font-size: 20px;
    text-align: center;
    padding: 0px 20px;
    transform: none;
    height: 100vh;
    background: #d2edf5;
  }
  .container {
    overflow: hidden;
  }
  .layerImg {
    width: 153% !important;
    position: absolute;
    bottom: 0px;
    z-index: 1;
    overflow: hidden;
    right: 0%;
  }
  .designTxt h1 {
    font-size: 25px;
    text-align: center;
    font-weight: 600;
  }
  .designTxt {
    position: relative;
    padding: 101px 10%;
    background-color: #f5f0ec;
  }
  .designTxt p {
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    margin-top: 19px;
    text-align: center;
  }
  .patternIm {
    top: -47px;
  }

  .mainImg {
    width: 79%;
    margin: 0px auto;
    display: block;
  }
  .container_design .patternIm {
    top: -68px;
    width: 82%;
  }
  .container_design .designTxt p {
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    margin-top: 19px;
    text-align: center;
  }
  .underDesignTxt {
    margin-top: 78px;
    text-align: center;
  }
  .underDesignTxt p {
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    margin-top: 14px;
  }
  .underDesignTxt button {
    margin-bottom: 76px;
  }
}

@media screen and (min-width: 1900px) {
  .patternChair {
    top: -109px;
    height: 87vh;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .patternChair {
    top: -31px;
    height: 89vh;
  }
}
