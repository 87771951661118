
.mainContainer{
  padding-bottom: 100px;
}

.videoPlayerContainer{
  background-color: #f0f0f0;
  position: relative;
  height: 90vh;
}

.videoPlayerHeading{
  padding-top: 110px;
  font-size: 40px;
  font-weight: 700;
  color: #171717
}

.videoPlayerSubHeading{
  font-size: 16px;
  font-weight: 400;
  color: #171717;
  margin-bottom: 1.5rem;

}

.videoPlayerRow{
  position: absolute;
  bottom:-80px;
}

.videoPlayerWrapper{
  height: 70vh;
}

.stepsRow{
  padding-top: 120px;
  display: flex;
  flex-direction: column;
}

.stepsContainer{
  display: flex;
  justify-content: space-between;
}

.steps{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step{
  position: relative;
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #D8D6D6;;

}

.stepLeft{
  width: 20%;
  padding-right: 0.5rem;
}

.stepNoBox{
  display: flex;
  justify-content: center;
  align-items:center ;
  height: 44px;
  width: 44px;
  padding: 10px;
  border-radius: 4px;
  background-color: #F1EEEA;
  color: #6E6D6B;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.stepNoBoxActive{
  display: flex;
  justify-content: center;
  align-items:center ;
  height: 44px;
  width: 44px;
  padding: 10px;
  border-radius: 4px;
  background-color: #171717;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.stepAnimationLine{
  height: 1px;
  background-color: #171717;
  position: absolute;
  bottom: 0;
}

.stepRight{
  width: 80%;
  display: flex;
  flex-direction: column;
}

.stepTitle{
  color: #171717;
  opacity: 0.6;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.stepTitleActive{
  color: #171717;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.stepSubTitle{
  color: #393939;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.stepsImage{
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1000px){

  .mainContainer{
    padding: 0px 15px 100px 15px;
  }

  .videoPlayerContainer{
    background-color: #ffffff;
  }
  .videoPlayerContainer{
    height: 100%;
  }

  .videoPlayerRow{
    position: relative;
    bottom: 0;
  }

  .videoPlayerWrapper{
    height: 50vh;
  }

  .stepsRow{
    padding-top: 50px;
  }

  .stepsContainer{
    flex-direction: column-reverse;
  }

  .steps{
    width: 100%;
    margin-top: 2rem;
  }

  .stepsImage{
    width: 100%;
    
  }
}

@media only screen and (max-width: 600px){
.videoPlayerWrapper{
  height: 40vh;
}
}


