.filterBudgetCont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 0.5rem;

}

.filterBudgetHeader{
    font-size: 16px;
    font-weight: 400;
    color: #171717;
    margin-bottom: 0.5rem;
}

.filterBudgetSliderCont{
    width: 95%;
}

.filterBudgetRangeCont{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.sliderBox{
    max-width: 120px;
    width: 48%;
    height: 39px;
    background: #FFFFFF;
    border: 0.5px solid #A5A5A5;
    /* margin-right: 0.5rem; */
    padding: 4px;
}

/* .filterText{
    position: absolute;
    background-color: #F8F4F0;
    color: #171717;
    padding: 6px 20px;
    bottom:0;
    right:10px;
    font-size: 11px;
    font-weight: 500;

} */

.sliderBoxInner{
    margin-bottom:2.5px;
    line-height:1;
    font-size: 11px;
    font-weight: 400;
    color:#A5A5A5;
}

.sliderInput{
    font-size: 13px;
    border: none;
    outline: none;
    width: 100%;
    line-height: 1
}

.sliderBoxInnerValue{
    margin:0;
    font-size: 13px;
    font-weight: 500;
    color: #171717;
}

.filterCheckboxCont{
    margin-top: 1.5rem;
}

.filterButtonsCont{
    display: flex;
    width: 100%;
    margin-top: 1.5rem;
    justify-content: space-between
}

.filterClearBtn{
    max-width: 120px;
    width: 46%;
    height: 39px;
    padding: 10px 6px;
    cursor: pointer;
    background-color: #FFF;
    color: #171717;
    border: 1px solid #DEE3EC;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
}

.filterApplyBtn{
    max-width: 120px;
    width: 46%;
    height: 39px;
    padding: 10px 6px;
    cursor: pointer;
    background-color: #171717;
    color: #ffffff;
    border: 1px solid #DEE3EC;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
}